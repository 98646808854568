import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../common/styles/colors";

export const useDataIaStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerIa: {
      marginLeft: "100px",
      position: "relative",
      marginTop: 100,
      width: "92%",
      //paddingRight: 30,
      paddingBottom: 50,
    },
    titleIa: {
      marginLeft: "5px",
      position: "relative",
      marginTop: 50,
      width: "100%",
      paddingBottom: 50,
    },
    box: {
      width: "92%",
    },
    buttonRF: {
      border: "2px solid " + colors.greenDarkButton,
      color: colors.greenDarkButton,
    },
    buttonDisabled: {
      border: "2px solid " + colors.gray,
      color: colors.gray,
    },
    alert: {
      marginTop: "8px",
      marginBottom: "8px",
    },
    divider: {
      width: "100%",
      borderBottom: "1px solid " + colors.lilacMediumLight,
    },
  })
);
