import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useStyles } from './styles/index.styles'
import { Typography } from '@mui/material'

export default function TitleFormNumber({ index, title }: any) {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const styles = useStyles()

  return (
    <Box className={styles.titleBox}>
      <Typography className={styles.titleNumber} sx={{ fontWeight: 'bold' }}>
        {index}
      </Typography>
      <Typography className={styles.title} sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
    </Box>
  )
}
