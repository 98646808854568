import React from "react";
import { useTranslation } from "react-i18next";
import "moment/locale/pt-br";

import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Button, Grid, Icon, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useStyles } from "../../styles/index.styles";
import secureLocalStorage from "react-secure-storage";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import colors from "../../../common/styles/colors";

interface ListItemProps {
  title?: any;
  description?: any;
  btnVariant?: "info" | "warning" | "primary" | "secondary";
  btnTitle?: any;
  urlClick?: any;
  status_event_ended?: boolean;
  iconName?: string;
}

export function ListItem({
  title,
  description,
  iconName,
  btnVariant = "primary",
  btnTitle,
  urlClick,
  status_event_ended,
}: ListItemProps) {
  const styles = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const profile_id = secureLocalStorage.getItem("role");

  const theme = useTheme();

  const handleClickButton = () => {
    if (urlClick) {
      navigate(urlClick);
    }
    return;
  };

  return (
    <Box
      className={styles.containerListItem}
      onClick={handleClickButton}
      // disabled={profile_id === 5 ? status_event_ended : false}
    >
      <Box display={"flex"} width={"100%"} flexDirection={"column"}>
        {iconName && (
          <Box display={"flex"}>
            <Icon
              sx={{
                color: colors.purple,
                fontSize: "2.5vw !important",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "30px !important",
                },
              }}
            >
              {iconName}
            </Icon>
          </Box>
        )}
        <Box width={"100%"}>
          <Typography className={styles.titleCardBox} sx={{ fontWeight: 700 }}>
            {title}
          </Typography>
          <Box className={styles.barListItem} />
        </Box>
      </Box>
      <Box flex={1}>
        <Typography
          className={styles.titleStudentCard}
          textOverflow={"ellipsis"}
        >
          {description}
        </Typography>
      </Box>
      <Box className={styles.barFooter}>
        <Typography
          sx={{
            color: colors.purple,
            fontWeight: 600,
            textTransform: "capitalize",
            fontSize: "1vw",
            [theme.breakpoints.down("sm")]: {
              fontSize: 14,
            },
          }}
        >
          {t("title_dialog_access")}
        </Typography>
      </Box>
    </Box>
  );
}
