import api from "../config/api";
import ISkillData from "../types/Skill";

const getAll = () => {
  return api.get<ISkillData>("skills");
};

const getskillByChecklist = (id: any) => {
  return api.get<ISkillData>(`checklist/${id}/skills`);
};

const getItensChecklist = (checklist_id: any, skill_id: any) => {
  return api.get<ISkillData>(
    `checklist/${checklist_id}/skill/${skill_id}/items`
  );
};

const get = (id: any) => {
  return api.get<ISkillData>("/api/skills/" + id);
};

const update = (data: any, id: any) => {
  return api.put<ISkillData>("/skills/" + id, data);
};

const store = (data: any) => {
  return api.post<ISkillData>("/skills", data);
};

const linkskill = (data: any) => {
  return api.post<ISkillData>("/skills/link", data);
};

const remove = (id: any, checklist_id: any) => {
  return api.delete("/skills/" + id + "/checklist/" + checklist_id);
};

const createmany = (data: any) => {
  return api.post<ISkillData>("/skills/createmany", data);
};

const updatemany = (data: any) => {
  return api.put<ISkillData>("/skills/updatemany", data);
};

const skillService = {
  getAll,
  get,
  getskillByChecklist,
  getItensChecklist,
  store,
  update,
  linkskill,
  remove,
  createmany,
  updatemany,
};

export default skillService;
