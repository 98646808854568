import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDefaultStyles } from '../../common/styles/default'
import { Box, Grid, Typography } from '@mui/material'
import { useStyles } from '../styles/index.styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import GradeBar from './GradeBar'
import DashboardService from '../../services/DashboardService'
import { toast } from 'react-toastify'

export default function GraphStudentGrade({ event, student_id }: any) {
  const { t } = useTranslation()
  const styles = useDefaultStyles()
  const classes = useStyles()

  const [porc, setPorc] = useState(0)

  const loadGraph = async (id: any, student_id: any) => {
    await DashboardService.getAverageEvaluated(id, student_id)
      .then((response: any) => {
        if (response.data.length > 0) {
          setPorc(response.data[0].Nota)
        }
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: 'error',
        })
      })
  }

  useEffect(() => {
    if (event && student_id) {
      loadGraph(event.id, student_id)
    }
  }, [event, student_id])

  return (
    <Grid item xs={5}>
      <Card>
        <CardContent className={classes.cardContainer}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {t('graph_event_title_general_grade')}
          </Typography>
          <Typography variant="h6" sx={{ mt: 1, mb: 3 }}>
            {t('graph_event_subtitle_student_grade')}
          </Typography>
          <Box sx={{ height: 400 }}>
            <Box className={classes.boxGrade}>
              <GradeBar event={event} porc={porc} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}
