import api from '../config/api'
import IStudentsGroupData from '../types/StudentsGroup'
import IEventData from '../types/Event'
import IEventDataList from '../types/Event'

const get = (data: any) => {
  return api.get<IEventData>('/events/' + data)
}

const store = (data: any) => {
  return api.post<IEventData>('/events', data)
}

const listEvents = (id: number) => {
  return api.get<IEventData>('/events' + id)
}

const getAll = (page = 1, paginate = 0) => {
  return api.get<IEventDataList>(
    '/events?page=' + page + '&paginate=' + paginate,
  )
}

const update = (id: any, data: any) => {
  return api.put<IEventData>('/events/' + id, data)
}

const remove = (id: any) => {
  return api.delete<IEventData>('/events/' + id)
}

const getGroup = (id: number) => {
  return api.get<IStudentsGroupData>('entities/studentsgroup/' + id)
}

const getStationsByEvent = (eventId: number) => {
  return api.get('events/stations/' + eventId)
}

const getListByStatus = (status_id = '1', page = 1, paginate = 0) => {
  return api.get<IEventData>(
    '/events/status/' + status_id + '?page=' + page + '&paginate=' + paginate,
  )
}

const getCurrentStatus = (eventId: number) => {
  return api.get('events/currentstatus/' + eventId)
}

const getEventsByEvaluated = (evaluatedId: number) => {
  return api.get('events/evaluated/' + evaluatedId)
}

const getEventEvaluatedByEvent = (eventId: number) => {
  return api.get(`events/${eventId}/evaluated`)
}

const getLiveMonitoringByEvent = (eventId: number) => {
  return api.get(`events/${eventId}/livemonitoring`)
}

const concludeEvent = (eventId: number) => {
  return api.post(`/events/${eventId}/conclude`)
}

const EventsService = {
  get,
  getAll,
  store,
  listEvents,
  update,
  remove,
  getGroup,
  getStationsByEvent,
  getListByStatus,
  getCurrentStatus,
  getEventsByEvaluated,
  getEventEvaluatedByEvent,
  getLiveMonitoringByEvent,
  concludeEvent,
}

export default EventsService
