import React, { useState } from 'react';
import { ReactMic } from 'react-mic';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { Mic, Stop } from '@mui/icons-material';
import colors from '../../../styles/colors';
import './style.css';

export interface AudioRecording {
    blob: Blob;
    startTime: number;
    stopTime: number;
    options: {
        audioBitsPerSecond: number;
        mimeType: string;
    };
    blobURL: string;
}

interface RecorderProps extends React.HTMLAttributes<HTMLDivElement> {
    onSave: (blob: AudioRecording) => void;
    disabled?: boolean;
    loading?: boolean;
}

export const RecorderAudio: React.FC<RecorderProps> = ({ onSave, disabled, loading, ...divProps }) => {
    const [isRecording, setIsRecording] = useState(false);

    const startRecording = () => {
        setIsRecording(true);
    };

    const stopRecording = () => {
        setIsRecording(false);
    };

    const onStop = (recordedBlob: AudioRecording) => {
        onSave(recordedBlob);
    };

    return (
        <div {...divProps}>
            <ReactMic
                record={isRecording}
                onStop={onStop}
                mimeType="audio/mpeg"
                strokeColor="transparent"
                backgroundColor="transparent"
                visualSetting="sinewave"
                className="recorder-audio"
            />
            <IconButton
                aria-label="recorder"
                sx={{ bgcolor: disabled ? colors.purpleLightest : colors.purple, color: "#fff" }}
                size="large"
                onClick={isRecording ? stopRecording : startRecording}
                disabled={!!disabled}
            >
                {loading ? <CircularProgress size={20} /> : isRecording ? <Stop /> : <Mic />}
            </IconButton>
        </div>
    );
};
