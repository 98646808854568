import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { CircularProgress, Stack } from '@mui/material';
import { PlayArrow, Pause, Close } from '@mui/icons-material';
import colors from '../../../styles/colors';

interface AudioPlayerWithWaveformProps {
  audioUrl: string;
  onClickClose?: () => void
  loading?: boolean;
}

export const AudioPlayerWithWaveform: React.FC<AudioPlayerWithWaveformProps> = ({ audioUrl, onClickClose, loading }) => {
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (waveformRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#e2d6d6',
        progressColor: '#f6f5f5',
        height: 10,
        barHeight: 100,
        cursorWidth: 0
      });

      wavesurferRef.current.load(audioUrl);

      wavesurferRef.current.on('finish', () => {
        setIsPlaying(false);
        if (wavesurferRef.current) {
          wavesurferRef.current.stop();
        }
      });

      return () => {
        if (wavesurferRef.current) {
          wavesurferRef.current.destroy();
        }
      };
    }
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      if (isPlaying) {
        wavesurferRef.current.pause();
      } else {
        wavesurferRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Stack
      flexDirection={"row"}
      border={`1px solid ${colors.purple}`}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={182}
      px={1}
      bgcolor={"#C3B2FC"}
      borderRadius={1}
      gap={2}
      position={"relative"}
      aria-disabled={loading}
    >
      {loading && (
        <Stack width={"100%"} position={"absolute"} top={0} bgcolor={"#C3B2FC4a"} zIndex={50}>
          <CircularProgress />
        </Stack>
      )}
      <Stack onClick={handlePlayPause} sx={{color: colors.purple, height: '100%', alignItems: "center", cursor: "pointer"}}>
        {isPlaying ? <Pause /> : <PlayArrow />}
      </Stack>
      <div ref={waveformRef} style={{ flex: 1 }} />
      <Stack onClick={onClickClose} sx={{color: colors.purpleLight, height: '100%', alignItems: "center", cursor: "pointer"}}>
        <Close fontSize='small' />
      </Stack>
    </Stack>
  );
};
