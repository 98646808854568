import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../../../styles/colors";
import { StyleMenuProps } from "../../styles/index.styles";

export const useStyles = makeStyles<Theme, StyleMenuProps>((theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      padding: ({ visibleMenu }) => (visibleMenu ? 0 : "0px 10px"),
      "&:hover": {
        backgroundColor: ({ visibleMenu }) =>
          visibleMenu ? colors.purpleDarkMedium : "transparent",
      },
    },
    content: {
      display: "flex",
      alignItems: "center",
      padding: ({ visibleMenu }) =>
        visibleMenu ? "20px 10px 20px 20px" : "24px 0px",
      justifyContent: ({ visibleMenu }) =>
        visibleMenu ? "flex-start" : "center",
      gap: "10px",
      textDecoration: "none",
      borderRadius: ({ visibleMenu }) => (visibleMenu ? 0 : 50),
      textAlign: "center",
      "&:hover": {
        backgroundColor: colors.purpleDarkMedium,
        // backgroundColor: ({ visibleMenu }) => !visibleMenu ? colors.purple : 'transparent',
      },
    },
    containerSubmenu: {
      display: "flex",
      flexDirection: "column",
      gap: 4,
    },
    Icon: {
      color: colors.green,
      fontSize: 28,
      "&:hover": {
        // backgroundColor: ({ visibleMenu }) => !visibleMenu ? colors.purpleDarkMedium : 'transparent',
      },
    },
    wrapper: {
      display: "flex",
      width: "100%",
      // backgroundColor: 'red',
      justifyContent: "space-between",
      alignItems: "center",
      transition: "1s left,1s all,1s opacity",
    },
    titleMenu: {
      color: colors.white,
      transition: "1s left,1s all,1s opacity",
      cursor: "pointer",
    },
    iconArrow: {
      fontSize: 20,
      color: colors.grayTitlePoints,
      cursor: "pointer",
    },
    submenu: {
      cursor: "pointer",
      zIndex: 50,
      padding: ({ visibleMenu }) =>
        visibleMenu ? "0px 10px 0px 20px" : "0.5px 10px",
      "&:hover": {
        backgroundColor: colors.purpleLight,
      },
    },
    containerTooltip: {
      position: "absolute",
      flex: "none",
      display: "flex",
      textAlign: "left",
      justifyContent: "flex-start",
      flexDirection: "column",
      zIndex: 30,
      // backgroundColor: 'red',
      width: "200%",
    },
    wrapperTooltip: {
      backgroundColor: colors.purpleDark,
      padding: "5px 10px",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: colors.white,
    },
    wrapperTooltipNoSub: {
      backgroundColor: colors.purpleDark,
      padding: "5px 10px",
      borderRadius: 4,
      color: colors.white,
    },
    containerTooltipSubmenus: {
      backgroundColor: colors.bgSubmenuHover,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      padding: "10px 0px",
      // position: 'absolute',
      // display: 'flex',
      // flexDirection: 'column',
      // textAlign: 'left',
      // justifyContent: 'flex-start',
      // right: -120,
      // top: 20,
      // zIndex: 50,
      // backgroundColor: 'red',
      // width: '120%',
    },
  })
);
