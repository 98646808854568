import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import { useDefaultStyles } from '../../common/styles/default'
import secureLocalStorage from 'react-secure-storage'
import { useStyles } from '../styles/index.styles'
import { toast } from 'react-toastify'
import UsersService from '../../services/UsersService'

export default function ChangePassword({ setShowPassword, showPassword }: any) {
  const { t } = useTranslation()
  const styles = useDefaultStyles()
  const auth_user_id = secureLocalStorage.getItem('id')
  const classes = useStyles()

  const [password, setPassword] = useState('')
  const [new_password, setNewPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')

  const cancelPass = () => {
    setPassword('')
    setNewPassword('')
    setConfirmPassword('')
    setShowPassword(false)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (password == '') {
      toast(t('toast_msg_formpass_password'), {
        type: 'error',
      })
      return
    }

    if (new_password == '') {
      toast(t('toast_msg_formpass_newpassword'), {
        type: 'error',
      })
      return
    }

    if (confirm_password == '') {
      toast(t('toast_msg_formpass_confirmpassword'), {
        type: 'error',
      })
      return
    }

    if (new_password != confirm_password) {
      toast(t('toast_msg_formpass_confirmpassworddiff'), {
        type: 'error',
      })
      return
    }

    const dataPass = {
      password: new_password,
    }

    UsersService.resetPassword(auth_user_id, dataPass)
      .then((response: any) => {
        toast(response.data?.Msg, {
          type: 'success',
        })
        setPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setShowPassword(false)
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: 'error',
        })
      })
  }

  useEffect(() => {}, [])

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <Box sx={{ mb: 4, textAlign: 'left' }}>
        <Typography className={classes.titlePassword} sx={{ fontWeight: 700 }}>
          {t('title_change_password')}
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          required
          fullWidth
          id="password"
          name="password"
          type="password"
          label={t('input_label_actual_password')}
          variant="outlined"
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value)
          }}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          required
          fullWidth
          id="new_password"
          type="password"
          name="new_password"
          label={t('input_label_new_password')}
          variant="outlined"
          value={new_password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewPassword(event.target.value)
          }}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          required
          fullWidth
          id="confirm_password"
          type="password"
          name="confirm_password"
          label={t('input_label_confirm_password')}
          variant="outlined"
          value={confirm_password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(event.target.value)
          }}
        />
      </Box>
      <Box sx={{ mt: 4, textAlign: 'right' }}>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          sx={{ mr: 2 }}
          onClick={cancelPass}
        >
          {' '}
          {t('btn_cancel')}
        </Button>
        <Button variant="contained" type="submit" size="large">
          {' '}
          {t('btn_save')}
        </Button>
      </Box>
    </Box>
  )
}
