import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import ItemInstructionFileMulti from './ItemInstructionFileMulti'

export default function FormScenarioExams({ idScenario, feature }: any) {
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {}, [idScenario, feature])

  return (
    <Box>
      {idScenario && (
        <ItemInstructionFileMulti
          name="file_laboratory"
          title={t('input_label_file_laboratory')}
          feature={feature}
          idScenario={idScenario}
        />
      )}
      {idScenario && (
        <ItemInstructionFileMulti
          name="file_image"
          title={t('input_label_file_image')}
          feature={feature}
          idScenario={idScenario}
        />
      )}
    </Box>
  )
}
