import React from 'react'
import hipladeTemplate from '../common/template'
import ErrorPage from '../common/components/errorpage'

function NotFound() {
    
    return(
        <ErrorPage/>
    )
}

export default hipladeTemplate(NotFound)
