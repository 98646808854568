import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'
import { MenuProps } from '../../../dtos/menuDtos'

export type StyleMenuProps = MenuProps

export const useStyles = makeStyles<Theme, StyleMenuProps>((theme) =>
  createStyles({
    containerMenu: {
      height: '100%',
      backgroundColor: colors.purpleDark,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      //justifyContent: 'space-between',
      position: 'fixed',
      zIndex: 3,
      top: 0,
      boxShadow: '0 0 30px rgba(60, 37, 129, 1)',
      transition: '1s left,1s width,1s opacity',
    //[theme.breakpoints.between(0, 960)]: {
        //display: 'none',               
    //},
    [theme.breakpoints.between(0, 1200)]: {
      position: 'initial',  
      width: '100%',              
    },
    //[theme.breakpoints.between(900, 1200)]: {
        //display: 'none',               
    //},
    },
    menuOpen: {
      width: '220px',
    },
    menuClose: {
      width: '96px',
    },
    containerLogo: {
      marginTop: 30,
      width: '100%',
      [theme.breakpoints.between(0, 1200)]: {
      marginTop: 2,             
    },
    },
    containerMenuItens: {
      marginTop: 7, 
      maxHeight: '100%', 
      overflowY: 'auto',
      overflowX: 'hidden',
      transition: '1s left,1s width,1s opacity',
      width: '100%',
      '&::-webkit-scrollbar': {
        width: '0.7em',
        backgroundColor: colors.grayMedium,
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': '0 0 5px 0px rgba(0,0,0,0.17)',
        backgroundColor: colors.grayMedium,
        margin: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.purpleDarkMedium,
        borderRadius: '25px',
      }
    },
    containerMenuBottom: {
      marginTop: 7, 
      width: '100%',
    },
    colorItemMenu: {
      color: colors.green,
    },
    colorItemLogout: {
      color: colors.white,
    },
    linkContent: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      gap: '10px',
      padding: '0px 10px 0px 20px',
      '&:hover': {
        opacity: 0.6
      }
    },
    containerSubMenu: {
      // backgroundColor: colors.purpleDarkMedium,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    buttonOpenMenu: {
      backgroundColor: colors.purpleDarkMedium,
      width: '100%',
      color: colors.purpleMedium,
      paddingTop: 7,
      paddingBottom: 7,
      marginTop: 20,
      marginBottom: 36,
      textAlign: ({ visibleMenu }) => visibleMenu ? 'right !important' : 'center !important' as any, 
      paddingRight: ({ visibleMenu }) => visibleMenu ? 10 : '' as any
    },
    submenu: {
      paddingRight: 0,
      paddingLeft: 20,
      padding: 5,
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colors.purpleLight
      }
    },
    iconQuickGuide: {
      justifyContent: 'space-around',
    },
    boxAvatarMobile: {
      marginTop: 10,
      marginLeft: 14,
      marginBottom: 8,
    }
  }),
)
