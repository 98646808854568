import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../common/styles/colors'
import fonts from '../../../common/styles/fonts'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleCard: {
        color: colors.blueDark,
        textTransform: 'capitalize',
    },
    dividerCard: {
        backgroundColor: colors.purple,
        height: 1,
    },
    wrapper: {
        display: 'flex',
        gap: 120,
        [theme.breakpoints.down('sm')]: {
            gap: 20
        }
    }
  }),
)
