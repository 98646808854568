import * as React from "react";
import { Box } from "@mui/system";
import EventsService from "../../services/EventsService";
import { useStyles } from "../styles/index.styles";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

interface EventStatusProps {
  status: any;
}

export default function EventStatus({ status }: EventStatusProps) {
  const styles = useStyles();
  const [statusClassName, setStatusClassName] = React.useState("");
  const { t } = useTranslation();

  const getClassNameByStatus = () => {
    switch (status) {
      case "concluded":
        setStatusClassName(styles.statusEventEnded);
        break;
      case "in_correction":
        setStatusClassName(styles.statusEventRunning);
        break;
      case "scheduled":
        setStatusClassName(styles.statusEventNext);
        break;
    }

    return;
  };

  React.useEffect(() => {
    getClassNameByStatus();
  }, [status]);

  return (
    <Box>
      <Typography sx={{ fontSize: 25 }}>
        <span style={{ fontWeight: "bold" }} className={statusClassName}>
          {t("status")}{" "}
        </span>
        <span className={statusClassName}>
          {status ? t(status) : t("loading")}
        </span>
      </Typography>
    </Box>
  );
}
