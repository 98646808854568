import React from 'react'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useHomeStyles } from '../styles/home.styles'
import { CardMedia, Divider } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { useTheme } from '@mui/material/styles' // Importação necessária para acessar os breakpoints

export default function CardHomeAnalytics({ title, icon, path, subtitle, image }: any) {
  const navigate = useNavigate()
  const theme = useTheme() // Usando o hook useTheme para acessar os breakpoints
  const classes = useHomeStyles()

  const handleNavigate = (path: any) => {
    navigate(path)
  }

  return (
    <Card
      className={classes.containerCardHomeAnalytics}
      onClick={() => handleNavigate(path)}
    >
      <CardContent>
        {icon}
        <Typography
          variant="h4"
          component="div"
          className={classes.titleCardLink}
          sx={{ fontWeight: 700,
            [theme.breakpoints.between('xs', 'lg')]: { 
              fontSize: '24px', 
            },
          }}
        >
          {title}
        </Typography>
        <Divider className={classes.dividerCardLink} />
        <Typography variant="subtitle1" className={classes.dateEvent}
          sx={{ padding: '3px', marginRight: 5,
            [theme.breakpoints.between('xs', 'lg')]: {
              fontSize: '16px', 
            },
          }}
        >
          {subtitle}
        </Typography>
      </CardContent>
      <CardMedia
        component="img"
        sx={{ width: 400,
          [theme.breakpoints.down('md')]: { 
            width: '95%', // Ajustando a largura da imagem em telas menores ou iguais a 'sm'
            height: '100%',
            borderRadius: 3,
          },
        }}
        image={image}
        alt="image_card"
      />     
      <CardActions></CardActions>
    </Card>
  )
}