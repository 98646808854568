import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import colors from '../../../common/styles/colors'


type Props = {
    title: React.ReactNode
}
export default function TitleAndDivider({ title }: Props) {
  return (
    <Box>
        <Typography
            variant="h5"
            mb={1}
            component="div"
            color={colors.blueDark}
            // textTransform={'capitalize'}
            fontWeight={700}
        >
            {title}
        </Typography>
        <Divider sx={{ backgroundColor: colors.purple, height: 1 }} />
    </Box>
  )
}
