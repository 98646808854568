import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useStyles } from "../styles";

import FormGeneralActions from "./FormGeneralActions";

import CheckListService from "../../services/CheckListService";
import { toast } from "react-toastify";

import TitleForm from "../../common/components/titleform";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { useModal } from "../../common/hooks/useModal";

export default function FormGeneralData({
  name,
  setName,
  setThemeId,
  theme_id,
  themes,
  handleThemeChange,
  openModal,
  setOpenModal,
  valueDid,
  valueHalfDid,
  valueDidWrong,
  valueDidNt,
  setValueDid,
  setValueHalfDid,
  setValueDidWrong,
  setValueDidNt,
  idChecklist,
  loadChecklist,
  loadThemes,
  competence_id,
  handleCompetenceChange,
  loadCompetences,
  competences,
}: any) {
  const { t } = useTranslation();

  const classes = useStyles();

  useEffect(() => {}, [
    name,
    setName,
    setThemeId,
    theme_id,
    themes,
    handleThemeChange,
    openModal,
    setOpenModal,
    valueDid,
    valueHalfDid,
    valueDidWrong,
    valueDidNt,
    setValueDid,
    setValueHalfDid,
    setValueDidWrong,
    setValueDidNt,
    idChecklist,
    competence_id,
    competences,
  ]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id: any) => {
    setOpen(true);
  };

  const addTheme = () => {
    handleOpenModal({
      displayName: "ThemeModal",
      data: { loadThemes: loadThemes, checklist: 1 },
    });
  };

  const addCompetence = () => {
    handleOpenModal({
      displayName: "CompetenceModal",
      data: { loadCompetences: loadCompetences, checklist: 1 },
    });
  };

  const { handleOpenModal, dataModal } = useModal();

  const duplicateChecklist = () => {
    CheckListService.importChecklist(idChecklist)
      .then((response: any) => {
        loadChecklist(response.data.id);
        toast(response?.data?.Msg, {
          type: "success",
        });
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <TitleForm title={t("title_checklist_config")} />
      <Grid container spacing={2} sx={{ mt: 8 }}>
        <Grid item xs={8}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("lbl_form_checklist")}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "right" }}>
          {idChecklist && (
            <Button
              variant="contained"
              size="large"
              sx={{ ml: 2 }}
              onClick={handleClickOpen}
              className={classes.importButton}
            >
              {" "}
              {t("btn_label_import_checklist")}
            </Button>
          )}
        </Grid>
      </Grid>
      <Box className={classes.topBox}>
        <Divider className={classes.divider} />
      </Box>
      <Typography sx={{ mb: 4, mt: 4 }} className={classes.txtConfig}>
        <b>{t("text_checklist_config_1")}</b> {t("text_checklist_config_2")}
      </Typography>
      <Grid container spacing={2} sx={{ mb: 8 }}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            label={t("input_label_theme_checklist")}
            variant="outlined"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("lbl_form_theme")}
          </Typography>
        </Grid>
        <Grid item xs={10} sx={{ textAlign: "right" }}>
          <Button
            variant="contained"
            size="large"
            sx={{ ml: 2 }}
            onClick={addTheme}
            className={classes.importButton}
          >
            {" "}
            {t("btn_label_create_theme")}
          </Button>
        </Grid>
      </Grid>
      <Box className={classes.topBox}>
        <Divider className={classes.divider} />
      </Box>
      <Typography sx={{ mb: 4, mt: 4 }} className={classes.txtConfig}>
        <b>{t("text_checklist_theme_config_1")}</b>{" "}
        {t("text_checklist_theme_config_2")}
      </Typography>

      <Grid container spacing={2} sx={{ mb: 8 }}>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="theme-label">
              {t("input_label_theme_ch")}
            </InputLabel>
            <Select
              labelId="theme-label"
              id="theme_id"
              label={t("input_label_theme_ch")}
              value={theme_id}
              onChange={handleThemeChange}
            >
              {themes.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={2}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("lbl_form_competence")}
          </Typography>
        </Grid>
        <Grid item xs={10} sx={{ textAlign: "right" }}>
          <Button
            variant="contained"
            size="large"
            sx={{ ml: 2 }}
            onClick={addCompetence}
            className={classes.importButton}
          >
            {" "}
            {t("btn_label_create_competence")}
          </Button>
        </Grid>
      </Grid>
      <Box className={classes.topBox}>
        <Divider className={classes.divider} />
      </Box>
      <Typography sx={{ mb: 4, mt: 4 }} className={classes.txtConfig}>
        <b>{t("text_checklist_theme_config_1")}</b>{" "}
        {t("text_checklist_competence_config_2")}
      </Typography>

      <Grid container spacing={2} sx={{ mb: 8 }}>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="theme-label">
              {t("input_label_competencia_ch")}
            </InputLabel>
            <Select
              labelId="theme-label"
              id="competence_id"
              label={t("input_label_competencia_ch")}
              value={competence_id}
              onChange={handleCompetenceChange}
            >
              {competences.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <FormGeneralActions
        valueDid={valueDid}
        valueHalfDid={valueHalfDid}
        valueDidWrong={valueDidWrong}
        valueDidNt={valueDidNt}
        setValueDid={setValueDid}
        setValueHalfDid={setValueHalfDid}
        setValueDidWrong={setValueDidWrong}
        setValueDidNt={setValueDidNt}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("title_dialog_duplicate_checklist")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("description_dialog_duplicate_checklist")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("btn_dialog_no")}</Button>
          <Button
            onClick={() => {
              duplicateChecklist();
              handleClose();
            }}
            autoFocus
          >
            {t("btn_dialog_yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
