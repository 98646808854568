import React from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Modal,
} from "@mui/material";
import { ModalBase } from "../Base";
import { useModal } from "../../../hooks/useModal/index";
import EventsService from "../../../../services/EventsService";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles/index.styles";
import Divider from "@material-ui/core/Divider";

interface EventConclusionModalProps {
  eventId: number;
  onClose: () => void; // Função para fechar o modal após a conclusão do evento
  onConclude: () => void; // Função para indicar que o evento foi concluído
}

export const EventConclusionModal: React.FC<EventConclusionModalProps> = ({
  onClose,
  onConclude,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { dataModal } = useModal();

  const handleConclude = () => {
    onConclude();
    onClose();
  };

  return (
    <Modal open onClose={onClose}>
      <Box className={classes.modalContainer}>
        <Typography variant="h4" component="h2" className={classes.modalTitle}>
          <strong>{t("title_conclusion_of_the_event")}</strong>
        </Typography>
        <Typography className={classes.modalText}>
          {t("first_paragraph_conclusion_of_the_event")}{" "}
          {t("second_paragraph_conclusion_of_the_event")}{" "}
          <strong>{t("strong_description_conclusion_of_the_event")}</strong>.
        </Typography>

        <Divider variant="middle" className={classes.modalDivider} />

        <Box className={classes.modalActions}>
          <Button onClick={onClose} className={classes.backButton}>
            {t("btn_return")}
          </Button>
          <Button onClick={handleConclude} className={classes.concludeButton}>
            {t("btn_complete_event")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EventConclusionModal;
