import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useStationStyle } from "../styles/index.styles";
import AvatarHiplade from "../../common/components/avatarhiplade";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

export default function ItemAppraiser({
  appraiser,
  appraisers_id,
  setAppraisers_id,
}: any) {
  const styles = useStationStyle();
  const { t } = useTranslation();

  const removeAppraiser = () => {
    setAppraisers_id("");
  };
  return (
    <Box className={styles.item}>
      <Box className={styles.itemName}>
        <AvatarHiplade
          nameUser={appraiser?.name}
          size="mini"
          id={appraiser?.id}
        />

        {appraisers_id ? appraiser?.name : t("empty_appraiser")}
      </Box>
      <Box>
        {appraisers_id && (
          <CloseIcon className={styles.close} onClick={removeAppraiser} />
        )}
      </Box>
    </Box>
  );
}
