import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import Button from '@mui/material/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDefaultStyles } from '../../common/styles/default'
import Banner from '../../common/components/banner'
import TabsHiplade from '../../common/components/tabshiplade'
import { Grid } from '@mui/material'
import { useStyles } from '../styles/index.styles'
import EventsService from '../../services/EventsService'
import IEventData from '../../types/Event'
import { toast } from 'react-toastify'
import secureLocalStorage from 'react-secure-storage'
import moment from 'moment'
import 'moment/locale/pt-br'
import EventGraph from './EventGraph'
import EventStudentGraph from './EventStudentGraph'

export default function Event() {
  const navigate = useNavigate()

  const userAuth = {
    id:secureLocalStorage.getItem('id'),
    role: secureLocalStorage.getItem('role')
  }
  

  const { t } = useTranslation()
  const styles = useDefaultStyles()
  const classes = useStyles()
  const { id } = useParams()
  const tabs = [
    { id: '1', title: t('tab_graph_report_event_general') },
    { id: '2', title: t('tab_graph_report_event_students') },
  ]

  const [tab, setTab] = useState(userAuth.role == 5 ? 2 : '1')
  const [event, setEvent] = useState<IEventData>()

  const backEvent = () => {
    navigate('/events/view/' + id)
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const loadEvent = async (id: any) => {
    await EventsService.get(id)
      .then((response: any) => {
        setEvent(response.data)
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: 'error',
        })
      })
  }

  useEffect(() => {
    if (id) {
      loadEvent(id)
    }
  }, [])

  return (
    <Box>
      <Banner
        type="student"
        title={event?.name}
        subtitle={event?.studentsgroup_name}
        subtitle_second={
          t('dashboard_banner_label') +
          moment(event?.date).locale('pt-br').format('DD/MM/YYYY')
        }
        button={
          <Button variant="contained" color="secondary" onClick={backEvent}>
            {t('btn_return')}
          </Button>
        }
      />
      <Box className={classes.containerTabMenu}>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            {userAuth.role != 5 && (
              <TabsHiplade
                status_id={tab}
                handleChangeTab={handleChangeTab}
                tabs={tabs}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box className={styles.containerForm}>
        {userAuth.role === 5 ? (
          <EventStudentGraph isStudent={userAuth as any} event={event} />
        ) : (
          <>
            {tab === '1' && <EventGraph event={event} />}
            {tab === '2' && <EventStudentGraph event={event} />}
          </>
        )}
      </Box>
    </Box>
  )
}
