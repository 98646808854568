import * as React from "react";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Divider, Grid } from "@mui/material";
import { useStyles } from "../../events/styles/index.styles";
import { useTranslation } from "react-i18next";
import MyEventStatus from "./MyEventStatus";
import moment from "moment";
import "moment/locale/pt-br";

export default function General({ event }: any) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [eventData, setEventData] = React.useState(event.event);

  React.useEffect(() => {}, [event]);

  return (
    <Box className={styles.container}>
      <Typography
        variant="h4"
        component="div"
        className={styles.titleCard}
        sx={{ fontWeight: 700 }}
      >
        {t("overview_data")}
      </Typography>
      <Divider className={styles.dividerCard} />

      <Box className={styles.container}>
        <MyEventStatus status={event?.status} />
        <Grid
          sx={{ mt: 3, mb: 4 }}
          container
          spacing={3}
          className={styles.eventOverviewBody}
        >
          <Grid item xs={4}>
            <Typography variant="h6">
              <strong>{t("label_form_event_date")}: </strong>
              {moment(event?.date).locale("pt-br").format("DD/MM/YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">
              <strong>{t("table_label_entity")}: </strong>
              {event?.entity_name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">
              <strong>{t("input_label_studentsgroup")}: </strong>
              {event?.studentsgroup_name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
