import React from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDebriefingStyles } from "../../styles/debriefing.styles";

import { Typography } from "@mui/material";

export default function SkillTitleView({ index, title }: any) {
  const { t } = useTranslation();
  const debriefingStyle = useDebriefingStyles();

  return (
    <Box className={debriefingStyle.titleBoxChecklist}>
      <Typography
        className={debriefingStyle.titleNumberskill}
        sx={{ fontWeight: "bold" }}
      >
        {index < 10 ? `0${index}` : index}/
      </Typography>
      <Typography
        className={debriefingStyle.titleskill}
        sx={{ fontWeight: "bold" }}
      >
        {title}
      </Typography>
    </Box>
  );
}
