import React from 'react';
import { Chip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import colors from '../../common/styles/colors';

interface VideoTagProps {
  label: string;
  onDelete: () => void;
}

const VideoTag: React.FC<VideoTagProps> = ({ label, onDelete }) => {
  return (
    <Chip
      label={label}
      onDelete={onDelete}
      deleteIcon={<CancelIcon sx={{color: colors.purpleTextButton}} />}
      sx={{
        backgroundColor: 'transparent',
        color: colors.purpleTextButton,
        borderRadius: '20px',
        fontSize: '14px',
        height: '30px',
        padding: '0 10px',
        border: '1px solid '+ colors.purpleTextButton
      }}
    />
  );
};

export default VideoTag;
