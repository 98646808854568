import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDebriefingVideoLiveStyles } from "../../styles/debriefing-video-live";
import DebriefingVideoMeetingLive from "./DebriefingVideoMeetingLive";
import AvatarHiplade from "../../../common/components/avatarhiplade";
import Banner from "../../../common/components/banner";
import UsersService from "../../../services/UsersService";
import StationsService from "../../../services/StationsService";

export default function DebriefingMeetingLive() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useDebriefingVideoLiveStyles();

  const { eventId, stationId } = useParams();
  const [student, setStudent] = useState({} as any);
  const [station, setStation] = useState({} as any);

  const handleBack = () => {
    navigate(-1);
  };

  const loadStation = async (stationId: any) => {
    await StationsService.get(stationId)
      .then((response: any) => {
        if (response) {
          setStation(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (stationId) {
      loadStation(stationId);
    }
  }, []);

  return (
    <Box>
      <Banner
        type="student"
        title={station?.name + " / " + station?.scenario_name}
        button={
          <Button
            className={classes.buttonBanner}
            variant="contained"
            color="secondary"
            onClick={handleBack}
          >
            {t("btn_return")}
          </Button>
        }
      />

      <DebriefingVideoMeetingLive stationId={stationId} />
    </Box>
  );
}
