import React, { useState, useEffect } from 'react'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { useStyles } from './styles/index.styles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import facebook from '../../../assets/images/facebook.png'
import instagram from '../../../assets/images/instagram.png'
import twitter from '../../../assets/images/twitter.png'
import linkedin from '../../../assets/images/linkedin.png'
import logo_hipocampus from '../../../assets/images/hipocampus_logo.png'
import LoginService from '../../../services/LoginService'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import useLayoutStyles from '../../hooks/useLayoutStyles'

export default function Footer({ showMenu }: any) {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const [versionBackend, setVersionBackend] = useState('')
  const versionFrontend = process.env.REACT_APP_VERSION

  const navigate = useNavigate()

  // Verifique a correspondência da media query para md e lg
  const isTwoColumns = useMediaQuery(`(min-width: ${theme.breakpoints.values.md}px) and (max-width: ${theme.breakpoints.values.lg}px)`);

  const { menuSideBarWidth, contentContainerMarginLeft } = useLayoutStyles()
  // Defina o layout de duas colunas se a correspondência for verdadeira
  const columnLayout = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  };

  // Função para buscar a versão do backend
  const getVersion = () => {
    LoginService.version()
      .then((response: any) => {
        setVersionBackend(response.data.version);
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    getVersion()
  }, [])

  return (
    <Box 
      className={classes.containerFooter} 
      sx={{ flexDirection: 'column', alignItems: 'center',
            [theme.breakpoints.between(900, 1200)]: { //breakpoint para expandir em 100% o footer entre 900px a 960px
              marginLeft: 0,           
            },
          }}
      ml={contentContainerMarginLeft+"px"}
    >
      <CssBaseline />
      <Box
        className={classes.boxContainer}
        //sx={{ marginLeft: `${showMenu ? '270px' : '130px'}` }}
        sx={{      
          display: 'flex',
          justifyContent: 'space-evenly',
          flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' },
          alignItems: 'start',
          width: '84%',
          paddingX: { xs: 2, sm: 2, md: 2, xl: 2 },
          paddingTop: 4,
          paddingBottom: 4, 
          // Aplicar layout de duas colunas apenas se for verdadeiro
          ...(isTwoColumns && columnLayout),        
        }}
      >         
        <Box sx={{ marginBottom: { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 } }}>
          <img src={logo_hipocampus} style={{ width: '170px' }} />
        </Box>
        <Box sx={{ marginBottom: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 } }}>
          <Typography className={classes.textFooter} sx={{ fontSize: 14 }}>
            {t('footer_politic_title')}
          </Typography>
          <Typography className={classes.textFooter} sx={{ fontSize: 14 }}>
            {t('footer_politic_subtitle_1')}
          </Typography>
          <Typography className={classes.textFooter} sx={{ fontSize: 14 }}>
            {t('footer_politic_subtitle_2')}
          </Typography>
        </Box>        
        <Box sx={{ marginBottom: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4} }}>
          <Typography className={classes.textFooter} sx={{ fontSize: 14 }}>
            {t('footer_version_title')} 
          </Typography>
          <Typography
            sx={{
              fontSize: 10,
            }}
          >
            {t('footer_version_front')} {versionFrontend}
          </Typography>
          <Typography
            sx={{
              fontSize: 10,
            }}
          >
            {t('footer_version_backend')} {versionBackend}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.textFooter} sx={{ fontSize: 14 }}>
            {t('footer_midia_title')}
          </Typography>
          <Box className={classes.boxIconMedia} sx={{ marginTop: { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }} }>
            <Link href="#">
              <img src={facebook} />
            </Link>
            <Link href="#">
              <img src={instagram} />
            </Link>
            <Link href="#">
              <img src={twitter} />
            </Link>
            <Link href="#">
              <img src={linkedin} />
            </Link>
          </Box>
        </Box>        
      </Box>
    </Box>
  )
}