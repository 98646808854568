import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EntitiesService from "../../services/EntitiesService";
import RoomsService from "../../services/RoomsService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/event_room_banner.png";
import TitleForm from "../../common/components/titleform";

import IEntityData from "../../types/Entities";
import { useDefaultStyles } from "../../common/styles/default";
import { Grid } from "@mui/material";
import CameraList from "./CameraList";

export default function FormRoom() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const styles = useDefaultStyles();

  const { id } = useParams();

  const [name, setName] = useState("");
  const [entity_id, setEntityId] = useState("");
  const [camera_name, setCameraName] = useState("#");
  const [camera_url, setCameraUrl] = useState("#");
  const [entities, setEntities] = useState<IEntityData[]>([]);
  const [idRoom, setIdRoom] = useState(!id ? "" : id);

  const handleEntity = (event: SelectChangeEvent) => {
    setEntityId(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    if (name == "") {
      toast(t("toast_msg_formroom_name"), {
        type: "error",
      });
      return;
    }

    if (entity_id == "") {
      toast(t("toast_msg_formroom_entity"), {
        type: "error",
      });
      return;
    }

    const dataEntities = {
      name: name,
      camera_name: camera_name,
      camera_url: camera_url,
      entity_id: entity_id,
    };

    if (!idRoom) {
      RoomsService.store(dataEntities)
        .then((response: any) => {
          setIdRoom(response.data.id);
          toast(t("toast_description_store_form_room"), {
            type: "success",
          });
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    } else {
      RoomsService.update(id, dataEntities)
        .then((response: any) => {
          toast(t("toast_description_update_form_room"), {
            type: "success",
          });
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const loadRoom = async (id: any) => {
    await RoomsService.get(id)
      .then((response: any) => {
        if (response) {
          setName(response.data.name);
          setCameraName(response.data.camera_name);
          setCameraUrl(response.data.camera_url);
          setEntityId(response.data.entity_id);
        }
      })
      .catch((e: Error) => {});
  };

  const loadEntities = async () => {
    await EntitiesService.getAll()
      .then((response: any) => {
        if (response) {
          setEntities(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    loadEntities();
    if (idRoom) {
      loadRoom(idRoom);
    }
  }, []);

  return (
    <Box>
      <Banner
        type="medium"
        image={banner}
        title={t("banner_title_event_room")}
        subtitle={t("banner_subtitle_event_room")}
      />
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{
          height: "1000px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box className={styles.containerForm}>
          <TitleForm title={t("subtitle_form_room")} />

          <Grid container spacing={2} sx={{ mb: 0 }}>
            <Grid item xs={8}>
              <TextField
                required
                fullWidth
                id="name"
                name="name"
                label={t("input_label_name_room")}
                variant="outlined"
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="owner-label">
                  {t("input_label_entity_room")}
                </InputLabel>
                <Select
                  labelId="owner-label"
                  label={t("input_label_entity_room")}
                  value={entity_id}
                  onChange={handleEntity}
                >
                  {entities.map((row, index) => (
                    <MenuItem key={index} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {idRoom && <TitleForm title={t("subtitle_form_camera")} />}
          {idRoom && <CameraList id={idRoom} />}
        </Box>
        <Box className={styles.containerButton}>
          <Button type="submit" variant="contained" size="large">
            {" "}
            {t("btn_save_and_conclude")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
