import api from "../config/api";
import ISkillItemData from "../types/SkillItem";

const createmany = (data: any) => {
  return api.post<ISkillItemData>("/items/createmany", data);
};

const updatemany = (data: any) => {
  return api.put<ISkillItemData>("/items/updatemany", data);
};

const remove = (id) => {
  return api.delete("/items/" + id);
};

const skillItemService = {
  createmany,
  updatemany,
  remove,
};

export default skillItemService;
