import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { useStyles } from '../styles/index.styles'

import { Divider, Link } from '@mui/material'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

import moment from 'moment'
import 'moment/locale/pt-br'
import secureLocalStorage from 'react-secure-storage'

export default function ScheduleListCard({
  title,
  date,
  index,
  status_id,
  event_id,
  status,
}: any) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const classes = useStyles()

  const role = secureLocalStorage.getItem("role");

  const openEvent = (event_id: any) => {
    if (role == 5) {
      navigate('/myevents/view/' + event_id)
    } else {
      navigate('/events/view/' + event_id)
    }
  }

  if(status == 'in_correction') {
    status = 'status_event_running'
  }

  return (
    <Card
      className={classes.containerCard}
      key={index}
      onClick={() => openEvent(event_id)}
    >
      <CardContent>
        <Typography
          variant="caption"
          className={classes.breadCrumbCard}
          sx={{ fontWeight: 700 }}
        >
          {t('lbl_card_event_schedule')}
        </Typography>
        <Typography
          variant="h5"
          component="div"
          className={classes.titleCard}
          sx={{ fontWeight: 700 }}
        >
          {title}
        </Typography>
        <Divider className={classes.dividerCard} />
        <Typography variant="subtitle1" className={classes.dateEvent}>
          <b>{t('lbl_card_event_schedule_date')}</b>{' '}
          {moment(date).locale('pt-br').format('DD/MM/YYYY')}
        </Typography>
        <Typography variant="body2">
          <b
            className={
              status_id == '1'
                ? classes.statusLabelEventNext
                : status_id == '2'
                ? classes.statusLabelEventRunning
                : classes.statusLabelEventEnded
            }
          >
            {t('lbl_card_event_schedule_status')}
          </b>{' '}
          <span
            className={
              status_id == '1'
                ? classes.statusEventNext
                : status_id == '2'
                ? classes.statusEventRunning
                : classes.statusEventEnded
            }
          >
            {/*status_id == '1'
              ? t('status_event_next')
              : status_id == '2'
              ? t('status_event_running')
              : t('status_event_ended')*/}
              {t(status)}
          </span>
        </Typography>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  )
}
