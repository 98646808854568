import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DebriefingService from "../../../services/DebriefingService";
import { useGeneralPerformanceAStyles } from "./styles/general-performance.styles";
import GeneralPerformanceBar from "./GeneralPerformanceBar";
import GeneralPerformanceLabel from "./GeneralPerformanceLabel";
import colors from "../../../common/styles/colors";
import { FooterResult } from "./FooterResult";
import { useTranslation } from "react-i18next";

interface GeneralPerformanceBoxProps {
  result?: any;
}

export default function GeneralPerformanceBox({
  result,
}: GeneralPerformanceBoxProps) {
  const { t } = useTranslation();
  const styleGenenralPerformance = useGeneralPerformanceAStyles();
  const [porc, setPorc] = useState("0");

  useEffect(() => {}, []);

  return (
    <Box className={styleGenenralPerformance.container}>
      <Box className={styleGenenralPerformance.boxPerformance}>
        <Typography
          sx={{ fontWeight: "bold" }}
          className={styleGenenralPerformance.subtitle}
        >
          {t("title_dialog_overall_event_performance")}
        </Typography>
        <GeneralPerformanceLabel porc={result} />
        <GeneralPerformanceBar porc={result <= 100 ? result : 100} />
        {/* <FooterResult score={result} /> */}
      </Box>
    </Box>
  );
}
