import api from '../config/api'
import IScenarioData from '../types/Scenario'
import IScenarioList from '../types/Scenario'

const store = (data: any) => {
  return api.post<IScenarioData>('/scenarios', data)
}

const getScenarioById = (scenarioId) => {
  return api.get('/scenarios/' + scenarioId)
}

const getAll = (page = 1, paginate = 0) => {
  return api.get<IScenarioList>(
    '/scenarios?page=' + page + '&paginate=' + paginate,
  )
}

const update = (id: any, data: any) => {
  return api.put<IScenarioData>('/scenarios/' + id, data)
}

const remove = (id: any) => {
  return api.delete('/scenarios/' + id)
}

const importScenario = (id: any) => {
  return api.get<IScenarioData>(`/scenarios/${id}/import`)
}

const ScenarioService = {
  store,
  getScenarioById,
  getAll,
  remove,
  update,
  importScenario,
}

export default ScenarioService
