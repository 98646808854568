import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useDefaultStyles } from "../../common/styles/default";
import { useThemeStyle } from "../styles/index.styles";

import { useModal } from "../../common/hooks/useModal";
import ThemesService from "../../services/ThemesService";

import IAreaData from "../../types/Area";
import ISubAreaData from "../../types/SubArea";
import ISpecialtyData from "../../types/Specialty";

import AreaService from "../../services/AreaService";
import SubAreaService from "../../services/SubAreaService";
import SpecialtyService from "../../services/SpecialtyService";

export default function FormTheme() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const styles = useDefaultStyles();
  const classes = useThemeStyle();

  const [name, setName] = useState("");
  const [area_id, setAreaId] = useState("");
  const [subarea_id, setSubareaId] = useState("");
  const [specialty_id, setSpecialty] = useState("");

  const [areas, setAreas] = useState<IAreaData[]>([]);
  const [subareas, setSubAreas] = useState<ISubAreaData[]>([]);
  const [specialties, setSpecialties] = useState<ISpecialtyData[]>([]);

  const handleArea = (event: SelectChangeEvent) => {
    setAreaId(event.target.value);
  };

  const handleSubArea = (event: SelectChangeEvent) => {
    setSubareaId(event.target.value);
  };

  const handleSpecialty = (event: SelectChangeEvent) => {
    setSpecialty(event.target.value);
  };

  const loadAreas = async () => {
    await AreaService.getAll()
      .then((response: any) => {
        if (response) {
          setAreas(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadSubAreas = async () => {
    await SubAreaService.getAll()
      .then((response: any) => {
        if (response) {
          setSubAreas(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadSpecialties = async () => {
    await SpecialtyService.getAll()
      .then((response: any) => {
        if (response) {
          setSpecialties(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const handleSubmit = () => {
    if (name == "") {
      toast(t("toast_description_name_camera"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }
    if (area_id == "") {
      toast(t("toast_msg_formscenaro_area"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }
    if (subarea_id == "") {
      toast(t("toast_msg_theme_subarea"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }
    if (specialty_id == "") {
      toast(t("studentsgroups"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    const dataTheme = {
      name: name,
      area_id: area_id,
      specialty_id: specialty_id,
      subarea_id: subarea_id,
    };
    if (!dataModal?.data?.idTheme) {
      ThemesService.store(dataTheme)
        .then((response: any) => {
          if (response?.data?.Msg) {
            if (dataModal?.data?.checklist == 0) {
              dataModal?.data?.loadThemes(1, 1);
            } else {
              dataModal?.data?.loadThemes(1, 0);
            }
            toast(response?.data?.Msg, {
              type: "success",
            });
            onCloseModalReset();
          }
        })
        .catch((e: any) => {
          handleStopSaveModal();
          toast(e?.response?.data?.Error, {
            type: "error",
          });
          return;
        });
    } else {
      ThemesService.update(dataModal?.data?.idTheme, dataTheme)
        .then((response: any) => {
          if (response?.data?.Msg) {
            if (dataModal?.data?.checklist == 0) {
              dataModal?.data?.loadThemes(1, 1);
            } else {
              dataModal?.data?.loadThemes(1, 0);
            }
            toast(response?.data?.Msg, {
              type: "success",
            });
            onCloseModalReset();
          }
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const loadTheme = async (id: any) => {
    await ThemesService.get(id)
      .then((response: any) => {
        if (response) {
          setName(response.data.name);
          setAreaId(response.data.area_id);
          setSubareaId(response.data.subarea_id);
          setSpecialty(response.data.specialty_id);
        }
      })
      .catch((e: Error) => {});
  };

  const {
    dataModal,
    onCloseModalReset,
    handleChangedModal,
    handleStopSaveModal,
  } = useModal();

  useEffect(() => {
    loadAreas();
    loadSpecialties();
    loadSubAreas();
    if (dataModal?.data?.idTheme) {
      loadTheme(dataModal?.data?.idTheme);
    }
    if (dataModal.loadingBtnSave) {
      handleSubmit();
    }
  }, [dataModal.loadingBtnSave]);

  return (
    <Box className={classes.modalContainer}>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Box>
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            label={t("input_label_theme_specialty")}
            variant="outlined"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
            sx={{ mb: 3 }}
          />

          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="type-label">{t("table_theme_label_area")}</InputLabel>
            <Select
              labelId="type-label"
              label={t("table_theme_label_area")}
              value={area_id}
              onChange={handleArea}
            >
              {areas.map((row, index) => (
                <MenuItem key={index} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="type-label">{t("title_dialog_subArea")}</InputLabel>
            <Select
              labelId="type-label"
              label={t("title_dialog_subArea")}
              value={subarea_id}
              onChange={handleSubArea}
            >
              {subareas.map((row, index) => (
                <MenuItem key={index} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="type-label">{t("table_theme_label_specialty")}</InputLabel>
            <Select
              labelId="type-label"
              label={t("table_theme_label_specialty")}
              value={specialty_id}
              onChange={handleSpecialty}
            >
              {specialties.map((row, index) => (
                <MenuItem key={index} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}
