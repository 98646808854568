import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDebriefingStyles } from "../../styles/debriefing.styles";
import SkillItem from "./SkillItem";
import skillService from "../../../services/SkillService";

export default function SkillItemListView({
  eventId,
  stationId,
  evaluatedId,
  debriefingId,
  checklistId,
  skillId,
}: any) {
  const { t } = useTranslation();
  const debriefingStyle = useDebriefingStyles();

  const [item, setItem] = useState<any[]>([]);

  const loadskillsItens = async (checklistId: any, skillId: any) => {
    await skillService
      .getItensChecklist(checklistId, skillId)
      .then((response: any) => {
        if (response) {
          setItem(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (checklistId && skillId) {
      loadskillsItens(checklistId, skillId);
    }
  }, [checklistId, skillId, debriefingId]);

  return (
    <Box>
      {item.map((item, index) => (
        <SkillItem
          key={index}
          item={item}
          eventId={eventId}
          stationId={stationId}
          evaluatedId={evaluatedId}
          debriefingId={debriefingId}
          checklistId={checklistId}
          skillId={skillId}
        />
      ))}
    </Box>
  );
}
