import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Divider from "@material-ui/core/Divider";
import { FormControl, Grid, InputLabel, MenuItem } from "@mui/material";

import FormScenarioArea from "./FormScenarioArea";
import FormScenarioskill from "./FormScenarioCompotence";
import FormScenarioCharacteristics from "./FormScenarioCharacteristics";

import ScenarioService from "../../services/ScenarioService";
import MetricsService from "../../services/MetricsService";

import { useDefaultStyles } from "../../common/styles/default";

import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/event_scenario_banner.png";

import { useStyles } from "../styles/index.styles";
import { useScenarioStyles } from "../styles/scenarios.styles";

import TitleFormNumber from "../../common/components/titleformnumber";

import TabsHiplade from "../../common/components/tabshiplade";

import FormScenarioHistoryHealth from "./FormScenarioHistoryHealth";
import FormScenarioPrescriptions from "./FormScenarioPrescriptions";
import FormScenarioHistoryOfVitalSigns from "./FormScenarioHistoryOfVitalSigns";
import FormScenarioExams from "./FormScenarioExams";
import FormScenarioInstructionsFiles from "./FormScenarioInstructionsFiles";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MAXLENGTH } from "../constants";
import { useModal } from "../../common/hooks/useModal";

import ThemesService from "../../services/ThemesService";
import IThemeList from "../../types/Themes";
import ICompetenceList from "../../types/Competence";

import CompetenceService from "../../services/CompetenceService";
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';

export default function Form() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { handleOpenModal, dataModal } = useModal();

  const styles = useDefaultStyles();
  const classes = useStyles();
  const scenarioStyle = useScenarioStyles();

  const { id } = useParams();

  const handleCompetenceChange = (event: SelectChangeEvent) => {
    setCompetenceId(event.target.value);
  };

  const skillOptions = [
    "Habilidades Clínicas",
    "Procedimentos Práticos",
    "Investigação do Paciente",
    "Gestão de Pacientes",
    "Promoção da Saúde e Prevenção de Doenças",
    "Comunicação",
    "Tratamento de informações",
    "Compreensão das Ciências Básicas e Clínicas",
    "Atitudes e Ética",
    "Tomada de Decisão / Raciocínio Clínico",
    "Papel do médico",
    "Desenvolvimento pessoal",
  ];

  const [idScenario, setIdScenario] = useState(id);
  const [title, setTitle] = useState("");
  const [theme_id, setThemeId] = useState("");
  const [area_id, setAreaId] = useState("");
  const [subarea_id, setSubareaId] = useState("");
  const [specialty_id, setSpecialtyId] = useState("");
  const [dcn_id, setDcnId] = useState("");
  const [theme_specialty, setThemeSpecialty] = useState("");
  const [skill, setskill] = useState("");
  const [complexity, setComplexity] = useState("");
  const [environment, setEnvironment] = useState("");
  const [sex, setSex] = useState("");
  const [time_duration, setTimeDuration] = useState("");
  const [age, setAge] = useState("");
  const [goal_scene, setGoalScene] = useState("");
  const [description_scene, setDescriptionScene] = useState("");
  const [illness, setIllness] = useState("");
  const [allergy, setAllergy] = useState("");
  const [habits, setHabits] = useState("");
  const [surgeries, setSurgeries] = useState("");
  const [family_health_history, setFamilyHealthHistory] = useState("");
  const [continuous_use_medications, setContinuousUseMedications] =
    useState("");
  const [temporary_medications, setTemporaryMedications] = useState("");
  const [health_guidelines, setHealthGuidelines] = useState("");
  const [physical_exam, setPhysicalExam] = useState("");
  const [history_vital_sign, setHistoryVitalSign] = useState<any[]>([]);
  const [dataExam1, setDataExam1] = useState("");
  const [dataExam2, setDataExam2] = useState("");
  const [dataExam3, setDataExam3] = useState("");
  const [competences, setCompetences] = useState<ICompetenceList[]>([]);
  const [competence_id, setCompetenceId] = useState("");

  const [themes, setThemes] = useState<IThemeList[]>([]);

  const handleAreaChange = (event: SelectChangeEvent) => {
    setAreaId(event.target.value);
  };

  const addTheme = () => {
    handleOpenModal({
      displayName: "ThemeModal",
      data: { loadThemes: loadThemes, checklist: 1 },
    });
  };

  const addCompetence = () => {
    handleOpenModal({
      displayName: "CompetenceModal",
      data: { loadCompetences: loadCompetences, checklist: 1 },
    });
  };

  const handleThemeChange = (event: SelectChangeEvent) => {
    setThemeId(event.target.value);
  };

  const handleEnvironmentChange = (event: SelectChangeEvent) => {
    setEnvironment(event.target.value);
  };

  const handleComplexityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setComplexity((event.target as HTMLInputElement).value);
  };

  const handleSexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSex((event.target as HTMLInputElement).value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (title == "") {
      toast(t("toast_msg_formscenaro_title"), {
        type: "error",
      });
      return;
    }

    if (theme_id == "") {
      toast(t("toast_msg_formscenaro_theme"), {
        type: "error",
      });
      return;
    }

    if (competence_id == "") {
      toast(t("toast_msg_formscenaro_competence"), {
        type: "error",
      });
      return;
    }

    if (complexity == "") {
      toast(t("toast_msg_formscenaro_complexity"), {
        type: "error",
      });
      return;
    }

    if (environment == "") {
      toast(t("toast_msg_formscenaro_environment"), {
        type: "error",
      });
      return;
    }

    if (sex == "") {
      toast(t("toast_msg_formscenaro_gender"), {
        type: "error",
      });
      return;
    }

    if (time_duration == "") {
      toast(t("toast_msg_formscenaro_time_duration"), {
        type: "error",
      });
      return;
    }

    if (age == "") {
      toast(t("toast_msg_formscenaro_age"), {
        type: "error",
      });
      return;
    }

    if (goal_scene == "") {
      toast(t("toast_msg_formscenaro_goal_scene"), {
        type: "error",
      });
      return;
    }

    if (description_scene == "") {
      toast(t("toast_msg_formscenaro_description_scene"), {
        type: "error",
      });
      return;
    }

    const arrHistoryVital = formatArrHistoryVital();

    const dataScenario = {
      name: title,
      theme_id: theme_id,
      complexity: complexity,
      environment: environment,
      sex: sex,
      time_duration: time_duration,
      age: age,
      goal_scene: goal_scene,
      description_scene: description_scene,
      illness: illness,
      allergy: allergy,
      habits: habits,
      surgeries: surgeries,
      family_health_history: family_health_history,
      continuous_use_medications: continuous_use_medications,
      temporary_medications: temporary_medications,
      health_guidelines: health_guidelines,
      history_vital_sign: arrHistoryVital,
      physical_exam: physical_exam,
      competence_id: competence_id,
    };

    if (!idScenario) {
      ScenarioService.store(dataScenario)
        .then((response: any) => {
          setIdScenario(response.data.id);
          loadMetrics();
          toast(response.data.Msg, {
            type: "success",
          });
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    } else {
      ScenarioService.update(idScenario, dataScenario)
        .then((response: any) => {
          loadMetrics();
          toast(response.data.Msg, {
            type: "success",
          });
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const loadMetrics = async (id = idScenario) => {
    await MetricsService.getAll()
      .then((response: any) => {
        if (response) {
          let arrMetric: any[] = [];
          response.data?.map((item: any, index: any) => {
            const dataMetric = {
              id: item?.id,
              title: item?.title,
              unit: item?.unit_of_measurement,
              exam_1: { id: "", value: "" },
              exam_2: { id: "", value: "" },
              exam_3: { id: "", value: "" },
            };

            arrMetric.push(dataMetric);
          });

          if (id) {
            loadScenario(arrMetric, id);
          }

          setHistoryVitalSign(arrMetric);
        }
      })
      .catch((e: Error) => {});
  };

  const loadScenario = async (arrMetrics: any, id: any) => {
    await ScenarioService.getScenarioById(id)
      .then((response: any) => {
        if (response) {
          setTitle(response.data.name);
          setThemeId(response.data.theme_id);
          setCompetenceId(response.data.competence_id);
          setAreaId(response.data.area_id);
          setSubareaId(response.data.subarea_id);
          setSpecialtyId(response.data.specialty_id);
          setThemeSpecialty(response.data.theme_specialty);
          setskill(response.data.skill);
          setDcnId(response.data.dcn_id);
          setComplexity(response.data.complexity);
          setEnvironment(response.data.environment);
          setSex(response.data.sex);
          setTimeDuration(response.data.time_duration);
          setAge(response.data.age);
          setGoalScene(response.data.goal_scene);
          setDescriptionScene(response.data.description_scene);
          setIllness(!response.data.illness ? "" : response.data.illness);
          setAllergy(!response.data.allergy ? "" : response.data.allergy);
          setHabits(!response.data.habits ? "" : response.data.habits);
          setSurgeries(!response.data.surgeries ? "" : response.data.surgeries);
          setFamilyHealthHistory(
            !response.data.family_health_history
              ? ""
              : response.data.family_health_history
          );
          setContinuousUseMedications(
            !response.data.continuous_use_medications
              ? ""
              : response.data.continuous_use_medications
          );
          setTemporaryMedications(
            !response.data.temporary_medications
              ? ""
              : response.data.temporary_medications
          );
          setHealthGuidelines(
            !response.data.health_guidelines
              ? ""
              : response.data.health_guidelines
          );
          setPhysicalExam(
            !response.data.physical_exam ? "" : response.data.physical_exam
          );

          if (response.data.history_vital_signs.length > 0) {
            formatRecArrHistoryVital(
              arrMetrics,
              response.data.history_vital_signs
            );
          }
        }
      })
      .catch((e: Error) => {});
  };

  const changeHistoryVitalItem = useCallback(
    (event_value: any, id: any, item_type: any) => {
      let arrHelp = history_vital_sign;

      if (item_type == 1) {
        arrHelp[id].exam_1.value = event_value;
      } else if (item_type == 2) {
        arrHelp[id].exam_2.value = event_value;
      } else {
        arrHelp[id].exam_3.value = event_value;
      }

      setHistoryVitalSign(arrHelp);
    },
    [history_vital_sign]
  );

  const formatArrHistoryVital = () => {
    let arrHelp = history_vital_sign;
    let arrReturn: any[] = [];
    for (let index = 0; index < arrHelp.length; index++) {
      if (dataExam1 != "" && arrHelp[index].exam_1.value != "") {
        arrReturn.push({
          id: arrHelp[index].exam_1.id,
          scenario_id: !idScenario ? "" : idScenario,
          metric_id: arrHelp[index].id,
          date: dataExam1,
          value: arrHelp[index].exam_1.value,
        });
      }

      if (dataExam2 != "" && arrHelp[index].exam_2.value != "") {
        arrReturn.push({
          id: arrHelp[index].exam_2.id,
          scenario_id: !idScenario ? "" : idScenario,
          metric_id: arrHelp[index].id,
          date: dataExam2,
          value: arrHelp[index].exam_2.value,
        });
      }

      if (dataExam3 != "" && arrHelp[index].exam_3.value != "") {
        arrReturn.push({
          id: arrHelp[index].exam_3.id,
          scenario_id: !idScenario ? "" : idScenario,
          metric_id: arrHelp[index].id,
          date: dataExam3,
          value: arrHelp[index].exam_3.value,
        });
      }
    }

    return arrReturn;
  };

  const formatRecArrHistoryVital = (arrMetrics, arrHistoryVital: any) => {
    let arrMetric = arrMetrics;
    let data_1, data_2, data_3: any;

    for (let j = 0; j < arrHistoryVital.length; j++) {
      for (let i = 0; i < arrMetric.length; i++) {
        if (arrMetric[i].id == arrHistoryVital[j].metric_id) {
          if (arrMetric[i].exam_1.value == "") {
            arrMetric[i].exam_1.id = arrHistoryVital[j].id;
            arrMetric[i].exam_1.value = arrHistoryVital[j].value;
            data_1 = arrHistoryVital[j].date;
            break;
          }
          if (
            arrMetric[i].exam_1.value != "" &&
            arrMetric[i].exam_2.value == ""
          ) {
            arrMetric[i].exam_2.id = arrHistoryVital[j].id;
            arrMetric[i].exam_2.value = arrHistoryVital[j].value;
            data_2 = arrHistoryVital[j].date;
            break;
          }
          if (
            arrMetric[i].exam_1.value != "" &&
            arrMetric[i].exam_2.value != "" &&
            arrMetric[i].exam_3.value == ""
          ) {
            arrMetric[i].exam_3.id = arrHistoryVital[j].id;
            arrMetric[i].exam_3.value = arrHistoryVital[j].value;
            data_3 = arrHistoryVital[j].date;
            break;
          }
        }
      }
    }

    setHistoryVitalSign(arrMetric);
    setDataExam1(data_1);
    setDataExam2(data_2);
    setDataExam3(data_3);
  };

  const tabs = [
    { id: "1", title: t("title_health_history") },
    { id: "2", title: t("title_prescriptions_simple") },
    { id: "3", title: t("title_history_of_vital_signs") },
    { id: "4", title: t("title_exams") },
  ];

  const [tab_id, setTabId] = useState("1");

  const handleChangeSubTab = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabId(newValue);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id: any) => {
    setOpen(true);
  };

  const duplicateScenario = () => {
    ScenarioService.importScenario(idScenario)
      .then((response: any) => {
        setIdScenario(response?.data?.id);
        loadMetrics(response?.data?.id);
        toast(response?.data?.Msg, {
          type: "success",
        });
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadThemes = async (page: number, paginate: number) => {
    await ThemesService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setThemes(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadCompetences = async (page: number, paginate: number) => {
    await CompetenceService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setCompetences(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    loadMetrics();
    loadThemes(1, 0);
    loadCompetences(1, 0);
  }, []);

  return (
    <Box>
      <Banner
        type="small"
        image={banner}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <PlaylistAddOutlinedIcon sx={{ width: '3em', height: '3em' }} />
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {!id
                ? t('title_page_form_register_scenarios')
                : t('title_page_form_register_scenarios_edit')}
            </Typography>
          </Box>
        }
      />

      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Box className={styles.containerForm}>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={8}>
              <TitleFormNumber
                index="01"
                title={t("title_settings_scenarios")}
              />
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}></Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={2}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("input_label_title")}*
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ textAlign: "right" }}>
              {idScenario && (
                <Button
                  variant="contained"
                  size="large"
                  sx={{ ml: 2 }}
                  onClick={handleClickOpen}
                  className={scenarioStyle.importButton}
                >
                  {" "}
                  {t("btn_label_import_scenario")}
                </Button>
              )}
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>

          <Typography sx={{ mb: 4, mt: 4 }}>
            <b>{t("subtitle01_scenario")}</b> {t("subtitle02_scenario")}
          </Typography>

          <Box className={scenarioStyle.topField}>
            <TextField
              required
              fullWidth
              id="title"
              name="title"
              label={t("input_label_title")}
              variant="outlined"
              value={title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(event.target.value);
              }}
            />
          </Box>
          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={2}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("lbl_form_theme")}
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                size="large"
                sx={{ ml: 2 }}
                onClick={addTheme}
                className={scenarioStyle.importButton}
              >
                {" "}
                {t("btn_label_create_theme")}
              </Button>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Typography sx={{ mb: 4, mt: 4 }}>
            <b>{t("text_checklist_theme_config_1")}</b>{" "}
            {t("text_scenario_theme_config_2")}
          </Typography>

          <Grid container spacing={2} sx={{ mb: 8 }}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="theme-label">
                  {t("input_label_theme_ch")}
                </InputLabel>
                <Select
                  labelId="theme-label"
                  id="theme_id"
                  label={t("input_label_theme_ch")}
                  value={theme_id}
                  onChange={handleThemeChange}
                >
                  {themes.map((row) => (
                    <MenuItem value={row.id} key={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("lbl_form_competence")}*
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                size="large"
                sx={{ ml: 2 }}
                onClick={addCompetence}
                className={scenarioStyle.importButton}
              >
                {" "}
                {t("btn_label_create_competence")}
              </Button>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Typography sx={{ mb: 4, mt: 4 }}>
            <b>{t("text_checklist_theme_config_1")}</b>{" "}
            {t("text_scenario_competence_config_2")}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="theme-label">
                  {t("input_label_competencia_ch")}
                </InputLabel>
                <Select
                  labelId="theme-label"
                  id="competence_id"
                  label={t("input_label_competencia_ch")}
                  value={competence_id}
                  onChange={handleCompetenceChange}
                >
                  {competences.map((row) => (
                    <MenuItem value={row.id} key={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box className={scenarioStyle.boxScenarioRegister}>
          <Box className={styles.containerForm}>
            <TitleFormNumber
              index="02"
              title={t("title_component_form_scenario_register")}
            />
            <Box className={scenarioStyle.topBox}>
              <Divider className={scenarioStyle.divider} />
            </Box>
            <Box className={scenarioStyle.topField}>
              <FormScenarioCharacteristics
                complexity={complexity}
                environment={environment}
                sex={sex}
                time_duration={time_duration}
                age={age}
                setAge={setAge}
                setTimeDuration={setTimeDuration}
                handleComplexityChange={handleComplexityChange}
                handleEnvironmentChange={handleEnvironmentChange}
                handleSexChange={handleSexChange}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className={styles.containerForm}>
            <TitleFormNumber index="03" title={t("input_label_goal_scene")} />
            <Box className={scenarioStyle.topField}>
              <TextField
                required
                fullWidth
                id="goal_scene"
                name="goal_scene"
                placeholder={t("title_placeholder_objective_of_scene") || ""}
                variant="outlined"
                value={goal_scene}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGoalScene(event.target.value);
                }}
                multiline
                rows={5}
              />
            </Box>
            <Box className={scenarioStyle.topField}>
              <TitleFormNumber
                index="04"
                title={t("input_label_description_scene")}
              />
              <Box className={scenarioStyle.topField}>
                <TextField
                  required
                  fullWidth
                  id="description_scene"
                  name="description_scene"
                  variant="outlined"
                  placeholder={t("title_placeholder_clinical_case_of_scene") || ""}
                  value={description_scene}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setDescriptionScene(event.target.value);
                  }}
                  multiline
                  rows={5}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={scenarioStyle.boxScenarioRegister}>
          <Box className={styles.containerTitleTab}>
            <TitleFormNumber
              index="05"
              title={t("title_scenario_patient_chart")}
            />
          </Box>
          <Box className={classes.containerTabMenu} sx={{ marginTop: 5 }}>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <TabsHiplade
                  status_id={tab_id}
                  handleChangeTab={handleChangeSubTab}
                  tabs={tabs}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={styles.containerForm}>
            {tab_id == "1" && (
              <FormScenarioHistoryHealth
                illness={illness}
                setIllness={setIllness}
                allergy={allergy}
                setAllergy={setAllergy}
                habits={habits}
                setHabits={setHabits}
                surgeries={surgeries}
                setSurgeries={setSurgeries}
                family_health_history={family_health_history}
                setFamilyHealthHistory={setFamilyHealthHistory}
              />
            )}
            {tab_id == "2" && (
              <FormScenarioPrescriptions
                continuous_use_medications={continuous_use_medications}
                setContinuousUseMedications={setContinuousUseMedications}
                temporary_medications={temporary_medications}
                setTemporaryMedications={setTemporaryMedications}
                health_guidelines={health_guidelines}
                setHealthGuidelines={setHealthGuidelines}
              />
            )}
            {tab_id == "3" && (
              <FormScenarioHistoryOfVitalSigns
                physical_exam={physical_exam}
                setPhysicalExam={setPhysicalExam}
                dataExam1={dataExam1}
                dataExam2={dataExam2}
                dataExam3={dataExam3}
                setDataExam1={setDataExam1}
                setDataExam2={setDataExam2}
                setDataExam3={setDataExam3}
                history_vital_sign={history_vital_sign}
                changeHistoryVitalItem={changeHistoryVitalItem}
              />
            )}
            {tab_id == "4" && (
              <FormScenarioExams idScenario={idScenario} feature="scenario" />
            )}
          </Box>
        </Box>
        {idScenario && (
          <Box>
            <Box className={styles.containerForm}>
              <TitleFormNumber
                index="06"
                title={t("input_label_file_instructions_students")}
              />
              <Typography className={scenarioStyle.subtitleFiles}>
                {t("description_instructions_for_event")}
              </Typography>
              <FormScenarioInstructionsFiles
                idScenario={idScenario}
                feature="scenario"
              />
            </Box>
          </Box>
        )}
        <Box className={styles.containerButton}>
          <Button type="submit" variant="contained" size="large">
            {" "}
            {t("btn_save")}
          </Button>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("title_dialog_duplicate_scenario")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("description_dialog_duplicate_scenario")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("btn_dialog_no")}</Button>
          <Button
            onClick={() => {
              duplicateScenario();
              handleClose();
            }}
            autoFocus
          >
            {t("btn_dialog_yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
