import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'

export default function FormScenarioCharacteristics({
  complexity,
  environment,
  sex,
  time_duration,
  age,
  setAge,
  setTimeDuration,
  handleSexChange,
  handleComplexityChange,
  handleEnvironmentChange,
}: any) {
  const { t } = useTranslation()
  const classes = useStyles()

  const gdr = [
  {
    value: 'M',
    label: t("title_dialog_male"),
  },
  {
    value: 'F',
    label: t("title_dialog_female"),
  },
  {
    value: 'O',
    label: t("title_dialog_others"),
  },
]

  const complex = [
  {
    value: 'B',
    label: t("title_dialog_low_complexity"),
  },
  {
    value: 'M',
    label: t("title_dialog_medium_complexity"),
  },
  {
    value: 'A',
    label: t("title_dialog_high_complexity"),
  },
]

  const envir = [
  {
    value: 1,
    label: t("title_enviroment_outpatient"),
  },
  {
    value: 2,
    label: t("title_enviroment_emergency_room"),
  },
  {
    value: 3,
    label: t("title_enviroment_impatient_unit"),
  },
  {
    value: 4,
    label: t("title_enviroment_intensive_care_unit"),
  },
  {
    value: 5,
    label: t("title_enviroment_surgical_center"),
  },
  {
    value: 6,
    label: t("title_enviroment_public_space"),
  },
]

  return (
    <Box>
      <Grid container spacing={5} sx={{ mb: 10 }}>
        <Grid item xs={6}>
          <Box className={classes.checkBoxArea}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className={classes.titleScenarioCheckbox}
                sx={{ fontWeight: 'bold' }}
              >
                {t('input_label_complexity')}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={complexity}
                onChange={handleComplexityChange}
              >
                {complex.map((c, index) => (
                  <FormControlLabel
                    key={index}
                    value={c.value}
                    control={<Radio />}
                    label={c.label}
                    sx={{ mr: 10 }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.checkBoxArea}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className={classes.titleScenarioCheckbox}
                sx={{ fontWeight: 'bold' }}
              >
                {t('input_label_gender')}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={sex}
                onChange={handleSexChange}
              >
                {gdr.map((g, index) => (
                  <FormControlLabel
                    key={index}
                    value={g.value}
                    control={<Radio />}
                    label={g.label}
                    sx={{ mr: 10 }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={5}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className={classes.titleScenarioCheckbox}
                sx={{ fontWeight: 'bold' }}
              >
                {t('input_label_time_duration')}
              </FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                required
                fullWidth
                id="time_duration"
                name="time_duration"
                variant="outlined"
                value={time_duration}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTimeDuration(event.target.value)
                }}
                type="time"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2} sx={{ mb: 5 }}>
            <Grid item xs={5}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className={classes.titleScenarioCheckbox}
                sx={{ fontWeight: 'bold' }}
              >
                {t('input_label_age')}
              </FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                required
                fullWidth
                id="age"
                name="age"
                variant="outlined"
                value={age}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAge(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className={classes.titleScenarioCheckbox}
                sx={{ fontWeight: 'bold' }}
              >
                {t('input_label_environment')}
              </FormLabel>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Select
                  labelId="environment-label"
                  id="environment"
                  value={environment}
                  onChange={handleEnvironmentChange}
                >
                  {envir.map((row, index) => (
                    <MenuItem value={row.value} key={index}>
                      {row.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ mb: 3 }}></Box>
    </Box>
  )
}
