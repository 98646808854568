import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { AppContext } from '../../contexts/AppContext';
import { WIDTH_CLOSE_SIDEBAR, WIDTH_OPEN_SIDEBAR } from '../constants';

export default function useLayoutStyles() {

    const { state: { menu } } = React.useContext(AppContext);

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.between(0, 1200));

    const { spacing, breakpoints } = useTheme()
    const small = useMediaQuery(breakpoints.down('sm'));
    const medium = useMediaQuery(breakpoints.down('md'));
    const large = useMediaQuery(breakpoints.down('lg'));
    

    const mobileTablet = small || medium

    const sideBarWidth = menu?.visibleMenu ? WIDTH_OPEN_SIDEBAR : WIDTH_CLOSE_SIDEBAR

    // console.log("sideBarWidth: ", sideBarWidth);
    
    
    

  return {
    menuSideBarWidth: !smallScreen ? sideBarWidth : '100%',
    contentContainerMarginLeft: mobileTablet ? 0 : sideBarWidth,
    contentContainerPaddingLeft: mobileTablet? 8 : 40,
    positionSideBarClosedTooltip: -WIDTH_OPEN_SIDEBAR,
    mlLayout: mobileTablet ? 20 : sideBarWidth + 40,
    smallScreen
  }

}
