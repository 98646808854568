import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import LayersClearOutlinedIcon from "@mui/icons-material/LayersClearOutlined";
import { useStyles } from "./styles/index.styles";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../hooks/useModal";
import { Textarea } from "@mui/joy";
import CanvasDraw from "react-canvas-draw";
import colors from "../../../styles/colors";
import { useMarkers } from "../../../../events/hooks/useMarkers";
import { IMarker } from "../../../../types/Markers";
import { ListItem, List, ListItemText } from "@mui/material";

export function UploadConfirmation() {
  const classes = useStyles();

  const { t } = useTranslation();

  const { dataModal, onCloseModalReset, onChangeTextBtnFooter } = useModal();

  const listItem = [
    t("photoRequirement.singlePerson"),
    t("photoRequirement.background"),
    t("photoRequirement.quality"),
    t("photoRequirement.posture"),
  ];

  useEffect(() => {
    onChangeTextBtnFooter({ typeText: "save", text: t("iUnderstood") });
    if (dataModal.btnCloseModalClicked) {
      onCloseModalReset();
    }
  }, [dataModal.btnCloseModalClicked]);

  return (
    <Box className={classes.containerBody}>
      <Box className={classes.containerHeader}>
        <Typography className={classes.headerTitle}>
          {t("title_attention")}
        </Typography>
      </Box>
      <Box className={classes.wrapperBody}>
        <Typography className={classes.titlebody}>
          {t("paragraph_description_upload")}
        </Typography>
      </Box>
      <List sx={{ listStyle: "disc", pl: 8, lineHeight: 1, pt: 0 }}>
        {listItem.map((item, index) => (
          <ListItem key={index} sx={{ display: "list-item", padding: 0 }}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
