import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/system";
import { useDebriefingStyles } from "../../styles/debriefing.styles";
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import ReactPlayer from "react-player";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import html2canvas from "html2canvas";
import TimerBox from "./TimerBox";
import { useMarkers } from "../../hooks/useMarkers";
import { LoadingButton } from "@mui/lab";
import { IMarker } from "../../../types/Markers";
import { useModal } from "../../../common/hooks/useModal";
import secureLocalStorage from "react-secure-storage";
import { useParams } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import colors from "../../../common/styles/colors";
import { useTranslation } from "react-i18next";

export default function DebriefingVideo({
  video,
  videoId,
  arrVideo,
  setVideo,
  setVideoId,
  cameraMain,
  setCameraMain,
  heightVideo,
  widthVideo,
  notMarker,
  cameraIndex,
  setCameraIndex,
}: any) {
  const { t } = useTranslation();
  const stylesDebriefing = useDebriefingStyles();
  const {
    setMarkerByDebriefing,
    handleFormatTime,
    storeMarkerData,
    markerData,
    getMarkerByDebriefing,
  } = useMarkers();
  const { handleOpenModal, dataModal } = useModal();

  const { debriefingId } = useParams();

  const profileId = secureLocalStorage.getItem("role") as number | null;

  const [play, setPlay] = useState(false);
  const [progressVideo, setProgressVideo] = useState(0);
  const [markerTime, setMarkerTime] = useState<IMarker[] | boolean>();
  const playerRef = useRef<ReactPlayer | null>(null);

  const handleProgress = (state: any) => {
    setProgressVideo(parseInt(state.playedSeconds));
  };

  const getTimeVideo = () => {
    let seconds = progressVideo;
    handleCaptureFrame(seconds);
  };

  const handleCaptureFrame = async (seconds: any) => {
    if (playerRef.current && typeof markerTime === "object") {
      const videoElement =
        playerRef.current.getInternalPlayer() as HTMLVideoElement;

      videoElement.currentTime = seconds;

      await new Promise((resolve) => {
        videoElement.onseeked = resolve;
      });

      html2canvas(videoElement).then((canvas) => {
        const frameUrl = canvas.toDataURL();
        let arrTime = markerTime;
        const data = {
          time: seconds,
          base64_data: frameUrl.split("data:image/png;base64,")[1],
        };

        arrTime.push(data);
        setMarkerTime(arrTime);
        setMarkerByDebriefing({
          base64_data: data.base64_data,
          time: handleFormatTime(data.time),
          video_id: videoId,
        });
      });
    }
  };

  const handleModal = (data: IMarker) => {
    handleOpenModal({
      displayName: "editScreenshot",
      data: data,
    });
  };

  const changeCamera = (index: any) => {
    const arr = arrVideo;
    setVideo("");
    setPlay(false);
    setVideo(arr[index]?.recording_url);
    setVideoId(arr[index]?.streaming_id);
    setCameraMain(arr[index]?.camera_main);
    setCameraIndex(index + 1);
    getMarkerByDebriefing(debriefingId, arr[index]?.streaming_id);

    if (playerRef.current && !play) {
      setTimeout(() => {
        playerRef?.current?.seekTo(progressVideo, "seconds");
        setPlay(true);
      }, 1000);
    }
  };

  useEffect(() => {
    setMarkerTime(markerData);
  }, [markerData]);

  useEffect(() => {
    if (arrVideo.length > 1) {
      arrangeCheckboxCamera();
    }
    getMarkerByDebriefing(debriefingId, videoId);
  }, [video, arrVideo, cameraMain, videoId, cameraIndex]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [checkedItems, setCheckedItems] = useState(
    arrVideo.reduce(
      (acc, item) => ({ ...acc, [item?.streaming_id]: false }),
      {}
    )
  );
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setSelectAll(checked);

    if (checked) {
      getMarkerByDebriefing(debriefingId);
    }

    if (!checked) {
      getMarkerByDebriefing(debriefingId, videoId);
    }

    setCheckedItems(
      arrVideo.reduce(
        (acc, item) => ({ ...acc, [item?.streaming_id]: checked }),
        {}
      )
    );
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const obj = checkedItems;

    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });

    let arrItems: any = [];

    if (event.target.checked) {
      arrItems.push(event.target.name);
    }

    if (!event.target.checked) {
      setSelectAll(false);
    }

    for (let i = 0; i < arrVideo.length; i++) {
      const element = arrVideo[i];
      if (
        obj[element?.streaming_id] == true &&
        element?.streaming_id != event.target.name
      ) {
        arrItems.push(element?.streaming_id);
      }
    }

    if (arrItems.length > 0) {
      const itens = arrItems.join(",");
      getMarkerByDebriefing(debriefingId, itens);
    }
  };

  const arrangeCheckboxCamera = () => {
    arrVideo.map((item: any, index: any) => {
      setCheckedItems({
        ...checkedItems,
        [item?.streaming_id]: false,
      });
    });
  };

  return (
    <Box>
      <Box className={stylesDebriefing.videoBar}>
        <Box className={stylesDebriefing.boxTitleVideo}>
          <VideocamOutlinedIcon
            sx={{ fontSize: "40px" }}
            className={stylesDebriefing.cameraIconVideo}
          />{" "}
          {arrVideo &&
            arrVideo.map((v, index) => (
              <Button
                sx={{
                  fontSize: "bold",
                  ml: 2,
                  border: `1px solid ${v?.camera_color}`,
                  backgroundColor:
                    v?.streaming_id == videoId ? v?.camera_color : "",
                  color: v?.font_color,
                }}
                key={index}
                onClick={() => changeCamera(index)}
              >
                {v?.camera_name}
              </Button>
            ))}
        </Box>
      </Box>
      {video && (
        <ReactPlayer
          url={video}
          playing={play}
          width={widthVideo ?? "100%"}
          height={heightVideo ?? "100%"}
          onProgress={handleProgress}
          controls
          config={{
            file: {
              attributes: {
                crossOrigin: "anonymous",
              },
            },
          }}
          ref={playerRef}
        />
      )}

      <Box className={stylesDebriefing.videoBar}>
        <Box className={stylesDebriefing.boxMarker}>
          {!notMarker && (
            <Box>
              <Box
                className={
                  progressVideo > 0
                    ? stylesDebriefing.boxNewMark
                    : stylesDebriefing.boxNewMarkRight
                }
              >
                {progressVideo > 0 && (
                  <Box className={stylesDebriefing.boxNewMarkButton}>
                    <LoadingButton
                      className={stylesDebriefing.btnNewMark}
                      onClick={getTimeVideo}
                      variant="contained"
                      loading={storeMarkerData.loading}
                    >
                      {profileId !== 5 && !storeMarkerData.loading ? (
                        t("title_debriefing_video_new_marking")
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </LoadingButton>

                    <Typography
                      sx={{ marginLeft: 4 }}
                      className={stylesDebriefing.textInfoNewMark}
                    >
                      {t("title_description_debriefing_video_markings")}
                    </Typography>
                  </Box>
                )}
                {arrVideo.length > 1 && (
                  <ClickAwayListener onClickAway={handleClose}>
                    <div>
                      <Button
                        className={stylesDebriefing.buttonFilter}
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <FilterAltIcon />
                      </Button>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        sx={{
                          "& .MuiPaper-root": {
                            backgroundColor: colors.grayButtonCam2,
                          },
                        }}
                      >
                        <MenuItem className={stylesDebriefing.menuFilter}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                                name="selectAll"
                                color="secondary"
                              />
                            }
                            label="Todas"
                          />
                        </MenuItem>
                        {arrVideo.map((video, index) => (
                          <MenuItem
                            key={index}
                            className={stylesDebriefing.menuFilter}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  onChange={handleCheckboxChange}
                                  name={video?.streaming_id}
                                  id={video?.streaming_id}
                                  checked={
                                    checkedItems[video?.streaming_id] ?? false
                                  }
                                />
                              }
                              label={video?.camera_name}
                            />
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </ClickAwayListener>
                )}
              </Box>
            </Box>
          )}
          {!notMarker && <Divider className={stylesDebriefing.divider} />}

          <Box className={stylesDebriefing.boxMarkerTime}>
            {typeof markerTime === "object" &&
              markerTime.map((item, index) => (
                <TimerBox
                  onClick={() => handleModal(item)}
                  time={handleFormatTime(item?.time)}
                  cameraIndex={cameraIndex}
                  key={index}
                  player={true}
                  arrVideo={arrVideo}
                  videoId={item?.video_id}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
