import api from "../config/api";
import ISubAreaData from "../types/SubArea";

const getAll = () => {
  return api.get<ISubAreaData>("themes/subareas");
};

const SubAreaService = {
  getAll,
};

export default SubAreaService;
