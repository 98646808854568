import React from "react";
import { Navigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import "moment/locale/pt-br";
import NotFound from "../NotFound";

function PrivateRoute({ children, rule, profile }: any) {
  const isAuthenticated = !!secureLocalStorage.getItem("token");
  let isValidExpires = true;
  const dtStartSession = moment(
    secureLocalStorage.getItem("dt_start_session")?.toString()
  );
  const today = moment();
  const expiresIn = secureLocalStorage.getItem("expires_in")?.toString();

  const profileUser: any = secureLocalStorage
    .getItem("role")
    ?.toString()
    .split(",");
  const localPermissions = secureLocalStorage
    .getItem("permissions")
    ?.toString();
  const permissionsUser: any = localPermissions?.split(",");

  const permissionModule: any = rule.toString().split(",");
  const profileModule = profile.toString().split(",");

  const permissionVerify = () => {
    if (
      permissionModule.length == 1 &&
      permissionModule[0] == "all" &&
      profileModule.length == 1 &&
      profileModule[0] == "0"
    ) {
      return true;
    }

    const perInArray = permissionsUser.some((el) =>
      permissionModule.includes(el)
    );

    const proInArray = profileUser.some((el) => profileModule.includes(el));

    if (perInArray && proInArray) {
      return true;
    }
    return false;
  };

  if (!permissionVerify()) {
    return <NotFound />;
  }

  if (expiresIn && dtStartSession) {
    var seconds = today.diff(dtStartSession, "seconds");
    var expires_in = parseInt(expiresIn);
    if (seconds > expires_in) {
      isValidExpires = false;
    }
  }

  if (!isValidExpires) {
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("permissions");
    secureLocalStorage.removeItem("name");
    secureLocalStorage.removeItem("id");
    secureLocalStorage.removeItem("role");
    secureLocalStorage.removeItem("avatar_url");
    secureLocalStorage.removeItem("dt_start_session");
    secureLocalStorage.removeItem("expires_in");
  }

  if (isAuthenticated && isValidExpires && permissionVerify()) {
    return children;
  }

  return <Navigate to="/login" />;
}

export default PrivateRoute;
