import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles/index.styles";
import { useDefaultStyles } from "../../common/styles/default";
import { useScenarioStyles } from "../styles/scenarios.styles";
import ScenarioTitleView from "./ScenarioTitleView";
import { Divider, Grid } from "@mui/material";
import CardFile from "../../common/components/cardfile";
import ScenarioExamsHistoryViewItem from "./ScenarioExamsHistoryViewItem";
import ScenarioScriptItem from "./ScenarioScriptItem";

export default function ScenarioScriptsView({ idScenario }: any) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const scenarioStyle = useScenarioStyles();

  useEffect(() => {}, [idScenario]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Box className={scenarioStyle.containerScenario} sx={{ mb: 10 }}>
          <ScenarioTitleView index="06" title="Roteiros e orientações" />
          <ScenarioScriptItem
            name="file_instructions_appraisers"
            title={t("input_label_file_instructions_appraisers")}
            idScenario={idScenario}
          />

          <ScenarioScriptItem
            name="file_instructions_students"
            title={t("input_label_file_instructions_students")}
            idScenario={idScenario}
          />

          <ScenarioScriptItem
            name="file_instructions_actors"
            title={t("input_label_file_instructions_actors")}
            idScenario={idScenario}
          />

          <ScenarioScriptItem
            name="file_necessary_items"
            title={t("input_label_file_necessary_items")}
            idScenario={idScenario}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
