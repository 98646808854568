import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";

import { useDefaultStyles } from "../../common/styles/default";
import { useCompetenceStyle } from "../styles/index.styles";

import { useModal } from "../../common/hooks/useModal";
import CompetenceService from "../../services/CompetenceService";
import { Typography } from "@mui/material";

export default function ExtraCompetence() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const styles = useDefaultStyles();
  const classes = useCompetenceStyle();

  const [checklists, setChecklists] = useState([] as any);
  const [scenarios, setScenarios] = useState([] as any);

  const {
    dataModal,
    onCloseModalReset,
    handleChangedModal,
    handleStopSaveModal,
  } = useModal();

  const loadRelationships = async (id: any) => {
    await CompetenceService.relationships(id)
      .then((response: any) => {
        if (response) {
          setChecklists(response.data?.checklists);
          setScenarios(response.data?.scenarios);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (dataModal?.data?.idCompetence) {
      loadRelationships(dataModal?.data?.idCompetence);
    }
  }, [dataModal.loadingBtnSave]);

  return (
    <Box className={classes.modalContainer}>
      {checklists.length == 0 && scenarios.length == 0 && (
        <Typography sx={{ mb: 3 }}>
          {t("title_dialog_no_linked_items")}
        </Typography>
      )}

      {checklists.length > 0 && (
        <Typography sx={{ mb: 3 }}>
          {t("title_dialog_related_checklists")}
        </Typography>
      )}

      {checklists.map((row, index) => (
        <Box key={index} className={classes.boxExtraInfo} sx={{ mb: 1 }}>
          <span>{row?.name}</span>
          <span>
            {t("span_extra_competence_theme")} {row?.theme_name}
          </span>
          <span>
            {moment(row?.created_at).locale("pt-br").format("DD/MM/YYYY")}
          </span>
        </Box>
      ))}

      {scenarios.length > 0 && (
        <Typography sx={{ mb: 3, mt: 4 }}>
          {t("title_dialog_related_scenarios")}
        </Typography>
      )}

      {scenarios.map((row, index) => (
        <Box key={index} className={classes.boxExtraInfo} sx={{ mb: 1 }}>
          <span>
            <span>{row?.name}</span>
          </span>
          <span>
            {t("span_extra_competence_theme")} {row?.theme_name}
          </span>
          <span>
            {moment(row?.created_at).locale("pt-br").format("DD/MM/YYYY")}
          </span>
        </Box>
      ))}
    </Box>
  );
}
