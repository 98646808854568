import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDefaultStyles } from '../../common/styles/default'
import { Box, Grid, Typography } from '@mui/material'
import { useStyles } from '../styles/index.styles'
import 'moment/locale/pt-br'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ResponsiveBar } from '@nivo/bar'
import DashboardService from '../../services/DashboardService'
import { toast } from 'react-toastify'

export default function GraphHistogramGrade({ event }: any) {
  const { t } = useTranslation()
  const styles = useDefaultStyles()
  const classes = useStyles()
  const [data, setData] = useState([])

  const loadGraph = async (id: any) => {
    await DashboardService.getEventHistogram(id)
      .then((response: any) => {
        setData(response.data)
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: 'error',
        })
      })
  }

  useEffect(() => {
    if (event) {
      loadGraph(event.id)
    }
  }, [event])

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.cardContainer}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {t('graph_event_title_histogram_grade')}
            </Typography>
            <Typography variant="h6" sx={{ mt: 1, mb: 3 }}>
              {t('graph_event_subtitle_histogram_grade')}
            </Typography>
            <Box sx={{ height: 500 }}>
              <ResponsiveBar
                data={data}
                keys={['Estudantes']}
                indexBy="Nota"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'set1' }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Notas',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Médias (%)',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={(e) =>
                  e.id +
                  ': ' +
                  e.formattedValue +
                  ' in country: ' +
                  e.indexValue
                }
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
