import { useState } from 'react';
import { toast } from 'react-toastify';
import { AudioRatingItemProps, CommentRatingItemProps, getRatingItemService } from '../DebriefingCommentService';
import { useTranslation } from 'react-i18next';


export const useRatingItem = () => {
    const { t } = useTranslation()
    const [commentRatingData, setCommentRatingData] = useState<CommentRatingItemProps>();
    const [audioRatingData, setAudioRatingData] = useState<AudioRatingItemProps>();
    const [loadingRatingItem, setLoadingRatingItem] = useState<boolean>(false);
    const [successRatingItem, setSuccessRatingItem] = useState<boolean>(false);
    const [errorRatingItem, setErrorRatingItem] = useState<boolean>(false);

    const getDebriefingRatingItem = async (debriefingId: number, itemId: number) => {
        setLoadingRatingItem(true)
        setSuccessRatingItem(false)
        setErrorRatingItem(false)

        try {
            const response = await getRatingItemService(debriefingId, itemId);

            setCommentRatingData(response.data.comment);
            setAudioRatingData(response.data.audio)


            setSuccessRatingItem(true)

        } catch (e) {
            toast(t("toast_dialog_error"), { type: 'error' });
            setErrorRatingItem(true);
        } finally {
            setLoadingRatingItem(false)
        }
    };

    return {
        getDebriefingRatingItem,
        commentRatingData,
        audioRatingData,
        loadingRatingItem,
        successRatingItem,
        errorRatingItem,
    };
};
