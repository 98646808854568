import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../common/styles/colors'
import fonts from '../../common/styles/fonts'

export const useStationFilesStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      backgroundColor: colors.lilacMediumLight,
      width: '100% !important',
    },
    box: {
      borderRadius: '12px',
      backgroundColor: colors.white,
      boxShadow: '0px 2px 2px 0px rgba(145, 145, 145, 0.25)',
      padding: '30px',
      marginBottom: '25px',
    },
    title: {
      fontSize: '1.5em !important',
      marginBottom: '10px !important',
    },
    gridFiles: {
      fontSize: '1.5em !important',
      marginTop: '10px !important',
      width: '100% !important',
    },
    boxListFiles: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '20px',
      paddingBottom: '20px',
      borderBottom: '1px solid ' + colors.grayLight,
    },
    boxListFiles_1: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '10px',
      paddingBottom: '20px',
      borderBottom: '1px solid ' + colors.grayLight,
    },
    boxFile: {
      borderRadius: '10px',
      backgroundColor: colors.purpleBackButton,
      padding: '7px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '6px',
    },
    fontItemFile: {
      fontSize: '0.7em !important',
      color: colors.purpleTextButton,
      textDecoration: 'none',
      cursor: 'pointer',
    },
    titleSubText: {
      marginBottom: '20px',
    },
  }),
)
