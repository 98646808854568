import React, { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'

import Avatar from '@mui/material/Avatar'
import { useStyles } from './styles/index.styles'

import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined'

import AttachmentService from '../../../services/AttachmentService'
import secureLocalStorage from 'react-secure-storage'
import colors from '../../styles/colors'

import { toast } from 'react-toastify'
import { useModal } from '../../hooks/useModal'
import { useSocket } from '../../../services/hooks/socketHook'

export default function AvatarHiplade({
  nameUser,
  size,
  theme,
  upload,
  id,
  source,
  only
}: any) {
  const classes = useStyles()

  const { handleOpenModal, dataModal, onResetModal } = useModal();

  const [avatar, setAvatar] = useState('')
  const [src, setSrc] = useState('')
  const [uploadClicked, setUploadClicked] = useState(false);


  let auth_user_id = secureLocalStorage.getItem('id')

  const confirmationUpload = dataModal.displayName === 'uploadConfirmationModal'
  const inputRef = useRef<HTMLInputElement>(null);

  if (id != '') {
    auth_user_id = id
  }

   /**
   * Utilização de WEBSOCKET
   */
   /*useSocket({
     callBack: (payload) => {
      setSrc(payload.avatar_pic_static_url)
     },
     channel: 'user.' + auth_user_id, // Nome do privateChannel na classe do MANAGER
     event: '.App\\Events\\UserUpdateChannel' // Nome de classe caminho completo
   });
   /**
    *  END WEBSOCKET
    */

  const createNameAvatar = () => {
    const name = nameUser?.toString()
    let arrName = name?.split(' ')
    let nameAvatar = ''

    if (arrName && arrName?.length > 0) {
      nameAvatar =
        arrName.length > 1
          ? arrName[0].substring(0, 1) +
            arrName[arrName.length - 1].substring(0, 1)
          : arrName[0].substring(0, 1)
      nameAvatar = nameAvatar.toUpperCase()
      setAvatar(nameAvatar)
    }
  }

  const loadAvatar = async () => {
    await AttachmentService.get(
      'users',
      id,
      'avatar-upload-pic',
    ).then((response) => {
      if (response.data != '') {
        setSrc(response.data[0].url)
      }
    })
  }

  const sizeAvatar = (size: any) => {
    switch (size) {
      case 'mini':
        return '24px'
      case 'small':
        return '40px'
      case 'medium':
        return '64px'
      case 'large':
        return '98px'
      case 'extra-large':
        return '252px'
      default:
        return '64px'
    }
  }

  const sizeConfig = () => {
    return {
      mr: 2,
      width: `${sizeAvatar(size)}`,
      height: `${sizeAvatar(size)}`,
      fontSize: `${fontAvatar(size)}`,
    }
  }

  const fontAvatar = (size: any) => {
    if (size == 'mini') {
      return '13px !important'
    }

    if (size == 'extra-large') {
      return '150px !important'
    }
  }

  const themeAvatar = (theme: any) => {
    if (theme == 'gray') {
      return classes.avatarGray
    } else {
      return classes.avatar
    }
  }

  const handleChange = async (e) => {
    setUploadClicked(false)
    await AttachmentService.upload({
      feature: 'users',
      object_id: auth_user_id,
      field_name: e.target.name,
      file: e.target.files[0],
      file_type: 'image',
      multiple: 'false',
    })
      .then((response) => {
        setSrc(response.data.url)
        toast(response.data.register_face, {
          type: 'info',
        })
        toast(response.data.Msg, {
          type: 'success',
        })
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: 'error',
        })
        //location.reload();
      })

  }



  useEffect(() => {
    createNameAvatar()
    setSrc(source)
    loadAvatar()
  }, [nameUser])

  useEffect(() => {
    if (confirmationUpload && uploadClicked && inputRef.current) {
      inputRef.current.click();
    }
  }, [uploadClicked, confirmationUpload])


  useEffect(() => {
    if (dataModal.btnSaveModalClicked) {
      setUploadClicked(true)
    }
  }, [uploadClicked, dataModal.btnSaveModalClicked])

  if(only) {
    return (
      <Avatar
        alt="Avatar"
        sx={sizeConfig}
        className={themeAvatar(theme)}
        src={src}
      >
        {avatar}
      </Avatar>
    )
  }

  return (
    <Box>
      <Box className={classes.profileUpload} sx={sizeConfig}>
        <Avatar
          alt="Avatar"
          sx={sizeConfig}
          className={themeAvatar(theme)}
          src={src}
        >
          {avatar}
        </Avatar>

        {upload ? (
          <Box onClick={() => handleOpenModal({ displayName: 'uploadConfirmationModal'})}>
            {uploadClicked && (
              <input
                ref={inputRef}
                accept="image/*"
                className={classes.displayNone}
                id="avatar-upload-pic"
                name="avatar-upload-pic"
                type="file"
                onChange={(e) => handleChange(e)}
              />
            )}
            <label htmlFor="avatar-upload-pic">
              <UploadOutlinedIcon
                className={classes.uploadIcon}
                sx={{ fontSize: sizeConfig }}
              />
            </label>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  )
}
