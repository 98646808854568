import React from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDebriefingStyles } from "../../styles/debriefing.styles";

import { Typography } from "@mui/material";

export default function ChecklistTitleView({ title }: any) {
  const { t } = useTranslation();
  const debriefingStyle = useDebriefingStyles();

  return (
    <Box className={debriefingStyle.titleBoxChecklist}>
      <Typography
        className={debriefingStyle.titleChecklist}
        sx={{ fontWeight: "bold" }}
      >
        {t("title_dialog_checklist_view")}
      </Typography>
      <Typography
        className={debriefingStyle.titleChecklist}
        sx={{ textTransform: "uppercase" }}
      >
        {title}
      </Typography>
    </Box>
  );
}
