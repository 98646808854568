import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import GraphSkillGrade from "./GraphSkillGrade";
import GraphGeneralGrade from "./GraphGeneralGrade";
import GraphHistogramGrade from "./GraphHistogramGrade";
import GraphStationGrade from "./GraphStationGrade";

export default function EventGraph({ event }: any) {
  useEffect(() => {}, [event]);

  return (
    <Box>
      <Grid container spacing={2}>
        <GraphGeneralGrade event={event} />
        <GraphStationGrade event={event} />
      </Grid>
      <GraphSkillGrade event={event} />
      <GraphHistogramGrade event={event} />
    </Box>
  );
}
