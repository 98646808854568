import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../common/styles/colors'
import fonts from '../../common/styles/fonts'

export const useGeneralPerformanceStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 50,
    },
    title: {
      color: colors.blueDarkest,
      fontSize: '3em !important',
    },
    divider: {
      backgroundColor: colors.lilacMediumLight,
      width: '100% !important',
      marginBottom: '25px !important',
    },
    boxPerformance: {
      borderRadius: '4px',
      backgroundColor: colors.white,
      boxShadow: '0px 4px 8px 0px rgba(57, 54, 67, 0.25)',
      padding: '25px',
    },
    subtitle: {
      textAlign: 'center',
      color: colors.purpleMediumSub,
      fontSize: '2em !important',
    },
    itemGrid: {
      backgroundColor: colors.lilacPerformance,
      color: colors.lilacPerformanceTitle,
      paddingLeft: '10px',
      textTransform: 'uppercase',
      fontSize: '0.75em !important',
      minHeight: '50px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    itemGridOn: {
      backgroundColor: colors.lilacLight,
      color: colors.purpleMediumSub,
      paddingLeft: '10px',
      textTransform: 'uppercase',
      fontSize: '0.75em !important',
      minHeight: '50px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    porcBarBack: {
      borderRadius: '12px',
      backgroundColor: colors.grayBarPerformance,
      boxShadow: '0px 2px 2px 0px rgba(145, 145, 145, 0.25)',
      minHeight: '63.939px',
      marginTop: '20px',
    },
    porcBar: {
      borderRadius: '12px',
      boxShadow: '0px 2px 2px 0px rgba(235, 184, 6, 0.25)',
      minHeight: '63.939px',
      marginTop: '20px',
      color: colors.white,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    },
  }),
)
