import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EntitiesService from "../../services/EntitiesService";
import RoomsService from "../../services/RoomsService";
import { toast } from "react-toastify";

import IEntityData from "../../types/Entities";
import { useDefaultStyles } from "../../common/styles/default";
import { Grid } from "@mui/material";
import { useCameraStyle } from "../styles/index.styles";

import { useModal } from "../../common/hooks/useModal";
import CameraService from "../../services/CameraService";
import colors from "../../common/styles/colors";

import { MuiColorInput } from "mui-color-input";
import { Divider, Typography } from "@material-ui/core";

export default function FormCameraColor() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const styles = useDefaultStyles();
  const classes = useCameraStyle();

  const [name, setName] = useState("");
  const [ip, setIp] = useState("");
  const [port, setPort] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  const [main, setMain] = useState("");
  const [camera_color, setCameraColor] = useState("");
  const [font_color, setFontColor] = useState("");

  const handleSubmit = () => {
    if (camera_color == "") {
      toast(t("toast_description_color_camera"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    if (font_color == "") {
      toast(t("toast_description_color_font"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    const dataCam = {
      name: name,
      ip: ip,
      port: port,
      user: user,
      password: password,
      url: url,
      type: type,
      main: main,
      camera_color: camera_color,
      font_color: font_color,
      room_id: dataModal?.data?.idRoom,
    };
    CameraService.update(dataModal?.data?.idCam, dataCam)
      .then((response: any) => {
        dataModal?.data?.loadCameras(dataModal?.data?.idRoom);
        onCloseModalReset();
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  const handleChangeCameraColor = (value: any) => {
    setCameraColor(value);
  };

  const loadCamera = async (id: any) => {
    await CameraService.get(id)
      .then((response: any) => {
        if (response) {
          console.log("Cameras");
          console.log(response.data);
          setName(response.data.name);
          setIp(response.data.ip);
          setPort(response.data.port);
          setUser(response.data.user);
          setPassword(response.data.password);
          setUrl(response.data.url);
          setType(response.data.type);
          setMain(response.data.main);
          setCameraColor(response.data.camera_color);
          setFontColor(response.data.font_color);
        }
      })
      .catch((e: Error) => {});
  };

  const handleFontColor = (event: SelectChangeEvent) => {
    setFontColor(event.target.value);
  };

  const {
    dataModal,
    onCloseModalReset,
    handleChangedModal,
    handleStopSaveModal,
  } = useModal();

  useEffect(() => {
    if (dataModal?.data?.idCam) {
      loadCamera(dataModal?.data?.idCam);
    }

    if (dataModal.loadingBtnSave) {
      handleSubmit();
    }
  }, [dataModal.loadingBtnSave]);

  return (
    <Box className={classes.modalContainer}>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h5">{t("title_dialog_color_palette")}</Typography>
          <Divider
            style={{
              backgroundColor: colors.lilacMediumLight,
              width: "100% !important",
              marginTop: 7,
              marginBottom: 30,
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography style={{ marginBottom: 4 }}>{t("title_button_camera_edit")}</Typography>
              <MuiColorInput
                format="hex"
                value={camera_color}
                onChange={handleChangeCameraColor}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ marginBottom: 4 }}>{t("title_font_color_camera_edit")}</Typography>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <Select
                  labelId="type-label"
                  label="Cor da fonte"
                  value={font_color}
                  onChange={handleFontColor}
                >
                  <MenuItem value="#FFFFFF">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          backgroundColor: `#FFFFFF`,
                          borderRadius: 1,
                          width: 24,
                          height: 24,
                          marginRight: 2,
                          border: "1px solid #CCCCCC",
                        }}
                      ></Box>
                      <span>{t("span_light_color")}</span>
                    </Box>
                  </MenuItem>
                  <MenuItem value="#000000">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          backgroundColor: `#000000`,
                          borderRadius: 1,
                          width: 24,
                          height: 24,
                          marginRight: 2,
                        }}
                      ></Box>
                      <span>{t("span_dark_color")}</span>
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
