import * as React from "react";
import { Box } from "@mui/system";
import EventsService from "../../services/EventsService";
import { useStyles } from "../styles/index.styles";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import AttachmentService from "../../services/AttachmentService";
import { useEffect, useState } from "react";
import ItemFile from "./ItemFile";

export default function MyEventFilesItem({ item }: any) {
  const styles = useStyles();

  const { t } = useTranslation();

  const [file_info_1, setFileInfo_1] = useState([] as any);
  const [file_info_2, setFileInfo_2] = useState([] as any);
  const [file_info_3, setFileInfo_3] = useState([] as any);
  const [file_info_4, setFileInfo_4] = useState([] as any);
  const file_name_1 = "file_instructions_appraisers";
  const file_name_2 = "file_instructions_students";
  const file_name_3 = "file_instructions_actors";
  const file_name_4 = "file_necessary_items";
  const feature = "scenario";

  const getFile = async (feature: any, idScenario: any, name: any) => {
    await AttachmentService.get(feature, idScenario, name)
      .then((response) => {
        if (response.data.length > 0) {
          if (name == "file_instructions_appraisers") {
            setFileInfo_1(response.data);
          }
          if (name == "file_instructions_students") {
            setFileInfo_2(response.data);
          }
          if (name == "file_instructions_actors") {
            setFileInfo_3(response.data);
          }
          if (name == "file_necessary_items") {
            setFileInfo_4(response.data);
          }
        }
      })
      .catch((e: any) => {});
  };

  useEffect(() => {
    if (item?.scenario_id) {
      getFile(feature, item?.scenario_id, file_name_1);
      getFile(feature, item?.scenario_id, file_name_2);
      getFile(feature, item?.scenario_id, file_name_3);
      getFile(feature, item?.scenario_id, file_name_4);
    }
  }, [item]);

  return (
    <Box className={styles.containerFiles}>
      {file_info_1.length > 0 && (
        <Box className={styles.boxListFiles_1}>
          {file_info_1.length > 0 && (
            <ItemFile
              name={file_info_1[0]?.original_name}
              id={file_info_1[0]?.id}
              url={file_info_1[0]?.url}
            />
          )}
        </Box>
      )}
      {file_info_2.length > 0 && (
        <Box className={styles.boxListFiles}>
          {file_info_2.length > 0 && (
            <ItemFile
              name={file_info_2[0]?.original_name}
              id={file_info_2[0]?.id}
              url={file_info_2[0]?.url}
            />
          )}
        </Box>
      )}
      {file_info_3.length > 0 && (
        <Box className={styles.boxListFiles}>
          {file_info_3.length > 0 && (
            <ItemFile
              name={file_info_3[0]?.original_name}
              id={file_info_3[0]?.id}
              url={file_info_3[0]?.url}
            />
          )}
        </Box>
      )}
      {file_info_4.length > 0 && (
        <Box className={styles.boxListFiles}>
          {file_info_4.length > 0 && (
            <ItemFile
              name={file_info_3[0]?.original_name}
              id={file_info_4[0]?.id}
              url={file_info_4[0]?.url}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
