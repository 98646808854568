import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDefaultStyles } from "../../common/styles/default";
import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from "../styles/index.styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ResponsiveRadar } from "@nivo/radar";
import DashboardService from "../../services/DashboardService";
import { toast } from "react-toastify";

export default function GraphSkillGrade({ event }: any) {
  const { t } = useTranslation();
  const styles = useDefaultStyles();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [station, setStation] = useState("");

  const loadGraph = async (station_id: any, station_name: any) => {
    await DashboardService.getAverageSkills(station_id)
      .then((response: any) => {
        if (response.data) {
          setData(response.data);
        }
        setStation(station_name);
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (event && event.stations.length > 0) {
      loadGraph(event.stations[0].id, event.stations[0].name);
    }
  }, [event]);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.cardContainer}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("graph_event_title_competence_grade")}
            </Typography>
            <Typography variant="h6" sx={{ mt: 1, mb: 3 }}>
              {" "}
              {t("graph_event_subtitle_competence_grade")}
            </Typography>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: "bold" }}>
              {" "}
              {station}
            </Typography>
            <Box sx={{ height: 500 }}>
              <ResponsiveRadar
                data={data}
                keys={["estacao"]}
                indexBy="habilidade"
                valueFormat=">-.2f"
                margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                borderColor={{ from: "color" }}
                gridLevels={12}
                gridShape="circular"
                gridLabelOffset={36}
                dotSize={10}
                dotColor={{ theme: "background" }}
                dotBorderWidth={2}
                colors={{ scheme: "set2" }}
                blendMode="multiply"
                motionConfig="wobbly"
              />
            </Box>
            <Box className={classes.boxStationArea}>
              {event?.stations.length > 0 &&
                event?.stations.map((row, index) => (
                  <Box
                    key={index}
                    onClick={() => loadGraph(row?.id, row?.name)}
                    className={classes.boxStation}
                  >
                    {row?.name}
                  </Box>
                ))}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
