import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // backgroundColor: 'red'
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      outline: "none",
      gap: 10
    },
    header: {
      display: "flex",
      width: '100%',
      height: '20%',
      backgroundColor: colors.white,
      alignItems: "center",
      borderRadius: 5,
      padding: 30
    },
    headerTitle: {
      fontSize: '2em !important',
      color: colors.blueDark,
      fontWeight: 'bold',
    },
    content: {
      display: "flex",
      width: '100%',
      height: '100%',
      backgroundColor: colors.white,
      flexDirection: 'column',
      // alignItems: "center",
      borderRadius: 5
    },
    body: {
      display: "flex",
      width: '100%',
      height: '100%',
      // alignItems: "center",
      padding: 30
    },
    footer: {
      display: "flex",
      width: '100%',
      // alignItems: "center",
      borderTop: `1px solid ${colors.gray}`,
      padding: 30
    },
    footerContainerButton: {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: 10,
      width: '100%',
    },
    buttonPrimary: {
      backgroundColor: colors.purple,
      color: colors.white
    },
    buttonSecondary: {
      backgroundColor: colors.white,
      color: colors.purple
    }
  }),
)
