import * as React from 'react'

import { useStyles } from './styles/index.styles'
import { useTranslation } from 'react-i18next'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import { Box, Card, CardContent, Typography } from '@mui/material'
import ButtomDownload from '../buttondownload'

export default function CardFile({ path, title }: any) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box>
      <Card>
        <CardContent className={classes.container}>
          <Typography variant="h5">{title}</Typography>
          <ButtomDownload url={path} />
        </CardContent>
      </Card>
    </Box>
  )
}
