import React, { useState, useEffect } from "react";
import { Button, Box, Typography, LinearProgress, Grid } from "@mui/material";
import { useStyles } from "./styles/index.styles";
import EventsService from "../../../services/EventsService";
import { EventConclusionModal } from "../../../common/components/modal/EventConclude/EventConclusionModal";

import { toast } from "react-toastify";

import moment from "moment";
import "moment/locale/pt-br";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useTranslation } from "react-i18next";

interface ConcludedEventProps {
  event: any;
  getCurrentEvent: any;
}

const ConcludeEvent: React.FC<ConcludedEventProps> = ({
  event,
  getCurrentEvent,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [eventConcluded, setEventConcluded] = useState(false);
  const [correctionsCompleted, setCorrectionsCompleted] = useState(100);

  useEffect(() => {}, [eventConcluded, event]);

  console.log(event);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleEventConcluded = async () => {
    try {
      await EventsService.concludeEvent(event?.id);
      getCurrentEvent(event?.id);
      toast.success(t("toast_conclude_event_description_success"));
    } catch (error) {
      toast.error(t("toast_conclude_event_description_error"));
    }
  };

  return (
    <Box>
      {event?.status == "in_correction" && (
        <Typography variant="h6" sx={{ marginBottom: "40px" }}>
          {t("first_description_dialog_in_correction")}
          {t("second_description_dialog_in_correction")}
        </Typography>
      )}
      {event?.status == "concluded" && (
        <Typography variant="h6" sx={{ marginBottom: "40px" }}>
          {t("first_description_dialog_concluded")}
          {moment(event.concluded_date).format("DD/MM/YYYY")}
          {t("second_description_dialog_concluded")}
        </Typography>
      )}

      <Box
        className={
          event?.status == "concluded"
            ? classes.concludedBox
            : classes.boxConclude
        }
      >
        <Box className={classes.boxConcludeProgress}>
          {event?.status == "concluded" && (
            <CheckCircleIcon
              style={{
                color: "#2E7D32",
                fontSize: "28px",
                marginRight: "16px",
                alignItems: "center",
              }}
            />
          )}
          <Typography variant="h5">
            <strong>
              {event?.status == "concluded"
                ? t("concluded")
                : t("title_conclusion_of_the_event")}
            </strong>
          </Typography>
          <LinearProgress
            className={classes.progressBar}
            style={{ width: "30%", height: 8, borderRadius: 5 }}
            variant="determinate"
            value={correctionsCompleted}
          />
          <Typography variant="body2" sx={{ fontSize: "18px" }}>
            {correctionsCompleted}% das correções concluídas.
          </Typography>
        </Box>
        {event?.status == "concluded" && (
          <Box>
            <Typography
              variant="body2"
              style={{ fontSize: "16px", justifyContent: "center" }}
            >
              {t("first_description_dialog_events_concluded")}
              <strong>{t("strong_dialog_events_concluded")}</strong>
              {t("second_description_dialog_events_concluded")}
              &quot;{t("quotation_marks_description_dialog_events_concluded")}
              &quot;.
            </Typography>
          </Box>
        )}

        {event?.status == "in_correction" && (
          <Grid container spacing={3} className={classes.contentContainer}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body2"
                className={classes.description}
                sx={{ fontSize: "18px" }}
              >
                {t("first_description_dialog_events_in_correction")}
                {t("second_description_dialog_events_in_correction")}
                {t("third_description_dialog_events_in_correction")}
                <strong>
                  &quot;{t("strong_description_dialog_events_in_correction")}
                  &quot;
                </strong>
                .
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                className={classes.boxConcludeButton}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleOpenModal}
                  sx={{ fontSize: "16px" }}
                >
                  {t("btn_complete_event")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}

        {modalOpen && (
          <EventConclusionModal
            eventId={event?.id}
            onClose={handleCloseModal}
            onConclude={handleEventConcluded}
          />
        )}
      </Box>
    </Box>
  );
};

export default ConcludeEvent;
