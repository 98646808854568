import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'
import { WidthFull } from '@mui/icons-material'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigBanner: {
      width: '100%',
      height: '27.938em',
      backgroundColor: colors.white,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    mediumBanner: {
      width: '100%',
      height: '19em',
      //paddingLeft: '30px',
      backgroundColor: colors.white,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      [theme.breakpoints.between('xs', 'lg')]: {
        fontSize: 'x-small',
      },
    },
    smallBanner: {
      width: '100%',
      height: '11.313em',
      background: 'linear-gradient(89.83deg, #601DCC 38.88%, rgba(90, 61, 184, 0.75) 99.79%)',
      [theme.breakpoints.between('xs', 'lg')]: {
        //fontSize: 'x-small', 
        width: '100%',
      },
    },
    studentBanner: {
      width: '100%',
      height: '11.313em',
      background: 'linear-gradient(89.88deg, #FFFFFF 11.31%, #C2B1FB 89.67%)',
    },
    iconBanner: {
      width: '100%',
      height: '8em',
      background: 'linear-gradient(89.88deg, #FFFFFF 11.31%, #C2B1FB 89.67%)',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
      padding: '0 16px',
    },
    gridIconBanner: {
      height: '8em',
      display: 'flex',
      alignItems: 'center',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    iconElement: {
      marginRight: '16px', // Espaço entre o ícone e o título
      fontSize: '2.5em', // Ajuste conforme necessário
      color: colors.purplePrimary, // Ajuste a cor conforme necessário
    },
    titleIconBanner: {
      fontSize: '1.5em !important',
      color: colors.purplePrimary,
      fontWeight: 'bold',
    },
    titleBigBanner: {
      fontSize: '4.5em !important',
      color: colors.white,
      fontWeight: 'bold',
    },
    titleMediumBanner: {
      fontSize: '4em !important',
      color: colors.white,
      fontWeight: 'bold',
    },
    titleSmallBanner: {
      fontSize: '3em !important',
      color: colors.white,
      fontWeight: 'bold',
    },
    subtitleBanner: {
      fontSize: '1.125em !important',
      color: colors.white,
      fontWeight: 'bold',
      marginTop: '20px !important',
      fontFamily: fonts.interComponent,
    },
    containerBanner: {},

    gridBigBanner: {
      height: '27.938em',
      display: 'flex',
      alignItems: 'center',
    },
    gridMediumBanner: {
      height: '19em',
      display: 'flex',
      alignItems: 'center',
    },
    gridSmallBanner: {
      height: '11.313em',
      display: 'flex',
      alignItems: 'center',
    },
    divider: {
      backgroundColor: colors.purpleDivDark,
      height: 5,
      width: 103,
    },
    dividerLight: {
      backgroundColor: colors.lilacDarkMedium,
      height: 5,
      width: 103,
    },
    dividerNew: {
      marginTop: '38px !important',
      marginBottom: '38px !important',
      backgroundColor: colors.purpleNewDivider,
      height: 8,
      width: 103,
    },
    smallContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '85% !important',
    },
    studentContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '95% !important',
    },
    subStudentContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    titleStudentBanner: {
      fontSize: '2.25em !important',
      color: colors.purplePrimary,
      fontWeight: 'bold',
    },
    subtitleStudentBanner: {
      fontSize: '1.125em !important',
      color: colors.purpleMediumSub,
      fontWeight: 'bold',
      marginTop: '5px !important',
      fontFamily: fonts.interComponent,
    },
    subSubtitleStudentBanner: {
      fontSize: '1em !important',
      color: colors.purpleMediumSub,
      fontWeight: 'bold',
      fontFamily: fonts.interComponent,
    },
    studentAvatar: {
      marginRight: '40px',
    },
  }),
)
