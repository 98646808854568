import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../common/styles/colors";
import fonts from "../../common/styles/fonts";

export const useThemeStyle = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      width: "100%",
    },
    boxExtraInfo: {
      borderRadius: "6px",
      width: "100%",
      backgroundColor: colors.purpleLightStation,
      padding: "20px",
      color: colors.black,
      display: "flex",
      justifyContent: "space-between",
    },
  })
);
