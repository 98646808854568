import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import { useDefaultStyles } from '../../common/styles/default'
import { useScenarioStyles } from '../styles/scenarios.styles'
import ScenarioTitleView from './ScenarioTitleView'
import { Divider, Grid, Typography } from '@mui/material'

export default function ScenarioPrescriptionsView({
  continuous_use_medications,
  temporary_medications,
  health_guidelines,
}: any) {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const classes = useStyles()
  const styles = useDefaultStyles()
  const scenarioStyle = useScenarioStyles()

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Box className={scenarioStyle.containerScenario}>
          {continuous_use_medications && (
            <Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                {t("title_dialog_medications_continuos_use")}
              </Typography>
              <Divider className={scenarioStyle.divider} />
              <Typography sx={{ marginTop: 2 }}>
                {continuous_use_medications}
              </Typography>
            </Box>
          )}

          {temporary_medications && (
            <Box>
              <Typography sx={{ fontWeight: 'bold', mt: 3 }}>
                {t("title_dialog_other_medicines")}
              </Typography>
              <Divider className={scenarioStyle.divider} />
              <Typography sx={{ marginTop: 2 }}>
                {temporary_medications}
              </Typography>
            </Box>
          )}

          {health_guidelines && (
            <Box>
              <Typography sx={{ fontWeight: 'bold', mt: 3 }}>
                {t("title_dialog_health_guidelines")}
              </Typography>
              <Divider className={scenarioStyle.divider} />
              <Typography sx={{ marginTop: 2, marginBottom: 5 }}>
                {health_guidelines}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
