import React from 'react'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { Divider, InputAdornment, TextField, Typography } from '@mui/material'
import { useStyles } from '../styles'

export default function CardActionChecklist({
  label,
  label_number,
  setValue,
  value,
  color,
}: any) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.cardGeneralAction}>
      <Typography
        variant="h2"
        sx={{ fontWeight: 'bold' }}
        className={classes.labelNumberCard}
      >
        {label_number}
      </Typography>
      <Box sx={{ backgroundColor: color }} className={classes.cardAction}>
        <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
        <Divider className={classes.dividerCardAction} />
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          type="number"
          variant="outlined"
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value)
          }}
        />
      </Box>
    </Box>
  )
}
