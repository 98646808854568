import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../common/styles/colors'
import fonts from '../../common/styles/fonts'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerTabMenu: {
      width: '100%',
      boxShadow: '0px 3px 8px rgba(145, 145, 145, 0.25)',
      backgroundColor: colors.white,
    },
    cardContainer: {
      padding: '40px !important',
    },
    boxStation: {
      borderRadius: '4px',
      border: '1px solid ' + colors.purple,
      backgroundColor: colors.lilacLight,
      color: colors.purple,
      textAlign: 'center',
      paddingTop: '2px',
      paddingLeft: '12px',
      paddingRight: '12px',
      paddingBottom: '2px',
      marginLeft: '10px',
      marginRight: '10px',
      marginTop: '14px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    boxStationArea: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: '10px',
      flexWrap: 'wrap',
    },
    closeGraph: {
      cursor: 'pointer',
      textAlign: 'right',
    },
    barBackground: {
      backgroundColor: colors.grayMediumLight,
      width: '100%',
      height: '50px',
    },
    barGrade: {
      backgroundColor: colors.green,
      height: '100%',
      color: colors.white,
      fontWeight: 'bold',
      paddingTop: '13px',
      textAlign: 'right',
      paddingRight: '7px !important',
    },
    boxGrade: {
      paddingTop: '30%',
    },
  }),
)
