import api from '../config/api'
import IRolesData from '../types/Roles'

const getAll = (page = 1, paginate = 0) => {
  return api.get<IRolesData>('/roles?page=' + page + '&paginate=' + paginate)
}

const getAllWithPermissions = (page = 1, paginate = 0) => {
  return api.get<IRolesData>(
    '/roles/permissions?page=' + page + '&paginate=' + paginate,
  )
}

const RolesService = {
  getAll,
  getAllWithPermissions,
}

export default RolesService
