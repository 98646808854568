import * as React from 'react'

import { useStyles } from './styles/index.styles'
import { useTranslation } from 'react-i18next'
import { useLocation, NavigateFunction } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { Link, Typography } from '@mui/material'
import Box from '@material-ui/core/Box'

type Props = {
  navigate: NavigateFunction
}

export default function BreadCrumb({ navigate }: Props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const [infoBread, setInfoBread] = useState<any[]>([])

  let crumbs = location.pathname.toString().split('/')
  let arrInfoBread: any[] = []

  const breadcrumb = (crumbs: any) => {
    let currentLink = ''
    let labelCrumb = ''
    let isHome = 0
    let endLabelCrumb = 0

    if (crumbs.length > 3) {
      crumbs.pop()
      endLabelCrumb = 1
    }

    if (location.pathname.indexOf('home') != -1) {
      isHome = 1
    }
    crumbs
      .filter((crumb) => crumb !== '' && crumb !== 'list' && crumb !== 'area')
      .map((crumb, index) => {
        currentLink += '/' + crumb
        labelCrumb += crumb
        const data = {
          link: isHome == 0 && index == 0 ? currentLink + '/list' : currentLink,
          crumb: crumb,
          label_crumb:
            'bread_' +
            labelCrumb +
            (endLabelCrumb == 1 && index > 0 ? '_u' : ''),
        }
        if (index < 3) {
          arrInfoBread.push(data)
        }
      })
    setInfoBread(arrInfoBread)
  }

  useEffect(() => {
    breadcrumb(crumbs)
  }, [])
  

  return (
    <Box className={classes.boxBreadItens}>
      {infoBread.map((crumb, index) => (
        <Box key={index} className={classes.boxBreadItens}>
          {index > 0 && (
            <Typography
              className={classes.breadcrumbText}
              sx={{ mx: 2 }}
            >
              /
            </Typography>
          )}
          {infoBread.length - 1 == index ? (
            <Box>
              <Typography className={classes.breadcrumbText}>
                {t(crumb.label_crumb)}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Link
                className={classes.breadcrumbText}
                sx={{ textDecoration: 'none' }}
                // href={crumb.link}
                onClick={() => navigate(-1)}
              >
                {t(crumb.label_crumb)}
              </Link>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  )
}
