import * as React from "react";
import { Box } from "@mui/system";
import { useStyles } from "../../events/styles/index.styles";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Divider, Grid } from "@mui/material";
import StationItem from "./StationItem";
import "moment/locale/pt-br";

export default function StationsList({ event, stations }: any) {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <Typography
        component="div"
        className={styles.titleCard}
        sx={{ fontWeight: 700, fontSize: 35 }}
      >
        {t("title_page_form_station_management")}
      </Typography>
      <Divider className={styles.dividerStation} />

      {Array.isArray(stations) &&
        stations.length > 0 &&
        stations?.map((station, index) => (
          <Box key={index} sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <Typography sx={{ fontWeight: "bold" }}>{t("list_event_station_namestation")}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontWeight: "bold" }}>{t("table_theme_label_name")}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontWeight: "bold" }}>{t("table_station_list_appraiser")}</Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography sx={{ fontWeight: "bold" }}>{t("table_station_list_performance")}</Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography sx={{ fontWeight: "bold" }}>{t("title_result")}</Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography sx={{ fontWeight: "bold" }}>{t("table_station_list_action")}</Typography>
              </Grid>
            </Grid>
            <Box sx={{ mb: 5 }}>
              <Divider />
            </Box>
            <StationItem
              station={station}
              eventId={event?.id}
              event={event}
              stationId={station?.station_id}
              evaluated={station?.evaluated}
              appraisers={station?.appraisers}
            />
          </Box>
        ))}
    </Box>
  );
}
