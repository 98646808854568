import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useScenarioStyles } from "../styles/scenarios.styles";
import ScenarioTitleView from "./ScenarioTitleView";
import { Grid } from "@mui/material";

export default function ScenarioMedicalRecordView({}: any) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const scenarioStyle = useScenarioStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Box className={scenarioStyle.containerScenario}>
          <ScenarioTitleView index="05" title={t("title_dialog_patient_record")} />
        </Box>
      </Grid>
    </Grid>
  );
}
