import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: colors.blueDarkest,
      fontSize: '2.625em !important',
      marginRight: '10px! important',
    },

    divider: {
      backgroundColor: colors.lilacMediumLight,
      width: '100% !important',
      marginTop: '25px !important',
      marginBottom: '25px !important',
    },
  }),
)
