import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'

import { useStyles } from '../styles'

import TitleForm from '../../common/components/titleform'
import CardActionChecklist from './CardActionChecklist'
import colors from '../../common/styles/colors'

export default function FormGeneralActions({
  valueDid,
  valueHalfDid,
  valueDidWrong,
  valueDidNt,
  setValueDid,
  setValueHalfDid,
  setValueDidWrong,
  setValueDidNt,
}: any) {
  const { t } = useTranslation()

  const classes = useStyles()

  useEffect(() => {}, [
    valueDid,
    valueHalfDid,
    valueDidWrong,
    valueDidNt,
    setValueDid,
    setValueHalfDid,
    setValueDidWrong,
    setValueDidNt,
  ])

  return (
    <Box>
      <TitleForm title={t('title_actions_checklist')} font_size="32px" />
      <Typography className={classes.subtitleAction}>
        {t('subtitle_actions_checklist')}
      </Typography>
      <Typography>{t('text_actions_checklist')}</Typography>
      <Grid container spacing={4} sx={{ mb: 4, mt: 4 }}>
        <Grid item xs={3} className={classes.gridCardAction}>
          <CardActionChecklist
            value={valueDid}
            setValue={setValueDid}
            label={t('label_value_action_did')}
            label_number="01"
            color={colors.green}
          />
        </Grid>
        <Grid item xs={3} className={classes.gridCardAction}>
          <CardActionChecklist
            value={valueHalfDid}
            setValue={setValueHalfDid}
            label={t('label_value_action_half_did')}
            label_number="02"
            color={colors.yellow}
          />
        </Grid>
        <Grid item xs={3} className={classes.gridCardAction}>
          <CardActionChecklist
            value={valueDidWrong}
            setValue={setValueDidWrong}
            label={t('label_value_action_did_wrong')}
            label_number="03"
            color={colors.pinkMedium}
          />
        </Grid>
        <Grid item xs={3} className={classes.gridCardAction}>
          <CardActionChecklist
            value={valueDidNt}
            setValue={setValueDidNt}
            label={t('label_value_action_didnt')}
            label_number="04"
            color={colors.pink}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
