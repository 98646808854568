import * as React from "react";
import { useTranslation } from "react-i18next";
import "moment/locale/pt-br";
import { Box } from "@mui/system";
import { useStyles } from "../../styles/index.styles";
import { useDefaultStyles } from "../../../common/styles/default";
import { ListItem } from "./ListItem";
import { Grid, Typography, useTheme } from "@mui/material";
import TitleAndDivider from "../TitleAndDivider";

interface EventAdditionalInformationViewProps {
  event: any;
  statusRoom: any;
}

export default function EventAdditionalInformationView({
  event,
  statusRoom,
}: EventAdditionalInformationViewProps) {
  const styles = useStyles();
  const stylesDefault = useDefaultStyles();
  const { t } = useTranslation();

  const theme = useTheme()
  

  const dataItem = [
    {
      icon: 'video_camera_front',
      title: 'title_report_event',
      description: 'description_report_event',
      url: '/dashboard/event/'
    },
    {
      icon: 'leaderboard',
      title: 'title_monitoring_room_class',
      description: 'description_monitoring_room_class',
      url: '/events/controlroom/'
    },
    {
      icon: 'file_copy',
      title: 'title_monitoring_room_files',
      description: 'description_data_ai',
      url: '/events/files/'
    },
    {
      icon: 'developer_board',
      title: 'title_data_ai',
      description: 'description_monitoring_room_files',
      url: '/events/data-ai/'
    },
  ]

  return (
    
    <Box 
      display={'flex'}
      flexDirection={'column'}
      gap={3}
    >
      <TitleAndDivider title={t('controls_and_content')}/>
      <Typography>
        {t('controls_and_content_info')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '1.2vw',
          [theme.breakpoints.down('md')]: {
            // flexDirection: 'column',
            gap: 2
          }
        }}
      >
        {dataItem.map(item => (
          <ListItem
            key={item.url+event}
            title={t(item.title)}
            description={t(item.description)}
            urlClick={item.url + event}
            iconName={item.icon}
            // status_event_ended={statusRoom !== "Corrigido"}
          />
        ))}
      </Box>
    </Box>
  );
}
