import React, { useEffect, useState } from 'react'
import hipladeTemplate from '../../common/template'
import EventScenarioView from '../components/EventScenarioView'
import UsersService from '../../services/UsersService'
import StationsService from '../../services/StationsService'
import ScenarioService from '../../services/ScenarioService'
import { useParams } from 'react-router-dom'

function StationsView() {
  const { eventId, stationId, evaluatedId, debriefingId } = useParams()
  const [student, setStudent] = useState('')
  const [station, setStation] = useState('')
  const [scenario, setScenario] = useState('')

  const loadStudent = async (evaluatedId: any) => {
    await UsersService.get(evaluatedId)
      .then((response: any) => {
        if (response) {
          setStudent(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  const loadStation = async (stationId: any) => {
    await StationsService.get(stationId)
      .then((response: any) => {
        if (response) {
          setStation(response.data)
          loadScenario(response.data.scenario_id);
        }
      })
      .catch((e: Error) => {})
  }

  const loadScenario = async (scenarioId: any) => {
    await ScenarioService.getScenarioById(scenarioId)
      .then((response: any) => {
        if (response) {
          setScenario(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  useEffect(() => {
    loadStudent(evaluatedId)
    loadStation(stationId)
  }, [])

  return (
    <EventScenarioView
      student={student as any}
      eventId={eventId}
      stationId={stationId}
      evaluatedId={evaluatedId}
      station={station as any}
      scenario={scenario}
      debriefingId={debriefingId}
    />
  )
}

export default hipladeTemplate(StationsView)
