import React from "react";
import EventInLive from "../components/debriefing-meeting-live/index";
import hipladeTemplate from "../../common/template";
import { useLocation } from "react-router-dom";

function EventMeetingLive() {
  return <EventInLive />;
}

export default hipladeTemplate(EventMeetingLive);
