import React, { useState } from 'react'
import StationsService from '../../../services/StationsService'

type InitialStateProps = {
  loading: boolean 
  success: boolean
  error: boolean
  data: any
}

const initialState:InitialStateProps = {
  data: null,
  error: false,
  loading: false,
  success: false
}

export function useObserver() {

  const [ observerStatusState, setObserverStatusState ] = useState<InitialStateProps>(initialState)
  const [ observerStartState, setObserverStartState ] = useState<InitialStateProps>(initialState)
  const [ observerStopState, setObserverStopState ] = useState<InitialStateProps>(initialState)

  const getObserverStatusByStation = async (idStation: any) => {
    setObserverStatusState({...initialState, loading: true})
    try {
      const response = await StationsService.getObserverStatusByStation(idStation)
      setObserverStatusState({...initialState, success: true, data: response?.data})
      
    } catch (error) {
      console.log("Error: ", error);
      setObserverStatusState({...initialState, error: true})
    }
  }

  const startObserver = async (data: {station_id: any, event_id: any}) => {
    try {
      const response = await StationsService.startObserver(data)
      
    } catch (error) {
      console.log("Error: ", error);
      setObserverStartState({...initialState, error: true})
    }
  }

  const stopObserver = async (data: {station_id: any}) => {
    
    try {
      const response = await StationsService.stopObserver(data)
      setObserverStartState({...initialState, success: true, data: response})
      
    } catch (error) {
      console.log("Error: ", error);
    } 
  }

  return {
    observerStatusState,
    observerStopState,
    observerStartState,
    getObserverStatusByStation,
    startObserver,
    stopObserver
  }
}
