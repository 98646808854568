import api from '../config/api'

interface RatingItemsProps {
  comment: CommentRatingItemProps,
  audio: AudioRatingItemProps
}

export interface AudioRatingItemProps {
  id: number;
  audio_url: string
}
export interface CommentRatingItemProps {
  id: number;
  comment: string
}



export const storeCommentService = (data) => {
  return api.post('debriefing/comments', data)
}

export const storeAudioDebriefingService = (data:any) => {
  return api.post('debriefing/audios', data)
}

export const deleteAudioDebriefingService = (id:number) => {
  return api.delete(`debriefing/audios/${id}`)
}

export const updateCommentService = (data, id:any) => {
  return api.put(`debriefing/comment/${id}`, data)
}

export const getRatingItemService = (debriefingId:any, itemId:any) => {
  return api.get<RatingItemsProps>(`debriefing/${debriefingId}/item/${itemId}/ratingitems`)
}

