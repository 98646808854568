import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import { useDefaultStyles } from '../../common/styles/default'
import { useScenarioStyles } from '../styles/scenarios.styles'
import ScenarioTitleView from './ScenarioTitleView'
import { Divider, Grid, Typography } from '@mui/material'
import CardFile from '../../common/components/cardfile'
import AttachmentService from '../../services/AttachmentService'

export default function ScenarioScriptItem({ idScenario, name, title }: any) {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const classes = useStyles()
  const styles = useDefaultStyles()
  const scenarioStyle = useScenarioStyles()
  const feature = 'scenario'

  const [attachment, setAttachment] = useState([] as any)

  const getFile = async () => {
    await AttachmentService.get(feature, idScenario, name)
      .then((response) => {
        setAttachment(response.data)
      })
      .catch((e: any) => {})
  }

  useEffect(() => {
    if (idScenario) {
      getFile()
    }
  }, [name, title, idScenario])

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Box>
          {attachment.map((f, index) => (
            <Box sx={{ mt: 3, mb: 2 }} key={index}>
              <CardFile title={title} path={f.url} />
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  )
}
