import * as React from "react";
import { Box } from "@mui/system";
import EventsService from "../../services/EventsService";
import { useStyles } from "../styles/index.styles";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import MyEventFilesItem from "./MyEventFilesItem";

export default function MyEventFiles({ stations }: any) {
  const styles = useStyles();
  const [statusClassName, setStatusClassName] = React.useState("");
  const { t } = useTranslation();

  const stationsData = Array.isArray(stations) ? stations : false

  React.useEffect(() => {}, [stations]);

  return (
    <Box className={styles.container}>
      <Typography
        className={styles.titleCard_2}
        sx={{ fontWeight: 700, fontSize: 35 }}
      >
        {t("title_files_event")}
      </Typography>
      <Divider className={styles.dividerStation} />
      <Box className={styles.boxFiles}>
        <Typography sx={{ mb: 2 }}>
          {t("description_my_event_files")}
        </Typography>
        <Divider className={styles.dividerStation} />
        {stationsData && stationsData.map((item, index) => (
          <MyEventFilesItem item={item} key={index} />
        ))}
      </Box>
    </Box>
  );
}
