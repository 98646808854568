import { Box, Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useState, useEffect, useContext } from "react";
import { useStyles } from "../styles/index.styles";
import { useNavigate } from "react-router-dom";
import AvatarHiplade from "../../common/components/avatarhiplade";
import StationsService from "../../services/StationsService";
import colors from "../../common/styles/colors";
import loadstatus from "../../assets/images/load-status.gif";
import { AppContext } from "../../contexts/AppContext";
import secureLocalStorage from "react-secure-storage";
import PerformanceItem from "./PerformanceItem";
import { useTranslation } from "react-i18next";
import {
  DataStoreDebriefing,
  useUploadFile,
} from "../../events/hooks/useUploadFile";
import VideoTag from "./VideoTag";

interface StationItemProps {
  station: any;
  eventId: string;
  stationId: string;
  evaluated: any;
  appraisers: any;
  event: any;
}

const StationItem: React.FC<StationItemProps> = ({
  station,
  eventId,
  stationId,
  evaluated,
  appraisers,
  event,
}) => {
  const arrStatus = ["revised", "corrected"];
  const { t } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();

  const {
    state: { debriefing },
  } = useContext(AppContext);
  const profileId = secureLocalStorage.getItem("role") as number | null;

  const {
    loadingUploadFile,
    errorUploadFile,
    successUploadFile,
    fileUploaded,
    storeDebriefing,
    setFileUploaded,
  } = useUploadFile();

  const goToFeedback = (
    studentId: string,
    eventId: string,
    stationId: string,
    debriefingId: string
  ) => {
    if (profileId === 5) {
      // Verifica se é estudante
      navigate(
        `/myevents/feedback/view/${eventId}/${stationId}/${studentId}/${debriefingId}?id_tab=2` // aqui foi adicionado um novo parâmetro (query-params)
      );
    } else {
      // Redirecionar para outra página se não for estudante
      navigate(
        `/myevents/feedback/view/${eventId}/${stationId}/${studentId}/${debriefingId}`
      );
    }
  };

  const handleTextColorUploadFile = () => {
    if (successUploadFile) {
      return {
        text: t("title_video_sent"),
        color: colors.greenMedium,
      };
    }
    if (errorUploadFile) {
      return {
        text: t("title_video_not_sent"),
        color: colors.pinkLight,
      };
    }
    if (loadingUploadFile) {
      return {
        text: t("loading"),
        color: colors.blackMedium,
      };
    }

    return {
      text: "-",
      color: colors.blackMedium,
    };
  };

  const responseUploadFile = handleTextColorUploadFile();

  const [statusPerformance, setStatusPerformance] = useState("");

  const colorStatus = (status: string) => {
    switch (status) {
      case "initializing":
      case "processing_recording":
        return colors.purpleStatus;
      case "not_corrected":
      case "in_simulation":
        return colors.pinkLight;
      case "correcting":
        return colors.yellow;
      default:
        return colors.greenMedium;
    }
  };

  const handleOnchageInputFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data: DataStoreDebriefing = {
      evaluated_id: evaluated?.user_id,
      station_id: stationId as any,
      event_id: eventId as any,
      appraiser_id: appraisers.id,
      status: "not_corrected",
    };

    if (event.target.files != null) {
      storeDebriefing(event, data);
    }
  };

  const handleDeleteVideo = () => {
    setFileUploaded(null);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2}>
          <Typography className={styles.titleStudent}>
            <b>{station?.station_name + " / "}</b>
            {event?.studentsgroup_name}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          <Typography className={styles.titleStudent}>
            {station?.theme_name}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} className={styles.boxContentAvatar}>
          <AvatarHiplade
            nameUser={appraisers?.name}
            size="mini"
            theme="gray"
            id={appraisers?.id}
          />
          <Typography className={styles.titleStudent}>
            {appraisers?.name}
          </Typography>
        </Grid>

        <Grid item xs={2} sm={2} md={2}>
          <Typography
            sx={{
              fontWeight: 700,
              color: responseUploadFile.color,
            }}
          >
            {evaluated?.station_score ? (
              <PerformanceItem
                score={evaluated?.station_score}
                setStatusPerformance={setStatusPerformance}
              />
            ) : (
              "-"
            )}
          </Typography>
        </Grid>

        <Grid item xs={2} sm={2} md={2}>
          <Typography
            sx={{
              fontWeight: 700,
              color: responseUploadFile.color,
            }}
          >
            {evaluated?.station_score
              ? statusPerformance
              : responseUploadFile.text}
          </Typography>
        </Grid>

        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          className={styles.alignButtonsListStation}
        >
          {event?.status == "scheduled" && evaluated?.debriefing_status && (
            <Typography
              sx={{
                fontWeight: 700,
                color: colorStatus(evaluated?.debriefing_status),
              }}
            >
              {t(evaluated?.debriefing_status)}
            </Typography>
          )}

          {!loadingUploadFile && !evaluated?.debriefing_status && (
            <>
              {fileUploaded ? (
                <VideoTag
                  label={fileUploaded.name.split(".")[0]}
                  onDelete={handleDeleteVideo}
                />
              ) : (
                <Box>
                  <input
                    id={"student"}
                    name={"student"}
                    style={{ display: "none" }}
                    onChange={handleOnchageInputFile}
                    accept="application/mp4"
                    type="file"
                  />
                  <label htmlFor={"student"}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        borderRadius: "4px",
                        cursor: "pointer",
                        color: colors.pinkLight,
                        border: "2px solid " + colors.pinkLight,
                        textTransform: "uppercase",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        textAlign: "center",
                        width: "170px !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {t("btn_upload_video")}
                    </Typography>
                  </label>
                </Box>
              )}
            </>
          )}

          {arrStatus.includes(evaluated?.debriefing_status) && (
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{ ml: 2 }}
              onClick={() =>
                goToFeedback(
                  evaluated?.user_id,
                  eventId,
                  stationId,
                  evaluated?.debriefing_id
                )
              }
            >
              {profileId === 5 ? t("btn_access_simulation") : t("btn_access")}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default StationItem;
