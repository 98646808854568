import * as React from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
// import { useModal } from '../../hooks/useModal';
import { useStyles } from "./styles/index.styles";
import { Box, CircularProgress } from "@material-ui/core";
import { Typography } from "@mui/material";
import { HeaderEditingImageSnapshot } from "./EditingImageSnapshot/Header";
import { ModalBaseComponentProps } from "../../dtos/modalDtos";
import { useTranslation } from "react-i18next";
import { useModal } from "../../hooks/useModal";
import useLayoutStyles from "../../hooks/useLayoutStyles";

export function ModalBase({
  BodyModal,
  HeaderModal,
  FooterModal,
}: ModalBaseComponentProps) {
  const classes = useStyles();

  const { dataModal, handleCloseModal, handleSaveModal } = useModal();

  const { contentContainerMarginLeft, smallScreen } = useLayoutStyles();

  const { t } = useTranslation();

  const stateDisabled = dataModal.loadingBtnClosed || dataModal.loadingBtnSave;

  return (
    <Modal
      open={dataModal.visible as boolean}
      onClose={handleCloseModal}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className={classes.container}
    >
      <Box
        sx={{ width: "100%", height: "100%" }}
        ml={contentContainerMarginLeft + "px"}
        className={classes.container}
      >
        <Box className={classes.wrapper}>
          {HeaderModal && (
            <Box className={classes.header}>
              <HeaderModal />
            </Box>
          )}
          <Box className={classes.content}>
            {BodyModal && (
              <Box className={classes.body}>
                <BodyModal />
              </Box>
            )}
            <Box className={classes.footer}>
              {FooterModal ? (
                <FooterModal />
              ) : (
                <Box className={classes.footerContainerButton}>
                  {dataModal?.data?.showBtBack != 0 && (
                    <Button
                      variant="contained"
                      onClick={handleCloseModal}
                      className={classes.buttonSecondary}
                      disabled={stateDisabled}
                      sx={{
                        "&.Mui-disabled": {
                          opacity: 0.5,
                        },
                      }}
                    >
                      {dataModal.loadingBtnClosed && (
                        <CircularProgress size={20} />
                      )}{" "}
                      {dataModal.txtBtnBack}
                    </Button>
                  )}

                  {dataModal?.data?.showBtSave != 0 && (
                    <Button
                      variant="contained"
                      disabled={stateDisabled}
                      onClick={handleSaveModal}
                      className={classes.buttonPrimary}
                      sx={{
                        "&.Mui-disabled": {
                          opacity: 0.5,
                        },
                      }}
                    >
                      {dataModal.loadingBtnSave && (
                        <CircularProgress color="inherit" size={20} />
                      )}{" "}
                      {dataModal.txtBtnSave}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
