import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../common/styles/colors";
import fonts from "../../common/styles/fonts";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerItem: {
      backgroundColor: "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(4),
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: 7,
    },
    boxContainer: {
      padding: "50px 120px",
      [theme.breakpoints.down("sm")]: {
        padding: "30px 15px",
      },
    },
    titleStudentCard: {
      color: colors.blackMedium,
      fontSize: "16px !important",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1em !important",
      },
    },
    titleCardBox: {
      color: colors.blueDark,
      textAlign: "left",
      fontSize: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1em !important",
      },
    },
    containerListItem: {
      width: "48%",
      height: "35vh",
      borderRadius: 5,
      // gap: 24,
      backgroundColor: "#fff",
      boxShadow: "0px 3px 8px rgba(145, 145, 145, 0.25)",
      display: "flex",
      flexDirection: "column",
      padding: "32px 32px 10px 32px",
      justifyContent: "space-between",
      alignItems: "flex-start",
      cursor: "pointer",
      "&:hover": { bgcolor: "#fff" },
      [theme.breakpoints.down("sm")]: {
        width: 170,
        height: "250px !important",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: 180,
      },
    },
    barListItem: {
      width: "3.5vw",
      height: 3,
      margin: "10px 0px",
      borderRadius: 10,
      backgroundColor: colors.purple,
      [theme.breakpoints.down("sm")]: {
        width: 50,
      },
    },
    barFooter: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    containerTabMenu: {
      width: "100%",
      boxShadow: "0px 3px 8px rgba(145, 145, 145, 0.25)",
      backgroundColor: colors.white,
    },
    container: {
      marginTop: 40,
    },
    containerCards: {
      display: "flex",
      flexWrap: "wrap",
    },
    containerCard: {
      width: 289,
      marginRight: 15,
      marginBottom: 15,
      padding: 20,
      cursor: "pointer",
    },
    containerConsiderations: {
      width: "100%",
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 30,
      paddingBottom: 70,
      display: "block",
      backgroundColor: "#F3EDFC",
    },
    breadCrumbCard: {
      textTransform: "uppercase",
      color: colors.purpleMedium,
    },
    titleCard: {
      color: colors.blueDark,
      textAlign: "left",
    },
    dividerCard: {
      backgroundColor: colors.purple,
      height: 3,
    },
    dividerStation: {
      backgroundColor: colors.lilacMediumLight,
    },
    dateEvent: {
      color: colors.black,
      marginTop: "15px !important",
      marginBottom: "10px !important",
    },
    statusLabelEventNext: {
      color: colors.blueMedium,
      fontWeight: 700,
    },
    statusEventNext: {
      color: colors.blue,
      fontWeight: 700,
    },
    statusLabelEventEnded: {
      color: colors.greenDark,
      fontWeight: 700,
    },
    statusEventEnded: {
      color: colors.green,
      fontWeight: 700,
    },
    statusLabelEventRunning: {
      color: colors.pink,
      fontWeight: 700,
    },
    statusEventRunning: {
      color: colors.pinkLight,
    },
    eventStatusTitle: {
      fontFamily: fonts.interComponent,
    },
    boxEventCreateItens: {
      border: "2px solid " + colors.lilacLightest,
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      padding: "35px",
      marginTop: 30,
    },
    cardItemAdd: {
      backgroundColor: colors.lilacMedium,
      boxShadow: "0px 2px 8px rgba(95, 69, 191, 0.6)",
      borderRadius: "12px",
      width: "16.133em",
      height: "12.234em",
      cursor: "pointer",
    },
    textCardItemAdd: {
      fontSize: "2em !important",
      textAlign: "center",
    },
    iconCircleCardAdd: {
      borderRadius: "50%",
      backgroundColor: colors.lilacDarkMedium,
      width: "2.188em",
      height: "2.188em",
    },
    iconAdd: {
      color: colors.purpleIcon,
    },
    boxIconAdd: {
      padding: 10,
      display: "flex",
      justifyContent: "flex-end",
    },
    boxTextCardAdd: {
      padding: "20px",
    },
    boxEventLinks: {
      width: "100%",
      display: "flex",
      justifyContent: "start",
      marginTop: 30,
    },
    dividerCardLink: {
      backgroundColor: colors.green,
      height: 3,
      width: 62,
    },
    containerCardLink: {
      width: 289,
      minHeight: 329,
      marginRight: 15,
      marginBottom: 15,
      padding: 15,
      cursor: "pointer !important",
      display: "flex",
      alignItems: "center",
    },
    titleCardLink: {
      color: colors.blueDark,
      marginTop: "10px !important",
      marginBottom: "15px !important",
    },
    eventOverviewBody: {
      fontFamily: fonts.interComponent,
    },
    eventStationListStudents: {
      margin: 20,
      backgroundColor: colors.purpleLightStation,
      alignItems: "center",
      boxShadow: "0px 3px 8px rgba(145, 145, 145, 0.25)",
      borderRadius: "4px !important",
      padding: 15,
    },
    eventStationTitle: {
      color: colors.purpleMediumSub,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      marginRight: "10px !important",
      fontSize: "1.5em !important",
    },
    eventStationSubTitle: {
      color: colors.purpleSubTitle,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      marginRight: "10px !important",
      fontSize: "1.5em !important",
    },
    eventStationTitleAppraiser: {
      color: colors.blueDark,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      marginRight: "10px !important",
      alignItems: "right",
    },
    eventBoxListMonitoringRoom: {
      backgroundColor: colors.purpleLightStation,
      alignItems: "center",
      justifyContent: "space-between",
      border: `1px solid ${colors.purple}`,
      padding: "20px 30px ",
      display: "flex",
    },
    eventBoxListMonitoringRoomWithoutBG: {
      // backgroundColor: colors.purpleLightStation,
      alignItems: "center",
      justifyContent: "space-between",
      border: `1px solid ${colors.purple}`,
      padding: "20px 30px ",
      display: "flex",
    },
    eventBoxskill: {
      marginTop: 40,
      width: "100%",
      left: "392px",
      top: "544px",
      backgroundColor: colors.white,
      boxShadow: "red",
      display: "block",
    },
    boxTitleStation: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "end",
      alignContent: "center",
    },
    appraiserName: {
      fontSize: "1em !important",
      color: colors.purpleAvatarName,
    },
    textDetail: {
      color: colors.purpleDetail,
    },
    boxContentAvatar: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "start",
      alignContent: "center",
    },
    titleStudent: {
      color: colors.blackMedium,
      fontSize: "1em !important",
      textAlign: "left",
    },
    containerCardHomeLink: {
      width: "98%",
      minHeight: 329,
      marginRight: 15,
      marginBottom: 15,
      padding: 15,
      cursor: "pointer !important",
      display: "flex",
      alignItems: "center",
    },
    boxArea: {
      maxWidth: "937px !important",
      margin: "0 auto",
    },
    boxComponent: {
      width: "100% !important",
    },
    titleScenarioCheckbox: {
      fontSize: "1.7em !important",
      color: colors.blackDark,
      marginBottom: "20px !important",
    },
    boxInputVitalSigns: {
      paddingLeft: "70px",
      paddingRight: "70px",
      fontWeight: "bold",
      fontSize: "1.2em !important",
    },
    topField: {
      marginTop: "40px !important",
    },
    topBox: {
      marginTop: "20px !important",
    },
    divider: {
      backgroundColor: colors.lilacMediumLight,
      width: "100% !important",
    },
    buttonStartSimulation: {
      color: colors.greenMedium,
      border: `2px solid ${colors.greenMedium}`,
    },
    alignButtonsListStation: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
    },
    containerMarkerComment: {
      paddingTop: "20px",
      paddingBottom: "20px",
      display: "flex",
      width: "100%",
      marginTop: "10px",
      flexWrap: "wrap",
    },
    buttonMeeting: {
      color: colors.greenDarkButton,
      backgroundColor: colors.greenLight,
      border: `2px solid ${colors.greenDarkButton}`,
    },
    titleMetting: {
      color: colors.blackMedium,
      fontSize: "1em !important",
      textAlign: "left",
    },
    eventBoxListMeeting: {
      // backgroundColor: colors.purpleLightStation,
      alignItems: "center",
      width: "100%",
      border: `1px solid ${colors.purple}`,
      padding: "20px 30px ",
      display: "flex",
      marginBottom: 50,
    },
  })
);
