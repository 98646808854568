import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "./colors";

export const useDefaultStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginLeft: "80px",
      position: "relative",
      marginTop: 100,
      width: "92%",
      paddingBottom: 50,
      [theme.breakpoints.down('sm')]: {
        marginLeft: "40px",
      }
    },
    containerList: {
      marginLeft: "80px",
      paddingLeft: "80px",
      position: "relative",
      marginTop: 100,
      width: "92%",
      paddingBottom: 50,
    },
    containerForm: {
      position: "relative",
      width: "100%",
      paddingTop: "100px",
      paddingBottom: "100px",
      paddingLeft: "150px",
      paddingRight: "150px",
    },
    containerButton: {
      width: "100%",
      backgroundColor: colors.lilacLight,
      paddingTop: "70px",
      paddingBottom: "70px",
      paddingLeft: "150px",
      paddingRight: "150px",
      position: "relative",
      textAlign: "right",
    },
    containerButtonCenter: {
      width: "100%",
      backgroundColor: colors.lilacLight,
      paddingTop: "70px",
      paddingBottom: "70px",
      paddingLeft: "150px",
      paddingRight: "150px",
      position: "relative",
      textAlign: "center",
    },
    containerTitleTab: {
      position: "relative",
      width: "100%",
      paddingTop: "100px",
      paddingBottom: "20px",
      paddingLeft: "150px",
      paddingRight: "150px",
    },
    containerIa: {
      marginLeft: "150px",
      position: "relative",
      marginTop: 100,
      width: "92%",
      paddingRight: 30,
      paddingBottom: 50,
    },
    titleIa: {
      marginLeft: "80px",
      position: "relative",
      marginTop: 50,
      width: "92%",
      paddingBottom: 50,
    },
    dividerIa: {
      backgroundColor: colors.lilacMediumLight,
      width: "92% !important",
      marginTop: "25px !important",
      marginBottom: "25px !important",
    },
  })
);
