import { useEffect } from 'react';

//import { createSocketConnection } from '../socketService'

function listen(callBack: (payload: any) => void, channel: string, event: string) {
  console.log(`Call event: ${event} on Channel: ${channel}`)
  window.Echo.private(channel)
            .listen(event, (payload: any) => {
                //console.log(payload);
                callBack(payload);
    });

  return function cleanUp() {
    window.Echo.leaveChannel(`private-${channel}`);
  };
}

export const useSocket = ({callBack, channel, event}) => {

  useEffect(() => {

    //createSocketConnection();
    return listen(callBack, channel, event)

  });
};
