import api from "../config/api";
import ISpecialtyData from "../types/Specialty";

const getAll = () => {
  return api.get<ISpecialtyData>("themes/specialties");
};

const SpecialtyService = {
  getAll,
};

export default SpecialtyService;
