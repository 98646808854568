import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonDownload: {
      border: '1px solid' + colors.purpleTextButton,
      backgroundColor: colors.purpleBackButton,
    },
  }),
)
