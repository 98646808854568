import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useDefaultStyles } from '../../common/styles/default'
import AvatarHiplade from '../../common/components/avatarhiplade'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import secureLocalStorage from 'react-secure-storage'
import UsersService from '../../services/UsersService'
import IUsersData from '../../types/Users'
import Banner from '../../common/components/banner'
import { useStyles } from '../styles/index.styles'
import ChangePassword from './ChangePassword'

export default function profile() {
  const { t } = useTranslation()
  const styles = useDefaultStyles()
  const auth_user_id = secureLocalStorage.getItem('id')
  const classes = useStyles()

  const [user, setUser] = useState<IUsersData>()
  const [showPassword, setShowPassword] = useState(false)

  const loadUserInfo = async () => {
    await UsersService.get(auth_user_id).then((response) => {
      setUser(response.data)
    })
  }

  const changePass = () => {
    setShowPassword(true)
  }

  useEffect(() => {
    loadUserInfo()
  }, [])

  return (
    <Box>
      <Banner
        type="small"
        title={t('title_page_profile_user')}
        icon={
          <PersonOutlineOutlinedIcon
            sx={{ fontSize: '4em', color: '#FFFFFF', mr: 5 }}
          />
        }
      />
      <Box className={styles.containerForm}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <AvatarHiplade
              nameUser={user?.name}
              size="extra-large"
              upload={true}
              id={auth_user_id}
              /*source={
                user?.avatar && user?.avatar.length > 0
                  ? user?.avatar[0].static_url
                  : ''
              }*/
            />
          </Grid>
          <Grid item xs={7}>
            <Typography sx={{ fontWeight: 500 }} className={classes.nameUser}>
              {user?.name}
            </Typography>
            <Typography
              sx={{ fontWeight: 700 }}
              className={classes.profileUser}
            >
              {t('label_profile_profile')}: {t(user?.roles[0].name)}
            </Typography>
            <Box className={classes.boxInfo}>
              <Typography className={classes.infoUser}>
                <span style={{ fontWeight: 700, marginRight: 30 }}>
                  {t('label_profile_email')}:
                </span>{' '}
                {user?.email}
              </Typography>
            </Box>
            <Box className={classes.boxInfo}>
              <Typography className={classes.infoUser}>
                <span style={{ fontWeight: 700, marginRight: 30 }}>
                  {t('label_profile_document1')}:
                </span>{' '}
                {user?.cpf}
              </Typography>
            </Box>
            <Box className={classes.boxInfo}>
              <Typography className={classes.infoUser}>
                <span style={{ fontWeight: 700, marginRight: 30 }}>
                  {t('label_profile_document2')}:
                </span>{' '}
                {user?.rg}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={styles.containerButtonCenter}
        sx={{ mt: !showPassword ? 25 : 0 }}
      >
        {!showPassword && (
          <Button variant="contained" size="large" onClick={changePass}>
            {' '}
            {t('btn_change_password')}
          </Button>
        )}
        {showPassword && (
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={4}>
              <ChangePassword
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        )}
      </Box>
    </Box>
  )
}
