import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'
import { display } from 'html2canvas/dist/types/css/property-descriptors/display'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerHeader: {
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      justifyContent: 'end',
      backgroundColor: colors.white,
      width: '100%',
      top: 0,
      zIndex: 2,
      transition: '1s left,1s all,1s opacity',
    //[theme.breakpoints.down('sm')]: {
      //maxWidth: '100vw',
    //}, // Este breakpoint estava deixando o header sendo carregado com atraso quando expande a resolução
    [theme.breakpoints.between(0, 1200)]: {
      backgroundColor: colors.purpleDark,
    }
    },
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingTop: 10,
      paddingBottom: 10,
      transition: '1s left,1s all,1s opacity',
      [theme.breakpoints.between('xs', 'lg')]: {  
        width: '100%',
        marginLeft: 5,
      },
    },
    boxSearch: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    boxAvatar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    boxBreadCrumb: {
      display: 'flex',
      alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '40%',
      justifyContent: 'center',
    },
    [theme.breakpoints.between(900, 1200)]: {
      marginLeft: 0,
    },
    [theme.breakpoints.between(0, 1200)]: {
      display: 'none',
    }
    },
    iconArrowColor: {
      color: colors.purpleLight,
    [theme.breakpoints.between(0, 1200)]: {
      color: colors.white,
    }  
    },
    textSearch: {
      backgroundColor: colors.grayLightest,
      fontSize: '12px',
    },
    avatar: {
      backgroundColor: colors.lilacLight,
      color: colors.purple,
    },
    breadcrumbText: {
      color: colors.purple,
    },
    boxMenuOutlinedIcon: {
      display: 'flex',
    },
  }),
)
