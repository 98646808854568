import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between !important',
      alignItems: 'center',
      padding: '10px',
    },
  }),
)
