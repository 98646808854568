import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles/index.styles";
import { useDefaultStyles } from "../../common/styles/default";
import { useScenarioStyles } from "../styles/scenarios.styles";
import ScenarioTitleView from "./ScenarioTitleView";
import { Divider, Grid, Typography } from "@mui/material";

export default function ScenarioObjectiveView({ goal_scene }: any) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const classes = useStyles();
  const styles = useDefaultStyles();
  const scenarioStyle = useScenarioStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Box className={scenarioStyle.containerScenario}>
          <ScenarioTitleView index="03" title={t("input_label_goal_scene")} />
          <Divider className={scenarioStyle.divider} />
          <Typography sx={{ marginTop: 2 }}>{goal_scene}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
