import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import EventsService from "../../services/EventsService";
import Banner from "../../common/components/banner";
import { useDefaultStyles } from "../../common/styles/default";
import General from "./General";
import MyEventStatus from "./MyEventStatus";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { useStyles } from "../styles/index.styles";
import StationsList from "./StationsList";
import { Button, Typography, useTheme } from "@mui/material";
import moment from "moment";
import "moment/locale/pt-br";
import TitleAndDivider from "../../events/components/TitleAndDivider";
import { ListItem } from "../../events/components/EventAdditionalInformationView/ListItem";
import MyEventFiles from "./MyEventFiles";
import GraphStudentSkillGrade from "../../dashboard/components/GraphStudentSkillGrade";
import secureLocalStorage from "react-secure-storage";
import GeneralPerformanceBox from "./general-performance/GeneralPerformanceBox";
import DashboardService from "../../services/DashboardService";

export default function View() {
  const navigate = useNavigate();

  const theme = useTheme();

  const { t } = useTranslation();
  const styles = useDefaultStyles();
  const classes = useStyles();

  const { id } = useParams();
  const [event, setEvent] = useState({} as any);
  const [status, setStatus] = React.useState("");
  const [stations, setStations] = useState([] as any);
  const [arrStations, setArrStations] = useState([] as any);
  const [porc, setPorc] = useState(0);

  let student_id = secureLocalStorage.getItem("id");

  const goToEvent = (id: any) => {
    navigate(`/myevents/list`);
  };

  const loadStations = async (id: any) => {
    await EventsService.getEventEvaluatedByEvent(id)
      .then((response: any) => {
        if (response) {
          setStations(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadGraph = async (id: any) => {
    await DashboardService.getOverallAverage(id)
      .then((response: any) => {
        if (response.data && response.data.length > 0) {
          setPorc(response.data[0].value);
          console.log(response.data[0].value);
        }
      })
      .catch((e: any) => {
        setPorc(0);
      });
  };

  const loadStationsByEvent = async (id: any) => {
    await EventsService.getStationsByEvent(id)
      .then((response: any) => {
        if (response) {
          setArrStations(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadEvent = async (eventId) => {
    await EventsService.get(eventId)
      .then((response: any) => {
        //console.log("Load event: ", response);

        setEvent(response.data);
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  const eventStatus = async (eventId: any) => {
    try {
      const response = await EventsService.getCurrentStatus(eventId);
      console.log(response);
      setStatus(response?.data?.status);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const dataItem = [
    {
      icon: "video_camera_front",
      title: t("title_report_event"),
      description: t("description_see_the_reports"),
      url: "/dashboard/event/",
    },
    {
      icon: "file_copy",
      title: t("title_files_event"),
      description: t("description_download_these_files"),
      url: "/events/files/",
    },
  ];

  useEffect(() => {
    eventStatus(id);
    loadEvent(id);
    loadStations(id);
    loadStationsByEvent(id);
    loadGraph(id);
  }, []);

  return (
    <Box>
      <Banner
        type="small"
        image={"medium"}
        title={event?.name}
        icon={
          <VideocamOutlinedIcon
            sx={{ fontSize: "3.5em", color: "#FFFFFF", mr: 5 }}
          />
        }
        button={
          <Button variant="contained" color="secondary" onClick={goToEvent}>
            {t("btn_return")}
          </Button>
        }
      />
      <Box>
        <Box className={classes.containerStatus}>
          <General event={event} />

          {event?.status == "scheduled" && (
            <Typography>
              {t("first_description_myevents_scheduled")} 
              <strong>{event?.name}</strong> 
              {t("second_description_myevents_scheduled")} 
              {t("third_description_myevents_scheduled")} 
            </Typography>
          )}

          {event?.status == "in_correction" && (
            <Typography>
              {t("first_description_myevents_in_correction")}
              <strong>{event?.name}</strong> 
              {t("second_description_myevents_in_correction")} {" "}
              {moment(event?.date).locale("pt-br").format("DD/MM/YYYY")}
              {t("third_description_myevents_in_correction")}
            </Typography>
          )}

          {event?.status == "concluded" && (
            <Typography>
              {t("first_description_myevents_concluded")}O evento 
              <strong>{event?.name}</strong> 
              {t("second_description_myevents_concluded")} 
              {t("third_description_myevents_concluded")}
            </Typography>
          )}
        </Box>
      </Box>

      {event?.status == "concluded" && (
        <Box>
          <Box className={styles.container}>
            <GeneralPerformanceBox result={porc} />
          </Box>
        </Box>
      )}
      <Box>
        <Box className={styles.container}>
          <StationsList event={event} stations={stations} />
        </Box>
      </Box>
      <Box>
        <Box className={styles.container}>
          <MyEventFiles stations={arrStations} />
        </Box>
      </Box>
      {event?.status == "concluded" && (
        <Box>
          <Box className={styles.container}>
            <GraphStudentSkillGrade event={event} student_id={student_id} />
          </Box>
        </Box>
      )}
      <Box className={styles.containerButton}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={goToEvent}
        >
          {" "}
          {t("btn_return")}
        </Button>
      </Box>
    </Box>
  );
}
