import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import { FormLabel, TextField } from '@mui/material'
import { useScenarioStyles } from '../styles/scenarios.styles'

export default function FormScenarioHistoryHealth({
  illness,
  setIllness,
  allergy,
  setAllergy,
  habits,
  setHabits,
  surgeries,
  setSurgeries,
  family_health_history,
  setFamilyHealthHistory,
}: any) {
  const { t } = useTranslation()
  const classes = useStyles()
  const styleScenario = useScenarioStyles()

  useEffect(() => {}, [illness, allergy, habits, surgeries])

  return (
    <Box>
      <Box sx={{ mb: 10 }}>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          className={classes.titleScenarioCheckbox}
          sx={{ fontWeight: 'bold' }}
        >
          {t('input_label_illness')}
        </FormLabel>
        <Box className={styleScenario.topBox}>
          <TextField
            fullWidth
            id="illness"
            name="illness"
            variant="outlined"
            value={illness}
            placeholder={t("title_placeholder_type_here") || "" }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIllness(event.target.value)
            }}
            multiline
                rows={5}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 10 }}>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          className={classes.titleScenarioCheckbox}
          sx={{ fontWeight: 'bold' }}
        >
          {t('input_label_allergy')}
        </FormLabel>
        <Box className={styleScenario.topBox}>
          <TextField
            fullWidth
            id="allergy"
            name="allergy"
            variant="outlined"
            placeholder={t("title_placeholder_type_here") || "" }
            value={allergy}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAllergy(event.target.value)
            }}
            multiline
                rows={5}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 10 }}>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          className={classes.titleScenarioCheckbox}
          sx={{ fontWeight: 'bold' }}
        >
          {t('input_label_habits')}
        </FormLabel>
        <Box className={styleScenario.topBox}>
          <TextField
            fullWidth
            id="habits"
            name="habits"
            variant="outlined"
            placeholder={t("title_placeholder_type_here") || "" }
            value={habits}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setHabits(event.target.value)
            }}
            multiline
                rows={5}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 10 }}>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          className={classes.titleScenarioCheckbox}
          sx={{ fontWeight: 'bold' }}
        >
          {t('input_label_surgeries')}
        </FormLabel>
        <Box className={styleScenario.topBox}>
          <TextField
            fullWidth
            id="surgeries"
            name="surgeries"
            variant="outlined"
            value={surgeries}
            placeholder={t("title_placeholder_type_here") || "" }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSurgeries(event.target.value)
            }}
            multiline
                rows={5}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 3 }}>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          className={classes.titleScenarioCheckbox}
          sx={{ fontWeight: 'bold' }}
        >
          {t('input_label_family_health_history')}
        </FormLabel>
        <Box className={styleScenario.topBox}>
          <TextField
            fullWidth
            id="family_health_history"
            name="family_health_history"
            variant="outlined"
            placeholder={t("title_placeholder_type_here") || "" }
            value={family_health_history}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFamilyHealthHistory(event.target.value)
            }}
            multiline
                rows={5}
          />
        </Box>
      </Box>
    </Box>
  )
}
