import { Box, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useStyles } from "../../styles/index.styles";
import TimerBox from "../debriefing-video/TimerBox";
import { useMarkers } from "../../hooks/useMarkers";

export default function DebriefingCommentMarkers({
  debriefingId,
  itemId,
}: any) {
  const styles = useStyles();
  const { markers, getDebriefingCommentMarkers } = useMarkers();

  const openModal = () => {};

  useEffect(() => {
    if (debriefingId && itemId) {
      getDebriefingCommentMarkers(debriefingId, itemId);
    }
  }, [debriefingId, itemId]);

  return (
    <Box className={styles.containerMarkerComment}>
      {markers.map((row, index) => (
        <TimerBox
          key={index}
          onClick={openModal}
          time={row?.time}
          cameraIndex="1"
          player={false}
        />
      ))}
    </Box>
  );
}
