import React from 'react'
import ReactDOM from 'react-dom/client'
import Routers from './routers'
import { BrowserRouter as Router } from 'react-router-dom'
import './styles/styles.css'
import 'material-icons/iconfont/material-icons.css';

import './translation/i18n'
import { AppContextProvider } from './contexts/AppContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Router>
    <AppContextProvider>
      <Routers />
    </AppContextProvider>
  </Router>,
)
