
export const complex = [
  {
    value: 'B',
    label: 'Baixa',
  },
  {
    value: 'M',
    label: 'Média',
  },
  {
    value: 'A',
    label: 'Alta',
  },
]

export const envir = [
  {
    value: 1,
    label: 'ambulatório',
  },
  {
    value: 2,
    label: 'pronto socorro',
  },
  {
    value: 3,
    label: 'unidade de internação',
  },
  {
    value: 4,
    label: 'UTI',
  },
  {
    value: 5,
    label: 'centro cirúrgico',
  },
  {
    value: 6,
    label: 'espaço público',
  },
]
