import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/pt-br";

import EventStatus from "../EventStatus";
import TitleAndDivider from "../TitleAndDivider";
import ConcludeEvent from "../concluded-event/ConcludeEvent";

type Props = {
  event: any;
  status: any;
  getCurrentEvent: any;
};

export default function EventOverview({
  event,
  status,
  getCurrentEvent,
}: Props) {
  const classes = useStyles({ correctionsCompleted: 100 });
  const { t } = useTranslation();

  const [eventData, setEventData] = React.useState(event);

  React.useEffect(() => {
    setEventData(event);
  }, [event]);

  // concluded
  
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <TitleAndDivider title={t("overview_data")} />
      </Box>
      <EventStatus status={status} eventId={event?.id} />
      <Box className={classes.wrapper}>
        <Box>
          <Typography variant="h6">
            <strong>{t("label_form_event_date")}: </strong>
            {moment(eventData.date).locale("pt-br").format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="h6">
            <strong>{t("label_form_event_duration")}: </strong>
            {eventData.start_time} {t("label_form_event_duration_up_to")}{" "}
            {eventData.final_time}
          </Typography>
          <Typography variant="h6">
            <strong>{t("label_form_event_time_limit_per_station")}: </strong>
            {eventData.time_station}
          </Typography>
          <Typography variant="h6">
            <strong>{t("input_label_studentsgroup")}: </strong>
            {eventData.studentsgroup_name}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">
            <strong>{t("table_label_entity")}: </strong>
            {eventData.entity_name}
          </Typography>
        </Box>
      </Box>

      <ConcludeEvent event={eventData} getCurrentEvent={getCurrentEvent} />
    </Box>
  );
}
