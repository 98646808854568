import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles/index.styles";

import Banner from "../../common/components/banner";
import AvatarHiplade from "../../common/components/avatarhiplade";
import TabsHiplade from "../../common/components/tabshiplade";
import { Button, Grid } from "@mui/material";

import ScenarioOverview from "./ScenarioOverview";
import { useScenarioStyles } from "../styles/scenarios.styles";
import FormEventAppraiserFeedback from "./FormEventAppraiserFeedback";
import DebriefingForm from "./DebriefingForm";
import EventAppraiserFeedback from "./EventAppraiserFeedback";
import DebriefingVideoLive from "./debriefing-video-live/DebriefingVideoLive";
import { useDebriefings } from "../hooks/useDebriefings";
import FooterEvent from "./footer";
import DebriefingService from "../../services/DebriefingService";
import DataIa from "./DataIa";
import { toast } from "react-toastify";

interface EventScenarioViewProps {
  eventId: any;
  stationId: any;
  evaluatedId: any;
  student: {
    name: any;
    id: any;
    face_recognition: any;
  };
  station: {
    name: any;
    room_name: any;
    checklist_id?: any;
  };
  scenario: any;
  debriefingId: any;
  bannerOnBack?: () => void;
}

export default function EventScenarioView({
  eventId,
  stationId,
  evaluatedId,
  student,
  station,
  scenario,
  debriefingId,
  bannerOnBack
}: EventScenarioViewProps) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const id_tab = searchParams.get("id_tab") || "1" // se false ele devolve null o retorna 1

  const classes = useStyles();
  const scenarioStyle = useScenarioStyles();

  const { getDebriefingEventByEvaluated, dataTabsMenu } = useDebriefings();

  const [status_id, setStatusId] = useState(id_tab);
  const [loadingConsideration, setLoadingConsideration] = useState(false);

  const backEvent = (eventId: any) => {
    if(bannerOnBack) return bannerOnBack()
    navigate("/events/view/" + eventId);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setStatusId(newValue);
  };

  useEffect(() => {
    getDebriefingEventByEvaluated();
  }, [evaluatedId]);
  const handleConsiderationScales = async () => {
    setLoadingConsideration(true);
    try {
      const response = await DebriefingService.storeConsiderationsScales({
        debriefing_id: debriefingId,
        evaluated_id: evaluatedId,
        event_id: eventId,
      });
      setLoadingConsideration(false);
      toast(response.data.Msg, {
        type: "success",
        position: "top-center",
      });
    } catch (error: any) {
      setLoadingConsideration(false);
      toast(error?.response?.data?.Error, {
        type: "error",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [debriefingId]);

  return (
    <Box className={scenarioStyle.containerPage}>
      <Banner
        type="student"
        title={station?.name + "/ " + station?.room_name}
        subtitle={t("description_event_scenario_view") + student?.name}
        avatar={
          <AvatarHiplade nameUser={student?.name} id={student?.id} size="lg" />
        }
        button={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => backEvent(eventId)}
          >
            {t("btn_return_to _event")}
          </Button>
        }
      />

      <Box className={classes.containerTabMenu}>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            <TabsHiplade
              status_id={status_id}
              handleChangeTab={handleChangeTab}
              tabs={dataTabsMenu}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        {status_id == "1" && (
          <ScenarioOverview station={station} scenario={scenario} />
        )}
        {status_id == "2" && (
          <DebriefingForm
            eventId={eventId}
            stationId={stationId}
            evaluatedId={evaluatedId}
            debriefingId={debriefingId}
            checklistId={station?.checklist_id}
            station={station}
            scenario={scenario}
          />
        )}
        {status_id == "3" && (
          <>
            <FormEventAppraiserFeedback
              eventId={eventId}
              stationId={stationId}
              evaluatedId={evaluatedId}
              debriefingId={debriefingId}
              station={station}
              scenario={scenario}
            />
            <FooterEvent
              isLoading={loadingConsideration}
              onClickAction={handleConsiderationScales}
            />
          </>
        )}
        {status_id == "4" && (
          <EventAppraiserFeedback
            eventId={eventId}
            stationId={stationId}
            evaluatedId={evaluatedId}
            debriefingId={debriefingId}
            station={station}
            scenario={scenario}
          />
        )}
        {status_id == "5" && (
          <DataIa idEvaluated={evaluatedId} idDebriefing={debriefingId} faceRecognition={student.face_recognition} />
        )}
      </Box>
    </Box>
  );
}
