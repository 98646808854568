import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../common/styles/colors'
import fonts from '../../common/styles/fonts'

export const useCameraStyle = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      width: '100%',
    },
  }),
)
