import React from 'react'
import EventInLive from '../components/debriefing-video-live/index'
import hipladeTemplate from '../../common/template'
import { useLocation } from 'react-router-dom'

function DebriefingVideoLive() {
  return <EventInLive />
}

export default hipladeTemplate(DebriefingVideoLive)
