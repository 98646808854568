import React, { useState } from 'react'
import { Box, TextField, Button, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search'

interface SearchBarProps {
  onSearch: (query: string) => void
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSearch = () => {
    onSearch(searchQuery)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt:5 ,mb: 2 }}>
      <TextField
        variant="outlined"
        placeholder={t('search_placeholder') || ''}
        value={searchQuery}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: '90%', mr: 4 }}
      />
      <Button
        variant="outlined"
        onClick={handleSearch}
        sx={{ 
          width: '10%',
          height: '55px',
          color: '#310AA2',
          fontWeight: 'bold', 
          border: '2px solid', 
          borderColor: '#310AA2' 
        }}
      >
        {t('search_button')}
      </Button>
    </Box>
  );
};

export default SearchBar;
