import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../common/styles/colors";
import fonts from "../../common/styles/fonts";

export const useScenarioStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerPage: {
      overflowX: "hidden",
    },
    container: {
      marginLeft: "100px",
      position: "relative",
      marginTop: 50,
      width: "92%",
    },
    titleNumber: {
      color: colors.purpleMediumSub,
      fontSize: "3em !important",
      marginRight: "10px! important",
    },
    title: {
      color: colors.blueDarkest,
      fontSize: "2.625em !important",
      marginLeft: "10px! important",
    },
    titleScenario: {
      color: colors.blueDarkest,
      fontSize: "2.625em !important",
      marginLeft: "10px! important",
      textTransform: "uppercase",
    },
    titleBox: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
    description: {
      color: colors.blackMedium,
    },
    divider: {
      backgroundColor: colors.lilacMediumLight,
      width: "100% !important",
    },
    backgroundItem: {
      backgroundColor: colors.lilacMedium,
    },
    boxCard: {
      border: "1px solid " + colors.lilacMediumLight,
      padding: 20,
      marginTop: 20,
      minHeight: "200px",
    },
    importButton: {
      backgroundColor: colors.green,
      border: "1px solid " + colors.green,
    },
    containerScenario: {
      marginLeft: "100px !important",
      position: "relative",
      marginTop: "50px !important",
    },
    boxScenarioRegister: {
      backgroundColor: colors.lilacMedium,
      marginTop: "50px",
      width: "100%",
      minHeight: "650px",
    },
    boxItemRegister: {
      color: colors.blackMedium,
      border: "1px solid " + colors.lilacMediumLight,
      padding: "10px",
      marginTop: "20px !important",
    },
    topField: {
      marginTop: "40px !important",
    },
    topBox: {
      marginTop: "20px !important",
    },
    boxInputVitalSigns: {
      paddingLeft: "70px",
      paddingRight: "70px",
    },
    subtitleFiles: {
      fontSize: "1.3em !important",
      marginTop: "15px !important",
    },
    boxFiles: {
      padding: "3px",
      //40px
    },
    boxFile: {
      borderRadius: "12px",
      backgroundColor: colors.white,
      boxShadow: "0px 2px 2px 0px rgba(145, 145, 145, 0.25)",
      padding: "30px",
      marginBottom: "25px",
    },
    buttonUpload: {
      borderRadius: "4px",
      backgroundColor: colors.greenMediumLight,
      boxShadow: "0px 4px 8px 0px rgba(40, 102, 105, 0.20)",
      cursor: "pointer",
      color: colors.white,
      padding: "10px",
      textAlign: "center",
      width: "170px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleUpload: {
      fontSize: "1.5em !important",
    },
    linkUpload: {
      fontSize: "1.5em !important",
      textDecoration: "none !important",
      color: colors.black,
    },
    boxExam: {
      borderRadius: "12px",
      backgroundColor: colors.white,
      boxShadow: "0px 2px 2px 0px rgba(145, 145, 145, 0.25)",
      padding: "30px",
      marginBottom: "25px",
    },
    buttonUploadExam: {
      borderRadius: "4px",
      backgroundColor: colors.greenMediumLight,
      boxShadow: "0px 4px 8px 0px rgba(40, 102, 105, 0.20)",
      cursor: "pointer",
      color: colors.white,
      padding: "10px",
      textAlign: "center",
      width: "170px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleFile: {
      fontSize: "1.5em !important",
      marginBottom: "10px !important",
    },
    titleExam: {
      fontSize: "1.5em !important",
      marginBottom: "10px !important",
    },
    gridFiles: {
      fontSize: "1.5em !important",
      marginTop: "10px !important",
      width: "100% !important",
    },
    boxListFiles: {
      display: "flex",
      flexWrap: "wrap",
    },
    boxAddFile: {
      borderRadius: "10px",
      backgroundColor: colors.purpleBackButton,
      padding: "7px",
      marginRight: "17px",
      display: "flex",
      alignItems: "center",
      marginBottom: "6px",
    },
    fontFile: {
      fontSize: "0.7em !important",
      color: colors.purpleTextButton,
      textDecoration: "none",
      cursor: "pointer",
    },
    boxListFilesExams: {
      display: "flex",
      flexWrap: "wrap",
    },
    boxExamFile: {
      borderRadius: "10px",
      backgroundColor: colors.purpleBackButton,
      padding: "7px",
      marginRight: "17px",
      display: "flex",
      alignItems: "center",
      marginBottom: "6px",
    },
    fontExamFile: {
      fontSize: "0.7em !important",
      color: colors.purpleTextButton,
      textDecoration: "none",
      cursor: "pointer",
    },
  })
);
