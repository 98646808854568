import api from '../config/api'
import IMetricData from '../types/Metric'

const getAll = () => {
  return api.get<IMetricData>('scenarios/metrics')
}

const MetricsService = {
  getAll,
}

export default MetricsService
