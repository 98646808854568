import { Box, Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import colors from "../../common/styles/colors";

const PerformanceItem: React.FC<any> = ({ setStatusPerformance, score }) => {
  const { t } = useTranslation();
  const [color, setColor] = useState("");

  const colorBar = () => {
    if (score < 60) {
      setColor(colors.pinkDark);
      setStatusPerformance(t("title_below_expected"));
    } else if (score >= 60 && score < 70) {
      setColor(colors.yellow);
      setStatusPerformance(t("title_limit_expected"));
    } else if (score >= 70 && score < 90) {
      setColor(colors.green);
      setStatusPerformance(t("title_as_expected"));
    } else if (score >= 90) {
      setColor(colors.greenMedium);
      setStatusPerformance(t("title_Above_expected"));
    }
  };

  useEffect(() => {
    colorBar();
  }, [score]);

  return (
    <Box
      sx={{
        backgroundColor: colors.purpleLightMark,
        borderRadius: 12,
        width: "80%",
      }}
    >
      <Box
        sx={{
          backgroundColor: color,
          borderRadius: 12,
          width: score > 100 ? "100%" : score + "%",
          textAlign: "center",
          color: colors.white,
        }}
      >
        {score > 100 ? "100" : score}%
      </Box>
    </Box>
  );
};

export default PerformanceItem;
