import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import StationsService from "../../services/StationsService";
import EventsService from "../../services/EventsService";
import CheckListService from "../../services/CheckListService";
import UsersService from "../../services/UsersService";
import ScenarioService from "../../services/ScenarioService";
import RoomsService from "../../services/RoomsService";

import IStationData from "../../types/Station";
import IScenarioData from "../../types/Scenario";
import IChecklistData from "../../types/Checklist";
import IUsersData from "../../types/Users";
import IRoomData from "../../types/Room";

import { useDefaultStyles } from "../../common/styles/default";
import { useModal } from "../../common/hooks/useModal";
import { Checkbox, ListItemText, OutlinedInput } from "@mui/material";

export type IFormStations = {
  eventId: any;
  idStation: any;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function FormStations() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { dataModal, onCloseModalReset, handleChangedModal } = useModal();

  const data: IFormStations = dataModal.data;
  

  const [nameStation, setNameStation] = useState("");
  const [room_id, setRoomId] = useState("");
  const [scenario, setScenario] = useState<IScenarioData[]>([]);
  const [checklist, setChecklist] = useState<IChecklistData[]>([]);
  const [appraisers, setAppraisers] = useState<IUsersData[]>([]);
  const [rooms, setRooms] = useState<IRoomData[]>([]);
  const [checklist_id, setChecklist_id] = useState("");
  const [scenario_id, setScenario_id] = useState("");
  const [appraisers_id, setAppraisers_id] = useState<string[]>([]);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

  const handleScenario = (event: SelectChangeEvent) => {
    setScenario_id(event.target.value);
  };

  const handleRoom = (event: SelectChangeEvent) => {
    setRoomId(event.target.value);
  };

  const handleChecklist = (event: SelectChangeEvent) => {
    setChecklist_id(event.target.value);
  };

  const handleAppraiser = (event: SelectChangeEvent<typeof appraisers_id>) => {
    const { target:{value} } = event
    setAppraisers_id(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();

    if (nameStation == "") {
      toast(t("toast_msg_form_stations_name"), {
        type: "error",
      });
      return;
    }
    if (room_id == "") {
      toast(t("toast_msg_form_stations_name_room"), {
        type: "error",
      });
      return;
    }

    if (scenario_id == "") {
      toast(t("toast_msg_form_stations_scenario"), {
        type: "error",
      });
      return;
    }

    if (checklist_id == "") {
      toast(t("toast_msg_form_stations_checklist"), {
        type: "error",
      });
      return;
    }
    if (appraisers_id.length === 0) {
      toast(t("toast_msg_form_stations_appraisers"), {
        type: "error",
      });
      return;
    }

    //criar(enviar) dados p/ o backend
    const dataStation = {
      name: nameStation,
      room_id: room_id,
      scenario_id: scenario_id,
      checklist_id: checklist_id,
      event_id: data?.eventId,
      appraiser_id: appraisers_id,
    };

    if (!data?.idStation) {
      StationsService.store(dataStation)
        .then((response: any) => {
          onCloseModalReset();
          navigate("/events/form/" + data?.eventId);
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    } else {
      StationsService.update(data?.idStation, dataStation)
        .then((response: any) => {
          onCloseModalReset();
          navigate("/events/form/" + data?.eventId);
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const loadScenario = async () => {
    await StationsService.getScenario()
      .then((response: any) => {
        if (response) {
          setScenario(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadChecklist = async () => {
    await CheckListService.getAll()
      .then((response: any) => {
        if (response) {
          setChecklist(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadAppraisers = async () => {
    await UsersService.getAppraisers(0)
      .then((response: any) => {
        if (response) {
          setAppraisers(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadStation = async (idStation) => {
    await StationsService.getStations(idStation)
      .then((response: any) => {
        if (response) {
          let station = response.data;

          setNameStation(station.name);
          setRoomId(station.room_id);
          setScenario_id(station.scenario_id);
          setChecklist_id(station.checklist_id);
          setAppraisers_id(station.appraiser_id);
          
        }
      })
      .catch((e: Error) => {});
  };

  const loadRooms = async () => {
    await RoomsService.getAll()
      .then((response: any) => {
        if (response) {
          setRooms(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    loadScenario();
    loadChecklist();
    loadAppraisers();
    loadRooms();

    if (data?.idStation) {
      loadStation(data?.idStation);
    }

    if (dataModal.loadingBtnSave) {
      handleSubmit();
    }
  }, [saveSuccess, dataModal.loadingBtnSave]);

  // console.log(appraisers);
  

  return (
    <Box sx={{ width: "100%" }}>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Box sx={{ mb: 2, width: "100%" }}>
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            label={t("input_label_station")}
            variant="outlined"
            value={nameStation}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNameStation(event.target.value);
            }}
          />
        </Box>

        <Box sx={{ mb: 2, width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="appraiser-label">
              {t("input_label_appraiser")}
            </InputLabel>
            <Select
              labelId="appraisers-label"
              multiple
              id="checklist"
              label={t("input_label_appraiser")}
              input={<OutlinedInput label="Tag" />}
              value={appraisers_id}
              renderValue={(selected) => selected.join(', ')}
              onChange={handleAppraiser}
              MenuProps={MenuProps}
            >
              {appraisers.map((row) => (
                <MenuItem value={row.name} key={row.id}>
                  <Checkbox checked={appraisers_id.indexOf(row.name) > -1} />
                  <ListItemText primary={row.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
        </Box>

        <Box sx={{ mb: 2, width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="room-label">{t("input_label_room")}</InputLabel>
            <Select
              labelId="room-label"
              label={t("input_label_room")}
              value={room_id}
              onChange={handleRoom}
            >
              {rooms.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ mb: 2, width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="scenario-label">
              {t("input_label_scenario")}
            </InputLabel>
            <Select
              labelId="scenario-label"
              label={t("input_label_scenario")}
              value={scenario_id}
              onChange={handleScenario}
            >
              {scenario.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ mb: 2, width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="checklist-label">
              {t("input_label_checklist")}
            </InputLabel>
            <Select
              labelId="checklist-label"
              id="checklist"
              label={t("input_label_checklist")}
              value={checklist_id}
              onChange={handleChecklist}
            >
              {checklist.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}
