import api from "../config/api";
import {
  ILinkMarkers,
  ISetMarkerByDebriefing,
  IUpdaeDrawByMarker,
} from "../types/Markers";

const setMarkerByDebriefing = (data: ISetMarkerByDebriefing) => {
  return api.post("markers", {
    ...data,
    field_name: "marker",
    feature: "markers",
  });
};

const getMarkerByDebriefing = (
  debriefingId: string | number,
  videoId: any = ""
) => {
  return api.get(
    `markers/debriefing/${debriefingId}${videoId ? "/" + videoId : ""}`
  );
};

const getMarkerByDebriefingItem = (
  debriefingId: string | number,
  itemId: string | number
) => {
  return api.get(`markers/debriefing/${debriefingId}/item/${itemId}`);
};

const getDrawByMarker = (markerId: number) => {
  return api.get(`markers/${markerId}`);
};

const updateDrawByMarker = (markerId: number, data: IUpdaeDrawByMarker) => {
  return api.put(`markers/${markerId}`, data);
};

const linkmarkers = (data: ILinkMarkers) => {
  return api.post(`items/linkmarkers`, data);
};

const deleteMarkerByDebriefing = (markerId: number) => {
  return api.delete(`markers/${markerId}`);
};

const getMarkerByItem = (
  debriefingId: string | number,
  itemId: string | number
) => {
  return api.get(`items/${itemId}/debriefing/${debriefingId}/markers`);
};

const MarkersService = {
  setMarkerByDebriefing,
  getMarkerByDebriefing,
  deleteMarkerByDebriefing,
  getDrawByMarker,
  updateDrawByMarker,
  linkmarkers,
  getMarkerByDebriefingItem,
  getMarkerByItem,
};

export default MarkersService;
