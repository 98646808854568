import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import CheckListService from "../../services/CheckListService";
import skillItemService from "../../services/SkillItemService";
import { useStyles } from "../styles";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CardItemSkill from "./CardItemSkill";

export default function CardGeneralItenSkill({
  idskill,
  idChecklist,
  total,
}: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();

  console.log(total);

  const [arrItens, setArrItens] = useState([] as any);
  const [points, setPoints] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [itemRemove, setItemRemove] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (id: any) => {
    setItemRemove(id);
    setOpen(true);
  };

  const addItem = useCallback(() => {
    let arr = arrItens;
    const data = {
      item_id: "",
      skill_id: idskill,
      checklist_id: idChecklist,
      question: "",
      item_value: "",
    };
    arr.push(data);

    setArrItens((arr: any) => {
      return arr.map((item: any, index: any) => {
        return item;
      });
    });
  }, [arrItens]);

  const changeskillQuestionItem = useCallback(
    (event_value: any, item_id: any) => {
      let arrHelp = arrItens;
      arrHelp[item_id].question = event_value;
      setArrItens(arrHelp);
    },
    [arrItens]
  );

  const changeskillValueItem = useCallback(
    (event_value: any, item_id: any) => {
      let arrHelp = arrItens;
      arrHelp[item_id].item_value = event_value;
      setArrItens(arrHelp);
      calculatePoints(arrHelp);
    },
    [arrItens]
  );

  const calculatePoints = (itens: any) => {
    let arrHelp = itens;
    let sumPoints = 0;

    if (arrHelp.length > 0) {
      for (let i = 0; i < arrHelp.length; i++) {
        if (
          arrHelp[i].item_value != "" &&
          parseInt(arrHelp[i].item_value) > 0
        ) {
          sumPoints = sumPoints + parseInt(arrHelp[i].item_value);
        }
      }
    }

    setPoints(sumPoints);
  };

  const saveItens = async () => {
    let emptyValues = 0,
      invalidValues = 0;
    const arr = arrItens;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].question == "") {
        emptyValues++;
      }
      if (arr[i].item_value == "") {
        emptyValues++;
      }
      if (
        parseInt(arr[i].item_value) < 0 ||
        parseInt(arr[i].item_value) > 100
      ) {
        invalidValues++;
      }
    }

    if (emptyValues > 0) {
      toast(t("toast_msg_formchecklist_skill_item_id"), {
        type: "error",
      });
      return;
    }

    if (invalidValues > 0) {
      toast(t("toast_msg_formchecklist_skill_item_id_value"), {
        type: "error",
      });
      return;
    }

    if (points != total) {
      toast(t("toast_msg_formchecklist_skill_item_id_points"), {
        type: "error",
      });
      return;
    }

    const arrCreate = arr.filter((itens) => itens.item_id == "");
    const arrUpdate = arr.filter((itens) => itens.item_id != "");

    if (arrCreate.length > 0) {
      await skillItemService
        .createmany(arrCreate)
        .then((response: any) => {
          if (response) {
            loadskillItens();
            toast(response.data.Msg, {
              type: "success",
            });
          }
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }

    if (arrUpdate.length > 0) {
      await skillItemService
        .updatemany(arrUpdate)
        .then((response: any) => {
          if (response) {
            loadskillItens();
            toast(response.data.Msg, {
              type: "success",
            });
          }
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const loadskillItens = async () => {
    await CheckListService.getSkillItens(idChecklist, idskill)
      .then((response: any) => {
        if (response) {
          setArrItens(response.data);
          calculatePoints(response.data);
        }
      })
      .catch((e: Error) => {
        calculatePoints(arrItens);
      });
  };

  const removeItem = async () => {
    await skillItemService
      .remove(itemRemove)
      .then((response: any) => {
        if (response) {
          loadskillItens();
          handleClose();
          toast(response.data.Msg, {
            type: "success",
          });
        }
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  const removeArrItem = useCallback(
    (index: any) => {
      let arr = arrItens;
      arr.splice(index, 1);
      setArrItens(arr);

      calculatePoints(arr);

      setArrItens((arr: any) => {
        return arr.map((item: any, index: any) => {
          return item;
        });
      });
    },
    [arrItens]
  );

  useEffect(() => {
    if (idChecklist && idskill) {
      loadskillItens();
    }
  }, [idskill, idChecklist, total]);

  return (
    <Box className={classes.subContainerSkillItem}>
      <Box sx={{ paddingTop: "40px", paddingBottom: "40px" }}>
        <Box sx={{ mb: 3 }}>
          {arrItens.map((item, index) => (
            <CardItemSkill
              key={index}
              index={index}
              item_id={item?.item_id}
              question={item?.question}
              item_value={item?.item_value}
              changeskillQuestionItem={changeskillQuestionItem}
              changeskillValueItem={changeskillValueItem}
              handleClickOpen={handleClickOpen}
              removeArrItem={removeArrItem}
              points={total}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">
            {" "}
            {t("title_formchecklist_skill_points")} {points}{" "}
          </Typography>
          <Box>
            <Button variant="outlined" size="small" onClick={addItem}>
              {t("btn_add_action")}
            </Button>
            {arrItens.length > 0 && (
              <Button
                variant="contained"
                size="small"
                sx={{ ml: 2 }}
                onClick={saveItens}
              >
                {t("btn_save_items")}
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("title_dialog_delete_skill_item")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("description_dialog_delete_skill_item")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("btn_dialog_no")}</Button>
          <Button onClick={removeItem} autoFocus>
            {t("btn_dialog_yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
