import React, { useState, useEffect } from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AttachmentService from '../../../services/AttachmentService'
import { useStationFilesStyles } from '../../styles/stationfiles.styles'
import ItemFile from './ItemFile'

export default function StationItem({ stationId, scenarioId, title }: any) {
  const { t } = useTranslation()

  const styles = useStationFilesStyles()
  const [file_info_1, setFileInfo_1] = useState([] as any)
  const [file_info_2, setFileInfo_2] = useState([] as any)
  const [file_info_3, setFileInfo_3] = useState([] as any)
  const [file_info_4, setFileInfo_4] = useState([] as any)
  const file_name_1 = 'file_instructions_appraisers'
  const file_name_2 = 'file_instructions_students'
  const file_name_3 = 'file_instructions_actors'
  const file_name_4 = 'file_necessary_items'
  const feature = 'scenario'

  const getFile = async (feature: any, idScenario: any, name: any) => {
    await AttachmentService.get(feature, idScenario, name)
      .then((response) => {
        if (response.data.length > 0) {
          if (name == 'file_instructions_appraisers') {
            setFileInfo_1(response.data)
          }
          if (name == 'file_instructions_students') {
            setFileInfo_2(response.data)
          }
          if (name == 'file_instructions_actors') {
            setFileInfo_3(response.data)
          }
          if (name == 'file_necessary_items') {
            setFileInfo_4(response.data)
          }
        }
      })
      .catch((e: any) => {})
  }

  useEffect(() => {
    if (scenarioId) {
      getFile(feature, scenarioId, file_name_1)
      getFile(feature, scenarioId, file_name_2)
      getFile(feature, scenarioId, file_name_3)
      getFile(feature, scenarioId, file_name_4)
    }
  }, [stationId, scenarioId, title])

  return (
    <Box>
      {stationId &&
        (file_info_1.length > 0 ||
          file_info_2.length > 0 ||
          file_info_3.length > 0 ||
          file_info_4.length > 0) && (
          <Box className={styles.box}>
            <Box>
              <Typography className={styles.title} sx={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
            </Box>
            <Divider className={styles.divider} />
            <Grid container spacing={2} className={styles.gridFiles}>
              <Grid item xs={12}>
                <Box>
                  {file_info_1.length > 0 && (
                    <Box className={styles.boxListFiles_1}>
                      {t('input_label_file_instructions_appraisers')}
                      {file_info_1.length > 0 && (
                        <ItemFile
                          name={file_info_1[0]?.original_name}
                          id={file_info_1[0]?.id}
                          url={file_info_1[0]?.url}
                        />
                      )}
                    </Box>
                  )}
                  {file_info_2.length > 0 && (
                    <Box className={styles.boxListFiles}>
                      {t('input_label_file_instructions_students')}
                      {file_info_2.length > 0 && (
                        <ItemFile
                          name={file_info_2[0]?.original_name}
                          id={file_info_2[0]?.id}
                          url={file_info_2[0]?.url}
                        />
                      )}
                    </Box>
                  )}
                  {file_info_3.length > 0 && (
                    <Box className={styles.boxListFiles}>
                      {t('input_label_file_instructions_actors')}
                      {file_info_3.length > 0 && (
                        <ItemFile
                          name={file_info_3[0]?.original_name}
                          id={file_info_3[0]?.id}
                          url={file_info_3[0]?.url}
                        />
                      )}
                    </Box>
                  )}
                  {file_info_4.length > 0 && (
                    <Box className={styles.boxListFiles}>
                      {t('input_label_file_necessary_items')}
                      {file_info_4.length > 0 && (
                        <ItemFile
                          name={file_info_3[0]?.original_name}
                          id={file_info_4[0]?.id}
                          url={file_info_4[0]?.url}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
    </Box>
  )
}
