// EvaluatorCard.tsx
import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useStyles } from "./styles";
import AvatarHiplade from "../../../../common/components/avatarhiplade";
import { useTranslation } from "react-i18next";
import colors from "../../../../common/styles/colors";

interface EvaluatorCardProps {
  name: string;
  role: string;
  appraiserId: any;
}

const EvaluatorCard: React.FC<EvaluatorCardProps> = ({
  name,
  role,
  appraiserId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <AvatarHiplade
        nameUser={name}
        size="small"
        theme="gray"
        id={appraiserId}
      />
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <Select
          value={role}
          sx={{
            backgroundColor: colors.purpleLightStation, 
            "& .MuiSelect-select": {
              backgroundColor: colors.purpleLightStation,
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: colors.purpleLightStation
              },
            },
          }}
        >
          <MenuItem 
            value={t("title_menu_item_class_evaluator")}
          >
            {t("title_label_class_evaluator")}
          </MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};

export default EvaluatorCard;
