import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import AvatarHiplade from "../../../common/components/avatarhiplade";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useDefaultStyles } from "../../../common/styles/default";
import { Grid } from "@mui/material";
import StationsService from "../../../services/StationsService";

interface TablePaginationActionsProps {
  count: any;
  page: any;
  rowsPerPage: any;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function EventStudentList({ station, eventId }: any) {
  const navigate = useNavigate();

  const styles = useDefaultStyles();

  const { t } = useTranslation();

  const paginate = 1;
  const firstpage = 1;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [users, setUsers] = useState([] as any);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const npage = newPage + 1;

    setPage(newPage);
    //loadUsers(npage, paginate)
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {};

  const getStudentsList = async (stationId: any) => {
    await StationsService.getUsersStations(stationId)
      .then((response: any) => {
        if (response.data && response.data.evaluateds.length > 0) {
          setUsers(response.data.evaluateds);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (station && station.id) {
      getStudentsList(station.id);
    }
    console.log(station);
  }, [station]);

  const selectStudent = (id: any, debriefing_id: any) => {
    navigate(
      "/events/data-ai/detail/" + eventId + "/" + id + "/" + debriefing_id
    );
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3, mt: 3 }}>
        {station?.name}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                {t("table_label_name")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                {t("table_label_select")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row) => (
              <TableRow key={row?.id}>
                <TableCell component="th" scope="row">
                  <Grid container spacing={0}>
                    <Grid item sm={2}>
                      <AvatarHiplade
                        nameUser={row?.user_name}
                        size="mini"
                        id={row?.user_id}
                      />
                    </Grid>
                    <Grid item sm={10}>
                      {row?.user_name}
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell>
                  {row?.debriefing_id && (
                    <Button
                      size="small"
                      onClick={() =>
                        selectStudent(row?.user_id, row?.debriefing_id)
                      }
                    >
                      {t("table_btn_select")}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {users && users?.length > rowsPerPage && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={7}
                  count={users?.length}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={t("table_label_number_per_page")}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
