import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconAction: {
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.7
      }
    }
  }),
)
