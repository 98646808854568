import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EntitiesService from "../../services/EntitiesService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import UsersService from "../../services/UsersService";
import { useTheme } from "@mui/material/styles"; // Importação necessária para acessar os breakpoints

import IUsersData from "../../types/Users";
import { useDefaultStyles } from "../../common/styles/default";
import FooterEvent from "../../events/components/footer";
import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/event_form_banner.png";
import TitleForm from "../../common/components/titleform";
import secureLocalStorage from "react-secure-storage";
import IEntityList from "../../types/Entities";
import IEntityData from "../../types/Entities";

export default function FormEntity() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme(); // Usando o hook useTheme para acessar os breakpoints
  const styles = useDefaultStyles();

  let auth_user_id = secureLocalStorage.getItem("id");
  const role = secureLocalStorage.getItem("role")?.toString();

  const [users, setUsers] = useState<IUsersData[]>([]);
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [contact, setContact] = useState("");
  const [owner, setOwner] = useState("");
  const [entityParent, setEntityParent] = useState("");
  const [addressAgent, setAddressAgent] = useState("");
  const [agentVersion, setAgentVersion] = useState("");

  const [entities, setEntities] = useState<IEntityData[]>([]);

  const paginate = 0;
  const firstpage = 1;

  const handleOwner = (event: SelectChangeEvent) => {
    setOwner(event.target.value);
  };

  const handleEntityParent = (event: SelectChangeEvent) => {
    setEntityParent(event.target.value);
  };

  const { id } = useParams();

  const loadEntities = async (page: number, paginate: number) => {
    await EntitiesService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setEntities(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();

    if (name == "") {
      toast(t("toast_msg_formentity_name"), {
        type: "error",
      });
      return;
    }

    if (cnpj == "") {
      toast(t("toast_msg_formentity_cnpj"), {
        type: "error",
      });
      return;
    }

    if (cnpj.length < 14) {
      toast(t("toast_msg_formentity_cnpj_len"), {
        type: "error",
      });
      return;
    }

    if (entityParent == "") {
      toast(t("toast_msg_formentity_parent_id"), {
        type: "error",
      });
      return;
    }

    if (addressAgent == "") {
      toast(t("toast_msg_formentity_agent"), {
        type: "error",
      });
      return;
    }

    const dataEntities = {
      name,
      cnpj,
      contact,
      owner_id: !owner ? auth_user_id : owner,
      parent_id: entityParent ?? null,
      address_rca: addressAgent,
    };
    console.log(!id);
    if (!id) {
      EntitiesService.store(dataEntities)
        .then((response: any) => {
          setTimeout(
            () =>
              toast(response.data.Msg, {
                type: "success",
              }),
            300
          );

          navigate("/entities/list");
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    } else {
      EntitiesService.update(id, dataEntities)
        .then((response: any) => {
          setTimeout(
            () =>
              toast(response.data.Msg, {
                type: "success",
              }),
            300
          );
          navigate("/entities/list");
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const loadEntity = async (id: any) => {
    await EntitiesService.get(id)
      .then((response: any) => {
        if (response) {
          setName(response.data.name);
          setCnpj(response.data.cnpj);
          setContact(response.data.contact);
          setOwner(response.data.owner_id);
          setEntityParent(response.data.parent_id);
          setAgentVersion(response.data.agent_version);
          setAddressAgent(response.data.address_rca);
        }
      })
      .catch((e: Error) => {});
  };

  const getUsers = async () => {
    await UsersService.getAll(1, 0)
      .then((response: any) => {
        setUsers(response.data);
      })
      .catch((e: Error) => {});
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getUsers();
    loadEntities(firstpage, paginate);
    if (id) {
      loadEntity(id);
    }
  }, []);

  // console.log("entities: ", entities);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Banner
        type="small"
        image={banner}
        title={
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <DomainAddOutlinedIcon
              sx={{
                width: "3em",
                height: "3em",
                [theme.breakpoints.down("sm")]: {
                  width: "46px",
                  height: "46px",
                },
                [theme.breakpoints.between("sm", "md")]: {
                  width: "51px",
                  height: "51px",
                },
                [theme.breakpoints.between("md", "lg")]: {
                  width: "53px",
                  height: "53px",
                },
                [theme.breakpoints.between("lg", "xl")]: {
                  width: "65px",
                  height: "65px",
                },
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "32px",
                  width: "100%",
                  justifyItems: "center",
                },
                [theme.breakpoints.between("sm", "md")]: {
                  fontSize: "42px",
                  width: "100%",
                  justifyItems: "center",
                },
                [theme.breakpoints.between("md", "lg")]: {
                  fontSize: "48px",
                  width: "100%",
                  justifyItems: "center",
                },
                [theme.breakpoints.between("lg", "xl")]: {
                  fontSize: "48px",
                  width: "100%",
                  justifyItems: "center",
                },
                [theme.breakpoints.up("xl")]: {
                  fontSize: "52px",
                  width: "100%",
                  justifyItems: "center",
                },
              }}
            >
              {!id
                ? t("title_page_form_register_entitie")
                : t("title_page_form_register_entitie_edit")}
            </Typography>
          </Box>
        }
      />
      {/* <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}} className={styles.container}> */}
      <Box
        noValidate
        className={styles.container}
        component="form"
        onSubmit={handleSubmit}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            mb: 2,
            width: "50%",
            [theme.breakpoints.down("sm")]: {
              width: "92%",
              mr: "18px",
            },
            [theme.breakpoints.between("sm", "md")]: {
              width: "96%",
            },
            [theme.breakpoints.between("md", "lg")]: {
              width: "92%",
            },
            [theme.breakpoints.up("lg")]: {
              width: "70%",
              marginRight: "10px",
            },
          }}
        >
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            label={t("input_label_name")}
            variant="outlined"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
        </Box>

        <Box
          sx={{
            width: "50%",
            mb: 2,
            [theme.breakpoints.down("sm")]: {
              width: "92%",
              mr: "18px",
            },
            [theme.breakpoints.between("sm", "md")]: {
              width: "96%",
            },
            [theme.breakpoints.between("md", "lg")]: {
              width: "92%",
            },
            [theme.breakpoints.up("lg")]: {
              width: "70%",
              marginRight: "10px",
            },
          }}
        >
          <TextField
            required
            fullWidth
            id="cnpj"
            name="cnpj"
            label={t("input_label_cnpj")}
            variant="outlined"
            value={cnpj}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCnpj(event.target.value);
            }}
          />
        </Box>

        <Box
          sx={{
            mb: 2,
            width: "50%",
            display: "flex",
            gap: 3,
            [theme.breakpoints.down("sm")]: {
              width: "92%",
              mr: "18px",
            },
            [theme.breakpoints.between("sm", "md")]: {
              width: "96%",
            },
            [theme.breakpoints.between("md", "lg")]: {
              width: "92%",
            },
            [theme.breakpoints.up("lg")]: {
              width: "70%",
              marginRight: "10px",
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="owner-label">{t("input_label_user")}</InputLabel>
              <Select
                labelId="owner-label"
                label={t("input_label_user")}
                value={owner}
                onChange={handleOwner}
              >
                {users.map((row, index) => (
                  <MenuItem key={index} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box
          sx={{
            width: "50%",
            mb: 2,
            [theme.breakpoints.down("sm")]: {
              width: "92%",
              mr: "18px",
            },
            [theme.breakpoints.between("sm", "md")]: {
              width: "96%",
            },
            [theme.breakpoints.between("md", "lg")]: {
              width: "92%",
            },
            [theme.breakpoints.up("lg")]: {
              width: "70%",
              marginRight: "10px",
            },
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="owner-label">
              {t("input_label_entity_parent")}
            </InputLabel>
            <Select
              labelId="owner-label"
              label={t("input_label_entity_father")}
              value={entityParent}
              onChange={handleEntityParent}
            >
              {entities &&
                entities.map((row, index) => (
                  <MenuItem key={index} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            mb: 2,
            width: "50%",
            [theme.breakpoints.down("sm")]: {
              width: "92%",
              mr: "18px",
            },
            [theme.breakpoints.between("sm", "md")]: {
              width: "96%",
            },
            [theme.breakpoints.between("md", "lg")]: {
              width: "92%",
            },
            [theme.breakpoints.up("lg")]: {
              width: "70%",
              marginRight: "10px",
            },
          }}
        >
          <TextField
            fullWidth
            id="contact"
            name="contact"
            label={t("input_label_contact")}
            variant="outlined"
            value={contact}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setContact(event.target.value);
            }}
          />
        </Box>
        {role == "1" && (
          <Box
            sx={{
              width: "50%",
              mt: 5,
              justifyContent: "left",
              [theme.breakpoints.down("sm")]: {
                width: "92%",
                mr: "18px",
              },
              [theme.breakpoints.between("sm", "md")]: {
                width: "96%",
              },
              [theme.breakpoints.between("md", "lg")]: {
                width: "92%",
              },
              [theme.breakpoints.up("lg")]: {
                width: "70%",
                marginRight: "10px",
              },
            }}
          >
            <TitleForm title={t("title_entity_config")} />
            <Box>
              {t("first_description_dialog_entity_config")}
              <strong>{t("first_description_strong_entity_config")}</strong>
              {t("second_description_dialog_entity_config")}{" "}
              {t("third_description_dialog_entity_config")}{" "}
              <strong>{t("second_description_strong_entity_config")}</strong>,{" "}
              {t("fourth_description_dialog_entity_config")}{" "}
              <strong>{t("second_description_strong_entity_config")}</strong>{" "}
              {t("fifith_description_strong_entity_config")}{" "}
            </Box>
            <br />
            <TextField
              fullWidth
              id="addressAgent"
              name="addressAgent"
              label={"Endereço do RCA"}
              variant="outlined"
              value={addressAgent}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressAgent(event.target.value);
              }}
            />
            {agentVersion && (
              <Typography sx={{ mt: 1 }}>
                {t("title_dialog_entity_agent_version")} {agentVersion}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <FooterEvent onClickAction={handleSubmit as any} />
    </Box>
  );
}
