import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { Link, Typography } from '@mui/material'
import { useQuickGuide } from '../styles/quickguide'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

export default function ItemGuide({ id, name, url, removeFile }: any) {
  const { t } = useTranslation()
  const styles = useQuickGuide()

  useEffect(() => {}, [id, name, url])

  return (
    <Box className={styles.boxAddFile}>
      <Link
        className={styles.fontFile}
        sx={{ textDecoration: 'none' }}
        href={url}
        target="_blank"
      >
        {name.toString().substring(0, 20) + ' ...'}
      </Link>
      <Typography className={styles.fontFile}>
        <HighlightOffOutlinedIcon
          onClick={() => removeFile(id)}
          sx={{ fontSize: '1em', ml: 3 }}
        />
      </Typography>
    </Box>
  )
}
