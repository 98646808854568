import React from 'react'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useSettingsStyles } from '../styles/settings.style'
import { CardMedia, Divider } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { useTheme } from '@mui/material/styles' // Importação necessária para acessar os breakpoints
import Button from '@mui/material/Button';
import colors from '../../common/styles/colors'
import Footer from '../../common/components/footer'
import { useTranslation } from 'react-i18next'


export default function CardSettingsLink({ title, icon, path, subtitle }: any) {
  const navigate = useNavigate()
  const theme = useTheme() // Usando o hook useTheme para acessar os breakpoints
  const classes = useSettingsStyles()
  const { t } = useTranslation()

  const handleNavigate = (path: any) => {
    navigate(path)
  }

  return (
    <Card
      className={classes.containerCardLink}
      onClick={() => handleNavigate(path)}
    >
      <CardContent
        sx={{
        display: 'flex', // Adicionado para fazer o CardContent um container flex
        flexDirection: 'column', // Adicionado para alinhar os itens verticalmente
        justifyContent: 'space-between', // Adicionado para direcionar os itens para o final do container
      }}
      >
        {icon}
        <Typography
          variant="h4"
          component="div"
          className={classes.titleCardLink}
          sx={{ fontWeight: 700,
            [theme.breakpoints.between('xs', 'lg')]: { 
              fontSize: '24px', 
            },
          }}
        >
          {title}
        </Typography>
        <Divider className={classes.dividerCardLink} />
        <Typography variant="subtitle1" className={classes.dateEvent}
          sx={{ padding: '3px', marginRight: 5, 
            [theme.breakpoints.between('xs', 'lg')]: {
              fontSize: '16px', 
            },
           }}
        >
          {subtitle}
        </Typography>
        <Typography 
          sx={{
            mt: 10,
            //ml: 80,
            color: colors.purple, 
            fontWeight: 600, 
            textTransform: 'capitalize',
            fontSize: '1vw',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginLeft: 'auto',
            gap: '1.2vw',
            [theme.breakpoints.between('xs', 'lg')]: {
              fontSize: '12px',
            }
          }}
        >
          {t("title_dialog_access")}
        </Typography>
      </CardContent>      
      <CardActions></CardActions>
    </Card>
  )
}
