import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../common/styles/colors'
import fonts from '../../common/styles/fonts'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameUser: {
      color: colors.blueDarkest,
      fontSize: '2em !important',
    },
    profileUser: {
      color: colors.purple,
      fontSize: '1em !important',
      marginTop: '10px !important',
      marginBottom: '30px !important',
    },
    infoUser: {
      color: colors.blueDarkest,
      fontSize: '1em !important',
      marginBottom: '20px !important',
    },
    boxInfo: {
      marginTop: '45px',
      borderBottom: '1px solid ' + colors.lilacMediumLight,
    },
    titlePassword: {
      fontSize: '2em !important',
      color: colors.blueDarkest,
    },
  }),
)
