import Echo from "laravel-echo";
import Pusher from 'pusher-js';
import secureLocalStorage from "react-secure-storage";

declare global {
  interface Window {
    Pusher: any;
    Echo: Echo;
  }
}
const token = secureLocalStorage.getItem('token')
window.Pusher = Pusher;

const getCookieValue = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

const wsAppKey = process.env.REACT_APP_PUSHER_APP_KEY
const wsAppHost = process.env.REACT_APP_PUSHER_HOST
const backendURL = process.env.REACT_APP_BACKEND_URL

export function createSocketConnection() {

  if(typeof window.Echo !== 'object'){ // verifica se Echo já está instanciado
      window.Echo = new Echo({
        broadcaster: 'reverb',
        key: wsAppKey,
        wsHost: wsAppHost,
        wsPort: '80',
        wssPort: '443',
        forceTLS: false,
        enabledTransports: ['ws'/*, 'wss'*/],
        authEndpoint: backendURL + '/broadcasting/auth',
        auth: {
          headers: {
            /**
             * TODO: Verificar problemas de CORS
             */
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'X-CSRF-TOKEN': getCookieValue('XSRF-TOKEN')
          },
        },
    });
  }

}
