import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleNumber: {
      color: colors.purpleMediumSub,
      fontSize: '3em !important',
      marginRight: '10px! important',
    },
    title: {
      color: colors.blueDarkest,
      fontSize: '2.625em !important',
      marginLeft: '10px! important',
    },

    titleBox: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
  }),
)
