import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../common/styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 70,
      backgroundColor: colors.lilacLight,
      display: 'flex',
      justifyContent: 'start',
      [theme.breakpoints.between(0, 900)]: { 
        padding: 40,
        paddingTop:'70px',
        paddingBottom: '70px',
        marginTop: '90px',
        justifyContent: 'start',
      },
      [theme.breakpoints.between(0, 1200)]: { 
        padding: 65,
        paddingTop:'70px',
        paddingBottom: '70px',
        marginTop: '90px',
        justifyContent: 'start',
      }
    }
  }),
)
