import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../common/styles/colors";
import fonts from "../../common/styles/fonts";

export const useStationStyle = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      width: "100%",
    },
    importAppraiser: {
      backgroundColor: colors.green,
      border: "1px solid " + colors.green,
    },
    item: {
      backgroundColor: colors.white,
      marginTop: 20,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      justifyContent: "space-between",
    },
    itemName: {
      display: "flex",
    },
    close: {
      color: colors.grayClose,
    },
  })
);
