import React, { useState } from "react";
import hipladeTemplate from "../../common/template";
import { Box, Button } from "@mui/material";
import Banner from "../../common/components/banner";
import DeveloperBoardOutlinedIcon from "@mui/icons-material/DeveloperBoardOutlined";
import { useNavigate, useParams } from "react-router-dom";
import EventsService from "../../services/EventsService";
import { toast } from "react-toastify";
import { useDefaultStyles } from "../../common/styles/default";
import EventStudentList from "../components/data-ai/EventStudentList";
import { useTranslation } from "react-i18next";
import EventStationList from "../components/data-ai/EventStationList";

function EventsViewDataAiList() {
  const { eventId } = useParams();
  const styles = useDefaultStyles();

  const [event, setEvent] = useState<any>("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const getCurrentEvent = async (eventId) => {
    await EventsService.get(eventId)
      .then((response: any) => {
        setEvent(response.data);
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  React.useEffect(() => {
    if (eventId) {
      getCurrentEvent(eventId);
    }
  }, [eventId]);

  const backEvent = (eventId: any) => {
    navigate("/events/view/" + eventId);
  };

  return (
    <Box>
      <Banner
        type="small"
        image={"medium"}
        title={event?.name + " / " + t("bread_eventsdata-ai_u")}
        icon={
          <DeveloperBoardOutlinedIcon
            sx={{ fontSize: "3.5em", color: "#FFFFFF", mr: 5 }}
          />
        }
        button={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => backEvent(eventId)}
          >
            Voltar ao Evento
          </Button>
        }
      />
      <Box>
        <Box className={styles.containerList}>
          <EventStationList event={event} />
        </Box>
      </Box>
    </Box>
  );
}

export default hipladeTemplate(EventsViewDataAiList);
