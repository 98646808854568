import { Button, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import CheckListService from '../../services/CheckListService'
// import DebriefingService from '../../services/DebriefingService'
import { Box, CircularProgress } from "@material-ui/core";
// import LinearProgress from '@mui/joy/LinearProgress'
import LinearProgress from "@mui/material/LinearProgress";
import { useStyles } from "../../styles/index.styles";
import { useModal } from "../../../common/hooks/useModal";
import CheckListService from "../../../services/CheckListService";
import ScenarioTitleView from "../ScenarioTitleView";
import DebriefingComment from "../debriefing-rating-items/DebriefingComment";
import {
  DebriefingMocksProps,
  DebriefingProps,
  RatingItemsProps,
} from "../../../common/dtos/defriengsDtos";
import colors from "../../../common/styles/colors";
import { useStylesFeedback } from "./styles";
import ContentCard from "./ContentCard";
import { useTranslation } from "react-i18next";
// import DebriefingComment from './debriefing-rating-items/DebriefingComment'
// import ScenarioTitleView from './ScenarioTitleView'
// import { useModal } from '../../common/hooks/useModal'

type Props = {
  data: DebriefingMocksProps;
};

interface FeedbackResultProps {
  eventId?: any;
  stationId?: any;
  evaluatedId?: any;
  debriefingId?: any;
  station?: any;
  scenario?: any;
  items: RatingItemsProps[] | undefined;
  data?: DebriefingMocksProps;
}

export default function FeedbackResult({
  eventId,
  stationId,
  evaluatedId,
  debriefingId,
  station,
  scenario,
  items,
  data,
}: FeedbackResultProps) {
  if (!eventId || !stationId || !evaluatedId) {
    return <Box />;
  }

  const [skills, setskills] = React.useState<any[]>([]);
  const [skillItems, setskillItems] = React.useState<any[]>([]);
  const [itemsClosed, setItemsClosed] = React.useState<any[]>([]);
  const styles = useStyles();
  const stylesFeedback = useStylesFeedback();
  const { t } = useTranslation();

  const getChecklist = async (id: any) => {
    await CheckListService.get(id)
      .then((response) => {
        setskillItems(response.data.items);
        setskills(response.data.skills);
      })
      .catch((e) => console.log(e));
  };

  const handleOpenItem = (id: number | undefined) => {
    const itemExist = itemsClosed.filter((i) => i === id).length > 0;
    const itemNotExist = itemsClosed.filter((i) => i !== id);

    if (itemExist) return setItemsClosed(itemNotExist);
    else return setItemsClosed([...itemsClosed, id]);
  };

  useEffect(() => {
    if (station) {
      getChecklist(station?.checklist_id);
    }
  }, [eventId, stationId, evaluatedId, debriefingId, station, scenario]);

  return (
    <Box className={styles.eventBoxskill}>
      {data && data?.skills && data?.skills?.length > 0
        ? data?.skills?.map((skill, index) => (
            <Box key={index}>
              <Box p={4} className={stylesFeedback.containerItem}>
                <ScenarioTitleView
                  index={index >= 9 ? index : "0" + (index + 1)}
                  title={skill?.skill_name}
                  onClick={() => handleOpenItem(skill?.skill_id)}
                />

                {/* {itemsClosed.filter(i => i === skill.id).length > 0 && ( */}
                <Box className={stylesFeedback.containerRow} sx={{mb: 2}}>
                  <Stack
                    alignItems={"center"}
                    style={{ height: "100%" }}
                    flexDirection={"row"}
                    spacing={2}
                  >
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      className={stylesFeedback.title}
                    >
                      {t("title_feedback_performance")}
                    </Typography>
                    <Box
                      style={{
                        height: "100%",
                        paddingBottom: 10,
                        marginLeft: 20,
                      }}
                    >
                      <LinearProgress
                        className={stylesFeedback.progressBar}
                        style={{ width: 300, height: 20 }}
                        variant="determinate"
                        value={skill.skill_performace}
                        color={"success"}
                      />
                    </Box>
                    <Box
                      style={{
                        height: "100%",
                        paddingBottom: 10,
                        marginLeft: 20,
                      }}
                    >
                      <Typography className={stylesFeedback.title}>
                        <span style={{ fontWeight: "bold" }}>
                          {skill.skill_performace}
                        </span>
                        %
                      </Typography>
                    </Box>
                  </Stack>
                  <Box
                    py={0.1}
                    px={1.5}
                    bgcolor={colors.purpleMenuActive}
                    borderRadius={3}
                  >
                    <Typography
                      sx={{ color: "#fff", fontWeight: 500 }}
                    >
                      {t("title_feedback_skill_score")}
                    </Typography>
                  </Box>
                </Box>
                <Divider className={styles.dividerCard}></Divider>
                {skill.items &&
                  skill.items.map((item, index) => (
                    <>
                      <ContentCard
                        key={index}
                        description={item.item_name}
                        score={`${item.item_value}/${skill?.skill_points}`}
                        type={item.scale}
                        showDivisor={false}
                      />
                      <Box 
                        p={2} 
                        bgcolor={colors.grayFeedbackBg} 
                        borderRadius={3}
                      >
                        <Typography
                          sx={{ color: item.comment ? colors.purple : colors.grayMedium }}
                          className={stylesFeedback.title}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {item.comment ? t("span_feedback_evaluator_note") : "Sem apontamentos"}
                          </span>{" "}
                          {item?.comment && item.comment}
                        </Typography>
                      </Box>
                    </>
                  ))}
              </Box>
              <Stack mt={8} p={4} spacing={3} bgcolor={colors.lilacMedium}>
                <Typography
                  sx={{ fontWeight: 600 }}
                  className={stylesFeedback.title}
                >
                  {t("title_feedback_final_considerations_and_improvements")}
                </Typography>
                <Typography
                  className={stylesFeedback.title}
                  sx={{ fontSize: 14 }}
                >
                  {skill.skill_consideration}
                </Typography>
              </Stack>
            </Box>
          ))
        : t("loading")}
    </Box>
  );
}
