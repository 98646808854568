import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../common/styles/colors'
import fonts from '../../common/styles/fonts'

export const useMonitoringRoom = makeStyles((theme: Theme) =>
  createStyles({
    boxIconAdd: {
      padding: 10,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    boxTextCardAdd: {
      padding: '20px',
    },
    boxEventLinks: {
      marginLeft: 70,
      display: 'flex',
      flexWrap: 'wrap',
    },
    dividerCardLink: {
      backgroundColor: colors.green,
      height: 3,
      width: 62,
    },
    containerCardLink: {
      width: 'auto',
      minHeight: 329,
      marginRight: 15,
      marginLeft: 2,
      marginBottom: 15,
      marginTop: 11,
      padding: 5,
      cursor: 'pointer !important',
      display: 'flex',
      alignItems: 'center',
    },
    titleCardControlRoomLink: {
      color: colors.blueDark,
      marginTop: '10px !important',
      marginBottom: '15px !important',
    },
    containerCardHomeLink: {
      width: '98%',
      minHeight: 329,
      marginRight: -33,
      marginBottom: 15,
      padding: 15,
      cursor: 'pointer !important',
      display: 'flex',
      alignItems: 'center',
    },
  }),
)
