import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";

import EntitiesService from "../../services/EntitiesService";
import UsersService from "../../services/UsersService";
import StudentsGroupService from "../../services/StudentsGroupService";

import IEntityData from "../../types/Entities";
import IUsersData from "../../types/Users";
import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/event_form_banner.png";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";

import { useDefaultStyles } from "../../common/styles/default";

export default function FormStudentsGroup() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const styles = useDefaultStyles();

  const { id } = useParams<{ id: string }>();
  const [idGroup, setIdGroup] = useState<number | undefined>(
    id ? parseInt(id, 10) : undefined
  );
  const [entities, setEntities] = useState<IEntityData[]>([]);
  const [name, setName] = useState("");
  const [studentsgroup, setStudentsGroup] = useState("");
  const [students, setStudents] = useState<IUsersData[]>([]);
  const [studentsGroupData, setStudentsGroupData] = useState<any[]>([]);
  const [studentsStatus, setStudentsStatus] = useState<{
    [key: number]: string;
  }>({});
  const [studentsDebriefing, setStudentsDebriefing] = useState<{
    [key: number]: boolean;
  }>({}); // Novo estado para debriefing
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]); // Novo estado para alunos selecionados

  const handleStudentsGroup = (event: SelectChangeEvent) => {
    setStudentsGroup(event.target.value);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: t("title_header_id") || "", width: 140 },
    { field: "name", headerName: t("title_header_name") || "", width: 250 },
    { field: "email", headerName: t("title_header_email") || "", width: 230 },
    {
      field: "status",
      headerName: t("title_header_status") || "",
      width: 200,
      renderCell: (params) => {
        return (
          <Box>
            <Typography>
              {studentsDebriefing[params.row.id]
                ? t("titlte_description_linked_user")
                : t("titlte_description_unlinked_user")}
            </Typography>
          </Box>
        );
      },
    },
  ];

  //criar/editar turma
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (name === "" || studentsgroup === "") {
      toast(
        t(
          name === ""
            ? "toast_msg_formstudentsgroup_name"
            : "toast_msg_formestudentsgroup_entity"
        ),
        {
          type: "error",
        }
      );
      return;
    }

    // criar(enviar) dados p/ o backend
    const dataStudentsGroup = {
      id: "",
      name: name,
      entity_id: studentsgroup,
      parent_id: "",
    };

    //response (onde obtenho o id da turma criada vindo do back)
    if (!idGroup) {
      StudentsGroupService.store(dataStudentsGroup)
        .then((response: any) => {
          getStudents();
          setIdGroup(response.data.id);
          toast(t("toast_msg_formestudentsgroup_save"), { type: "success" });
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, { type: "error" });
        });
    } else {
      StudentsGroupService.update(idGroup, dataStudentsGroup)
        .then((response: any) => {
          toast(t("toast_msg_formestudentsgroup_save"), { type: "success" });
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, { type: "error" });
        });
    }
  };

  const AddStudentsGroup = () => {
    if (studentsGroupData.length === 0) {
      toast(t("toast_msg_formstudentsgroup_users"), {
        type: "error",
      });
      return;
    }

    const dataStudents = {
      id: idGroup,
      users: studentsGroupData,
    };

    StudentsGroupService.linkStudents(dataStudents)
      .then((response: any) => {
        navigate("/studentsgroup/list");
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  const loadStudentsGroup = async (id: number) => {
    await StudentsGroupService.get(id)
      .then((response: any) => {
        if (response) {
          setIdGroup(response.data.id);
          setName(response.data.name);
          setStudentsGroup(response.data.entity_id);

          // Atualizar o estado com os alunos vinculados
          let arrStudents: any[] = [];
          response?.data?.students?.map((student: { id: number }) => {
            arrStudents.push(student.id);
          });
          setStudentsGroupData(arrStudents);
          setSelectedStudents(arrStudents); // Adicionar aqui

          getStudents(); // Chamar depois de configurar o estado inicial
        }
      })
      .catch((e: Error) => {});
  };

  const getEntities = async () => {
    await EntitiesService.getAll(1, 0)
      .then((response: any) => {
        setEntities(response.data);
      })
      .catch((e: Error) => {});
  };

  const getStudents = async () => {
    await UsersService.getStudents()
      .then((response: any) => {
        setStudents(response.data);
        if (idGroup) {
          StudentsGroupService.getStudentsStatus(idGroup).then(
            (response: any) => {
              const status = response.data.reduce((acc: any, user: any) => {
                acc[user.id] = user.enabled ? "Enabled" : "Disabled";
                acc[user.id] = user.debriefing ? "Debriefing" : acc[user.id];
                return acc;
              }, {});
              setStudentsStatus(status);

              const debriefingStatus = response.data.reduce(
                (acc: any, user: any) => {
                  acc[user.id] = user.debriefing;
                  return acc;
                },
                {}
              );
              setStudentsDebriefing(debriefingStatus);

              // Configura os alunos selecionados inicialmente
              const initialSelectedStudents = response.data
                .filter((user: any) => user.debriefing)
                .map((user: any) => user.id);
              setSelectedStudents((prevSelected) => [
                ...prevSelected,
                ...initialSelectedStudents,
              ]);
            }
          );
        }
      })
      .catch((e: Error) => {});
  };

  const onRowsSelectionHandler = (ids: any) => {
    // Filtra os IDs que são permitidos para serem desmarcados e mantém os IDs de debriefing
    const validIds = ids.filter(
      (id: number) => studentsStatus[id] !== "False" && !studentsDebriefing[id]
    );
    const alwaysSelectedIds = Object.keys(studentsDebriefing).filter(
      (id) => studentsDebriefing[id]
    );

    // Combina os IDs permitidos com os IDs de debriefing que devem permanecer selecionados
    const finalSelectedIds = Array.from(
      new Map([...validIds, ...alwaysSelectedIds].map((id) => [id, id])).keys()
    );

    setStudentsGroupData(finalSelectedIds);
    setSelectedStudents(finalSelectedIds);
  };

  useEffect(() => {
    getEntities();
    if (idGroup !== undefined) {
      loadStudentsGroup(idGroup);
    }
  }, [idGroup]);

  return (
    <Box>
      <Banner
        type="small"
        image={banner}
        title={
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <GroupsOutlinedIcon sx={{ width: "3em", height: "3em" }} />
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {!id
                ? t("title_page_form_register_studentsgroup")
                : t("title_page_form_register_studentsgroup_edit")}
            </Typography>
          </Box>
        }
      />
      <Box className={styles.container}>
        {/*<Typography variant="h4" sx={{ mb: 3 }}>
          {!id
            ? t('title_page_form_register_studentsgroup')
            : t('title_page_form_register_studentsgroup')}
        </Typography>*/}
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Box sx={{ mb: 2, width: "50%" }}>
            <TextField
              required
              fullWidth
              id="name"
              name="name"
              label={t("input_label_studentsgroup")}
              variant="outlined"
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
            />
          </Box>

          <Box sx={{ mb: 2, width: "50%" }}>
            <FormControl fullWidth>
              <InputLabel id="studentsgroup-label">
                {t("input_label_entity")}
              </InputLabel>
              <Select
                labelId="studentsgroup-label"
                label={t("input_label_entity")}
                value={studentsgroup}
                onChange={handleStudentsGroup}
              >
                {entities.map((row, index) => (
                  <MenuItem key={index} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Button type="submit" variant="contained" size="large">
            {!idGroup ? t("btn_create_class") : t("btn_salvar_class")}
          </Button>

          <Button
            variant="contained"
            size="large"
            color="secondary"
            sx={{ ml: 2 }}
            onClick={() => {
              navigate("/studentsgroup/list");
            }}
          >
            {" "}
            {t("btn_return")}
          </Button>
        </Box>

        {idGroup !== undefined && (
          <Box>
            <Box sx={{ mb: 4, width: "52%" }}>
              <Typography variant="h5" sx={{ mb: 3, mt: 5 }}>
                {t("title_component_form_classes_students")}
              </Typography>
              <Box sx={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={students}
                  columns={columns}
                  pageSize={5}
                  checkboxSelection
                  disableSelectionOnClick
                  isRowSelectable={(params: GridRowParams) =>
                    !studentsDebriefing[params.id]
                  }
                  selectionModel={selectedStudents} // Define os IDs selecionados inicialmente
                  onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                />
              </Box>
            </Box>

            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{ ml: 2, mt: 2 }}
              onClick={AddStudentsGroup}
            >
              {t("btn_add_students_class")}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
