import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../common/styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 30
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 30
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      gap: 10
    },
    grid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flex: 1,
      gap: 10
    }
  }),
)
