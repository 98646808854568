import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import { FormLabel, TextField } from '@mui/material'
import { useScenarioStyles } from '../styles/scenarios.styles'

export default function FormScenarioPrescriptions({
  continuous_use_medications,
  setContinuousUseMedications,
  temporary_medications,
  setTemporaryMedications,
  health_guidelines,
  setHealthGuidelines,
}: any) {
  const { t } = useTranslation()
  const classes = useStyles()
  const styleScenario = useScenarioStyles()

  useEffect(() => {}, [
    continuous_use_medications,
    temporary_medications,
    health_guidelines,
  ])

  return (
    <Box>
      <Box sx={{ mb: 10 }}>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          className={classes.titleScenarioCheckbox}
          sx={{ fontWeight: 'bold' }}
        >
          {t('input_label_continuous_use_medications')}
        </FormLabel>
        <Box className={styleScenario.topBox}></Box>
        <TextField
          fullWidth
          id="continuous_use_medications"
          name="continuous_use_medications"
          variant="outlined"
          placeholder={t("title_placeholder_type_here") || ""}
          value={continuous_use_medications}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setContinuousUseMedications(event.target.value)
          }}
          multiline
                rows={5}
        />
      </Box>

      <Box sx={{ mb: 10 }}>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          className={classes.titleScenarioCheckbox}
          sx={{ fontWeight: 'bold' }}
        >
          {t('input_label_temporary_medications')}
        </FormLabel>
        <Box className={styleScenario.topBox}></Box>
        <TextField
          fullWidth
          id="temporary_medications"
          name="temporary_medications"
          variant="outlined"
          placeholder={t("title_placeholder_type_here") || ""}
          value={temporary_medications}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTemporaryMedications(event.target.value)
          }}
          multiline
                rows={5}
        />
      </Box>

      <Box sx={{ mb: 3 }}>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          className={classes.titleScenarioCheckbox}
          sx={{ fontWeight: 'bold' }}
        >
          {t('input_label_health_guidelines')}
        </FormLabel>
        <Box className={styleScenario.topBox}></Box>
        <TextField
          fullWidth
          id="health_guidelines"
          name="health_guidelines"
          variant="outlined"
          placeholder={t("title_placeholder_type_here") || ""}
          value={health_guidelines}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHealthGuidelines(event.target.value)
          }}
          multiline
                rows={5}
        />
      </Box>
    </Box>
  )
}
