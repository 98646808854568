import { makeStyles, Theme, createStyles } from "@material-ui/core";

import colors from "../../../../common/styles/colors";
import fonts from "../../../../common/styles/fonts";

// interface StyleProps {
//   correctionsCompleted: number;
// }

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    boxConclude: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      padding: theme.spacing(4),
      border: "1px solid #FE3A8A !important",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "#F6ECF0 !important",
    },
    progressBar: {
      marginLeft: "25px",
      marginRight: "25px",
      margin: theme.spacing(1, 0),
      "& .MuiLinearProgress-bar": {
        backgroundColor: "#80B558",
      },
    },
    boxConcludeProgress: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      marginBottom: theme.spacing(3),
    },
    boxConcludeButton: {
      width: "90%",
      height: "43px",
      display: "flex",
      borderRadius: "5px",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: theme.spacing(4),
    },
    description: {
      width: "100%",
      textAlign: "left",
      paddingTop: "10px",
      justifyContent: "flex-start",
      marginTop: theme.spacing(2),
    },
    concludedBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      backgroundColor: "#E6F4EA",
      borderRadius: "8px",
      padding: theme.spacing(8),
      border: `1px solid #24946B`,
    },
  })
);
