import React from "react";
import menus from "../../components/menu/menu.json";
import { AppContext } from "../../../contexts/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuProps } from "../../dtos/menuDtos";
import { useTranslation } from "react-i18next";

export function useMenu() {
  const {
    state: { menu },
    updateDataContext,
  } = React.useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmenuPathname = () => {
    for (const menuItem of menus) {
      const filterSubmenu =
        Array.isArray(menuItem.submenu) &&
        menuItem.submenu.find((submenuItem) =>
          pathname.includes(submenuItem.link)
        );

      if (filterSubmenu) {
        updateDataContext({
          menu: {
            ...menu!,
            menuActive: String(t(menuItem.title)),
            openMenuActive: String(t(menuItem.title)),
          },
        });
        break;
      }
    }
  };

  const handleOpenMenu = () => {
    updateDataContext({
      menu: { ...menu!, visibleMenu: !menu!.visibleMenu },
    });
  };

  const handleMenuActive = (title: string, id?: any, link?: any) => {
    if (id) {
      localStorage.setItem("menu", id);
    }
    if (menu && title === menu.menuActive) {
      updateDataContext({
        menu: { ...menu, menuActive: "" },
      });
    } else if (title === "Home") {
      navigate("/home");
    } else if (title === t("menu_my_events")) {
      navigate("/myevents/list");
    } else if (menu) {
      updateDataContext({
        menu: { ...menu, menuActive: title, openMenuActive: title },
      });
    }

    if (link) {
      navigate(link);
    }
  };

  const handelNavigateSubmenu = (href: string, title: string) => {
    handleMenuActive(title);
    if (menu && !menu.visibleMenu) {
      updateDataContext({
        menu: { ...menu, menuHoverActive: "", menuHover: "" },
      });
      handleMenuActive(title);
    }
    navigate(href);
  };

  return {
    onSubmenuPathname,
    handleOpenMenu,
    handleMenuActive,
    handelNavigateSubmenu,
  };
}
