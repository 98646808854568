import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles/index.styles";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../hooks/useModal";
import { Textarea } from "@mui/joy";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import { useParams } from "react-router-dom";
import { useMarkers } from "../../../../events/hooks/useMarkers";
import { ILinkMarkers, IMarker } from "../../../../types/Markers";
import colors from "../../../styles/colors";

export function ChooseBookmark() {
  const classes = useStyles();

  const { t } = useTranslation();

  const { dataModal, onCloseModalReset, handleChangedModal } = useModal();
  const {
    getMarkerByDebriefing,
    getMarkerByDebriefingItem,
    markerData,
    linkMarkersState,
    setLinkmarkers,
  } = useMarkers();
  const [markersSelected, setMarkersSelected] = useState<IMarker[]>([]);

  const markers = markerData as IMarker[];

  useEffect(() => {
    if (dataModal.loadingBtnSave) {
      const data: ILinkMarkers = {
        debriefing_id: dataModal.data.debriefingId,
        item_id: dataModal.data.itemId as number,
        markers: markersSelected.map((i) => i.id) as number[],
      };
      setLinkmarkers(data);
    }
    if (dataModal.btnCloseModalClicked) {
      onCloseModalReset();
    }
  }, [dataModal.btnSaveModalClicked, dataModal.btnCloseModalClicked]);

  useEffect(() => {
    getMarkerByDebriefingItem(
      dataModal.data.debriefingId,
      dataModal.data.itemId
    );
  }, []);

  useEffect(() => {
    if (linkMarkersState.success) {
      onCloseModalReset();

      if (dataModal?.data.station) {
        dataModal?.data.getChecklist(dataModal?.data.station?.checklist_id);
      }
    }
  }, [linkMarkersState.success]);

  const onClickCard = (item: IMarker) => {
    if (isSelected(item.id as number)) {
      const newList = markersSelected.filter(({ id }) => id !== item.id);
      setMarkersSelected(newList);
    } else {
      setMarkersSelected([...markersSelected, item]);
    }
  };

  const isSelected = (id: number) =>
    markersSelected.filter((item) => item.id === id).length > 0;

  return (
    <Box className={classes.containerBody}>
      {markers ? (
        markers.map((item, index) => (
          <Card
            key={item.id}
            onClick={() => onClickCard(item as IMarker)}
            sx={{
              maxWidth: 220,
              border: `2px solid ${
                isSelected(item.id as number) && colors.purplePrimary
              }`,
            }}
          >
            <CardActionArea sx={{ backgroundColor: "#cecece5a" }}>
              <CardMedia
                component="img"
                height="120"
                image={item.attachments && item.attachments[0].static_url}
                alt={t("card_media_image_capture")}
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  color="primary"
                  style={{ fontWeight: "bold", display: "flex" }}
                  variant="body1"
                >
                  {t("card_content_marker_capture")} {index + 1}:
                </Typography>
                <Typography color="primary" variant="body1">
                  {item.time}s
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))
      ) : (
        <Card
          sx={{
            maxWidth: 220,
          }}
        >
          <CircularProgress size={20} />
        </Card>
      )}
    </Box>
  );
}
