import api from "../config/api";
import IStationData from "../types/Station";

const getScenario = () => {
  return api.get<IStationData>("/scenarios");
};

const getCheckList = () => {
  return api.get<IStationData>("/checklists");
};

const getAppraiser = () => {
  return api.get<IStationData>("/users/appraisers");
};

const get = (id: any) => {
  return api.get("/stations/" + id);
};

const store = (data: any) => {
  return api.post("/stations", data);
};

const getStations = (idStation: number) => {
  return api.get<IStationData>("/stations/" + idStation);
};

const update = (id: any, data: any) => {
  return api.put<IStationData>("/stations/" + id, data);
};

const remove = (id: any) => {
  return api.delete("/stations/" + id);
};

const getUsersStations = (idStation: any) => {
  return api.get(`stations/${idStation}/users`);
};

const getAppraiserStations = (idStation: any) => {
  return api.get(`stations/${idStation}/appraiser`);
};

const getObserverStatusByStation = (idStation: any) => {
  return api.get(`/observers/status/${idStation}`);
};
const startObserver = (data: { station_id: any; event_id: any }) => {
  return api.post(`startobserver`, data);
};
const stopObserver = (data: { station_id: any }) => {
  return api.post(`stopobserver`, data);
};

const StationsService = {
  getObserverStatusByStation,
  startObserver,
  stopObserver,
  getScenario,
  getCheckList,
  getAppraiser,
  get,
  store,
  getStations,
  update,
  remove,
  getUsersStations,
  getAppraiserStations,
};

export default StationsService;
