import { Box, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DebriefingService from '../../../services/DebriefingService'
import { useGeneralPerformanceStyles } from '../../styles/general-performance.styles'
import { useTranslation } from "react-i18next"

export default function GeneralPerformanceLabel({ porc }: any) {
  const { t } = useTranslation()
  const styleGenenralPerformance = useGeneralPerformanceStyles()
  const [lowerThanExpected, setLowerThanExpected] = useState(0)
  const [limitOfTheExpected, setLimitOfTheExpected] = useState(0)
  const [asExpected, setAsExpected] = useState(0)
  const [higherThanExpected, setHigherThanExpected] = useState(0)

  const labelPerformance = () => {
    setAsExpected(0)
    setLowerThanExpected(0)
    setLimitOfTheExpected(0)
    setHigherThanExpected(0)

    if (porc < 60) {
      setLowerThanExpected(1)
    } else if (porc >= 60 && porc < 70) {
      setLimitOfTheExpected(1)
    } else if (porc >= 70 && porc < 90) {
      setAsExpected(1)
    } else if (porc >= 90) {
      setHigherThanExpected(1)
    }
  }

  useEffect(() => {
    if (porc) {
      labelPerformance()
    }
  }, [porc])

  return (
    <Grid container spacing={0.5} sx={{ mt: 4 }}>
      <Grid item xs={7} md={7}>
        <Box
          className={
            lowerThanExpected == 1
              ? styleGenenralPerformance.itemGridOn
              : styleGenenralPerformance.itemGrid
          }
        >
          {' '}
          {t("title_dialog_overall_below_expected")}
        </Box>
      </Grid>
      <Grid item xs={1} md={1}>
        <Box
          className={
            limitOfTheExpected == 1
              ? styleGenenralPerformance.itemGridOn
              : styleGenenralPerformance.itemGrid
          }
        >
          {t("title_dialog_overall_limit_of_expected")}
        </Box>
      </Grid>
      <Grid item xs={2} md={2}>
        <Box
          className={
            asExpected == 1
              ? styleGenenralPerformance.itemGridOn
              : styleGenenralPerformance.itemGrid
          }
        >
          {t("title_dialog_overall_as_expected")}
        </Box>
      </Grid>
      <Grid item xs={2} md={2}>
        <Box
          className={
            higherThanExpected == 1
              ? styleGenenralPerformance.itemGridOn
              : styleGenenralPerformance.itemGrid
          }
        >
          {t("title_dialog_overall_above_expected")}
        </Box>
      </Grid>
    </Grid>
  )
}
