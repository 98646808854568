import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { DebriefingProps } from '../../../common/dtos/defriengsDtos';
import { AppContext } from '../../../contexts/AppContext';
import DebriefingService from '../../../services/DebriefingService';
import { ISetMarkerByDebriefing } from '../../../types/Markers';

interface GetByStationIdEvaluatedIdProps {
  studentId: number;
  stationId: number;
}

interface TabsProps {
  id: string | number;
  title: 'Dados Simulação' | 'Checklist' | 'Video Simulação' | 'Considerações' | 'Feedback' | 'Dados IA';
}

const TABS: TabsProps[] = [
  { id: '1', title: 'Dados Simulação' },
  { id: '2', title: 'Checklist' },
  { id: '5', title: 'Dados IA' },
  { id: '3', title: 'Considerações' },
  { id: '4', title: 'Feedback' },
];

const TABS_STUDENT: TabsProps[] = [
  { id: '1', title: 'Dados Simulação' },
  { id: '2', title: 'Video Simulação' },
  { id: '4', title: 'Feedback' },
];

export const useDebriefings = () => {

  const { evaluatedId, eventId } = useParams<{ evaluatedId: string; eventId: string }>();

  const { state: { debriefing }, updateDataContext } = useContext(AppContext);

  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [successFeedback, setSuccessFeedback] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<Error | null>(null);
  const [dataTabsMenu, setDataTabsMenu] = useState<TabsProps[]>([]);
  const [feedbacksData, setFeedbacksData] = useState<Record<string, any>>({});

  const profileId = secureLocalStorage.getItem('role') as number | null;

  const onSaveFeedback = useCallback(
    (data: DebriefingProps) => updateDataContext({ debriefing: data }),
    [updateDataContext]
  );

  const getDebriefingFeedback = useCallback(async ({ studentId, stationId }: GetByStationIdEvaluatedIdProps) => {
    setLoadingFeedback(true);
    try {
      const response = await DebriefingService.getByStationIdEvaluatedId(evaluatedId, stationId);
      const debriefing = response.data[0];

      if (response.data.length === 0) {
        setSuccessFeedback(false);
      } else {
        setSuccessFeedback(true);
        onSaveFeedback(debriefing);
      }
    } catch (e: any) {
      setErrorFeedback(e);
    } finally {
      setLoadingFeedback(false);
    }
  }, [evaluatedId, onSaveFeedback]);

  const getFeedbackByDebriefing = useCallback(async (debriefingId: any) => {
    try {
      const response = await DebriefingService.getFeedbackByDebriefingId(debriefingId);
      setFeedbacksData(response.data);
    } catch (error) {
      console.error('Error fetching feedback by debriefing:', error);
    }
  }, []);

  const getDebriefingEventByEvaluated = useCallback(async () => {
    if (!evaluatedId || !eventId || !profileId) return;

    try {
      const response = await DebriefingService.getDebriefingEventByEvaluated({ evaluatedId, eventId });
      const status = response.data[0]?.status;

      if (profileId === 5) {
        setDataTabsMenu(TABS_STUDENT);
      } else {
        setDataTabsMenu(TABS);
      }
    } catch (error) {
      console.error('Error fetching debriefing event by evaluated:', error);
    }
  }, [evaluatedId, eventId, profileId]);

  useEffect(() => {
    getDebriefingEventByEvaluated();
  }, [getDebriefingEventByEvaluated]);

  const setMarkerByDebriefing = (data: ISetMarkerByDebriefing) => {
    // Implement functionality as needed
  };

  return {
    debriefing,
    loadingFeedback,
    successFeedback,
    errorFeedback,
    dataTabsMenu,
    feedbacksData,
    onSaveFeedback,
    getDebriefingFeedback,
    getFeedbackByDebriefing,
    getDebriefingEventByEvaluated,
    setMarkerByDebriefing,
  };
};

