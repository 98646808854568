import * as React from 'react'

import { useStyles } from './styles/index.styles'
import { useTranslation } from 'react-i18next'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { Button, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import Box from '@material-ui/core/Box'

type props = {
  tableHead: {field: string, headerName: string, actions?: any}[],
  data?: any
  onClickEdit?: (id: number) => void
  onClickRemove?: (id: number) => void
}

export default function TableView({
  tableHead,
  data,
  onClickEdit,
  onClickRemove
}: props) {
  
  const classes = useStyles()

  return (
    <Box>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 600 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {tableHead.map((item, index) => (
                <TableCell key={index} sx={{ fontWeight: 'bold', fontSize: 20 }}>
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                {tableHead.map((cell, index) => (
                  <>
                    <TableCell key={index}>
                      {row[cell.field]}
                      {cell.field === 'action' && (
                        <>
                          <EditIcon className={classes.iconAction} onClick={() => onClickEdit && onClickEdit(row.id as number)} sx={{mr: 2}}/>
                          <DeleteIcon className={classes.iconAction} onClick={() => onClickRemove && onClickRemove(row.id as number)}/> 
                        </>
                      )}
                    </TableCell>
                  </>
                  
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
