import React from 'react'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, Typography } from '@mui/material'
import { Container } from '@mui/material'
import { toast } from 'react-toastify'
import AttachmentService from '../../services/AttachmentService'
import AddIcon from '@mui/icons-material/Add'
import Banner from '../../common/components/banner'
import banner from '../../assets/images/banners/banner_settings.png'
import { useDefaultStyles } from '../../common/styles/default'
import { useStyles } from '../../events/styles/index.styles'
import { useSettingsStyles } from '../styles/settings.style'
import colors from "../../common/styles/colors"
import CardSettingsLink from './CardSettingsLink'


import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'; 
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssignmentIcon from '@mui/icons-material/Assignment';
import StarIcon from '@mui/icons-material/Star';


const Settings: React.FC = () => {
    const { t } = useTranslation();
    const styles = useDefaultStyles();
    const stylesSettings = useStyles();
    const classes = useSettingsStyles()

  return (
    <Box>
      <Banner
        type="big"
        image={banner}
        divider="1"
        colorDivider="new"
        title={t('banner_title_settings')}
        subtitle={t('banner_subtitle_settings')}
      />

      <Box className={classes.Box}
        display={'flex'}
        width={'90%'}
        flexDirection={'column'}
        sx={{
            mr: '5%',
            ml: '5%',
            mt: 10,            
            mb: 10,
        }}
      >
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CardSettingsLink
                title={t('btn_settings_quick_guide')}
                subtitle={t('subtitle_settings_quick_guide')}
                path="/home/quickguide"
                icon={<HelpCenterIcon sx={{ color: colors.purpleMedium, fontSize: 48 }} />}
                
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CardSettingsLink
                title={t('btn_settings_room_registration')}
                subtitle={t('subtitle_settings_room_registration')}
                path="/events/rooms/list"
                icon={<MeetingRoomIcon sx={{ color: colors.purpleMedium, fontSize: 48 }} />}
            />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CardSettingsLink
                title={t('btn_settings_profiles')}
                subtitle={t('subtitle_settings_profiles')}
                path="/roles/list"
                icon={<ContactPageIcon sx={{ color: colors.purpleMedium, fontSize: 48 }} />}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CardSettingsLink
                title={t('btn_settings_class_registration')}
                subtitle={t('subtitle_settings_class_registration')}
                path="/studentsgroup/list"
                icon={<PeopleAltIcon sx={{ color: colors.purpleMedium, fontSize: 48 }} />}
            />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CardSettingsLink
                title={t('btn_settings_entity')}
                subtitle={t('subtitle_settings_entity')}
                path="/entities/list"
                icon={<DomainAddIcon sx={{ color: colors.purpleMedium, fontSize: 48 }} />}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CardSettingsLink
                title={t('btn_settings_users')}
                subtitle={t('subtitle_settings_users')}
                path="/users/list"
                icon={<AccountBoxIcon sx={{ color: colors.purpleMedium, fontSize: 48 }} />}
            />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CardSettingsLink
                title={t('btn_settings_themes')}
                subtitle={t('subtitle_settings_themes')}
                path="/themes/list"
                icon={<AssignmentIcon sx={{ color: colors.purpleMedium, fontSize: 48 }} />}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CardSettingsLink
                title={t('btn_settings_skills')}
                subtitle={t('subtitle_settings_skills')}
                path="/competences/list"
                icon={<StarIcon sx={{ color: colors.purpleMedium, fontSize: 48 }} />}               
            />            
            </Grid>
        </Grid>
      </Box>

    </Box>
  );
};

export default Settings;