import { Box, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStylesFeedback } from "./styles";
import colors from "../../../common/styles/colors";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";

interface ContentCardProps {
  description: string | undefined;
  type: "done" | "partially_done" | "done_wrong" | "not_done";
  score: string | undefined;
  showDivisor?: boolean | undefined;
}

export const TypeTextFeedback = ({
  type,
}: {
  type: "done" | "partially_done" | "done_wrong" | "not_done";
}) => {
  const stylesFeedback = useStylesFeedback();
  const { t } = useTranslation();

  const handleType = () => {
    switch (type) {
      case "done":
        return {
          color: stylesFeedback.titleSuccess,
          title: t("label_value_action_did"),
        };
      case "partially_done":
        return {
          color: stylesFeedback.titleWarning,
          title: t("label_value_action_half_did"),
        };
      case "done_wrong":
        return {
          color: stylesFeedback.titleError,
          title: t("label_value_action_did_wrong"),
        };
      case "not_done":
        return {
          color: stylesFeedback.titleError,
          title: t("label_value_action_didnt"),
        };
      default:
        return {
          color: stylesFeedback.titleError,
          title: t("label_value_action_didnt"),
        };
    }
  };

  const { color, title } = handleType();

  return <span className={color}>{title}</span>;
};

export default function ContentCard({
  description,
  type,
  score,
  showDivisor = true,
}: ContentCardProps) {
  const stylesFeedback = useStylesFeedback();
  const [color, setColor] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const { t } = useTranslation();

  const handleType = () => {
    if (type === "done") {
      setColor("#D1E9CF");
      setTitle(t("label_value_action_did") as string);
    } else if (type === "partially_done") {
      setColor("#D0DDA2");
      setTitle(t("label_value_action_half_did") as string);
    } else if (type === "done_wrong") {
      setColor("#EEDEA6");
      setTitle(t("label_value_action_did_wrong") as string);
    } else {
      setColor("#FBC9DD");
      setTitle(t("label_value_action_didnt") as string);
    }
  };
  useEffect(() => {
    handleType();
  }, [type]);
  

  return (
    <Stack>
      <Stack className={stylesFeedback.wrapper}>
        <Typography className={stylesFeedback.title}>{description}</Typography>
        <Stack flexDirection={"row"} spacing={4} alignItems={"center"}>
          <Typography
            sx={{ fontWeight: "bold" }}
            className={stylesFeedback.title}
          >
            <span
              className={stylesFeedback.feedback}
              style={{ backgroundColor: color }}
            >
              {title}
            </span>
            <span 
              className={stylesFeedback.feedback}
              style={{ backgroundColor: "#E4E4E4", color: colors.purplePrimary }}
            >
              {score} {t("title_points")}
            </span>
            
          </Typography>
        </Stack>
      </Stack>
      {showDivisor && (
        <Divider className={stylesFeedback.dividerCardCompentence}></Divider>
      )}
    </Stack>
  );
}

