import React, { useState } from 'react'
import AttachmentService from '../../services/AttachmentService'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import DebriefingService from '../../services/DebriefingService';
import IDebriefingData from '../../types/Debriefing';

export interface DataStoreDebriefing extends IDebriefingData {
    appraiser_id: string;
    status: 'not_corrected';
}

export function useUploadFile() {

    const { t } = useTranslation()

    const [ loadingUploadFile, setLoadingUploadFile ] = useState(false)
    const [ successUploadFile, setSuccessUploadFile ] = useState(false)
    const [ errorUploadFile, setErrorUploadFile ] = useState(false)
    const [ fileUploaded, setFileUploaded ] = useState<{name:string, url:string} | null>(null)

    const uploadFileDebriefing = async (e: any, debriefing_id: any) => {

        const data = {
          feature: 'debriefing',
          object_id: debriefing_id,
          field_name: 'debriefing_video',
          file: e.target.files[0],
          file_type: 'video',
          multiple: 'false',
        }

        try {
            const response = await AttachmentService.upload(data)
            
            toast(t('toast_load_video'), {
                type: 'success',
            })

            setFileUploaded({
                name: e.target.files[0]?.name,
                url: response.data.url,
            })
        } catch (error:any) {
            setErrorUploadFile(true)
            toast(error?.response?.data?.Error, {
                type: 'error',
            })
        }finally {
            setLoadingUploadFile(false)
        }
    
    }

    const storeDebriefing = async (
        event: React.ChangeEvent<HTMLInputElement>,
        data: DataStoreDebriefing
      ) => {

        setLoadingUploadFile(true)
        setSuccessUploadFile(false)
        setErrorUploadFile(false)

        if (event.target.files !== null && event.target.files[0].type != 'video/mp4') {
          toast(t('toast_type_video'), {
            type: 'error',
          })
          return
        }

        try {
            const response:any = await DebriefingService.store(data)
            if (response.data) {
                const debriefing_id = response.data.debriefing_id
                uploadFileDebriefing(event, debriefing_id)
            }
        } catch (error) {
            setErrorUploadFile(true)
        }
    }

    return {
        uploadFileDebriefing,
        storeDebriefing,
        setFileUploaded,
        fileUploaded,
        loadingUploadFile,
        successUploadFile,
        errorUploadFile,
    }
}
