import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerFooter: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      justifyContent: 'start',
      backgroundColor: colors.lilac,
    //   width: '100%',
     //[theme.breakpoints.between(900, 960)]: {
       //width: '100%',
       //paddingLeft: '50px',
     //},
    // [theme.breakpoints.down('md')]: {
    //   maxWidth: '100%',
    // },
    // [theme.breakpoints.up('lg')]: {
    //   maxWidth: '100%',
    // }
    },
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '90%',
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 50,
    [theme.breakpoints.up('xl')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      gridTemplateColumns: '1fr 1fr', // Define duas colunas entre md e lg
    }
    },
    textFooter: {
      fontSize: 14,
      color: colors.blackMedium,
    },
    boxIconMedia: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 5,
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '100px',
    }, 
    },
  }),
)
