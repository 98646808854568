import * as React from 'react'
import { useState, useEffect } from 'react'
import { Box, Button, useMediaQuery, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import secureLocalStorage from 'react-secure-storage'
import { useHomeStyles } from '../styles/home.styles'
import CardQuickGuide from './CardQuickGuide'
import Link from '@mui/material/Link'

import CardHomeTeacherLink from '../components/CardHomeTeacherLink'
import CardTeacherLink from '../components/CardTeacherLink'
import CardHomeAnalyticsStudent from '../components/CardHomeAnalyticsStudent'
import CardStudentLink from './CardStudentLink'
import CardEventLink from '../../events/components/CardEventLink'

import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined'
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined'
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import colors from '../../common/styles/colors'
import QuickGuideMobile from '../../assets/images/card_02_mobile.png'
import QuickGuide from '../../assets/images/img_card_guia.png'
import AttachmentService from '../../services/AttachmentService'
import MaleStudentMobile from '../../assets/images/card_04_mobile.png'
import MaleStudent from '../../assets/images/img_card_student.png'
import AnalyticsImageMobile from '../../assets/images/card_03_mobile.png'
import AnalyticsImage from '../../assets/images/imagem_card_02.png'

export default function HomeAreaStudent() {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const classes = useHomeStyles()


  const localPermissions = secureLocalStorage.getItem('permissions')?.toString()
  const permissions = localPermissions?.split(',')

  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault()

  const [attachment, setAttachment] = useState([] as any)
  const [url, setUrl] = useState('')

  const isMobile = useMediaQuery('(max-width: 1535px)') // Alterado para max-width 1535px

  const feature = 'quickguide'
  const idGuide = 2 //id do perfil coordenador, porque o manual é o mesmo p/ adm
  const name = 'manual_hiplade_coordinator'// nome de campo do perfil coodenador, porque o manual é o mesmo p/ adm

  const getFile = async () => {
    await AttachmentService.get(feature, idGuide, name)
      .then((response) => {
        setAttachment(response.data)
        if (response.data.length > 0) {
          setUrl(response.data[0].url)
        }
      })
      .catch((e: any) => {})
  }

  useEffect(() => {
    if (idGuide) {
      getFile()
    }
  }, [])
  return (
    <Box className={classes.Box}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={5.3} xl={5.6}>
          <Link href={url} target="_blank">               
                <CardQuickGuide            
                    title={t('btn_quick_guide')}
                    subtitle={t('subtitle_quick_guide')}
                    icon={
                    <FindInPageOutlinedIcon
                    
                  sx={{ color: '#fff', fontSize: 40 }}
                  />
                  }
                  image={isMobile ? QuickGuideMobile : QuickGuide}               
                />  
              </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5.3} xl={5.5}>
            <CardHomeTeacherLink
              title={t('btn_notes_and_feedback')}
              subtitle={t('subtitle_notes_and_feedback')}
              //path=""
              icon={
                <TextsmsOutlinedIcon
                  sx={{ color: colors.purpleIcon, fontSize: 40 }}
                />
              }
              image={isMobile ? MaleStudentMobile : MaleStudent}
            />
        </Grid>     
        <Grid item xs={12} sm={12} md={6} lg={5.6} xl={7}>
          <CardHomeAnalyticsStudent 
            title={t('btn_student_performance')}
            subtitle={t('subtitle_student_performance')}
            icon={
              <LeaderboardOutlinedIcon
                sx={{ color: colors.purpleIcon, fontSize: 40 }}
              />
            }
            image={isMobile ? AnalyticsImageMobile : AnalyticsImage}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5.4} xl={4}>
          {permissions?.includes('read_event') && (
            <CardStudentLink
              title={t('btn_schedule_events')}
              subtitle={t('subtitle_schedule_events')}
              path="/events/schedule"
              icon={<TodayOutlinedIcon sx={{ color: colors.purpleIcon, fontSize: 40 }} />}
              date="10/10/2023"
              status_id="1"
              index="1"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}



