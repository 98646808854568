import React from 'react'
import { Box } from '@mui/system'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import secureLocalStorage from 'react-secure-storage'
import { useStyles } from '../styles/index.styles'
import CardAddItem from './CardAddItem'
import CardEventLink from './CardEventLink'
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined'
import colors from '../../common/styles/colors'
import { ListItem } from './EventAdditionalInformationView/ListItem'
import theme from '../../common/theme'

export default function EventBoxCreateLinks() {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const classes = useStyles()

  const localPermissions = secureLocalStorage.getItem('permissions')?.toString()
  const permissions = localPermissions?.split(',')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '1.2vw',
        [theme.breakpoints.down('md')]: {
          // flexDirection: 'column',
          gap: 2
        }
      }}
    >
      {permissions?.includes('read_event') && (
        <ListItem
          title={t("title_event_box_create_theme")}
          description={t("description_event_box_create_theme")}
          iconName='event_note_outlined'
          urlClick={'/themes/list'}
        />
      )}
      {permissions?.includes('read_event') && (
        <ListItem
          title={t("title_event_box_create_skills")}
          description={t("description_event_box_create_skills")}
          iconName='star_purple_500_outlined'
          urlClick={'/competences/list'}
        />
      )}
    </Box>
  )
}
