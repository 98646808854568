export const WIDTH_OPEN_SIDEBAR = 250
export const WIDTH_CLOSE_SIDEBAR = 96

export const ALL_ROLES = 0
export const ADMIN = 1
export const COORDINATOR = 2
export const TEACHER = 3
export const SUPPORT = 4
export const STUDENT = 5
export const EVENT_MANAGER = 6
