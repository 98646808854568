import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles/index.styles";
import { useScenarioStyles } from "../styles/scenarios.styles";
import ScenarioBasicView from "./ScenarioBasicView";
import ScenarioCardArea from "./ScenarioCardArea";
import ScenarioCardDnc from "./ScenarioCardDnc";
import ScenarioRegisterView from "./ScenarioRegisterView";
import ScenarioObjectiveView from "./ScenarioObjectiveView";
import ScenarioDescriptionView from "./ScenarioDescriptionView";
import ScenarioMedicalRecordView from "./ScenarioMedicalRecordView";
import ScenarioScriptsView from "./ScenarioScriptsView";
import ScenarioHealthHistoryView from "./ScenarioHealthHistoryView";
import ScenarioPrescriptionsView from "./ScenarioPrescriptionsView";
import ScenarioExamsHistoryView from "./ScenarioExamsHistoryView";
import ScenarioHistoryOfVitalSignsView from "./ScenarioHistoryOfVitalSignsView";
import { Grid } from "@mui/material";
import TabsHiplade from "../../common/components/tabshiplade";

export default function ScenarioOverview({
  eventId,
  stationId,
  evaluatedId,
  student,
  station,
  scenario,
}: any) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const classes = useStyles();
  const scenarioStyle = useScenarioStyles();

  const tabs = [
    { id: "1", title: t("title_health_history")},
    { id: "2", title: t("title_prescriptions_scenario_overview")},
    { id: "3", title: t("title_dialog_history_vital_signs")},
    { id: "4", title: t("title_exams")},
  ];

  const [tab_id, setTabId] = useState("1");

  const handleChangeSubTab = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabId(newValue);
  };

  if (!scenario) {
    return null
  }

  return (
    <Box>
      <ScenarioBasicView name={scenario.name} />

      <Box className={scenarioStyle.boxScenarioRegister}>
        <ScenarioRegisterView
          complexity={scenario.complexity}
          sex={scenario.sex}
          time_duration={scenario.time_duration}
          age={scenario.age}
          environment={scenario.environment}
        />
      </Box>
      <ScenarioObjectiveView goal_scene={scenario.goal_scene} />
      <ScenarioDescriptionView description_scene={scenario.description_scene} />
      <Box className={scenarioStyle.boxScenarioRegister}>
        <ScenarioMedicalRecordView />
        <Box className={classes.containerTabMenu} sx={{ marginTop: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <TabsHiplade
                status_id={tab_id}
                handleChangeTab={handleChangeSubTab}
                tabs={tabs}
              />
            </Grid>
          </Grid>
        </Box>
        {tab_id == "1" && (
          <ScenarioHealthHistoryView
            illness={scenario.illness}
            allergy={scenario.allergy}
            habits={scenario.habits}
            surgeries={scenario.surgeries}
            family_health_history={scenario.family_health_history}
          />
        )}
        {tab_id == "2" && (
          <ScenarioPrescriptionsView
            continuous_use_medications={scenario.continuous_use_medications}
            temporary_medications={scenario.temporary_medications}
            health_guidelines={scenario.health_guidelines}
          />
        )}
        {tab_id == "3" && (
          <ScenarioHistoryOfVitalSignsView
            history_vital_signs={scenario.history_vital_signs}
            physical_exam={scenario.physical_exam}
          />
        )}
        {tab_id == "4" && <ScenarioExamsHistoryView idScenario={scenario.id} />}
      </Box>
      <ScenarioScriptsView idScenario={scenario.id} />
    </Box>
  );
}
