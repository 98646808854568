import React from 'react'
import { Box } from '@mui/system'
import { useStyles } from '../styles/index.styles'
import AddIcon from '@mui/icons-material/Add'

export default function IconCardAdd() {
  const classes = useStyles()

  return (
    <Box className={classes.iconCircleCardAdd}>
      <AddIcon sx={{ fontSize: 35 }} />
    </Box>
  )
}
