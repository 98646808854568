import { Box, Typography, Divider, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDataIaStyles } from "../styles/data-artificial-intelligence";
import TitleForm from "../../common/components/titleform";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import colors from "../../common/styles/colors";
import SheAiService from "../../services/SheAiService";
import loading from "../../assets/images/load-status.gif";
import LoaderHiplade from "../../common/components/loaderhiplade";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ResponsiveRadar } from "@nivo/radar";

import { Alert, AlertTitle } from '@mui/lab';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import CircularProgress from '@material-ui/core/CircularProgress'
import { useSocket } from "../../services/hooks/socketHook";
import DebriefingVideo from "./debriefing-video/DebriefingVideo";
import DebriefingService from "../../services/DebriefingService";
import { useTranslation } from "react-i18next"

export default function DataIa({ idEvaluated, idDebriefing, faceRecognition }: any) {
  const styles = useDataIaStyles();
  const { t } = useTranslation()
  const [status, setStatus] = useState("");
  const [graph, setGraph] = useState([]);
  const [keys, setKeys] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errorSheAi, setErrorSheAi] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const [video, setVideo] = useState("");
  const [videoId, setVideoId] = useState("");
  const [arrVideo, setArrVideo] = useState<any[]>([]);
  const [cameraMain, setCameraMain] = useState(false);

  const loadDebriefingUrl = async (debriefingId: any) => {
    await DebriefingService.getDebriefingUrl(debriefingId)
      .then((response: any) => {
        if (response) {
          if (
            response.data.length > 0 &&
            response.data[0].field_name != "debriefing_video"
          ) {
            setVideo(response.data[0]?.recording_url);
            setVideoId(response.data[0]?.streaming_id);
            const cameraDefault = response.data[0]?.camera_main ? true : false;
            setCameraMain(cameraDefault);
            setArrVideo(response.data);
          } else {
            setVideo(response.data[0]?.static_url);
            setVideoId(response.data[0]?.id);
            setCameraMain(true);
          }
        }
      })
      .catch((e: Error) => {});
  };

  /**
   * Utilização de WEBSOCKET
   */

  const [wsProgressStatusUpdate, setWsProgressStatusUpdate] = useState(Number)
  useSocket({
    callBack: (payload) => {
     //console.log(payload.status)

     if(parseInt(payload.status) == 100){
        setStatus('finished');
        statusDataAi(idDebriefing);
     }

     setWsProgressStatusUpdate(parseInt(payload.status))
    },
    channel: 'status', // Nome do privateChannel na classe do MANAGER
    event: '.App\\Events\\EventProcessingFexStatus' // Nome de classe caminho completo
  });

  /**
   *  END WEBSOCKET
   */

  const startDataAi = async () => {
    const data = {
      evaluated_id: idEvaluated,
      debriefing_id: idDebriefing,
      priority: 0,
    };

    await SheAiService.fex(data)
      .then((response: any) => {
        if (response) {
          statusDataAi(idDebriefing);
        }
      })
      .catch((e: Error) => {});
  };

  const statusDataAi = async (debriefing_id: any) => {
    setLoader(true);
    await SheAiService.fexStatus(debriefing_id)
      .then((response: any) => {
        if (response) {
          const data = response.data;
          if (data.length != 0 || data !== undefined) {
            setStatus(data?.status);
            if (data?.status == "finished") {
              setGraph(data.data.radar);
              setKeys(data.keys);
            }
            setLoader(false);
          } else {
            if (data.status) {
              setStatus(data?.status);
              setLoader(false);
            }
          }
        } else {
          setLoader(false);
        }
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };

  const showResultChange = (status: any) => {
    setShowResult(status);
  };

  useEffect(() => {
    if (idDebriefing) {
      statusDataAi(idDebriefing);
      loadDebriefingUrl(idDebriefing);
    }
  }, [idEvaluated, idDebriefing]);

  return (
    <Box className={styles.containerIa}>
      {loader && <LoaderHiplade />}
      {!showResult && (
        <Box>
          <Box className={styles.box} sx={{ mb: 10 }}>
            <TitleForm
              title={t("title_face_and_emotion_recognition")}
              icon={
                <EmojiEmotionsIcon
                  sx={{
                    color: colors.purpleIcon,
                    fontSize: 37,
                    marginRight: 3,
                  }}
                />
              }
              button={
                !errorSheAi &&
                (status == "not_found" ? (
                    <Typography
                      sx={{
                        ml: 4,
                        backgroundColor: colors.grayStatusDataIA,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingLeft: 3,
                        paddingRight: 3,
                        borderRadius: 2,
                        color: colors.pink,
                        fontWeight: "bold",
                      }}
                    >
                      {t("title_dialog_unprocessed")}
                    </Typography>                    
                ) : status == "finished" ? (
                  <Typography
                    sx={{
                      ml: 4,
                      backgroundColor: colors.grayStatusDataIA,
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingLeft: 3,
                      paddingRight: 3,
                      borderRadius: 2,
                      color: colors.greenMedium,
                      fontWeight: "bold",
                    }}
                  >
                    {t("title_dialog_processing_completed")}
                  </Typography>
                ) : (
                  <>
                  <Typography
                    sx={{
                      ml: 4,
                      backgroundColor: colors.grayStatusDataIA,

                      paddingLeft: 3,
                      paddingRight: 3,
                      borderRadius: 2,
                      color: colors.purpleMediumSub,
                      fontWeight: "bold",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>
                        {t("span_dialog_processing_simulation")}
                      </span>{" "}
                      <img src={loading} />
                    </Box>
                  </Typography>
                  <Box position="relative" display="inline-flex">
                    <CircularProgress color="secondary" variant="determinate" value={wsProgressStatusUpdate} />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                      <Typography 
                        variant="caption" 
                        component="div" 
                        color="textPrimary">{`${wsProgressStatusUpdate}%`}</Typography>
                      </Box>
                  </Box>
                  </>
                ))
              }
            />
            <Typography className={styles.titleIa}>
              {t("first_title_dialog_description_data_ia")}
              &quot;{t("second_title_dialog_description_data_ia")}&quot;
              {t("third_title_dialog_description_data_ia")}
              &quot;{t("fourth_title_dialog_description_data_ia")}&quot;
              {t("fifth_title_dialog_description_data_ia")}
            </Typography>
            { faceRecognition ?
                <></> :
                <div>
                <Alert className={styles.alert} severity="error">
                  <AlertTitle>{t("first_title_alert_dialog_ia")}</AlertTitle>
                  {t("first_title_alert_description_data_ia")}
                   (<strong>{t("title_strong_fex")}</strong>) 
                   {t("second_title_alert_description_data_ia")}
                  <List>
                    <ListItem>{t("first_title_list_item_data_ia")}</ListItem>
                    <ListItem>{t("second_title_list_item_data_ia")}
                    </ListItem>
                  </List>
                </Alert>
                <Alert className={styles.alert} severity="info">
                  <AlertTitle>{t("second_title_alert_dialog_ia")}</AlertTitle>
                  {t("third_title_alert_description_data_ia")} <strong>{t("first_title_strong_alert_description_data_ia")}</strong>
                  {t("fourth_title_alert_description_data_ia")}{" "}
                  <strong>{t("second_title_strong_alert_description_data_ia")}</strong>.
                </Alert>
              </div>
            }
            {!errorSheAi && status == "not_found" && (
              <>
                <Button
                  variant="outlined"
                  className={faceRecognition ? styles.buttonRF : styles.buttonDisabled}
                  onClick={startDataAi}
                  disabled={faceRecognition ? false : true}
                >
                  {t("btn_start_analysis")}
                </Button>

              </>
            )}
            {!errorSheAi && status == "finished" && (
              <Button
                variant="contained"
                onClick={() => showResultChange(true)}
              >
                {t("title_result")}
              </Button>
            )}
            {errorSheAi && (
              <Typography>
                {t("title_description_error_sheai")}
              </Typography>
            )}
          </Box>

          <Box className={styles.box}>
            <TitleForm
              title={"Transcrição audio"}
              icon={
                <VolumeUpIcon
                  sx={{
                    color: colors.purpleIcon,
                    fontSize: 37,
                    marginRight: 3,
                  }}
                />
              }
            />
            <Typography className={styles.titleIa}>
              {t("first_title_dialog_description_data_ia")}
              &quot;{t("second_title_dialog_description_data_ia")}&quot;
              {t("third_title_dialog_description_data_ia")}
              &quot;{t("title_dialog_description_data_ia_view")}&quot;
              {t("fifth_title_dialog_description_data_ia")}
            </Typography>
          </Box>
        </Box>
      )}
      {showResult && (
        <Box className={styles.box}>
          <Grid container>
            <Grid item xs={11}>
              <Grid container spacing={0}>
                <Grid item sm={11}>
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    {t("title_face_and_emotion_recognition")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="outlined"
                onClick={() => showResultChange(false)}
                sx={{ borderWidth: 2 }}
              >
                {t("btn_close")} <CloseOutlinedIcon sx={{ ml: 1 }} />
              </Button>
            </Grid>
          </Grid>
          <Box className={styles.divider} sx={{ mt: 3 }}></Box>
          <Box sx={{p: 3}}>
            <DebriefingVideo
              video={video}
              heightVideo="500px"
              notMarker
              videoId={videoId}
              arrVideo={arrVideo}
              setVideo={setVideo}
              setVideoId={setVideoId}
              cameraMain={cameraMain}
              setCameraMain={setCameraMain}
            />
          </Box>
          <Typography sx={{ mt: 4, mb: 10 }}>
            {t("title_dialog_lifting_emotions")}
          </Typography>
          <Box sx={{ height: 500 }}>
            <ResponsiveRadar
              data={graph}
              keys={keys}
              indexBy="taste"
              valueFormat=">-.2f"
              margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
              borderColor={{ from: "color" }}
              gridLevels={12}
              gridShape="circular"
              gridLabelOffset={36}
              dotSize={10}
              dotColor={{ theme: "background" }}
              dotBorderWidth={2}
              colors={{ scheme: "set2" }}
              blendMode="multiply"
              motionConfig="wobbly"
              legends={[
              {
                  anchor: 'top-left',
                  direction: 'column',
                  translateX: -50,
                  translateY: -40,
                  itemWidth: 180,
                  itemHeight: 40,
                  itemTextColor: '#000',
                  symbolSize: 15,
                  symbolShape: 'circle',
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemTextColor: '#999',
                              symbolSize: 20,
                              symbolBorderWidth: 5
                          }
                      }
                  ]
              }
            ]}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
