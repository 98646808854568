import * as React from 'react'
import { Box } from '@mui/system'
import EventsService from '../../services/EventsService'
import { useStyles } from '../../events/styles/index.styles'
import { useTranslation } from 'react-i18next'
import colors from '../../common/styles/colors'

interface EventStatusProps {
    eventId: any
    status: any
}

export default function EventStatus({ eventId, status }: EventStatusProps) {

    /**
     * Se não possui o ID do Evento não carrega o componente
     */
    if (!eventId) {
        return <Box />
    }

    if(status == 'in_correction') {
      status = 'status_event_running'
    }

    const styles = useStyles()
    const [statusClassName, setStatusClassName] = React.useState('')
    const { t } = useTranslation()

    /**
     * Altera a classe de estilo baseado no status
     */
    const getClassNameByStatus = () => {

        switch (status) {
            case t('status_event_ended'):
                setStatusClassName(styles.statusEventEnded)
                break
            case t('status_event_running'):
                setStatusClassName(styles.statusEventRunning)
                break
            case t('status_event_next'):
                setStatusClassName(styles.statusEventNext)
                break
        }

        return;

    }

    React.useEffect(() => {
        /**
         * Carrega o STATUS do evento corrente
         */
        getClassNameByStatus();
    }, [])


    return (
        <Box>
            <p>
                <b className={styles.statusEventRunning}>{t("status")} </b>
                <span className={statusClassName}>{status ? t(status) : t("loading")}</span>
            </p>
        </Box>
    )
}
