import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { Typography } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDefaultStyles } from '../../../common/styles/default'
import Banner from '../../../common/components/banner'
import AttachmentIcon from '@mui/icons-material/Attachment'
import { Button } from '@mui/material'
import EventsService from '../../../services/EventsService'
import StationItem from './StationItem'
import TitleForm from '../../../common/components/titleform'
import { useStationFilesStyles } from '../../styles/stationfiles.styles'

export default function View() {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const styles = useDefaultStyles()
  const stylesStationFile = useStationFilesStyles()
  const { id } = useParams()
  const [event, setEvent] = useState([] as any)
  const [stations, setStations] = useState([] as any)

  const loadEvent = async (id: any) => {
    await EventsService.get(id)
      .then((response: any) => {
        if (response) {
          setEvent(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  const loadStationsByEvent = async (id: any) => {
    await EventsService.getStationsByEvent(id)
      .then((response: any) => {
        if (response) {
          setStations(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  const handleBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (id) {
      loadEvent(id)
      loadStationsByEvent(id)
    }
  }, [])

  return (
    <Box>
      <Box>
        <Banner
          type="small"
          image={'medium'}
          title={event?.name + ' / ' + t('title_files_event')}
          icon={
            <AttachmentIcon
              sx={{ fontSize: '3.5em', color: '#FFFFFF', mr: 5 }}
            />
          }
          button={
            <Button variant="contained" color="secondary" onClick={handleBack}>
              {t('btn_return')}
            </Button>
          }
        />
      </Box>

      <Box className={styles.container}>
        <TitleForm title={t('title_files_event')} />
        <Typography className={stylesStationFile.titleSubText}>
          {t('subtitle_files_event')}
        </Typography>
        {stations.map((row, index) => (
          <Box key={index}>
            <StationItem
              stationId={row?.id}
              scenarioId={row?.scenario_id}
              title={row?.name}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
