import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import { useStyles } from '../styles/index.styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import DashboardService from '../../services/DashboardService'
import { toast } from 'react-toastify'
import GradeBar from './GradeBar'

export default function GraphGeneralGrade({ event }: any) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [porc, setPorc] = useState(0)

  const loadGraph = async (id: any) => {
    await DashboardService.getOverallAverage(id)
      .then((response: any) => {
        if (response.data && response.data.length > 0) {
          setPorc(response.data[0].value)
        }
      })
      .catch((e: any) => {
        setPorc(0)
        toast(e?.response?.data?.Error, {
          type: 'error',
        })
      })
  }

  useEffect(() => {
    if (event) {
      loadGraph(event.id)
    }
  }, [event])

  return (
    <Grid item xs={5}>
      <Card>
        <CardContent className={classes.cardContainer}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {t('graph_event_title_general_grade')}
          </Typography>
          <Typography variant="h6" sx={{ mt: 1, mb: 3 }}>
            {t('graph_event_subtitle_general_grade')}
          </Typography>
          <Box sx={{ height: 400 }}>
            <Box className={classes.boxGrade}>
              <GradeBar event={event} porc={porc} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}
