import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DebriefingService from '../../../services/DebriefingService'
import { useGeneralPerformanceStyles } from '../../styles/general-performance.styles'
import GeneralPerformanceBar from './GeneralPerformanceBar'
import GeneralPerformanceLabel from './GeneralPerformanceLabel'
import colors from '../../../common/styles/colors'
import { FooterResult } from './FooterResult'
import { useTranslation } from "react-i18next"

interface GeneralPerformanceBoxProps {
  debriefingId?: string | number | undefined
  result?: {
    score?: number | undefined
  }
}

export default function GeneralPerformanceBox({ debriefingId, result }: GeneralPerformanceBoxProps) {
  const styleGenenralPerformance = useGeneralPerformanceStyles()
  const { t } = useTranslation()
  const [porc, setPorc] = useState('0')

  const getPerformanceStation = async (debriefingId: any) => {
    await DebriefingService.getPerformanceStation(debriefingId)
      .then((response) => {
        setPorc(response.data.performace_station)
      })
      .catch()
  }

  useEffect(() => {
    getPerformanceStation(debriefingId)
  }, [debriefingId])

  return (
    <Box className={styleGenenralPerformance.container}>
      <Typography
        sx={{ fontWeight: 'bold' }}
        className={styleGenenralPerformance.title}
      >
        {t("title_dialog_overall_performance")}
      </Typography>
      <Divider className={styleGenenralPerformance.divider} />
      <Box className={styleGenenralPerformance.boxPerformance}>
        <Typography
          sx={{ fontWeight: 'bold' }}
          className={styleGenenralPerformance.subtitle}
        >
          {t("title_dialog_overall_student_performance")} 
        </Typography>
        <GeneralPerformanceLabel porc={result?.score ?? porc} />
        <GeneralPerformanceBar porc={result?.score ?? porc} />
        <FooterResult score={result && result?.score} />
      </Box>
    </Box>
  )
}
