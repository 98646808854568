import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useScenarioStyles } from '../styles/scenarios.styles'
import { Grid } from '@mui/material'
import ScenarioExamsHistoryViewItem from './ScenarioExamsHistoryViewItem'

export default function ScenarioExamsHistoryView({ idScenario }: any) {
  const { t } = useTranslation()
  const scenarioStyle = useScenarioStyles()

  useEffect(() => {}, [idScenario])

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Box className={scenarioStyle.containerScenario} sx={{ mb: 6 }}>
          <ScenarioExamsHistoryViewItem
            name="file_laboratory"
            title={t('input_label_file_laboratory')}
            idScenario={idScenario}
          />
          <ScenarioExamsHistoryViewItem
            name="file_image"
            title={t('input_label_file_image')}
            idScenario={idScenario}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
