import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import { Divider, Grid, Link, TextField, Typography } from '@mui/material'
import { useScenarioStyles } from '../styles/scenarios.styles'
import AttachmentService from '../../services/AttachmentService'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'

export default function ItemFileExam({ id, name, url, removeFile }: any) {
  const { t } = useTranslation()
  const classes = useStyles()
  const styleScenario = useScenarioStyles()

  useEffect(() => {}, [id, name, url])

  return (
    <Box className={styleScenario.boxExamFile}>
      <Link
        className={styleScenario.fontExamFile}
        sx={{ textDecoration: 'none' }}
        href={url}
        target="_blank"
      >
        {name.toString().substring(0, 20) + ' ...'}
      </Link>
      <Typography className={styleScenario.fontExamFile}>
        <HighlightOffOutlinedIcon
          onClick={() => removeFile(id)}
          sx={{ fontSize: '1em', ml: 3 }}
        />
      </Typography>
    </Box>
  )
}
