import React, { useState } from 'react'
import { Box, CssBaseline } from '@material-ui/core'
import Menu from '../components/menu'
import Header from '../components/header'
import Footer from '../components/footer'
import { useStyles } from './styles/index.styles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/ReactToastify.min.css'

import { ThemeProvider } from '@mui/material/styles'
import theme from '../theme'
import useLayoutStyles from '../hooks/useLayoutStyles'
import { createSocketConnection } from '../../services/socketService'

const Wrapper = (Component: any) => {
  const classes = useStyles()

  const [showMenu, setShowMenu] = useState(true)

  const { contentContainerMarginLeft, smallScreen } = useLayoutStyles()

  const openMenu = () => {
    setShowMenu(!showMenu)
  }

  /**
   * Abre a conexão com o servidor websocket
   */
  createSocketConnection();

  // alert(contentContainerMarginLeft);


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className={classes.container}>
        <ToastContainer position="bottom-right" newestOnTop theme="colored" />
        {!smallScreen && <Menu showMenu={showMenu} openMenu={openMenu} />}
        <Header showMenu={showMenu} />
        <Box
          component="main"
          // pl={contentContainerPaddingLeft}
          ml={contentContainerMarginLeft+"px"}
          className={classes.contentContainer}
        >
          <Component />
        </Box>
        <Footer showMenu={showMenu} />
      </Box>
    </ThemeProvider>
  )
}

const hipladeTemplate = (InputComponent: any) => {
  return () => Wrapper(InputComponent)
}

export default hipladeTemplate
