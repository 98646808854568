import React, { useState } from "react";
import hipladeTemplate from "../../common/template";
import { Box, useTheme } from "@mui/material";
import Banner from "../../common/components/banner";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { useParams } from "react-router-dom";
import EventsService from "../../services/EventsService";
import { toast } from "react-toastify";
// import EventStatus from "../components/EventStatus"; DEPRECIADO
import { useDefaultStyles } from "../../common/styles/default";
import EventOverview from "../components/EventOverview";
import EventStationsView from "../components/EventStationsView";
import EventAdditionalInformationView from "../components/EventAdditionalInformationView";
import colors from "../../common/styles/colors";
import FooterEvent from "../components/footer";
import { useStyles } from "../styles/index.styles";

function EventsView() {
  const { eventId } = useParams();
  const styles = useDefaultStyles();
  const stylesEventsView = useStyles();

  const [status, setStatus] = React.useState("");

  const [event, setEvent] = useState<any>("");

  const theme = useTheme();

  const getCurrentEvent = async (eventId) => {
    await EventsService.get(eventId)
      .then((response: any) => {
        setEvent(response.data);
        setStatus(response.data.status);
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  /**
   *
   * DEPRECIADO.
   * O status do evento está contido no objeto EVENTO
   *const getCurrentStatus = async (eventId: any) => {
   * try {
   *   const response = await EventsService.getCurrentStatus(eventId);
   *   setStatus(response.data.status);
   *   console.log(response.data)
   * } catch (error) {
   *   console.log("Error: ", error);
   * }
   *};
   */

  React.useEffect(() => {
    /**
     * Carrega informações do evento corrente
     */
    if (eventId) {
      /**
       * DEPRECIADO.
       * O status do evento está contido no objeto EVENTO
       *
       * getCurrentStatus(eventId);
       */

      getCurrentEvent(eventId);
    }
  }, [eventId]);

  return (
    <Box>
      {/** Banner da Página */}
      <Banner
        type="small"
        image={"medium"}
        title={event?.name}
        icon={
          <VideocamOutlinedIcon
            sx={{ fontSize: "3.5em", color: "#FFFFFF", mr: 5 }}
          />
        }
      />
      <Box
      // className={styles.container}
      // px={5}
      >
        {
          /**
           * TODO: refatorar lógica de validade de objeto
           */
          /** Componentes de Página */
          event?.id != 0 ? (
            <Box display={"flex"} flexDirection={"column"}>
              <Box mt={"50px"} className={stylesEventsView.boxContainer}>
                <EventOverview
                  status={status}
                  event={event}
                  getCurrentEvent={getCurrentEvent}
                />
              </Box>
              <Box
                className={stylesEventsView.boxContainer}
                bgcolor={colors.purpleLightStation}
              >
                <EventAdditionalInformationView
                  statusRoom={status}
                  event={event?.id}
                />
              </Box>
              <Box className={stylesEventsView.boxContainer}>
                <EventStationsView status={status} event={event} />
              </Box>
            </Box>
          ) : (
            "Carregando..."
          )
        }
      </Box>
      <FooterEvent />
    </Box>
  );
}

export default hipladeTemplate(EventsView);
