import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DebriefingService from "../../../services/DebriefingService";
import { useStyles } from "../../styles/index.styles";

import { useGeneralPerformanceAStyles } from "./styles/general-performance.styles";
import colors from "../../../common/styles/colors";

export default function GeneralPerformanceBar({ porc }: any) {
  const styleGenenralPerformance = useGeneralPerformanceAStyles();
  const [color, setColor] = useState("");

  const colorBar = () => {
    if (porc < 60) {
      setColor(colors.pinkDark);
    } else if (porc >= 60 && porc < 70) {
      setColor(colors.yellow);
    } else if (porc >= 70 && porc < 90) {
      setColor(colors.green);
    } else if (porc >= 90) {
      setColor(colors.greenMedium);
    }
  };

  useEffect(() => {
    colorBar();
  }, [porc]);

  return (
    <Box className={styleGenenralPerformance.porcBarBack}>
      <Box
        className={styleGenenralPerformance.porcBar}
        sx={{ width: porc + "%", backgroundColor: color }}
      >
        {parseFloat(porc) > 0 && (
          <Box sx={{ mr: 2 }}>{porc.toString().replace(".", ",") + "%"}</Box>
        )}
      </Box>
    </Box>
  );
}
