import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useStyles } from './styles/index.styles'
import CircularProgress from '@mui/material/CircularProgress'

export default function LoaderHiplade() {
  const classes = useStyles()

  useEffect(() => {}, [])

  return (
    <Box className={classes.container}>
      <Box className={classes.loader}>
        <CircularProgress color="primary" />
      </Box>
    </Box>
  )
}
