export const student = [
  {
    type: 'text',
    name: 'nickname',
    value: '',
    label: 'Como quer ser chamado?',
  },
  {
    type: 'number',
    name: 'age',
    value: '',
    label: 'Idade',
  },
]
export const admin = []
export const entity_support = []
export const teacher = []
export const external_measurer = []
