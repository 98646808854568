import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";

import { useStyles } from "../styles";

import colors from "../../common/styles/colors";

export default function CardItemskill({
  question,
  item_value,
  item_id,
  index,
  changeskillQuestionItem,
  changeskillValueItem,
  removeArrItem,
  handleClickOpen,
  points,
}: any) {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {}, [question, item_value, item_id, index]);

  return (
    <Box className={classes.cardGrade} sx={{ mb: 2 }}>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{ fontWeight: "bold" }}
          xs={7}
          className={classes.titleskill}
        >
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            defaultValue={question}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              changeskillQuestionItem(event.target.value, index)
            }
          />
        </Grid>

        <Grid item xs={1} className={classes.boxskillPoints}>
          <Typography
            sx={{
              fontWeight: "bold",
              width: "100%",
              textAlign: "right",
            }}
            className={classes.titleGrade}
          >
            {t("input_label_value")}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.boxskill}>
          <TextField
            type="number"
            variant="outlined"
            sx={{ width: "150px" }}
            size="small"
            value={item_value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              changeskillValueItem(event.target.value, index)
            }
          />
          <Typography
            sx={{
              ml: 3,
              color: "#9C9C9C !important",
              fontSize: "20px !important",
            }}
          >
            / {points}
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.boxskillPoints}>
          <Typography
            sx={{ fontWeight: "bold" }}
            className={classes.titleGrade}
          >
            <ClearIcon
              onClick={() =>
                !item_id ? removeArrItem(index) : handleClickOpen(item_id)
              }
              sx={{ color: colors.gray, cursor: "pointer" }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
