import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../../styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerHeader: {
      display: "flex",
      width: '100%',
      alignItems: "center",
      justifyContent: "space-between"
    },
    headerTitle: {
      fontSize: '1.4em !important',
      color: colors.black,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center'
    },
    containerBody: {
      display: 'flex',
      width: 780,
      maxHeight: 300,
      overflowY: 'auto',
      gap: 10,
      flexWrap: 'wrap',
      '&::-webkit-scrollbar': {
        width: '10px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.gray,
        borderRadius: 4
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: colors.grayLight
      }
    },
    cardContent: {
      backgroundColor: colors.purpleLightStation,
      display: 'flex',
      gap: 5
    },
  }),
)
