import React, { useEffect, useState } from 'react';
import HomeArea from '../components/HomeArea'
import hipladeTemplate from '../../common/template'
import secureLocalStorage from 'react-secure-storage'
import HomeAreaStudent from '../components/HomeAreaStudent'
import HomeAreaCoordinator from '../components/HomeAreaCoordinator'
import HomeAreaTeacher from '../components/HomeAreaTeacher'
import HomeAreaManager from '../components/HomeAreaManager'
import { Box, Typography } from '@mui/material'
import { useHomeStyles } from '../styles/home.styles'
import { useTranslation } from 'react-i18next'
import UsersService from '../../services/UsersService'
import { useTheme } from '@mui/material/styles' // Importação necessária para acessar os breakpoints
import useLayoutStyles from '../../common/hooks/useLayoutStyles';

function Home() {
  const classes = useHomeStyles();
  const { t } = useTranslation()
  const theme = useTheme() // Usando o hook useTheme para acessar os breakpoints
  const [userName, setUserName] = useState('');

  const { contentContainerPaddingLeft } =  useLayoutStyles()

  useEffect(() => {
    // Carrega as informações do usuário ao montar o componente
    const auth_user_id = secureLocalStorage.getItem('id');
    UsersService.get(auth_user_id).then((response) => {
      // Pegando apenas o primeiro nome
      setUserName(response.data.name.split(' ')[0]);
    });
  }, []);

  let profile_id = secureLocalStorage.getItem('role')

  return (
    <Box 
      className={classes.boxHomeCreateItens}
      px={contentContainerPaddingLeft+"px"}
    >
      <Box>
        <Typography  className={classes.message} variant="h4" 
          sx={{ // Utilizando sx para aplicar estilos responsivos
            [theme.breakpoints.between(0, 600)]: {
              fontSize: '28px',
              paddingLeft: '8%',
              maxWidth: '94%',
            },
            [theme.breakpoints.between(600, 900)]: {
              fontSize: '32px',
              paddingLeft: '6%',
              maxWidth: '94%',
            },
            [theme.breakpoints.between(900, 1200)]: {
              fontSize: '32px',
              paddingLeft: '4%',
              maxWidth: '94%',
            },
            [theme.breakpoints.between(1200, 1440)]: {
              fontSize: '36px',
              paddingLeft: '4%',
              maxWidth: '94%',
            },
            [theme.breakpoints.up(1440)]: {
              fontSize: '38px',
              paddingLeft: '2%',
              maxWidth: '94%',
            },
        }}
        >
          <strong>{t("welcome_message")} {userName}!</strong>
        </Typography>
        {profile_id == 1 && <HomeArea />}
        {profile_id == 2 && <HomeAreaCoordinator />}
        {profile_id == 5 && <HomeAreaStudent />}
        {profile_id == 3 && <HomeAreaTeacher />}
        {profile_id == 6 && <HomeAreaManager />}
      </Box>
    </Box>
  )
}
export default hipladeTemplate(Home)
