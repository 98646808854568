import api from "../config/api";
import ICompetenceData from "../types/Competence";

const store = (data: any) => {
  return api.post<ICompetenceData>("/competences", data);
};

const getAll = (page = 1, paginate = 0) => {
  return api.get<ICompetenceData>(
    "/competences?page=" + page + "&paginate=" + paginate
  );
};

const get = (id: number) => {
  return api.get<ICompetenceData>("/competences/" + id);
};

const update = (id: any, data: any) => {
  return api.put<ICompetenceData>("/competences/" + id, data);
};

const remove = (id: any) => {
  return api.delete("/competences/" + id);
};

const relationships = (id: number) => {
  return api.get(`/competences/${id}/relationships`);
};

const CompetenceService = {
  store,
  getAll,
  get,
  update,
  remove,
  relationships,
};

export default CompetenceService;
