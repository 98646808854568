import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../common/styles/colors";
import fonts from "../../common/styles/fonts";

export const useControlRoomStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerItem: {
      backgroundColor: "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(4),
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: 7,
    },
    containerTabMenu: {
      width: "100%",
      boxShadow: "0px 3px 8px rgba(145, 145, 145, 0.25)",
      backgroundColor: colors.white,
    },
    container: {
      marginTop: 40,
    },
    containerCards: {
      display: "flex",
      flexWrap: "wrap",
    },
    containerCard: {
      width: 289,
      marginRight: 15,
      marginBottom: 15,
      padding: 20,
      cursor: "pointer",
    },
    breadCrumbCard: {
      textTransform: "uppercase",
      color: colors.purpleMedium,
    },
    titleCard: {
      color: colors.blueDark,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      textTransform: "uppercase",
    },
    dividerCard: {
      backgroundColor: colors.purple,
      height: 3,
    },
    dividerStation: {
      backgroundColor: colors.lilacMediumLight,
    },
    buttonBanner: {
      color: "#310AA2!important",
      backgroundColor: "#FFFFFF!important",
      fontFamily: "Inter!important",
      fontWeight: 500,
      justifyContent: "end",
    },
    dateEvent: {
      color: colors.black,
      marginTop: "1px !important",
      marginBottom: "-10px !important",
    },
    statusLabelEventNext: {
      color: colors.blueMedium,
      fontWeight: 700,
    },
    statusEventNext: {
      color: colors.blue,
      fontWeight: 700,
    },
    statusLabelEventEnded: {
      color: colors.greenDark,
      fontWeight: 700,
    },
    statusEventEnded: {
      color: colors.green,
      fontWeight: 700,
    },
    statusLabelEventRunning: {
      color: colors.pink,
      fontWeight: 700,
    },
    statusEventRunning: {
      color: colors.pinkLight,
      fontWeight: 700,
    },
    eventStatusTitle: {
      fontFamily: fonts.interComponent,
    },
    boxHomeCreateItens: {
      //border: '2px solid ' + colors.lilacLightest,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: 12,
      marginTop: 50,
      marginBottom: -75,
    },
    boxHomeStudentItens: {
      //border: '2px solid ' + colors.lilacLightest,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: 13,
      marginTop: 50,
      marginBottom: -75,
    },
    boxControlRoom: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: 8,
      marginTop: 50,
    },
    boxHomeCoordinatorItens: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: 13,
      marginTop: 50,
      marginBottom: -75,
    },
    cardItemAdd: {
      backgroundColor: colors.lilacMedium,
      boxShadow: "0px 2px 8px rgba(95, 69, 191, 0.6)",
      borderRadius: "12px",
      width: "16.133em",
      height: "12.234em",
      cursor: "pointer",
    },
    textCardItemAdd: {
      fontSize: "2em !important",
      textAlign: "center",
    },
    iconCircleCardAdd: {
      borderRadius: "50%",
      backgroundColor: colors.lilacDarkMedium,
      width: "2.188em",
      height: "2.188em",
    },
    iconAdd: {
      color: colors.purpleIcon,
    },
    boxIconAdd: {
      padding: 10,
      display: "flex",
      justifyContent: "flex-end",
    },
    boxTextCardAdd: {
      padding: "20px",
    },
    boxEventLinks: {
      width: "100%",
      display: "flex",
      justifyContent: "start",
      marginTop: 30,
    },
    dividerCardLink: {
      backgroundColor: colors.green,
      height: 3,
      width: 62,
    },
    containerCardLink: {
      width: "auto",
      minHeight: 329,
      marginRight: 15,
      marginLeft: 2,
      marginBottom: 15,
      marginTop: 11,
      padding: 5,
      cursor: "pointer !important",
      display: "flex",
      alignItems: "center",
    },
    titleCardLink: {
      color: colors.purple,
      marginTop: "10px !important",
      marginBottom: "15px !important",
    },
    eventOverviewBody: {
      fontFamily: fonts.interComponent,
    },
    eventStationListStudents: {
      margin: 20,
      height: 76,
      backgroundColor: colors.purpleLightStation,
      alignItems: "center",
      boxShadow: "0px 3px 8px rgba(145, 145, 145, 0.25)",
      borderRadius: "4px !important",
      padding: 20,
    },
    eventStationTitle: {
      color: colors.purpleMediumSub,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      marginRight: "10px !important",
      fontSize: "1.5em !important",
    },
    eventStationSubTitle: {
      color: colors.purpleSubTitle,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      marginRight: "10px !important",
      fontSize: "1.5em !important",
    },
    eventStationTitleAppraiser: {
      color: colors.blueDark,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      marginRight: "10px !important",
      alignItems: "right",
    },
    eventBoxskill: {
      marginTop: 40,
      width: "100%",
      left: "392px",
      top: "544px",
      backgroundColor: colors.white,
      boxShadow: "red",
      display: "block",
    },
    boxTitleStation: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "end",
      alignContent: "center",
    },
    appraiserName: {
      fontSize: "1em !important",
      color: colors.purpleAvatarName,
    },
    textDetail: {
      color: colors.purpleDetail,
    },
    boxContentAvatar: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "start",
      alignContent: "center",
    },
    titleStudent: {
      color: colors.blackMedium,
      fontSize: "1em !important",
    },
    containerCardControlRoomLink: {
      width: "100%",
      marginBottom: 10,
      padding: 10,
      cursor: "pointer !important",
    },
  })
);
