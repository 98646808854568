import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDebriefingVideoLiveStyles } from "../../styles/debriefing-video-live";
import DebriefingVideoLive from "./DebriefingVideoLive";
import AvatarHiplade from "../../../common/components/avatarhiplade";
import Banner from "../../../common/components/banner";
import UsersService from "../../../services/UsersService";
import StationsService from "../../../services/StationsService";

export default function EventInLive() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useDebriefingVideoLiveStyles();

  const { eventId, stationId, evaluatedId, debriefingId } = useParams();
  const [student, setStudent] = useState({} as any);
  const [station, setStation] = useState({} as any);

  const handleBack = () => {
    navigate(-1);
  };

  const loadStudent = async (evaluatedId: any) => {
    await UsersService.get(evaluatedId)
      .then((response: any) => {
        if (response) {
          setStudent(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadStation = async (stationId: any) => {
    await StationsService.get(stationId)
      .then((response: any) => {
        if (response) {
          setStation(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (evaluatedId) {
      loadStudent(evaluatedId);
    }
    if (stationId) {
      loadStation(stationId);
    }
  }, []);

  return (
    <Box>
      <Banner
        type="student"
        title={station?.name + " / " + station?.scenario_name}
        subtitle={"Aluno(a): " + student?.name}
        avatar={
          <AvatarHiplade nameUser={student?.name} size="lg" id={evaluatedId} />
        }
        button={
          <Button
            className={classes.buttonBanner}
            variant="contained"
            color="secondary"
            onClick={handleBack}
          >
            {t("btn_return")}
          </Button>
        }
      />

      <DebriefingVideoLive debriefingId={debriefingId} />
    </Box>
  );
}
