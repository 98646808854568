import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'

import Banner from '../../common/components/banner'
import ScheduleListCard from './ScheduleListCard'
import banner from '../../assets/images/banners/event_schedule_banner.png'

import EventsService from '../../services/EventsService'
import IEventData from '../../types/Event'

import TabsHiplade from '../../common/components/tabshiplade'

import { Grid } from '@mui/material'

export default function ScheduleList() {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const classes = useStyles()
  const { tab } = useParams()

  const { pathname } = useLocation()

  const [events, setEvents] = useState<IEventData[]>([])
  const [status_id, setStatusId] = React.useState(!tab ? '1' : tab)

  const tabs = [
    { id: '1', title: t('tab_event_schedule_next') },
    { id: '2', title: t('tab_event_schedule_running') },
    { id: '3', title: t('tab_event_schedule_ended') },
    { id: '4', title: t('tab_event_schedule_concluded') },
  ]

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setStatusId(newValue)
    loadEvents(newValue)
  }

  const loadEvents = async (status_id: any) => {
    setEvents([])
    await EventsService.getListByStatus(status_id)
      .then((response: any) => {
        if (response) {
          setEvents(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  useEffect(() => {
    loadEvents(status_id)
  }, [])

  useEffect(() => {
    const id = pathname?.split('/events/schedule/')[1]?.toString()
    loadEvents(id)
    handleChangeTab('' as any, id)
  }, [pathname])


  return (
    <Box>
      <Banner
        type="big"
        image={banner}
        title={t('banner_title_event_schedule')}
        subtitle={t('banner_subtitle_event_schedule')}
        colorDivider="new"
        divider="1"
      />
      <Box className={classes.containerTabMenu}>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            {/* <ScheduleListTab
              status_id={status_id}
              handleChangeTab={handleChangeTab}
            /> */}
            <TabsHiplade
              status_id={status_id}
              handleChangeTab={handleChangeTab}
              tabs={tabs}
            />

          </Grid>
        </Grid>
      </Box>
      <Box className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} className={classes.containerCards}>
            {Array.isArray(events) && events.map((row, index) => (
              <ScheduleListCard
                key={index}
                event_id={row.id}
                title={row.name}
                date={row.date}
                status_id={status_id}
                index={index}
                status={row.status}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
