import { makeStyles, Theme, createStyles } from '@material-ui/core'
import fonts from '../../../styles/fonts'
import colors from '../../../styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({   
    boxError: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',  
      alignItems: 'center',
      backgroundColor: '#F6F5FB !important',
    },
    boxErrorButton: {
      backgroundColor: '#601DCC !important',
    },
  }),
)