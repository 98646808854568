import { Box } from "@mui/material";
import * as React from "react";
import api from "../../../config/api";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export default function GetObjectAttribute({
  foreignKey,
  apiGet,
  attributeName,
}: any) {
  if (!foreignKey && !apiGet) {
    return <Box />;
  }

  const { t } = useTranslation();

  const [attribute, setAttribute] = React.useState();
  const [queue, setQueue] = React.useState(Promise.resolve());

  /**
   *
   * @param apiGet // URL do endpoint do modelo
   * @param foreignKey // Chave de relacionamento. Chave estrangeira.
   * @param attributeName // Nome do atributo do obejeto Ex.: id, name, etc...
   */

  const getModel = async (apiGet, foreignKey) => {
    await api
      .get(apiGet + "/" + foreignKey)
      .then((response: any) => {
        setAttribute(response.data[attributeName]);
      })
      .catch((e: Error) => {});
  };

  React.useEffect(() => {
    setQueue(
      queue
        .then(() => getModel(apiGet, foreignKey))
        .catch((err) => {
          console.error(err);
        })
    );
  }, []);

  return (
    <Box>
      {/**
       * Se o valor de @attribute ainda não foi carregado apresenta
       * a mensagem de carregamento
       */}
      {attribute ? attribute : t("loading")}
    </Box>
  );
}
