import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../common/styles/colors";
import fonts from "../../common/styles/fonts";

export const useHomeStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerItem: {
      backgroundColor: "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(4),
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: 7,
    },
    containerTabMenu: {
      width: "100%",
      boxShadow: "0px 3px 8px rgba(145, 145, 145, 0.25)",
      backgroundColor: colors.white,
    },
    container: {
      marginTop: 40,
    },
    containerCards: {
      display: "flex",
      flexWrap: "wrap",
    },
    containerCard: {
      width: 289,
      marginRight: 15,
      marginBottom: 15,
      padding: 20,
      cursor: "pointer",
    },
    breadCrumbCard: {
      textTransform: "uppercase",
      color: colors.purpleMedium,
    },
    titleCard: {
      color: colors.blueDark,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      textTransform: "uppercase",
    },
    dividerCard: {
      backgroundColor: colors.purple,
      height: 3,
    },
    dividerStation: {
      backgroundColor: colors.lilacMediumLight,
    },
    dateEvent: {
      color: colors.black,
      marginTop: "15px !important",
      marginBottom: "10px !important",
    },
    dateCardQuickGuide: {
      color: colors.white,
      marginTop: "15px !important",
      marginBottom: "10px !important",
    },
    statusLabelEventNext: {
      color: colors.blueMedium,
      fontWeight: 700,
    },
    statusEventNext: {
      color: colors.blue,
      fontWeight: 700,
    },
    statusLabelEventEnded: {
      color: colors.greenDark,
      fontWeight: 700,
    },
    statusEventEnded: {
      color: colors.green,
      fontWeight: 700,
    },
    statusLabelEventRunning: {
      color: colors.pink,
      fontWeight: 700,
    },
    statusEventRunning: {
      color: colors.pinkLight,
      fontWeight: 700,
    },
    eventStatusTitle: {
      fontFamily: fonts.interComponent,
    },
    boxHomeCreateItens: {
      //border: '2px solid ' + colors.lilacLightest,
      // width: '80%',
      display: "flex",
      justifyContent: "center",
      // marginLeft: '15%',
      // [theme.breakpoints.down('md')]: {
      //   width: '80%',
      // }
    },
    boxHomeCreateItensStudent: {
      //border: '2px solid ' + colors.lilacLightest,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginLeft: "20px",
      padding: "10px",
    },
    boxHomeStudentItens: {
      //border: '2px solid ' + colors.lilacLightest,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginLeft: "20px",
    },
    boxHomeTeacherItens: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: 12,
      marginTop: 50,
      marginBottom: -75,
    },
    boxHomeCoordinatorItens: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: 13,
      marginTop: 50,
      marginBottom: -75,
    },
    cardItemAdd: {
      backgroundColor: colors.lilacMedium,
      boxShadow: "0px 2px 8px rgba(95, 69, 191, 0.6)",
      borderRadius: "12px",
      width: "16.133em",
      height: "12.234em",
      cursor: "pointer",
    },
    textCardItemAdd: {
      fontSize: "2em !important",
      textAlign: "center",
    },
    iconCircleCardAdd: {
      borderRadius: "50%",
      backgroundColor: colors.lilacDarkMedium,
      width: "2.188em",
      height: "2.188em",
    },
    iconAdd: {
      color: "colors.purpleIcon",
    },
    boxIconAdd: {
      padding: 10,
      display: "flex",
      justifyContent: "flex-end",
    },
    boxTextCardAdd: {
      padding: "20px",
    },
    boxEventLinks: {
      width: "100%",
      display: "flex",
      justifyContent: "start",
      marginTop: 30,
    },
    dividerCardLink: {
      backgroundColor: colors.green,
      height: 3,
      width: 62,
    },
    dividerCardQuickGuide: {
      backgroundColor: colors.white,
      height: 3,
      width: 62,
    },
    containerCardLink: {
      width: "auto",
      minHeight: 329,
      marginRight: 11,
      marginBottom: 15,
      padding: 5,
      cursor: "pointer !important",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.between("lg", "xl")]: {
        width: "98%",
      },
    },
    containerCardStudentLink: {
      width: "auto",
      minHeight: 329,
      marginRight: 11,
      marginBottom: 15,
      padding: 5,
      cursor: "pointer !important",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: "80px",
      },
      [theme.breakpoints.between("lg", "xl")]: {
        width: "96%",
        marginLeft: "-24px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "100%",
        maxWidth: "100%",
        marginLeft: "0px",
      },
    },
    titleCardLink: {
      color: colors.blueDark,
      marginTop: "10px !important",
      marginBottom: "15px !important",
    },
    titleCardQuickGuide: {
      color: colors.white,
      marginTop: "10px !important",
      marginBottom: "15px !important",
    },
    eventOverviewBody: {
      fontFamily: fonts.interComponent,
    },
    eventStationListStudents: {
      margin: 20,
      height: 76,
      backgroundColor: colors.purpleLightStation,
      alignItems: "center",
      boxShadow: "0px 3px 8px rgba(145, 145, 145, 0.25)",
      borderRadius: "4px !important",
      padding: 20,
    },
    eventStationTitle: {
      color: colors.purpleMediumSub,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      marginRight: "10px !important",
      fontSize: "1.5em !important",
    },
    eventStationSubTitle: {
      color: colors.purpleSubTitle,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      marginRight: "10px !important",
      fontSize: "1.5em !important",
    },
    eventStationTitleAppraiser: {
      color: colors.blueDark,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      marginRight: "10px !important",
      alignItems: "right",
    },
    eventBoxskill: {
      marginTop: 40,
      width: "100%",
      left: "392px",
      top: "544px",
      backgroundColor: colors.white,
      boxShadow: "red",
      display: "block",
    },
    boxTitleStation: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "end",
      alignContent: "center",
    },
    appraiserName: {
      fontSize: "1em !important",
      color: colors.purpleAvatarName,
    },
    textDetail: {
      color: colors.purpleDetail,
    },
    boxContentAvatar: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "start",
      alignContent: "center",
    },
    titleStudent: {
      color: colors.blackMedium,
      fontSize: "1em !important",
    },
    containerCardHomeLink: {
      width: "98%",
      minHeight: 329,
      maxWidth: 900,
      marginTop: "10px",
      marginBottom: 5,
      padding: 15,
      cursor: "pointer !important",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        width: "100%",
      },
      [theme.breakpoints.between("md", "lg")]: {
        flexDirection: "column-reverse",
        height: "93%",
        marginTop: "5%",
      },
      [theme.breakpoints.between("lg", "xl")]: {
        flexDirection: "column-reverse",
        width: "98%",
      },
      [theme.breakpoints.up("xl")]: {
        flexDirection: "row",
      },
    },
    containerCardQuickGuide: {
      width: "98%",
      minHeight: 329,
      marginTop: "10px",
      marginBottom: 5,
      padding: 15,
      cursor: "pointer !important",
      display: "flex",
      alignItems: "center",
      backgroundColor: colors.purple,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        marginTop: "10px",
        width: "100%",
      },
      [theme.breakpoints.between("md", "lg")]: {
        flexDirection: "column-reverse",
        height: "93%",
        marginTop: "5%",
      },
      [theme.breakpoints.between("lg", "xl")]: {
        flexDirection: "column-reverse",
        width: "98%",
      },
      [theme.breakpoints.up("xl")]: {
        flexDirection: "row",
      },
    },
    containerCardHomeAnalytics: {
      width: "93%",
      minHeight: 329,
      maxWidth: 900,
      marginBottom: 5,
      padding: 15,
      cursor: "pointer !important",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        width: "100%",
        marginBottom: "40px",
      },
      [theme.breakpoints.between("md", "lg")]: {
        flexDirection: "row",
      },
      [theme.breakpoints.between(1200, 1280)]: {
        maxWidth: "88%",
        marginBottom: "6%",
      },
      [theme.breakpoints.between(1200, 1536)]: {
        maxWidth: "87%",
        marginBottom: "6%",
      },
      [theme.breakpoints.up(1536)]: {
        width: "100%",
        maxWidth: "100%",
        marginBottom: "8%",
      },
    },
    containerCardHomeAnalyticsStudent: {
      width: "94%",
      minHeight: 329,
      maxWidth: 900,
      marginBottom: 5,
      padding: 15,
      cursor: "pointer !important",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        width: "100%",
      },
      [theme.breakpoints.between("md", "lg")]: {
        flexDirection: "column-reverse",
        width: "100%",
        marginBottom: "80px",
      },
      [theme.breakpoints.between("lg", "xl")]: {
        maxWidth: "93%",
        marginBottom: "80px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "98%",
        maxWidth: "100%",
        marginBottom: "8%",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        marginTop: "8px",
        marginBottom: "14px",
      },
    },
    cardImage: {
      maxWidth: "590px",
    },
    cardText: {
      maxWidth: "393px",
    },
    cardTextSchedule: {
      width: "393px",
      height: "355px",
    },
    Box: {
      //border: '1px solid black',
      padding: "35px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    message: {
      position: "relative",
      color: "#4F0DBB",
      width: "100%",
      paddingTop: "50px",
      marginBottom: "50px",
      marginLeft: '30px',
      justifyContent: "center",
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100%",
      },
    },
  })
);
