import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import {
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useScenarioStyles } from '../styles/scenarios.styles'

export default function FormScenarioHistoryOfVitalSigns({
  physical_exam,
  setPhysicalExam,
  dataExam1,
  dataExam2,
  dataExam3,
  setDataExam1,
  setDataExam2,
  setDataExam3,
  history_vital_sign,
  changeHistoryVitalItem,
}: any) {
  const { t } = useTranslation()
  const classes = useStyles()
  const styleScenario = useScenarioStyles()

  useEffect(() => {}, [
    physical_exam,
    dataExam1,
    dataExam2,
    dataExam3,
    history_vital_sign,
  ])

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}></Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <Typography
              className={classes.titleScenarioCheckbox}
              sx={{ fontWeight: 'bold' }}
            >
              {t("title_dialog_history_vital_signs_one")}
            </Typography>
            <Box className={styleScenario.boxInputVitalSigns}>
              <TextField
                variant="outlined"
                type="date"
                sx={{ mt: 2 }}
                value={dataExam1}
                size="small"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDataExam1(event.target.value)
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <Typography
              className={classes.titleScenarioCheckbox}
              sx={{ fontWeight: 'bold' }}
            >
              {t("title_dialog_history_vital_signs_two")}
            </Typography>
            <Box className={styleScenario.boxInputVitalSigns}>
              <TextField
                variant="outlined"
                type="date"
                sx={{ mt: 2 }}
                fullWidth
                value={dataExam2}
                size="small"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDataExam2(event.target.value)
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <Typography
              className={classes.titleScenarioCheckbox}
              sx={{ fontWeight: 'bold' }}
            >
              {t("title_dialog_history_vital_signs_three")}
            </Typography>
            <Box className={styleScenario.boxInputVitalSigns}>
              <TextField
                variant="outlined"
                type="date"
                size="small"
                fullWidth
                sx={{ mt: 2 }}
                value={dataExam3}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDataExam3(event.target.value)
                }}
              />
            </Box>
          </Grid>
        </Grid>
        {history_vital_sign.map((mat, index) => (
          <Grid
            container
            key={index}
            spacing={2}
            sx={{ mt: 3, textAlign: 'center' }}
          >
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              <Typography
                className={classes.titleScenarioCheckbox}
                sx={{ fontWeight: 'bold' }}
              >
                {mat.title}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box className={styleScenario.boxInputVitalSigns}>
                <TextField
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{mat.unit}</InputAdornment>
                    ),
                  }}
                  fullWidth
                  size="small"
                  defaultValue={mat.exam_1?.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    changeHistoryVitalItem(event.target.value, index, 1)
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={styleScenario.boxInputVitalSigns}>
                <TextField
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{mat.unit}</InputAdornment>
                    ),
                  }}
                  size="small"
                  defaultValue={mat.exam_2?.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    changeHistoryVitalItem(event.target.value, index, 2)
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={styleScenario.boxInputVitalSigns}>
                <TextField
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{mat.unit}</InputAdornment>
                    ),
                  }}
                  size="small"
                  defaultValue={mat.exam_3?.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    changeHistoryVitalItem(event.target.value, index, 3)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box className={styleScenario.topField} sx={{ mb: 4 }}>
        <Divider className={styleScenario.divider} />
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          className={classes.titleScenarioCheckbox}
          sx={{ fontWeight: 'bold' }}
        >
          {t('input_label_physical_exam')}
        </Typography>
        <Box className={styleScenario.topField}>
          <TextField
            fullWidth
            id="physical_exam"
            name="physical_exam"
            placeholder="Digite aqui."
            variant="outlined"
            value={physical_exam}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPhysicalExam(event.target.value)
            }}
            multiline
            rows={5}
          />
        </Box>
      </Box>
    </Box>
  )
}
