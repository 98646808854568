import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbText: {
      color: colors.purple,
      fontFamily: fonts.interComponent,
      cursor: 'pointer'
    },
    boxBreadItens: {
      display: 'flex',
      flexDirection: 'row',
    },
  }),
)
