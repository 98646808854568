import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../styles/index.styles";
import { useDebriefingStyles } from "../../styles/debriefing.styles";
import ChecklistTitleView from "./ChecklistTitleView";
import SkillTitleView from "./SkillTitleView";
import SkillItemListView from "./SkillItemListView";
import CheckListService from "../../../services/CheckListService";

export default function DebriefingChecklistView({
  eventId,
  stationId,
  evaluatedId,
  debriefingId,
  checklistId,
  station,
  scenario,
  skills,
}: any) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const debriefingStyle = useDebriefingStyles();
  const [checklist, setChecklist] = useState({} as any);

  const loadChecklist = async (id: any) => {
    await CheckListService.get(id)
      .then((response: any) => {
        if (response) {
          setChecklist(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (checklistId) {
      loadChecklist(checklistId);
    }
  }, [skills, station, debriefingId, checklistId]);

  return (
    <Box className={debriefingStyle.boxDebriefing}>
      <ChecklistTitleView
        title={station?.checklist_name}
        checklist={checklist}
      />
      {/* Informações de tema e competência */}
      <Box>
        <p>
          <strong>{t("title_dialog_checklist_view").toUpperCase()} </strong>
          {checklist?.theme_name}
        </p>
        <p>
          <strong>
            {t("title_dialog_debriefing_checklist_view").toUpperCase()}{" "}
          </strong>
          {checklist?.competence_name}
        </p>
      </Box>
      {skills?.map((skill, index) => (
        <Box className={debriefingStyle.boxskill} key={index}>
          <SkillTitleView title={skill.name} index={index + 1} />
          <SkillItemListView
            eventId={eventId}
            stationId={stationId}
            evaluatedId={evaluatedId}
            debriefingId={debriefingId}
            checklistId={checklistId}
            skillId={skill.skill_id}
          />
        </Box>
      ))}
    </Box>
  );
}
