import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'

import Banner from '../../common/components/banner'
import AvatarHiplade from '../../common/components/avatarhiplade'
import TabsHiplade from '../../common/components/tabshiplade'
import { useDefaultStyles } from '../../common/styles/default'
import { useScenarioStyles } from '../styles/scenarios.styles'
import { useParams } from 'react-router-dom'

import EventsService from '../../services/EventsService'
import IEventData from '../../types/Event'

import UsersService from '../../services/UsersService'

import { Button, Grid, Typography } from '@mui/material'

export default function ScenarioTitleView({ index, title, scenario, onClick }: any) {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const classes = useStyles()
  const styles = useDefaultStyles()
  const scenarioStyle = useScenarioStyles()

  return (
    <Box onClick={onClick} className={scenarioStyle.titleBox}>
      <Typography
        className={scenarioStyle.titleNumber}
        sx={{ fontWeight: 'bold' }}
      >
        {index}
      </Typography>
      <Typography className={scenarioStyle.title} sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      {scenario && (
        <Typography className={scenarioStyle.titleScenario}>
          {scenario}
        </Typography>
      )}
    </Box>
  )
}
