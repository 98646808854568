import api from '../config/api'
import ICameraData from '../types/Camera'

const store = (data: any) => {
  return api.post<ICameraData>('/cameras', data)
}

const getAll = (page = 1, paginate = 0) => {
  return api.get<ICameraData>('/cameras?page=' + page + '&paginate=' + paginate)
}

const get = (id: number) => {
  return api.get<ICameraData>('/cameras/' + id)
}

const update = (id: any, data: any) => {
  return api.put<ICameraData>('/cameras/' + id, data)
}

const remove = (id: any) => {
  return api.delete('/cameras/' + id)
}

const getByRoom = (idRoom: number) => {
  return api.get<ICameraData>(`rooms/${idRoom}/cameras`)
}

const CameraService = {
  store,
  getAll,
  get,
  update,
  remove,
  getByRoom
}

export default CameraService
