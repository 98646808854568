import React from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles/index.styles";
import { useTheme } from '@mui/material/styles' // Importação necessária para acessar os breakpoints

import { Divider, Typography } from "@mui/material";

type props = {
  title?: any;
  font_transform?: any;
  font_size?: any;
  icon?: any;
  button?: any;
};

export default function TitleForm({
  title,
  font_transform,
  font_size,
  icon,
  button,
}: props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme() // Usando o hook useTheme para acessar os breakpoints

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',            
        }
      }}>
        <Typography
          className={styles.title}
          sx={{
            textTransform: font_transform,
            fontWeight: "bold",
            fontSize: font_size + "!important",
          }}
        >
          {icon}
          {title}
        </Typography>
        {button}
      </Box>
      <Divider className={styles.divider} />
    </Box>
  );
}
