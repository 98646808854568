import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../../styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerHeader: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerButton: {
      fontWeight: 'bold',
      color: colors.purple,
      border: `2px solid ${colors.purple}`,
      fontSize: '12px',
      padding: '5px 20px',
    },
    headerTitle: {
      fontSize: '1.4em !important',
      color: colors.purple,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
    },
    headerSubtitle: {
      fontWeight: 'lighter',
      fontSize: '1.4em !important',
      color: colors.purple,
    },
    containerBody: {
      display: 'flex',
      flexDirection: 'column',
      width: 780,
      gap: 20,
    },
    wrapperBody: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 40,
    },
    wrapperBodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 10,
    },
    iconButton: {
      backgroundColor: colors.blue,
      color: colors.white,
      height: 40,
      width: 40,
      fontSize: 24,
    },
    containerBodyImage: {
      display: 'flex',
      width: '100%',
      height: 300,
    },
    containerLoading: {
      display: 'flex',
      width: '100%',
      height: 300,
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: '15%'
    },
    body: {
      display: 'flex',
      width: '100%',
      height: '100%',
      // alignItems: "center",
      padding: 30,
    },
    footerBody: {
      display: 'flex',
      width: '100%',
      // alignItems: "center",
      border: `1px solid ${colors.purpleLight}`,
      backgroundColor: colors.grayLight,
      padding: '20px 30px',
      borderRadius: 3,
    },
    footerTitle: {
      fontWeight: 'bold',
      fontSize: '1em !important',
      color: colors.blackMedium,
    },
    titlebody: {
      fontWeight: 'bold',
      fontSize: '1em !important',
      color: colors.purple,
    },
    footersubtitle: {
      fontWeight: 'lighter',
      fontSize: '0.9em !important',
      color: colors.blackMedium,
    },
  }),
)
