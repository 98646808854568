import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { useStyles } from '../../../events/styles/index.styles'

import colors from '../../styles/colors'

export default function TabsHiplade({ status_id, handleChangeTab, tabs }: any) {
  return (
    <Tabs
      value={status_id}
      onChange={handleChangeTab}
      sx={{
        '& .MuiTabs-indicator': {
          backgroundColor: colors.purpleMedium,
        },
        '& .MuiTab-root': { color: colors.purpleLightest },
        '& .Mui-selected': { color: colors.blueDark },
      }}
    >
      {tabs.map((tab, index) => (
        <Tab
          value={tab?.id.toString()}
          key={index}
          label={tab?.title}
          sx={{ textTransform: 'none', fontSize: '1.25em' }}
        />
      ))}
    </Tabs>
  )
}
