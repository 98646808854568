import React, { useState, useRef, useEffect } from 'react'
import { Box } from '@mui/material'
import { Typography } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDefaultStyles } from '../../common/styles/default'
import { useControlRoomStyles } from '../styles/card.styles'
import MonitoringRoom from './monitoring-room'
import Banner from '../../common/components/banner'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import { Button } from '@mui/material'
import EventsService from '../../services/EventsService'

export default function EventControlRoomView() {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const classes = useControlRoomStyles()

  const styles = useDefaultStyles()
  const { id } = useParams()
  const [event, setEvent] = useState([] as any)

  const loadEvent = async (id: any) => {
    await EventsService.get(id)
      .then((response: any) => {
        if (response) {
          setEvent(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  const handleBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (id) {
      loadEvent(id)
    }
  }, [])

  return (
    <Box>
      <Box>
        <Banner
          type="small"
          image={'medium'}
          title={event?.name + ' / ' + t('monitor_title')}
          icon={
            <VideocamOutlinedIcon
              sx={{ fontSize: '3.5em', color: '#FFFFFF', mr: 5 }}
            />
          }
          button={
            <Button variant="contained" color="secondary" onClick={handleBack}>
              {t('btn_return')}
            </Button>
          }
        />
      </Box>

      <Box className={styles.container}>
        <Typography>{t('monitor_subtitle')}</Typography>
      </Box>

      <Box>
        <MonitoringRoom eventId={id} />
      </Box>
    </Box>
  )
}
