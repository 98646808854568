import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { Link, Typography } from '@mui/material'
import { useStationFilesStyles } from '../../styles/stationfiles.styles'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
export default function ItemFile({ id, name, url }: any) {
  const styles = useStationFilesStyles()

  useEffect(() => {}, [id, name, url])

  return (
    <Box className={styles.boxFile}>
      <Link
        className={styles.fontItemFile}
        sx={{ textDecoration: 'none' }}
        href={url}
        target="_blank"
      >
        {name.toString().substring(0, 20) + ' ...'}
      </Link>
      <Typography className={styles.fontItemFile}>
        <DownloadOutlinedIcon sx={{ fontSize: '1em', ml: 3 }} />
      </Typography>
    </Box>
  )
}
