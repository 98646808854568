import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useDefaultStyles } from "../../common/styles/default";
import { useCompetenceStyle } from "../styles/index.styles";

import { useModal } from "../../common/hooks/useModal";
import CompetenceService from "../../services/CompetenceService";

export default function FormCompetence() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const styles = useDefaultStyles();
  const classes = useCompetenceStyle();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = () => {
    if (name == "") {
      toast(t("toast_form_competence_description"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    const dataCompetence = {
      name: name,
      description: description,
    };
    if (!dataModal?.data?.idCompetence) {
      CompetenceService.store(dataCompetence)
        .then((response: any) => {
          if (response?.data?.Msg) {
            if (dataModal?.data?.checklist == 0) {
              dataModal?.data?.loadCompetences(1, 1);
            } else {
              dataModal?.data?.loadCompetences(1, 0);
            }
            toast(response?.data?.Msg, {
              type: "success",
            });
            onCloseModalReset();
          }
        })
        .catch((e: any) => {
          handleStopSaveModal();
          toast(e?.response?.data?.Error, {
            type: "error",
          });
          return;
        });
    } else {
      CompetenceService.update(dataModal?.data?.idCompetence, dataCompetence)
        .then((response: any) => {
          if (response?.data?.Msg) {
            if (dataModal?.data?.checklist == 0) {
              dataModal?.data?.loadCompetences(1, 1);
            } else {
              dataModal?.data?.loadCompetences(1, 0);
            }
            toast(response?.data?.Msg, {
              type: "success",
            });
            onCloseModalReset();
          }
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const loadCompetence = async (id: any) => {
    await CompetenceService.get(id)
      .then((response: any) => {
        if (response) {
          setName(response.data.name);
        }
      })
      .catch((e: Error) => {});
  };

  const {
    dataModal,
    onCloseModalReset,
    handleChangedModal,
    handleStopSaveModal,
  } = useModal();

  useEffect(() => {
    if (dataModal?.data?.idCompetence) {
      loadCompetence(dataModal?.data?.idCompetence);
    }
    if (dataModal.loadingBtnSave) {
      handleSubmit();
    }
  }, [dataModal.loadingBtnSave]);

  return (
    <Box className={classes.modalContainer}>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Box>
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            label="Nome"
            variant="outlined"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
            sx={{ mb: 3 }}
          />

          {/* <TextField
            required
            fullWidth
            id="description"
            name="description"
            label="Descrição"
            variant="outlined"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(event.target.value);
            }}
            sx={{ mb: 3 }}
          /> */}
        </Box>
      </Box>
    </Box>
  );
}
