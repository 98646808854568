import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import colors from '../../../common/styles/colors'
import { useTranslation } from "react-i18next";

interface FooterResultProps {
    score: number | undefined
}

type ResultType = 'result_failed' | 'result_passed' | false;

export function FooterResult({
    score,
}: FooterResultProps) {
    const { t } = useTranslation();
    const [color, setColor] = useState('')
    const [result, setResult] = useState<ResultType>(false)

    const colorBar = () => {
        if (score) {
            if (score < 60) {
                setColor(colors.pinkDark)
            } else if (score >= 60 && score < 70) {
                setColor(colors.yellow)
            } else if (score >= 70 && score < 90) {
                setColor(colors.green)
            } else if (score >= 90) {
                setColor(colors.greenMedium)
            }
        }
    }

    const handleResult = () => {
        if (score) {
            if (score < 60) {
                setResult('result_failed')
            } else if(score >= 60) {
                setResult('result_passed')
            }
        }
    }

    useEffect(() => {
        colorBar()
        handleResult()
    }, [score])

    const getTranslatedResult = () => {
        if (result === false) return '';
        return t(result);
    }

    return (
        <Stack mt={8} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Stack flexDirection={'row'} alignItems={'center'}>
                <Box>
                    <Typography
                        sx={{color}}
                    >
                        <span style={{fontWeight: 'bold'}}>{t("final_result")}</span> {getTranslatedResult()}
                    </Typography>
                </Box>
            </Stack>
            <Button sx={{textTransform: 'none'}}>
                <Typography>{t("evaluation_criteria")}</Typography>
            </Button>
        </Stack>
    )
}