import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import LayersClearOutlinedIcon from "@mui/icons-material/LayersClearOutlined";
import { useStyles } from "./styles/index.styles";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../hooks/useModal";
import { Textarea } from "@mui/joy";
import CanvasDraw from "react-canvas-draw";
import colors from "../../../styles/colors";
import { useMarkers } from "../../../../events/hooks/useMarkers";
import { IMarker } from "../../../../types/Markers";
import secureLocalStorage from 'react-secure-storage'
import { AppContext } from "../../../../contexts/AppContext";

export function EditingImageSnapshot() {
  const classes = useStyles();

  const {
    updateDataContext,
  } = React.useContext(AppContext);

  let auth_user_role = secureLocalStorage.getItem('role')

  const { t } = useTranslation();

  const { dataModal, onCloseModalReset } = useModal();
  const [enableCanvas, setEnableCanvas] = useState(true);

  const {
    getDrawByMarker,
    updateDrawByMarker,
    updateDrawByMarkerState,
    drawByMarkerState,
    drawMarkerData,
  } = useMarkers();

  const [anotation, setAnotation] = useState<string>("");

  const modalData: IMarker = dataModal.data;

  const canvasRef = useRef<any>(null);

  const brushSize = 3;

  const saveMarker = () => {
    if (canvasRef.current) {
      const draw = canvasRef.current.getSaveData();
      updateDrawByMarker(modalData.id as number, {
        draw,
        anotation,
      });
    }
  };

  const handleChangeAnotation = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnotation(e.target.value);
  };

  const handleEraseAll = () => {
    if (canvasRef.current) canvasRef.current.eraseAll();
  };

  useEffect(() => {
    setAnotation(drawMarkerData.anotation as string);
  }, [drawMarkerData.anotation]);

  useEffect(() => {
    if(auth_user_role === 5) {
      updateDataContext({
        modal: {
          ...dataModal,
          showBtnSave: false
        }

      })
    }
  }, [auth_user_role])
  

  useEffect(() => {
    if (dataModal.loadingBtnClosed) {
      onCloseModalReset();
    }

    if (updateDrawByMarkerState.success) {
      onCloseModalReset();
    }

    if (dataModal.loadingBtnSave) {
      saveMarker();
    }

    if (modalData.id) {
      getDrawByMarker(modalData.id);
    }
  }, [
    dataModal.btnSaveModalClicked,
    dataModal.btnCloseModalClicked,
    modalData.id,
    updateDrawByMarkerState.success,
  ]);

  return (
    <Box className={classes.containerBody}>
      <Box className={classes.wrapperBody}>
        {auth_user_role !== 5 && (
          <Box className={classes.wrapperBodyContainer}>
            <Typography className={classes.titlebody}>
              {t("title_draft")}
            </Typography>
            <Box>
              <IconButton
                className={classes.iconButton}
                aria-label="delete"
                size="small"
                onClick={() => setEnableCanvas(false)}
              >
                <CreateOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                className={classes.iconButton}
                aria-label="layersClearOutlinedIcon"
                size="small"
                onClick={handleEraseAll}
              >
                <LayersClearOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        )}
        <Box className={classes.containerBodyImage}>
          <CanvasDraw
            style={{
              width: "100%",
              height: "100%",
              backgroundPosition: "40% 50%",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${
                drawMarkerData?.attachments &&
                drawMarkerData?.attachments[0]?.static_url
              })`,
            }}
            hideGrid
            brushColor={colors.redBrush}
            brushRadius={brushSize}
            ref={canvasRef}
            disabled={enableCanvas ? true : false}
            saveData={drawMarkerData.draw ?? ""}
          />
        </Box>
      </Box>
      <Box>
        <Typography
          style={{ fontWeight: "bold" }}
          className={classes.footerTitle}
        >
          {t("title_annotation")}
        </Typography>
        <Textarea
          value={anotation ?? ""}
          minRows={2}
          maxRows={2}
          className={classes.footerBody}
          onChange={handleChangeAnotation}
          disabled={auth_user_role === 5}
        />
      </Box>
    </Box>
  );
}
