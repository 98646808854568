import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { ClassNames } from "@emotion/react";
import { useStyles } from "./styles/index.styles";
import { useModal } from "../../../hooks/useModal";
import { useTranslation } from "react-i18next";

export const HeaderChooseBookmark = () => {
  const classes = useStyles();

  const { handleCloseModal } = useModal();

  const { t } = useTranslation();

  return (
    <Box className={classes.containerHeader}>
      <Typography className={classes.headerTitle}>
        {t("title_choose_bookmark_header")}
      </Typography>
    </Box>
  );
};
