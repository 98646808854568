import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'block',
      position: 'fixed',
      textAlign: 'center',
      zIndex: 9999999,
      top: 0,
      left: 0,
      width: '100',
      height: '100%',
      minWidth: '100%',
      background: colors.purpleLightest,
      backgroundSize: '41px',
      opacity: '0.8',
    },
    loader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      WebkitTransform: 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)',
    },
  }),
)
