import { Box, Stack, TextField } from "@mui/material";
import * as React from "react";
import {
  AudioRatingItemProps,
  storeCommentService,
  updateCommentService,
  storeAudioDebriefingService,
  deleteAudioDebriefingService,
} from "../../../services/DebriefingCommentService";
import { toast } from "react-toastify";
import {
  AudioPlayerWithWaveform,
  AudioRecording,
  RecorderAudio,
} from "../../../common/components/player/audio";
import { useRatingItem } from "../../../services/hooks/useRatingItem";
import { useTranslation } from "react-i18next";

interface Props {
  stationId: any;
  debriefingId: any;
  itemId: any;
  skillId: any;
  onAudioStoredSuccessfully: () => void;
}

export default function DebriefingComment({
  stationId,
  debriefingId,
  itemId,
  skillId,
  onAudioStoredSuccessfully,
}: Props) {
  if (!debriefingId) {
    return <Box />;
  }

  const { t } = useTranslation();
  const [label, setLabel] = React.useState(t("title_label_debriefing_comment"));
  const [placeholder, setPlaceholder] = React.useState(
    t("title_placeholder_debriefing_comment") ?? ""
  );

  const [disabled, setDisabled] = React.useState(false);
  const [disabledBtnPlayerAudio, setDisabledBtnPlayerAudio] =
    React.useState(false);
  const [queue, setQueue] = React.useState(Promise.resolve());
  const [commentId, setCommentId] = React.useState("");
  const [audiosDeletedRealTime, setAudiosDeletedRealTime] = React.useState<
    { id: number; audio_url: string }[]
  >([]);

  const [audioRating, setAudioRating] = React.useState<{
    id: number;
    audio_url: string;
  } | null>(null);

  const {
    audioRatingData,
    commentRatingData,
    errorRatingItem,
    getDebriefingRatingItem,
    loadingRatingItem,
    successRatingItem,
  } = useRatingItem();

  const [comment, setComment] = React.useState("");

  const handleChange = (event) => {
    setLabel(t("title_label_change_debriefing_comment"));
    setComment(event.target.value);
  };

  const storeComment = async () => {
    if (comment != "") {
      setLabel(t("title_label_store_debriefing_comment"));
      setDisabled(true);
    }

    let debriefingCommentData = {
      station_id: stationId,
      debriefing_id: debriefingId,
      skill_id: skillId,
      item_id: itemId,
      comment,
    };

    if (commentId == "" && comment != "") {
      await storeCommentService(debriefingCommentData)
        .then((response: any) => {
          setCommentId(response.data.id);
          setTimeout(function () {
            setLabel(t("title_label_debriefing_comment"));
            setDisabled(false);
          }, 5000);
        })
        .catch((e: Error) => {
          setDisabled(false);
        });
    } else if (comment != "") {
      await updateCommentService(debriefingCommentData, commentId)
        .then((response: any) => {
          setTimeout(function () {
            setDisabled(false);
          }, 5000);
        })
        .catch((e: Error) => {
          setDisabled(false);
        });
    }
  };

  const handleKeyDown = (event) => {
    event.key === "Enter" ? storeComment() : "";
  };

  const handleOnBlur = () => {
    storeComment();
  };

  const handleStoreAudio = async (blob: AudioRecording) => {
    const audioBlobTeste = await fetch(blob.blobURL).then((r) => r.blob());
    const audioFile = new File([audioBlobTeste], `ratingItem-${itemId}.mp3`, {
      type: "audio/mpeg",
    });

    let debriefingData = {
      station_id: stationId,
      debriefing_id: debriefingId,
      skill_id: localStorage.getItem(
        "@/debrifiengRecordingAudio/skillIdActive"
      )!,
      item_id: localStorage.getItem("@/debrifiengRecordingAudio/itemIdActive")!,
      file: audioFile,
    };

    const formData = new FormData();

    formData.append("station_id", debriefingData.station_id);
    formData.append("debriefing_id", debriefingData.debriefing_id);
    formData.append("skill_id", debriefingData.skill_id);
    formData.append("item_id", debriefingData.item_id);
    formData.append("file", debriefingData.file);

    try {
      console.log("Loading station");

      await storeAudioDebriefingService(formData);
      toast(t("title_audio_recorded_debriefing_comment"), {
        type: "success",
      });
      onAudioStoredSuccessfully();
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleDeleteAudioDebriefing = async () => {
    setDisabledBtnPlayerAudio(true);
    try {
      await deleteAudioDebriefingService(audioRating?.id!);
      toast(t("title_audio_deleted_debriefing_comment"), {
        type: "success",
      });
      onAudioStoredSuccessfully();
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setDisabledBtnPlayerAudio(false);
    }
  };

  React.useEffect(() => {
    setQueue(
      queue
        .then(() => {
          getDebriefingRatingItem(debriefingId, itemId);
        })
        .catch((err) => {
          console.error(err);
        })
    );
  }, []);

  const handleItemActive = () => {
    localStorage.setItem("@/debrifiengRecordingAudio/itemIdActive", itemId);
    localStorage.setItem("@/debrifiengRecordingAudio/skillIdActive", skillId);
  };

  React.useEffect(() => {
    if (commentRatingData) setComment(commentRatingData.comment);
    if (audioRatingData) setAudioRating(audioRatingData);
  }, [commentRatingData, audioRatingData]);

  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      flex={1}
      height={"100%"}
      gap={3}
    >
      <RecorderAudio
        disabled={!!audioRating?.audio_url}
        onSave={(blob) => handleStoreAudio(blob)}
        onMouseEnter={handleItemActive}
        onMouseLeave={handleItemActive}
      />
      <Stack flex={1} gap={1}>
        <Box marginTop={2} width={"100%"}>
          <TextField
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            fullWidth
            onChange={handleChange}
            onKeyPress={handleKeyDown}
            onBlur={handleOnBlur}
            value={comment}
          />
        </Box>
        {audioRating && (
          <AudioPlayerWithWaveform
            audioUrl={audioRating?.audio_url!}
            onClickClose={handleDeleteAudioDebriefing}
            loading={disabledBtnPlayerAudio}
          />
        )}
      </Stack>
    </Stack>
  );
}
