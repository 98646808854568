import * as React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { useStyles } from './styles/index.styles'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

interface Props {
  errorCode?: number
  ErrorMessage?: string
  imageError?: string
}


export default function ErrorPage({ errorCode=404, ErrorMessage, imageError='error_404.png' } : Props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleGoBack = () => {
    navigate(-1)
  }
  
  return (
    <Box>
      <Container>
        <Box className={classes.boxError}>      
          <img src={require(`../../../assets/images/${imageError}`)}/>            
        </Box>
        <Box className={classes.boxError}>      
          <h1>{t('error_code_404')} {errorCode}</h1>    
        </Box>
        <Box className={classes.boxError}>        
          <h2>{ErrorMessage ||  t('description_page_error')}</h2>  
        </Box>
        <Box className={classes.boxError}>           
          <Button className={classes.boxErrorButton}
            variant="contained"
            size="large"
            color="primary"
            sx={{mb: 2, }}
            onClick={handleGoBack}
            
          >
            {t('btn_back_to_previous_page')}
          </Button>      
        </Box>
      </Container>
    </Box>
  )
}