import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { AppContext } from '../../../contexts/AppContext';
import { ModalBase } from './Base';
import { HeaderEditingImageSnapshot } from './EditingImageSnapshot/Header';
import { useModal } from '../../hooks/useModal';

interface ModalComponentProps {

}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export function ModalComponent() {


  const { modalComponents: { BodyModal, FooterModal, HeaderModal } } = useModal()
  

  return (
    <ModalBase 
      HeaderModal={HeaderModal}
      BodyModal={BodyModal}
    />
  );
}

