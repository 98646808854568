import React from 'react'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useControlRoomStyles } from '../../styles/card.styles'
import { Box, CardMedia, Divider } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'

export default function ItemVideoLive({
  title,
  path,
  subtitle,
  evaluated_name,
  video,
}: any) {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const classes = useControlRoomStyles()

  const handleNavigate = (path: any) => {
    navigate(path)
  }

  return (
    <Box sx={{ marginRight: 2 }}>
      <Card
        className={classes.containerCardControlRoomLink}
        onClick={() => handleNavigate(path)}
      >
        <CardContent>
          <Typography
            variant="h6"
            className={classes.titleCardLink}
            sx={{ fontWeight: 700 }}
          >
            {title} / {subtitle}
          </Typography>

          <iframe
            src={video}
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '100%',
              minHeight: '250px',
              aspectRatio: '1280/720',
              border: 'none',
              marginTop: '12px',
              marginBottom: '10px',
            }}
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>

          <Typography
            variant="subtitle1"
            className={classes.dateEvent}
            sx={{
              padding: '3px',
              fontWeight: 700,
            }}
          >
            {t('subtitle_card_station_01_student')} {evaluated_name}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}
