import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../common/styles/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    importButton: {
      backgroundColor: colors.green,
      border: "1px solid " + colors.green,
    },
    subtitleAction: {
      fontSize: "2em !important",
      color: colors.purpleMediumSub,
      marginTop: "40px !important",
      marginBottom: "30px !important",
    },
    cardAction: {
      borderRadius: "12px",
      boxShadow: "0px 2px 2px 0px rgba(145, 145, 145, 0.25)",
      padding: "30px",
      textAlign: "center",
      color: colors.white,
      textTransform: "uppercase",
      fontSize: "1.125em !important",
    },
    dividerCardAction: {
      backgroundColor: colors.white,
      height: 3,
      marginBottom: "20px !important",
      marginTop: "20px !important",
    },
    labelNumberCard: {
      color: colors.purpleMediumSub,
      marginBottom: "10px !important",
    },
    cardGeneralAction: {
      textAlign: "center",
    },
    gridCardAction: {
      padding: "40px",
    },
    cardGrade: {
      borderRadius: "12px",
      backgroundColor: colors.white,
      boxShadow: "0px 2px 2px 0px rgba(145, 145, 145, 0.25)",
      padding: "20px",
    },
    titleskill: {
      fontSize: "2.0em !important",
      color: colors.purpleMediumSub,
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
    },
    titlePoints: {
      fontSize: "1em !important",
      color: colors.grayTitlePoints,
    },
    subtitlePoints: {
      fontSize: "1.5em !important",
      color: colors.grayTitlePoints,
    },
    titleGrade: {
      fontSize: "1.25em !important",
      color: colors.blackMedium,
      diplay: "flex",
      alignItems: "center",
    },
    boxskill: {
      display: "flex",
      alignItems: "center",
    },
    boxskillPoints: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    txtConfig: {
      fontSize: "16px !important",
    },
    containerForm: {
      position: "relative",
      width: "100%",
    },
    subContainer: {
      paddingTop: "100px",
      paddingBottom: "100px",
      paddingLeft: "150px",
      paddingRight: "150px",
    },
    subContainerButtons: {
      paddingTop: "70px",
      paddingBottom: "70px",
      paddingLeft: "150px",
      paddingRight: "150px",
    },
    subContainerSkill: {
      paddingTop: "100px",
      paddingBottom: "100px",
    },
    subContainerSkillItem: {
      paddingLeft: "150px",
      paddingRight: "150px",
    },
    containerSubColor: {
      backgroundColor: colors.lilacLight,
    },
    buttonsFooter: {
      width: "100%",
      textAlign: "right",
    },
    topBox: {
      marginTop: "20px !important",
    },
    divider: {
      backgroundColor: colors.lilacMediumLight,
      width: "100% !important",
    },
  })
);
