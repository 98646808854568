import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DebriefingVideo from "./debriefing-video/DebriefingVideo";
import { useDebriefingStyles } from "../styles/debriefing.styles";
import DebriefingChecklistView from "./debriefing-checklist/DebriefingChecklistView";
import skillService from "../../services/SkillService";
import DebriefingService from "../../services/DebriefingService";
import AttachmentService from "../../services/AttachmentService";

import { Grid } from "@mui/material";

export default function DebriefingForm({
  eventId,
  stationId,
  evaluatedId,
  debriefingId,
  checklistId,
  station,
  scenario,
}: any) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const debriefingStyle = useDebriefingStyles();

  const [skills, setskills] = useState<any[]>([]);
  const [video, setVideo] = useState("");
  const [videoId, setVideoId] = useState("");
  const [arrVideo, setArrVideo] = useState<any[]>([]);
  const [cameraMain, setCameraMain] = useState(false);
  const [cameraIndex, setCameraIndex] = useState("1");

  const loadskills = async (checklistId: any) => {
    await skillService
      .getskillByChecklist(checklistId)
      .then((response: any) => {
        if (response) {
          setskills(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadFile = async (debriefingId: any) => {
    await AttachmentService.get("debriefing", debriefingId, "debriefing_video")
      .then((response) => {
        if (response.data.length > 0) {
          setVideo(response.data[0].url);
          setVideoId(response.data[0]?.id);
          setCameraMain(true);
          setCameraIndex("1");
        }
      })
      .catch((e: any) => {});
  };

  const loadDebriefingUrl = async (debriefingId: any) => {
    await DebriefingService.getDebriefingUrl(debriefingId)
      .then((response: any) => {
        if (response) {
          if (
            response.data.length > 0 &&
            response.data[0].field_name != "debriefing_video"
          ) {
            setVideo(response.data[0]?.recording_url);
            setVideoId(response.data[0]?.streaming_id);
            const cameraDefault = response.data[0]?.camera_main ? true : false;
            setCameraMain(cameraDefault);
            setArrVideo(response.data);
            setCameraIndex("1");
          } else {
            setVideo(response.data[0]?.static_url);
            setVideoId(response.data[0]?.id);
            setCameraMain(true);
            setCameraIndex("1");
          }
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (checklistId) {
      loadskills(checklistId);
    }
    if (debriefingId) {
      loadDebriefingUrl(debriefingId);
    }
  }, [checklistId, debriefingId]);

  return (
    <Box>
      <Grid
        container
        spacing={2}
        className={debriefingStyle.containerDebriefing}
      >
        <Grid item md={6} xs={6}>
          <DebriefingVideo
            video={video}
            videoId={videoId}
            arrVideo={arrVideo}
            setVideo={setVideo}
            setVideoId={setVideoId}
            cameraMain={cameraMain}
            setCameraMain={setCameraMain}
            cameraIndex={cameraIndex}
            setCameraIndex={setCameraIndex}
          />
        </Grid>
        <Grid item md={4} xs={4}>
          <DebriefingChecklistView
            checklistId={checklistId}
            skills={skills}
            eventId={eventId}
            stationId={stationId}
            evaluatedId={evaluatedId}
            debriefingId={debriefingId}
            station={station}
            scenario={scenario}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
