import * as React from "react";

import { useStyles } from "./styles/index.styles";
import { useTranslation } from "react-i18next";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { Button } from "@mui/material";
import colors from "../../styles/colors";

export default function ButtomDownload({ url }: any) {
  const classes = useStyles();
  const { t } = useTranslation();

  const openFile = (path: any) => {
    window.open(path, "_blank");
  };

  return (
    <Button
      variant="contained"
      className={classes.buttonDownload}
      sx={{
        color: colors.purpleTextButton,
        textTransform: "none",
      }}
      onClick={() => openFile(url)}
    >
      {t("btn_download")} <AttachFileIcon />
    </Button>
  );
}
