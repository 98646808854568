import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: colors.lilacLight,
      color: colors.purple,
    },
    avatarGray: {
      backgroundColor: colors.gray,
      color: colors.white,
    },
    profileUpload: {
      position: "relative",
    },
    uploadIcon: {
      position: "absolute",
      bottom: "0px",
      borderBottomLeftRadius: "80px",
      borderBottomRightRadius: "80px",
      opacity: 0,      
      "&:hover" : {        
        cursor: "pointer",
        opacity: .8,
        transition: "opacity .75s linear",
      }
    },
    displayNone: {
      display: 'none'
    }
  }),
)