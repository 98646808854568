import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import EventStudentList from './EventStudentList'
import StudentGraph from './StudentGraph'

interface Props {
  event: any
  isStudent?: {
    role: number
    id: any
  }
}

export default function EventStudentGraph({ event, isStudent }: Props) {
  const [showGraph, setShowGraph] = useState(isStudent?.id)
  const [student_id, setStudentId] = useState(isStudent?.id)

  const selectStudent = (student_id: any) => {
    setShowGraph(true)
    setStudentId(student_id)
  }
  

  const closeGraph = () => {
    setShowGraph(false)
    setStudentId('')
  }

  useEffect(() => {}, [event])

  return (
    <Box>
      {!showGraph && (
        <EventStudentList event={event} selectStudent={selectStudent} />
      )}
      {showGraph && (
        <StudentGraph
          event={event}
          student_id={student_id}
          closeGraph={closeGraph}
          showIconClose={!isStudent?.id}
        />
      )}
    </Box>
  )
}
