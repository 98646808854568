import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../common/styles/colors'
import fonts from '../../common/styles/fonts'

export const useQuickGuide = makeStyles((theme: Theme) =>
  createStyles({
    containerPage: {
      overflowX: 'hidden',
    },
    container: {
      marginLeft: '100px',
      position: 'relative',
      marginTop: 50,
      width: '92%',
    },
    titleNumber: {
      color: colors.purpleMediumSub,
      fontSize: '3em !important',
      marginRight: '10px! important',
    },
    title: {
      color: colors.blueDarkest,
      fontSize: '2.625em !important',
      marginLeft: '10px! important',
    },
    titleScenario: {
      color: colors.blueDarkest,
      fontSize: '2.625em !important',
      marginLeft: '10px! important',
      textTransform: 'uppercase',
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
    description: {
      color: colors.blackMedium,
    },
    guideDescription: {
      width: '100%',
      minHeight: 250,
      padding: 10,
      display: 'flex',
      alignItems: 'center',
    },
    grid: {
      minHeight: 250,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    boxFile: {
      borderRadius: '12px',
      backgroundColor: colors.white,
      boxShadow: '0px 2px 2px 0px rgba(145, 145, 145, 0.25)',
      width: '100%',
      padding: '30px',
      marginBottom: '25px',
    },
    titleFile: {
      fontSize: '1.5em !important',
      marginBottom: '10px !important',
    },
    divider: {
      backgroundColor: colors.lilacMediumLight,
      width: '100% !important',
    },
    gridFiles: {
      fontSize: '1.5em !important',
      marginTop: '10px !important',
      width: '100% !important',
    },
    boxListFiles: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    buttonUpload: {
      borderRadius: '4px',
      backgroundColor: colors.greenMediumLight,
      boxShadow: '0px 4px 8px 0px rgba(40, 102, 105, 0.20)',
      cursor: 'pointer',
      color: colors.white,
      padding: '10px',
      textAlign: 'center',
      width: '185px !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    boxSpacing: {
      paddingTop: '8px',
      paddingBottom: '40px',
    },
    boxAddFile: {
      borderRadius: '10px',
      backgroundColor: colors.purpleBackButton,
      padding: '7px',
      marginRight: '17px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '6px',
    },
    fontFile: {
      fontSize: '0.7em !important',
      color: colors.purpleTextButton,
      textDecoration: 'none',
      cursor: 'pointer',
    },
  }),
)
