import api from '../config/api'
import IRoomData from '../types/Room'

const store = (data: any) => {
  return api.post<IRoomData>('/rooms', data)
}

const getAll = (page = 1, paginate = 0) => {
  return api.get<IRoomData>('/rooms?page=' + page + '&paginate=' + paginate)
}

const get = (id: number) => {
  return api.get<IRoomData>('/rooms/' + id)
}

const update = (id: any, data: any) => {
  return api.put<IRoomData>('/rooms/' + id, data)
}

const remove = (id: any) => {
  return api.delete('/rooms/' + id)
}

const RoomsService = {
  store,
  getAll,
  get,
  update,
  remove,
}

export default RoomsService
