import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../common/styles/colors";
import fonts from "../../common/styles/fonts";

export const useDebriefingStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerPage: {
      overflowX: "hidden",
    },
    container: {
      marginLeft: "100px",
      position: "relative",
      marginTop: 50,
      width: "92%",
    },
    containerDebriefing: {
      marginLeft: "100px !important",
      position: "relative",
      marginTop: "20px !important",
    },
    boxDebriefing: {
      boxShadow: "0px 4px 8px rgba(57, 54, 67, 0.25)",
      backgroundColor: colors.white,
      borderRadius: "4px",
      maxHeight: "644px",
      padding: 30,
      overflowX: "auto",
    },
    titleChecklist: {
      color: colors.purplePrimary,
      fontSize: "1.5em !important",
      marginRight: "10px! important",
    },
    titleNumberskill: {
      color: colors.purplePrimary,
      fontSize: "1.25em !important",
      marginRight: "10px! important",
    },
    titleskill: {
      color: colors.purpleMediumSub,
      fontSize: "1.25em !important",
      marginRight: "10px! important",
    },
    titleBoxChecklist: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
    boxskill: {
      paddingTop: "20px",
    },
    divider: {
      backgroundColor: colors.lilacMediumLight,
      width: "100% !important",
      marginTop: "25px !important",
      marginBottom: "25px !important",
    },
    selectResult: {
      marginTop: "25px !important",
      marginBottom: "25px !important",
    },
    videoBar: {
      width: "100% !important",
      backgroundColor: colors.blackDark,
      padding: "20px",
    },
    boxMarker: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: "120px",
    },
    boxMarkerTime: {
      display: "flex",
      justifyContent: "left",
      width: "100%",
      flexWrap: "wrap",
    },
    boxTime: {
      borderRadius: "4px",
      textAlign: "center",

      marginRight: "20px",
      marginTop: "14px",
      backgroundColor: colors.white,
      cursor: "pointer",
    },
    titleCameraVideo: {
      fontSize: "1.7em !important",
      color: colors.lilacLight,
    },
    cameraIconVideo: {
      color: colors.lilacLight,
    },
    boxTitleVideo: {
      display: "flex",
      alignItems: "center",
    },
    btnNewMark: {
      backgroundColor: colors.pink,
    },
    boxNewMark: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    boxNewMarkRight: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
    },
    boxNewMarkButton: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
    },
    textInfoNewMark: {
      color: colors.purpleLightMark,
      fontSize: "12px !important",
    },
    buttonCam1: {
      backgroundColor: colors.purpleDarkest,
      border: "1px solid " + colors.purpleButtonCam,
      color: colors.lilacButtonCam1,
    },
    buttonCam2: {
      backgroundColor: colors.grayButtonCam2,
      border: "1px solid " + colors.purpleButtonCam,
      color: colors.pinkLight,
    },
    buttonFilter: {
      width: 35,
      height: 35,
      borderRadius: 3,
      padding: "0px !important",
      backgroundColor: colors.grayButtonCam2,
      border: "1px solid " + colors.purpleButtonCam,
      color: colors.lilacMediumLight,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },

    menuFilter: {
      color: colors.white,
    },
  })
);
