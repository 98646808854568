import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import { FormLabel, TextField } from '@mui/material'
import { useScenarioStyles } from '../styles/scenarios.styles'
import ItemInstructionFile from './ItemInstructionFile'

export default function FormScenarioInstructionsFiles({
  idScenario,
  feature,
}: any) {
  const { t } = useTranslation()
  const classes = useStyles()
  const styleScenario = useScenarioStyles()

  useEffect(() => {}, [idScenario, feature])

  return (
    <Box className={styleScenario.boxFiles}>
      <ItemInstructionFile
        name="file_instructions_appraisers"
        title={t('input_label_file_instructions_appraisers')}
        feature={feature}
        idScenario={idScenario}
      />
      <ItemInstructionFile
        name="file_instructions_students"
        title={t('input_label_file_instructions_students')}
        feature={feature}
        idScenario={idScenario}
      />
      <ItemInstructionFile
        name="file_instructions_actors"
        title={t('input_label_file_instructions_actors')}
        feature={feature}
        idScenario={idScenario}
      />
      <ItemInstructionFile
        name="file_necessary_items"
        title={t('input_label_file_necessary_items')}
        feature={feature}
        idScenario={idScenario}
      />
    </Box>
  )
}
