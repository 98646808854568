import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDebriefingStyles } from "../../styles/debriefing.styles";
import DebriefingService from "../../../services/DebriefingService";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";

import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { InputLabel } from "@material-ui/core";
import { TypeTextFeedback } from "../EventAppraiserFeedback/ContentCard";

export default function SkillItem({
  eventId,
  stationId,
  evaluatedId,
  debriefingId,
  checklistId,
  skillId,
  item,
}: any) {
  const { t } = useTranslation();
  const debriefingStyle = useDebriefingStyles();

  let auth_user_role = secureLocalStorage.getItem("role");

  const [scale, setScale] = useState("");
  const [scaleId, setScaleId] = useState("");
  const handleScaleChange = (event: SelectChangeEvent) => {
    setScale(event.target.value);
    saveScale(event.target.value);
  };

  const saveScale = async (scale: any) => {
    if (scale != "") {
      if (!scaleId) {
        const data = {
          debriefing_id: debriefingId,
          station_id: stationId,
          skill_id: skillId,
          item_id: item?.item_id,
          scale: scale,
        };

        DebriefingService.storeDebriefingScales(data)
          .then((response: any) => {
            if (response) {
              setScaleId(response.data.id);
              toast(response.data.Msg, {
                type: "success",
              });
            }
          })
          .catch((e: Error) => {});
      } else {
        const data = {
          scale: scale,
        };
        DebriefingService.updateDebriefingScales(scaleId, data)
          .then((response: any) => {
            if (response) {
              toast(response.data.Msg, {
                type: "success",
              });
            }
          })
          .catch((e: Error) => {});
      }
    }
  };

  const loadScale = async (debriefingId: any, itemId: any) => {
    await DebriefingService.getDebriefingScale(debriefingId, itemId)
      .then((response: any) => {
        if (response && response.data.length > 0) {
          setScale(response.data[0]?.scale);
          setScaleId(response.data[0]?.id);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (item && item?.item_id && debriefingId) {
      loadScale(debriefingId, item?.item_id);
    }
  }, [item, debriefingId]);

  return (
    <Box>
      <Divider className={debriefingStyle.divider} />
      <Typography>{item?.question}</Typography>
      <FormControl fullWidth className={debriefingStyle.selectResult}>
        <InputLabel id="roles-label"></InputLabel>
        {auth_user_role === 5 ? (
          <TypeTextFeedback type={scale as any} />
        ) : (
          <Select
            labelId="roles-label"
            value={scale}
            onChange={handleScaleChange}
          >
            <MenuItem value="">{t("select_option")}</MenuItem>
            <MenuItem value="done">{t("scale_done")}</MenuItem>
            <MenuItem value="partially_done">
              {t("scale_partially_done")}
            </MenuItem>
            <MenuItem value="done_wrong">{t("scale_done_wrong")}</MenuItem>
            <MenuItem value="not_done">{t("scale_not_done")}</MenuItem>
          </Select>
        )}
      </FormControl>
    </Box>
  );
}
