import React, { createContext, useState } from 'react'
import { DEFAULT_VALUE_APPCONTEXT } from './defaultValue';
import { AppContextProps, AppContextStateProps, UpdateDataFn } from './types';

interface AppContextProviderProps {
  children: React.ReactNode;
}

export const AppContext = createContext<AppContextProps>({
  state: DEFAULT_VALUE_APPCONTEXT,
  updateDataContext: () => {}
});

export function AppContextProvider({ children }: AppContextProviderProps) {

  const [dataContext, setDataContext] = useState<AppContextStateProps>(DEFAULT_VALUE_APPCONTEXT)

  const updateDataContext: UpdateDataFn = (payload) => {
    setDataContext((prevData) => ({
      ...prevData,
      ...payload,
    }));
  };

  return (
    <AppContext.Provider value={{ state: dataContext, updateDataContext }}>{children}</AppContext.Provider>
  )
}
