import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'

import moment from 'moment'
import 'moment/locale/pt-br'

import secureLocalStorage from 'react-secure-storage'

import EventsService from '../../services/EventsService'

import Banner from '../../common/components/banner'
import event_banner from '../../assets/images/banners/event_first_page_banner.png'

import { useDefaultStyles } from '../../common/styles/default'

export default function List() {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const styles = useDefaultStyles()

  const goToEvent = (id: any) => {
    navigate(`/myevents/view/${id}`)
  }

  const [event, setEvent] = useState([] as any)

  const user_id = secureLocalStorage.getItem('id')

  const loadEvent = async (evaluated_id: any) => {
    await EventsService.getEventsByEvaluated(evaluated_id)
      .then((response: any) => {
        if (response) {
          setEvent(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  useEffect(() => {
    loadEvent(user_id)
  }, [])

  return (
    <Box>
      <Banner
        type="medium"
        image={event_banner}
        title={t('banner_title_event')}
        subtitle={t('banner_subtitle_event_list')}
      />
      <Box className={styles.containerForm}>
        <TableContainer component={Paper} sx={{ mt: 5 }}>
          <Table sx={{ minWidth: 600 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', fontSize: 20 }}>
                  {t('subtitle_table_event_name')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: 20 }}>
                  {t('subtitle_table_event_date')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {event?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => goToEvent(row.id)}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(row.date).locale('pt-br').format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
