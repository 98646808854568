import React, { useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { ClassNames } from "@emotion/react";
import { useStyles } from "./styles/index.styles";
import { useModal } from "../../../hooks/useModal";
import { useTranslation } from "react-i18next";
import { IMarker } from "../../../../types/Markers";
import { useMarkers } from "../../../../events/hooks/useMarkers";
import { LoadingButton } from "@mui/lab";
import secureLocalStorage from "react-secure-storage";

export const HeaderEditingImageSnapshot = () => {
  const classes = useStyles();

  let auth_user_role = secureLocalStorage.getItem("role");

  const { handleCloseModal, dataModal } = useModal();
  const { deleteMarkerByDebriefing, deleteMarkerState } = useMarkers();

  const data: IMarker = dataModal.data;

  const { t } = useTranslation();

  const handleDeleteMarker = () => {
    if (data.id) {
      deleteMarkerByDebriefing(data.id);
    }
  };

  useEffect(() => {
    deleteMarkerState.success && handleCloseModal();
  }, [deleteMarkerState.success]);

  return (
    <Box className={classes.containerHeader}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography className={classes.headerTitle}>
          {t("card_content_marker_capture")}
        </Typography>
        <Typography className={classes.headerSubtitle}>
          {" "}
          &nbsp;{data.time}
        </Typography>
      </Box>
      {auth_user_role !== 5 && (
        <LoadingButton
          variant="outlined"
          size="small"
          className={classes.headerButton}
          onClick={handleDeleteMarker}
          loading={deleteMarkerState.loading}
          loadingPosition="end"
        >
          {deleteMarkerState.loading ? (
            <span>&nbsp;</span>
          ) : (
            t("title_delete_bookmark")
          )}
        </LoadingButton>
      )}
    </Box>
  );
};
