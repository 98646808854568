import api from "../config/api";
import IAreaData from "../types/Area";

const getAll = () => {
  return api.get<IAreaData>("themes/areas");
};

const AreaService = {
  getAll,
};

export default AreaService;
