import { Box, Divider, LinearProgress, Stack, Typography } from '@mui/material';
import React from 'react'
import { useStylesFeedback } from './styles';
import colors from '../../../common/styles/colors';

interface FooterEventAppraiserFeedbackProps {
    description: string | undefined;
    title: string | undefined;
}

export default function FooterEventAppraiserFeedback({
    description,
    title,
}: FooterEventAppraiserFeedbackProps) {

    const stylesFeedback = useStylesFeedback()

  return (
    <Stack mt={5} spacing={2}>
        <Typography sx={{fontWeight: 'bold'}} className={stylesFeedback.title}>
            {title}
        </Typography>
        <Divider className={stylesFeedback.dividerCardCompentence}></Divider>
        <Typography className={stylesFeedback.title}>
            {description}
        </Typography>
    </Stack>
  )
}
