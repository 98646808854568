import { makeStyles, Theme, createStyles } from "@material-ui/core";
import colors from "../../common/styles/colors";
import fonts from "../../common/styles/fonts";

export const useSettingsStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerItem: {
      backgroundColor: "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(4),
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: 7,
    },
    containerTabMenu: {
      width: "100%",
      boxShadow: "0px 3px 8px rgba(145, 145, 145, 0.25)",
      backgroundColor: colors.white,
    },
    container: {
      marginTop: 40,
    },
    containerCards: {
      display: "flex",
      flexWrap: "wrap",
    },
    containerCard: {
      width: 289,
      marginRight: 15,
      marginBottom: 15,
      padding: 20,
      cursor: "pointer",
    },
    breadCrumbCard: {
      textTransform: "uppercase",
      color: colors.purpleMedium,
    },
    titleCard: {
      color: colors.blueDark,
      marginTop: "10px !important",
      marginBottom: "15px !important",
      textTransform: "uppercase",
    },
    dateEvent: {
      color: colors.black,
      marginTop: "15px !important",
      marginBottom: "10px !important",
      width: '100%',
    },
    dividerCardLink: {
      backgroundColor: colors.  purpleMediumSub,
      height: 3,
      width: 62,
    },
    containerCardLink: {
      width: "auto",
      minHeight: 329,
      marginRight: 11,
      marginBottom: 15,
      padding: 13,
      cursor: "pointer !important",
      display: "block",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.between("lg", "xl")]: {
        width: "98%",
      },
    },    
    titleCardLink: {
      color: colors.blueDarkest,
      marginTop: "10px !important",
      marginBottom: "15px !important",
    },
    Box: {
      //border: '1px solid black',
      padding: "40px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    Banner: {
      [theme.breakpoints.between('xs', 'lg')]: { 
        fontSize: '24px', 
      },
    },
  })
);
