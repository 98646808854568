import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDefaultStyles } from "../../../common/styles/default";
import GeneralPerformanceBox from "../general-performance/GeneralPerformanceBox";
import { useDebriefings } from "../../hooks/useDebriefings";
import secureLocalStorage from "react-secure-storage";
import FeedbackResult from "./FeedbackResult";
import FooterEventAppraiserFeedback from "./FooterEventAppraiserFeedback";
//import feedbacksData from '../../mocks/debriefing_feedback.json'
import DebriefingService from "../../../services/DebriefingService";
import EvaluatorCard from "./CardEvaluator";
import { useTranslation } from "react-i18next";

export default function EventAppraiserFeedback({
  eventId,
  stationId,
  evaluatedId,
  debriefingId,
  station,
  scenario,
}: any) {
  const styles = useDefaultStyles();
  const { t } = useTranslation();

  const {
    getDebriefingFeedback,
    getFeedbackByDebriefing,
    debriefing,
    feedbacksData,
    errorFeedback,
    loadingFeedback,
    successFeedback,
  } = useDebriefings();
  const auth_user_id = secureLocalStorage.getItem("id") as number;

  useEffect(() => {
    getDebriefingFeedback({ stationId, studentId: auth_user_id });
    getFeedbackByDebriefing(debriefingId);
  }, [eventId, stationId, evaluatedId, debriefingId, station, scenario]);

  return (
    <Box className={styles.container}>
      <Accordion defaultExpanded sx={{bgcolor: '#E4F6FD', color: '#527E9F', mb: 2}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Stack 
            flexDirection={"row"}
            alignItems={"center"}
            gap={1}
          >
            <InfoOutlinedIcon />
            <Typography 
              sx={{fontWeight: 'bold', fontSize: 18}}
            >
              Informação
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontWeight: 100}}>
            Está tela é apenas uma visuazalização dos apontamentos feitos na seção “considerações”. Também é possivel ver feedbacks de outros avaliadores (caso existam) através do seletor abaixo.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <EvaluatorCard
        name={feedbacksData?.appraiser_name}
        appraiserId={feedbacksData?.appraiser_id}
        role={t("title_label_class_evaluator")}
      />

      <FeedbackResult
        items={debriefing?.rating_items}
        eventId={eventId}
        stationId={stationId}
        evaluatedId={evaluatedId}
        debriefingId={debriefingId}
        station={station}
        scenario={scenario}
        data={feedbacksData}
      />
      <GeneralPerformanceBox
        result={{ score: feedbacksData.station_performace }}
        debriefingId={debriefingId}
      />
      <FooterEventAppraiserFeedback
        description={feedbacksData.station_consideration}
        title={t("title_final_considerations") ?? ""}
      />
    </Box>
  );
}
