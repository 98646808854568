import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, Typography } from '@mui/material'
import { Container } from '@mui/material'
import { toast } from 'react-toastify'
import AttachmentService from '../../services/AttachmentService'
import AddIcon from '@mui/icons-material/Add'
import Banner from '../../common/components/banner'
import banner from '../../assets/images/banners/Capa_guia_rapido.png'
import { useQuickGuide } from '../styles/quickguide'
import ItemGuide from './ItemGuide'
import { useTheme } from '@mui/material/styles' // Importação necessária para acessar os breakpoints

export default function QuickGuide() {
  const { t } = useTranslation()
  const styles = useQuickGuide()
  const theme = useTheme() // Usando o hook useTheme para acessar os breakpoints

  const [attachment, setAttachment] = useState([] as any)
  const [url, setUrl] = useState('')

  const feature = 'quickguide'
  const idGuide = 2
  const name = 'manual_hiplade_coordinator'

  const uploadFile = async (e) => {
    const data = {
      feature: feature,
      object_id: idGuide,
      field_name: name,
      file: e.target.files[0],
      file_type: 'document',
      multiple: 'false',
    }

    await AttachmentService.upload(data)
      .then((response) => {
        getFile()
        toast(response.data.Msg, {
          type: 'success',
        })
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: 'error',
        })
      })
  }

  const getFile = async () => {
    await AttachmentService.get(feature, idGuide, name)
      .then((response) => {
        setAttachment(response.data)
        if (response.data.length > 0) {
          setUrl(response.data[0].url)
        }
      })
      .catch((e: any) => {})
  }

  const removeFile = async (id: any) => {
    await AttachmentService.remove(id)
      .then((response) => {
        getFile()
        setUrl('')
        toast(response.data.Msg, {
          type: 'success',
        })
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: 'error',
        })
      })
  }

  useEffect(() => {
    if (idGuide) {
      getFile()
    }
  }, [])

  return (
    <Box>
      <Banner
        type="medium"
        image={banner}
        title={t('banner_title_quick_guide')}
        subtitle={t('banner_subtitle_quick_guide')}
      />
      <Box  sx={{ width: '94%',
                 position: 'relative', 
                 paddingLeft: '60px',
                 paddingRight: '120px',
                 paddingBottom: '100px',

                 [theme.breakpoints.down("sm")]: {
                  //border: '1px solid black',
                  position: 'relative',
                  ml: '25px',
                  width: '94%',
                  paddingLeft: '5px',
                  paddingRight: '30px',
                },

                [theme.breakpoints.between('sm', 'md')]: {
                  //border: '1px solid black',
                  position: 'relative',
                  ml: '25px',
                  width: '94%',
                  paddingLeft: '53px',
                  paddingRight: '53px',
                },

                [theme.breakpoints.between('md', 'lg')]: {
                  //border: '1px solid black',
                  position: 'relative',
                  ml: '25px',
                  width: '92%',
                  paddingLeft: '34px',
                  paddingRight: '30px',
                },

                [theme.breakpoints.between('lg', 'xl')]: {
                  //border: '1px solid black',
                  position: 'relative',
                  ml: '25px',
                  width: '96%',
                  paddingLeft: '65px',
                  paddingRight: '75px',
                },
       }} >
        <Container className={styles.boxSpacing}>
          <Box className={styles.guideDescription}>
            <Container>
              {t('description_first_paragraph_quick_guide')}
              {t('description_second_paragraph_quick_guide')}
              <strong>{t('description_strong_quick_guide')}</strong>
            </Container>
          </Box>

          <Box className={styles.boxFile}>
            <Box>
              <Typography
                className={styles.titleFile}
                sx={{ fontWeight: 'bold' }}
              >
                {t('title_guide_update_manual')}
              </Typography>
            </Box>

            <Divider className={styles.divider} />
            <Grid container spacing={2} className={styles.gridFiles}>
              <Grid item xs={10}>
                <Box className={styles.boxListFiles}>
                  {attachment.length > 0 &&
                    attachment.map((row, index) => (
                      <ItemGuide
                        key={index}
                        name={row?.original_name}
                        removeFile={removeFile}
                        id={row?.id}
                        url={row?.url}
                      />
                    ))}
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <input
                    id={name}
                    name={name}
                    style={{ display: 'none' }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files != null) {
                        uploadFile(event)
                      }
                    }}
                    accept="application/pdf"
                    type="file"
                  />
                  <label htmlFor={name}>
                    <Typography
                      sx={{ fontWeight: 'bold' }}
                      className={styles.buttonUpload}
                    >
                      {url == ''
                        ? t('lbl_btn_upload_create')
                        : t('lbl_btn_upload_update')}
                      <AddIcon
                        sx={{ fontSize: '18px', ml: 1, fontWeight: 'bold' }}
                      />
                    </Typography>
                  </label>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
