import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import secureLocalStorage from "react-secure-storage";
import ICameraList from "../../types/Camera";

import { useModal } from "../../common/hooks/useModal";

import { useDefaultStyles } from "../../common/styles/default";

import CameraService from "../../services/CameraService";
import colors from "../../common/styles/colors";

export default function CameraList({ id }: any) {
  const navigate = useNavigate();

  const { handleOpenModal, dataModal } = useModal();

  const { t } = useTranslation();
  const styles = useDefaultStyles();

  const handleAdd = () => {
    handleOpenModal({
      displayName: "RoomCameraModal",
      data: { idRoom: id, loadCameras: loadCameras, total: cameras.length },
    });
  };

  const paginate = 1;
  const firstpage = 1;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [cameras, setCameras] = useState([] as any);

  const [entityRemove, setEntityRemove] = useState("");

  const [open, setOpen] = React.useState(false);

  const localPermissions = secureLocalStorage
    .getItem("permissions")
    ?.toString();
  const permissions = localPermissions?.split(",");

  const handleClickOpen = (id: any) => {
    setEntityRemove(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadCameras = async (idRoom: any) => {
    await CameraService.getByRoom(idRoom)
      .then((response: any) => {
        if (response) {
          setCameras(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const editCamera = (idCam: number) => {
    handleOpenModal({
      displayName: "RoomCameraModal",
      data: {
        idRoom: id,
        loadCameras: loadCameras,
        idCam: idCam,
        total: cameras.length,
      },
    });
  };

  const editCameraColor = (idCam: number) => {
    handleOpenModal({
      displayName: "RoomCameraColorModal",
      data: {
        idRoom: id,
        loadCameras: loadCameras,
        idCam: idCam,
        total: cameras.length,
      },
    });
  };

  const removeCamera = () => {
    CameraService.remove(entityRemove)
      .then((response: any) => {
        setEntityRemove("");
        loadCameras(id);
        setOpen(false);
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    loadCameras(id);
  }, []);

  return (
    <Box>
      <Box>
        {permissions?.includes("create_entity") && (
          <Box sx={{ mb: 2, width: "10%" }}>
            <Button variant="contained" onClick={handleAdd}>
              {t("btn_add")}
            </Button>
          </Box>
        )}
        <TableContainer component={Paper} sx={{ mt: 5 }}>
          <Table sx={{ minWidth: 600 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("title_camera_room")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("title_ip_camera_room")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("title_port_camera_room")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("title_user_camera_room")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("title_address_camera_room")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("title_type_camera_room")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("title_principal_camera_room")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("title_color_camera_room")}
                </TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("table_label_button")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cameras.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row?.name}
                  </TableCell>
                  <TableCell>{row?.ip}</TableCell>
                  <TableCell>{row?.port}</TableCell>
                  <TableCell>{row?.user}</TableCell>
                  <TableCell>{row?.url}</TableCell>
                  <TableCell>{row?.type}</TableCell>
                  <TableCell>{row?.main == 1 ? t("title_camera_room_yes") : t("title_camera_room_no")}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        backgroundColor: `${
                          row?.camera_color ? row?.camera_color : colors.gray
                        }`,
                        borderRadius: 1,
                        width: 32,
                        height: 32,
                        cursor: "pointer",
                      }}
                      onClick={() => editCameraColor(row.id)}
                    ></Box>
                  </TableCell>

                  <TableCell>
                    {permissions?.includes("update_entity") && (
                      <EditIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => editCamera(row.id)}
                      />
                    )}
                    {permissions?.includes("delete_entity") && (
                      <DeleteIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleClickOpen(row.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("title_dialog_delete_room")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("description_dialog_delete_room")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("btn_dialog_no")}</Button>
          <Button onClick={removeCamera} autoFocus>
            {t("btn_dialog_yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
