import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useModal } from "../../common/hooks/useModal";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function ListAppraiser() {
  const {
    dataModal,
    onCloseModalReset,
    handleChangedModal,
    handleStopSaveModal,
  } = useModal();

  const { t } = useTranslation();
  const [selectedAppraisers, setSelectedAppraisers] = useState<number[]>([]);

  const columns: GridColDef[] = [
    { field: "id", headerName: t("title_header_id") || "", width: 140 },
    { field: "name", headerName: t("title_header_name") || "", width: 250 },
    { field: "email", headerName: t("title_header_email") || "", width: 230 },
  ];

  const handleSelectionChange = (newSelection) => {
    setSelectedAppraisers(newSelection);
  };

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (selectedAppraisers.length == 0) {
      toast(t("toast_appraise_empty"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    if (selectedAppraisers.length > 1) {
      toast(t("toast_appraise_select"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    const appraisers = dataModal?.data?.appraisers;
    const selectedAppraiser = appraisers.find(
      (appraiser) => appraiser?.id == selectedAppraisers[0]
    );

    if (selectedAppraiser) {
      dataModal?.data?.setAppraiser(selectedAppraiser);
      dataModal?.data?.setAppraisers_id(selectedAppraiser?.id);
    }

    onCloseModalReset();
  };

  useEffect(() => {
    if (dataModal.loadingBtnSave) {
      handleSubmit();
    }

    if (dataModal?.data?.idAppraiser) {
      setSelectedAppraisers(dataModal?.data?.idAppraiser);
    }
  }, [dataModal.loadingBtnSave, dataModal.data, dataModal.loadingBtnSave]);

  return (
    <Box>
      <Box
        sx={{ height: 400, width: "100%" }}
        component="form"
        onSubmit={handleSubmit}
      >
        <DataGrid
          rows={dataModal?.data?.appraisers}
          columns={columns}
          pageSize={5}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectedAppraisers}
          onSelectionModelChange={(ids) => {
            handleSelectionChange(ids);
          }}
          sx={{ width: "750px" }}
        />
      </Box>
    </Box>
  );
}
