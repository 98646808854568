import api from '../config/api'
import IEntitiesData from '../types/Entities'

const store = (data: any) => {
  return api.post<IEntitiesData>('/entities', data)
}

const getAll = (page = 1, paginate = 0) => {
  return api.get<IEntitiesData>(
    '/entities?page=' + page + '&paginate=' + paginate,
  )
}

const get = (id: number) => {
  return api.get<IEntitiesData>('/entities/' + id)
}

const update = (id: any, data: any) => {
  return api.put<IEntitiesData>('/entities/' + id, data)
}

const remove = (id: any) => {
  return api.delete('/entities/' + id)
}

const EntitiesService = {
  store,
  getAll,
  get,
  update,
  remove,
}

export default EntitiesService
