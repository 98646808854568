import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../../styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    modalContainer: {
        position: 'absolute',
        marginTop: '20%',
        marginLeft: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        boxShadow: '0px 3px 6px #00000029',
        padding: '20px',
        borderRadius: '8px',
        outline: 'none',
        width: '700px',
        height: '460px',
        textAlign: 'center',
    },
    modalTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
        paddingTop: 25,
        paddingBottom: 50,
    },
    modalText: {
        fontSize: '20px !important',
        marginBottom: '20px',
    },
    boldText: {
        fontWeight: 'bold',
    },
    modalDivider: {
        marginTop: '50px',
        marginBottom: '70px',
        backgroundColor: colors.lilacMediumLight,
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'end',
        gap: 12,
    },
    backButton: {
        width: '94px',
        height: '43px',
        backgroundColor: colors.white,
        color: colors.purple,
        border: '1px solid #6200EE',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        textTransform: 'uppercase',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    },
    concludeButton: {
        width: '185px',
        height: '43px',
        backgroundColor: colors.purple,
        color: colors.white,
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        textTransform: 'uppercase',   
        boxShadow: '0px 3px 8px rgba(145, 145, 145, 0.1)',
    },    
  }),
)
