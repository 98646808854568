import React, { useEffect, useState } from "react";
import hipladeTemplate from "../../common/template";
import DataIa from "../components/DataIa";
import { Box, Button } from "@mui/material";
import Banner from "../../common/components/banner";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import UsersService from "../../services/UsersService";
import AvatarHiplade from "../../common/components/avatarhiplade";

function EventDataIa() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { eventId, userId, debriefingId } = useParams();
  const [user, setUser] = useState({} as any);

  const loadUser = async (id: any) => {
    await UsersService.get(id)
      .then((response: any) => {
        if (response) {
          setUser(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (userId) {
      loadUser(userId);
    }
  }, [eventId, userId]);

  const backEvent = (eventId: any) => {
    navigate("/events/data-ai/" + eventId);
  };

  return (
    <Box>
      <Banner
        type="student"
        title={t("description_event_scenario_view") + user?.name}
        avatar={<AvatarHiplade nameUser={user?.name} id={user?.id} size="lg" />}
        button={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => backEvent(eventId)}
          >
            {t("btn_return")}
          </Button>
        }
      />
      <DataIa idEvaluated={userId} idDebriefing={debriefingId} />
    </Box>
  );
}

export default hipladeTemplate(EventDataIa);
