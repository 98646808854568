import api from "../config/api";

const getOverallAverage = (event_id: any) => {
  return api.get(`dashboards/generalevent/${event_id}/pie`);
};

const getAverageStations = (event_id: any) => {
  return api.get(`dashboards/meanstations/event/${event_id}/bar`);
};

const getAverageSkills = (station_id: any) => {
  return api.get(`dashboards/meanskills/station/${station_id}/radar`);
};

const getEventHistogram = (event_id: any) => {
  return api.get(`dashboards/histogram/event/${event_id}/bar`);
};

const getAverageEvaluated = (event_id: any, evaluated_id: any) => {
  return api.get(`dashboards/event/${event_id}/evaluated/${evaluated_id}/bar`);
};

const getAverageStationsEvaluated = (event_id: any, evaluated_id: any) => {
  return api.get(
    `dashboards/stations/event/${event_id}/evaluated/${evaluated_id}/bar`
  );
};

const getAverageSkillsEvaluated = (station_id: any, evaluated_id: any) => {
  return api.get(
    `dashboards/skills/station/${station_id}/evaluated/${evaluated_id}/radar`
  );
};

const DashboardService = {
  getOverallAverage,
  getAverageStations,
  getAverageSkills,
  getEventHistogram,
  getAverageEvaluated,
  getAverageStationsEvaluated,
  getAverageSkillsEvaluated,
};

export default DashboardService;
