import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDefaultStyles } from "../../common/styles/default";
import EventSkillFeedbackForm from "./EventSkillFeedbackForm";
import { useTranslation } from "react-i18next";

export default function FormEventAppraiserFeedback({
  eventId,
  stationId,
  evaluatedId,
  debriefingId,
  station,
  scenario,
}: any) {
  const styles = useDefaultStyles();
  const { t } = useTranslation();

  useEffect(() => {}, [
    eventId,
    stationId,
    evaluatedId,
    debriefingId,
    station,
    scenario,
  ]);

  return (
    <Box className={styles.container}>
      <Typography>
        {t("description_dialog_form_event_appraiser_feedback")} 
      </Typography>

      <EventSkillFeedbackForm
        eventId={eventId}
        stationId={stationId}
        evaluatedId={evaluatedId}
        debriefingId={debriefingId}
        station={station}
        scenario={scenario}
      />
    </Box>
  );
}
