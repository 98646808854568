import api from '../config/api'
import IStudentsGroupData from '../types/StudentsGroup'

const store = (data: any) => {
  return api.post<IStudentsGroupData>('/studentsgroup', data)
}

const getAll = (page = 1, paginate = 0) => {
  return api.get<IStudentsGroupData>(
    '/studentsgroup?page=' + page + '&paginate=' + paginate,
  )
}

const get = (id: number) => {
  return api.get<IStudentsGroupData>('/studentsgroup/' + id)
}

const update = (id: any, data: any) => {
  return api.put<IStudentsGroupData>('/studentsgroup/' + id, data)
}

const remove = (id: any) => {
  return api.delete('/studentsgroup/' + id)
}

const linkStudents = (data: any) => {
  return api.post<IStudentsGroupData>('studentsgroup/linkstudents', data)
}

// Novo método para obter os usuários vinculados a um grupo de estudantes
const getUsers = (studentsgroup_id: number) => {
  return api.get<IStudentsGroupData>(`/studentsgroup/${studentsgroup_id}/users`)
}

const getStudentsStatus = (studentsgroup_id: number) => {
  return api.get(`/studentsgroup/${studentsgroup_id}/users`);
}

const StudentsGroupService = {
  store,
  getAll,
  get,
  update,
  remove,
  linkStudents,
  getUsers, 
  getStudentsStatus,
}

export default StudentsGroupService
