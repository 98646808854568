import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useStyles } from '../styles/index.styles'

export default function GradeBar({ event, porc }: any) {
  const classes = useStyles()

  useEffect(() => {}, [event, porc])

  return (
    <Box>
      <Box className={classes.barBackground}>
        <Box className={classes.barGrade} sx={{ width: porc + '%' }}>
          {porc + '%'}
        </Box>
      </Box>
    </Box>
  )
}
