import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EntitiesService from "../../services/EntitiesService";
import RoomsService from "../../services/RoomsService";
import { toast } from "react-toastify";

import IEntityData from "../../types/Entities";
import { useDefaultStyles } from "../../common/styles/default";
import { Grid } from "@mui/material";
import { useCameraStyle } from "../styles/index.styles";

import { useModal } from "../../common/hooks/useModal";
import CameraService from "../../services/CameraService";
import colors from "../../common/styles/colors";

export default function FormCamera() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const styles = useDefaultStyles();
  const classes = useCameraStyle();

  const [name, setName] = useState("");
  const [ip, setIp] = useState("");
  const [port, setPort] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  const [main, setMain] = useState("");
  const arrColors = [colors.purple, colors.pinkLight, colors.green];

  const handleType = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  const handleMain = (event: SelectChangeEvent) => {
    setMain(event.target.value);
  };

  const handleSubmit = () => {
    if (name == "") {
      toast(t("toast_description_name_camera"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    if (ip == "") {
      toast(t("toast_description_ip_camera"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    if (port == "") {
      toast(t("toast_description_port_camera"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    if (user == "") {
      toast(t("toast_description_user_camera"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    if (password == "") {
      toast(t("toast_description_password_camera"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    if (url == "") {
      toast(t("toast_description_address_camera"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    if (type == "") {
      toast("toast_description_type_camera", {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    if (main == "") {
      toast(t("toast_description_principal_camera"), {
        type: "error",
      });
      handleStopSaveModal();
      return;
    }

    let dataCam = {};
    if (!dataModal?.data?.idCam) {
      console.log(dataModal?.data?.total);
      const dataCam = {
        name: name,
        ip: ip,
        port: port,
        user: user,
        password: password,
        url: url,
        type: type,
        main: main,
        camera_color:
          dataModal?.data?.total >= 3
            ? colors.purple
            : arrColors[dataModal?.data?.total].replace("!important", ""),
        font_color: colors.white.replace("!important", ""),
        room_id: dataModal?.data?.idRoom,
      };

      CameraService.store(dataCam)
        .then((response: any) => {
          if (!response?.Msg) {
            dataModal?.data?.loadCameras(dataModal?.data?.idRoom);
            onCloseModalReset();
          }
        })
        .catch((e: any) => {
          handleStopSaveModal();
          toast(e?.response?.data?.Error, {
            type: "error",
          });
          return;
        });
    } else {
      const dataCam = {
        name: name,
        ip: ip,
        port: port,
        user: user,
        password: password,
        url: url,
        type: type,
        main: main,
        room_id: dataModal?.data?.idRoom,
      };
      CameraService.update(dataModal?.data?.idCam, dataCam)
        .then((response: any) => {
          dataModal?.data?.loadCameras(dataModal?.data?.idRoom);
          onCloseModalReset();
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const loadCamera = async (id: any) => {
    await CameraService.get(id)
      .then((response: any) => {
        if (response) {
          console.log("Cameras");
          console.log(response.data);
          setName(response.data.name);
          setIp(response.data.ip);
          setPort(response.data.port);
          setUser(response.data.user);
          setPassword(response.data.password);
          setUrl(response.data.url);
          setType(response.data.type);
          setMain(response.data.main);
        }
      })
      .catch((e: Error) => {});
  };

  const {
    dataModal,
    onCloseModalReset,
    handleChangedModal,
    handleStopSaveModal,
  } = useModal();

  useEffect(() => {
    if (dataModal?.data?.idCam) {
      loadCamera(dataModal?.data?.idCam);
    }

    if (dataModal.loadingBtnSave) {
      handleSubmit();
    }
  }, [dataModal.loadingBtnSave]);

  return (
    <Box className={classes.modalContainer}>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Box>
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            label={t("table_label_name")}
            variant="outlined"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
            sx={{ mb: 3 }}
          />

          <TextField
            required
            fullWidth
            id="ip"
            name="ip"
            label={t("title_label_ip")}
            variant="outlined"
            value={ip}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIp(event.target.value);
            }}
            sx={{ mb: 3 }}
          />

          <TextField
            required
            fullWidth
            id="port"
            name="port"
            label={t("title_label_port")}
            variant="outlined"
            value={port}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPort(event.target.value);
            }}
            sx={{ mb: 3 }}
          />

          <TextField
            required
            fullWidth
            id="user"
            name="user"
            label={t("title_label_user")}
            variant="outlined"
            value={user}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUser(event.target.value);
            }}
            sx={{ mb: 3 }}
          />

          <TextField
            required
            fullWidth
            id="password"
            name="password"
            label={t("title_label_password")}
            variant="outlined"
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
            sx={{ mb: 3 }}
          />

          <TextField
            required
            fullWidth
            id="url"
            name="url"
            label={t("title_label_address")}
            variant="outlined"
            value={url}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUrl(event.target.value);
            }}
            sx={{ mb: 3 }}
          />

          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="type-label">{t("title_label_type")}</InputLabel>
            <Select
              labelId="type-label"
              label={t("title_label_type")}
              value={type}
              onChange={handleType}
            >
              <MenuItem value="ip">{t("menu_item_ip")}</MenuItem>
              <MenuItem value="webcam">{t("menu_item_webcam")}</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="type-label">{t("title_label_principal_camera")}</InputLabel>
            <Select
              labelId="type-label"
              label={t("title_label_principal_camera")}
              value={main}
              onChange={handleMain}
            >
              <MenuItem value="0">{t("title_camera_room_no")}</MenuItem>
              <MenuItem value="1">{t("title_camera_room_yes")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}
