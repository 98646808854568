import api from "../config/api";
import IAreaData from "../types/Area";

const fex = (data: any) => {
  return api.post("/sheai/fex", data);
};

const fexStatus = (debriefingId: any) => {
  return api.get("/sheai/statusfex/debriefing/" + debriefingId);
};

const SheAiService = {
  fex,
  fexStatus,
};

export default SheAiService;
