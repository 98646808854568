import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { ClassNames } from "@emotion/react";
import { useStyles } from "./styles/index.styles";
import { useModal } from "../../../hooks/useModal";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const HeaderThemeModal = () => {
  const classes = useStyles();
  const { dataModal } = useModal();

  const { t } = useTranslation();

  return (
    <Box className={classes.containerHeader}>
      <Typography className={classes.headerTitle}>
        {dataModal.data?.idTheme ? t("editTheme") : t("addTheme")}
      </Typography>
    </Box>
  );
};
