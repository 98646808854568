import { Box, BoxProps, Typography } from '@mui/material'
import React from 'react'
import { AppContext } from '../../../../contexts/AppContext';
import { useStyles } from './styles/index.styles';
import { MenuProps } from '../../../dtos/menuDtos';
import colors from '../../../styles/colors';

type Props = BoxProps & {
    submenuSelected: boolean
    titleSubmenu: string
}

export  function SubmenuItem({
    submenuSelected,
    titleSubmenu,
    ...rest
}: Props) {

    const { state: { menu } } = React.useContext(AppContext);
    

    const classes = useStyles(menu as MenuProps)

  return (
    <Box 
        className={classes.submenu} 
        sx={{ backgroundColor: submenuSelected ? colors.purpleLight : 'transparent' }}
        {...rest}
    >
        <Typography sx={{ color: colors.white, textAlign: 'left', my: 1, fontSize: 14 }}>
            {titleSubmenu}
        </Typography>
    </Box>
  )
}
