import api from "../config/api";
import IDebriefingData, { IConsiderationScales } from "../types/Debriefing";

const store = (data: IDebriefingData) => {
  return api.post<IDebriefingData>("debriefings", data);
};

const getByStationIdEvaluatedId = (studentId, stationId) => {
  return api.get(`/debriefing/station/${stationId}/evaluated/${studentId}`);
};

const getFeedbackByDebriefingId = (debriefingId: number) => {
  return api.get(`/feedbacks/debriefings/${debriefingId}`);
};

const storeDebriefingScales = (data: any) => {
  return api.post("debriefing/scales", data);
};

const updateDebriefingScales = (id: any, data: any) => {
  return api.put(`debriefing/scale/${id}`, data);
};

const getDebriefingScale = (debriefingId: any, itemId: any) => {
  return api.get(`debriefing/${debriefingId}/item/${itemId}/scale`);
};

const getPerformanceStation = (debriefingId: any) => {
  return api.get(`performace/station/debriefing/${debriefingId}`);
};

const getDebriefingEventByEvaluated = ({
  eventId,
  evaluatedId,
}: {
  eventId: string | number;
  evaluatedId: string | number;
}) => {
  return api.get(`debriefing/event/${eventId}/evaluated/${evaluatedId}`);
};

const storeConsiderationsScales = (data: IConsiderationScales) => {
  return api.post("scales/calculation", data);
};

const getDebriefingUrl = (debriefingId: any) => {
  return api.get(`debriefings/${debriefingId}/recordingsurls`);
};

const getDebriefingLiveUrl = (debriefingId: any) => {
  return api.get(`debriefings/${debriefingId}/streamingurl`);
};

const DebriefingService = {
  store,
  getByStationIdEvaluatedId,
  getFeedbackByDebriefingId,
  storeDebriefingScales,
  updateDebriefingScales,
  getDebriefingScale,
  getPerformanceStation,
  getDebriefingEventByEvaluated,
  storeConsiderationsScales,
  getDebriefingUrl,
  getDebriefingLiveUrl,
};

export default DebriefingService;
