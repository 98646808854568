import { Box, TextField } from "@mui/material";
import * as React from "react";
import DebriefingConsiderationsService from "../../../services/DebriefingConsiderationsService";
import { useTranslation } from "react-i18next";

export default function DebriefingConsiderations({
  debriefingId,
  skillId,
}: any) {
  if (!debriefingId) {
    return <Box />;
  }

  const { t } = useTranslation();
  const [label, setLabel] = React.useState(
    t("title_label_debriefing_considerations")
  );
  const [placeholder, setPlaceholder] = React.useState(
    t("title_placeholder_debriefing_considerations") ?? ""
  );
  const [consideration, setConsideration] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);

  const [considerationsId, setConsiderationsId] = React.useState("");

  const handleChange = (event) => {
    setLabel(t("title_label_change_debriefing_considerations"));
    setConsideration(event.target.value);
  };

  const storeConsiderations = async () => {
    if (consideration != "") {
      setLabel(t("title_label_store_debriefing_considerations"));
      setDisabled(true);
    }

    let debriefingConsiderationsData = {
      debriefing_id: debriefingId,
      skill_id: skillId,
      consideration: consideration,
    };

    if (considerationsId == "" && consideration != "") {
      await DebriefingConsiderationsService.store(debriefingConsiderationsData)
        .then((response: any) => {
          setConsiderationsId(response.data.id);
          setTimeout(function () {
            setLabel("");
            setDisabled(false);
          }, 5000);
        })
        .catch((e: Error) => {
          setLabel(t("title_label_debriefing_considerations"));
          setDisabled(false);
        });
    } else if (consideration != "") {
      await DebriefingConsiderationsService.update(
        considerationsId,
        debriefingConsiderationsData
      )
        .then((response: any) => {
          setTimeout(function () {
            setDisabled(false);
          }, 5000);
        })
        .catch((e: Error) => {
          setDisabled(false);
        });
    }
  };

  const getDebriefingConsideration = async (debriefingId, skillId) => {
    await DebriefingConsiderationsService.getConsideration(
      debriefingId,
      skillId
    )
      .then((response: any) => {
        if (response.data.length > 0) {
          setConsideration(response.data[0]?.consideration);
          setConsiderationsId(response.data[0]?.id);
          setLabel("");
          setTimeout(function () {
            setDisabled(false);
          }, 5000);
        }
      })
      .catch((e: Error) => {
        setConsideration("");
        setLabel("");
        setDisabled(false);
      });
  };

  const handleKeyDown = (event) => {
    event.key === "Enter" ? storeConsiderations() : "";
  };

  const handleOnBlur = () => {
    storeConsiderations();
  };

  React.useEffect(() => {
    getDebriefingConsideration(debriefingId, skillId);
  }, [debriefingId, skillId]);

  return (
    <Box marginTop={2}>
      <TextField
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        fullWidth
        onChange={handleChange}
        onKeyPress={handleKeyDown}
        onBlur={handleOnBlur}
        value={consideration}
      />
    </Box>
  );
}
