import React from 'react'
import { Box } from '@mui/system'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import secureLocalStorage from 'react-secure-storage'
import { useStyles } from '../styles/index.styles'
import CardAddItem from './CardAddItem'

export default function EventBoxCreate() {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const classes = useStyles()

  const localPermissions = secureLocalStorage.getItem('permissions')?.toString()
  const permissions = localPermissions?.split(',')

  return (
    <Box className={classes.boxEventCreateItens}>
      {permissions?.includes('create_scenario') && (
        <CardAddItem
          title={t('btn_label_add_scenario')}
          path="/scenarios/form"
        />
      )}
      {permissions?.includes('read_checklist') && (
        <CardAddItem
          title={t('btn_label_add_checklist')}
          path="/checklist/form"
        />
      )}
      {permissions?.includes('read_event') && (
        <CardAddItem
          title={t('btn_label_add_event_register')}
          path="/events/form"
        />
      )}
    </Box>
  )
}
