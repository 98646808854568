import React from "react";
import { Box } from "@mui/system";
import { useDebriefingStyles } from "../../styles/debriefing.styles";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import colors from "../../../common/styles/colors";

type Props = {
  onClick: () => void;
  time: any;
  cameraIndex: string;
  player: boolean;
  arrVideo?: any;
  videoId?: any;
};

export default function TimerBox({
  onClick,
  time,
  cameraIndex,
  player,
  arrVideo,
  videoId,
}: Props) {
  const stylesDebriefing = useDebriefingStyles();

  const video =
    !arrVideo && !videoId
      ? ""
      : arrVideo.find((video) => video.streaming_id === videoId);
  const indice =
    !arrVideo && !videoId
      ? ""
      : arrVideo.findIndex((video: any) => video.streaming_id === videoId);

  const hexToRgb = (hex: any) => {
    hex = hex.replace(/^#/, "");

    if (hex.length === 3) {
      hex = hex
        .split("")
        .map(function (hex) {
          return hex + hex;
        })
        .join("");
    }

    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return [r, g, b];
  };

  const colorConvert = (color: any) => {
    let rgbColor = hexToRgb(color);

    return `${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}`;
  };

  return (
    <Box
      className={stylesDebriefing.boxTime}
      sx={{
        border:
          "1px solid " +
          (video && player ? video?.camera_color : colors.purple),
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",

          backgroundColor:
            video && player
              ? `rgb(${colorConvert(video?.camera_color)},0.3)`
              : colors.lilacLight,
          color: video && player ? video?.camera_color : colors.purple,
          paddingTop: "2px",
          paddingLeft: "12px",
          paddingRight: "12px",
          paddingBottom: "2px",
        }}
      >
        <AccessTimeOutlinedIcon sx={{ fontSize: "16px" }} />
        <Box sx={{ ml: 1 }}>
          {player ? "C" + (indice + 1) + " - " : ""} {time}S
        </Box>
      </Box>
    </Box>
  );
}
