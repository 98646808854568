import React from "react";
import { useStyles } from "./styles";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles"; // Importação necessária para acessar os breakpoints
import { useTranslation } from "react-i18next";

interface Props {
  titleBack?: string;
  titleAction?: string;
  onClickAction?: () => void;
  onClickAreaEvent?: () => void;
  isLoading?: boolean;
  handleSubmit?: any;
  idEvent?: any;
}

export default function FooterEvent({
  onClickAction,
  onClickAreaEvent,
  titleAction,
  titleBack,
  isLoading,
  handleSubmit,
  idEvent,
}: Props) {
  const styles = useStyles();
  const theme = useTheme(); // Usando o hook useTheme para acessar os breakpoints
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBack = () => {
    navigate(-1);
  };

  const defaultTitleAction = t("btn_footer_save_and_finish");
  const defaultTitleBack = t("btn_footer_to_go_back");

  return (
    <Box
      className={styles.container}
      sx={{
        [theme.breakpoints.down("sm")]: {
          //alignItems: 'space-between',
          width: "100%",
        },
      }}
    >
      {onClickAction && (
        <Button
          variant="contained"
          size="large"
          color="primary"
          sx={{
            ml: 2,
            [theme.breakpoints.between(0, 960)]: {
              marginLeft: "-20px",
            },
            [theme.breakpoints.between(960, 1200)]: {
              marginLeft: "10px",
            },
            [theme.breakpoints.up(1200)]: {
              marginLeft: "10.7rem",
            },
          }}
          onClick={onClickAction}
          disabled={isLoading}
        >
          {isLoading ? t("loading") : titleAction || defaultTitleAction}
        </Button>
      )}
      <Button
        variant="contained"
        size="large"
        color="secondary"
        sx={{
          ml: 2,
          [theme.breakpoints.down("sm")]: {
            paddingRight: "25px",
          },
        }}
        onClick={handleBack}
      >
        {titleBack || defaultTitleBack}
      </Button>
    </Box>
  );
}
