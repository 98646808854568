import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Button, useMediaQuery, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import { useHomeStyles } from "../styles/home.styles";
import CardEventLink from "../../events/components/CardEventLink";
import CardHomeLink from "./CardHomeLink";
import CardHomeTeacherLink from "./CardHomeTeacherLink";
import CardTeacherLink from "./CardTeacherLink";
import CardQuickGuide from "./CardQuickGuide";
import CardHomeAnalytics from "./CardHomeAnalytics";
import Link from "@mui/material/Link";

import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import colors from "../../common/styles/colors";
import QuickGuideMobile from "../../assets/images/card_02_mobile.png";
import QuickGuide from "../../assets/images/img_card_guia.png";
import DoctorWomanMobile from "../../assets/images/card_01_mobile.png";
import DoctorWoman from "../../assets/images/img_card_teacher_02.png";
import AnalyticsImageMobile from "../../assets/images/card_03_mobile.png";
import AnalyticsImage from "../../assets/images/imagem_card_02.png";

import AttachmentService from "../../services/AttachmentService";

export default function HomeArea() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const classes = useHomeStyles();

  const localPermissions = secureLocalStorage
    .getItem("permissions")
    ?.toString();
  const permissions = localPermissions?.split(",");

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const [attachment, setAttachment] = useState([] as any);
  const [url, setUrl] = useState("");

  //const isDesktop = useMediaQuery('(min-width: 1200px)')
  //const isMobile = useMediaQuery('(max-width: 1535px)') // Alterado para max-width 1535px
  const isDesktop = useMediaQuery("(min-width: 1536px)"); // Alterado para min-width 1536px

  const feature = "quickguide";
  const idGuide = 2; //id do perfil coordenador, porque o manual é o mesmo p/ adm
  const name = "manual_hiplade_coordinator"; // nome de campo do perfil coodenador, porque o manual é o mesmo p/ adm

  const getFile = async () => {
    await AttachmentService.get(feature, idGuide, name)
      .then((response) => {
        setAttachment(response.data);
        if (response.data.length > 0) {
          setUrl(response.data[0].url);
        }
      })
      .catch((e: any) => {});
  };

  useEffect(() => {
    if (idGuide) {
      getFile();
    }
  }, []);

  return (
    <Box className={classes.Box}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={5.3} xl={5.6}>
          <Link href={url} target="_blank">
            <CardQuickGuide
              title={t("btn_quick_guide")}
              subtitle={t("subtitle_quick_guide")}
              icon={
                <FindInPageOutlinedIcon sx={{ color: "#fff", fontSize: 40 }} />
              }
              image={isDesktop ? QuickGuide : QuickGuideMobile}
            />
          </Link>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5.3} xl={5.6}>
          <CardHomeTeacherLink
            title={t("btn_prepare_events")}
            subtitle={t("subtitle_prepare_events")}
            path="/events/area"
            icon={
              <AnalyticsOutlinedIcon
                sx={{ color: colors.purpleIcon, fontSize: 40 }}
              />
            }
            image={isDesktop ? DoctorWoman : DoctorWomanMobile}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5.3} xl={4}>
          <CardTeacherLink
            title={t("btn_create_new_events")}
            subtitle={t("subtitle_create_new_events")}
            path="/events/form"
            icon={
              <AddBoxOutlinedIcon
                sx={{ color: colors.purpleIcon, fontSize: 40 }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5.3} xl={3.7}>
          <CardTeacherLink
            title={t("btn_ongoing_events")}
            subtitle={t("subtitle_ongoing_events")}
            path="/events/schedule/2"
            icon={
              <VideocamOutlinedIcon
                sx={{ color: colors.purpleIcon, fontSize: 40 }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5.3} xl={3.45}>
          <CardTeacherLink
            title={t("btn_fix_events")}
            subtitle={t("subtitle_fix_events")}
            path="/events/schedule/3"
            icon={
              <CheckOutlinedIcon
                sx={{ color: colors.purpleIcon, fontSize: 40 }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5.3} xl={4}>
          {permissions?.includes("read_event") && (
            <CardTeacherLink
              title={t("btn_schedule_events")}
              subtitle={t("subtitle_schedule_events")}
              path="/events/schedule"
              icon={
                <TodayOutlinedIcon
                  sx={{ color: colors.purpleIcon, fontSize: 40 }}
                />
              }
              date="10/10/2023"
              status_id="1"
              index="1"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={7.1}>
          <CardHomeAnalytics
            title={t("btn_metrics_and_analytics")}
            subtitle={t("subtitle_metrics_and_analytics")}
            icon={
              <LeaderboardOutlinedIcon
                sx={{ color: colors.purpleIcon, fontSize: 40 }}
              />
            }
            image={isDesktop ? AnalyticsImage : AnalyticsImageMobile}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
