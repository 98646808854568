import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import { useDefaultStyles } from '../../common/styles/default'
import { useScenarioStyles } from '../styles/scenarios.styles'
import ScenarioTitleView from './ScenarioTitleView'
import { Divider, Grid, Typography } from '@mui/material'

export default function ScenarioHealthHistoryView({
  illness,
  allergy,
  habits,
  surgeries,
  family_health_history,
}: any) {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const classes = useStyles()
  const styles = useDefaultStyles()
  const scenarioStyle = useScenarioStyles()

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Box className={scenarioStyle.containerScenario}>
          {illness && (
            <Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                {t("input_label_illness")}
              </Typography>
              <Divider className={scenarioStyle.divider} />
              <Typography sx={{ marginTop: 2 }}>{illness}</Typography>
            </Box>
          )}

          {allergy && (
            <Box>
              <Typography sx={{ fontWeight: 'bold', mt: 3 }}>
                {t("input_label_allergy")}
              </Typography>
              <Divider className={scenarioStyle.divider} />
              <Typography sx={{ marginTop: 2 }}>{allergy}</Typography>
            </Box>
          )}

          {habits && (
            <Box>
              <Typography sx={{ fontWeight: 'bold', mt: 3 }}>
                {t("input_label_habits")}
              </Typography>
              <Divider className={scenarioStyle.divider} />
              <Typography sx={{ marginTop: 2 }}>{habits}</Typography>
            </Box>
          )}

          {surgeries && (
            <Box>
              <Typography sx={{ fontWeight: 'bold', mt: 3 }}>
                {t("input_label_surgeries")}
              </Typography>
              <Divider className={scenarioStyle.divider} />
              <Typography sx={{ marginTop: 2 }}>{surgeries}</Typography>
            </Box>
          )}

          {family_health_history && (
            <Box>
              <Typography sx={{ fontWeight: 'bold', mt: 3 }}>
                {t("input_label_family_health_history")}
              </Typography>
              <Divider className={scenarioStyle.divider} />
              <Typography sx={{ marginTop: 2, marginBottom: 5 }}>
                {family_health_history}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
