import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import { useDefaultStyles } from '../../common/styles/default'
import { useScenarioStyles } from '../styles/scenarios.styles'
import { Divider, Grid, Typography } from '@mui/material'
import MetricsService from '../../services/MetricsService'
import moment from 'moment'
import 'moment/locale/pt-br'

export default function ScenarioHistoryOfVitalSignsView({
  history_vital_signs,
  physical_exam,
}: any) {
  const navigate = useNavigate()
  const [metrics, setMetrics] = useState<any[]>([])

  const [dataExam1, setDataExam1] = useState('')
  const [dataExam2, setDataExam2] = useState('')
  const [dataExam3, setDataExam3] = useState('')

  const loadMetrics = async () => {
    await MetricsService.getAll()
      .then((response: any) => {
        if (response) {
          let arrMetric: any[] = []
          response.data?.map((item: any, index: any) => {
            const dataMetric = {
              id: item?.id,
              title: item?.title,
              unit: item?.unit_of_measurement,
              exam_1: { id: '', value: '' },
              exam_2: { id: '', value: '' },
              exam_3: { id: '', value: '' },
            }

            arrMetric.push(dataMetric)
          })

          formatRecArrHistoryVital(arrMetric, history_vital_signs)
        }
      })
      .catch((e: Error) => {})
  }

  const formatRecArrHistoryVital = (arrMetrics, arrHistoryVital: any) => {
    let arrMetric = arrMetrics
    let data_1, data_2, data_3: any

    for (let j = 0; j < arrHistoryVital.length; j++) {
      for (let i = 0; i < arrMetric.length; i++) {
        if (arrMetric[i].id == arrHistoryVital[j].metric_id) {
          if (arrMetric[i].exam_1.value == '') {
            arrMetric[i].exam_1.id = arrHistoryVital[j].id
            arrMetric[i].exam_1.value = arrHistoryVital[j].value
            data_1 = arrHistoryVital[j].date
            break
          }
          if (
            arrMetric[i].exam_1.value != '' &&
            arrMetric[i].exam_2.value == ''
          ) {
            arrMetric[i].exam_2.id = arrHistoryVital[j].id
            arrMetric[i].exam_2.value = arrHistoryVital[j].value
            data_2 = arrHistoryVital[j].date
            break
          }
          if (
            arrMetric[i].exam_1.value != '' &&
            arrMetric[i].exam_2.value != '' &&
            arrMetric[i].exam_3.value == ''
          ) {
            arrMetric[i].exam_3.id = arrHistoryVital[j].id
            arrMetric[i].exam_3.value = arrHistoryVital[j].value
            data_3 = arrHistoryVital[j].date
            break
          }
        }
      }
    }

    setMetrics(arrMetric)
    setDataExam1(data_1)
    setDataExam2(data_2)
    setDataExam3(data_3)
  }

  useEffect(() => {
    loadMetrics()
  }, [history_vital_signs, physical_exam])

  const { t } = useTranslation()
  const classes = useStyles()
  const styles = useDefaultStyles()
  const scenarioStyle = useScenarioStyles()

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Box className={scenarioStyle.containerScenario}>
          <Box>
            <Box sx={{ mb: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={3} sx={{ textAlign: 'center' }}>
                  <Typography
                    className={classes.titleScenarioCheckbox}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {t("title_dialog_history_vital_signs_one")}
                  </Typography>
                  <Box className={classes.boxInputVitalSigns}>
                    {moment(dataExam1).locale('pt-br').format('DD/MM/YYYY')}
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'center' }}>
                  <Typography
                    className={classes.titleScenarioCheckbox}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {t("title_dialog_history_vital_signs_two")}
                  </Typography>
                  <Box className={classes.boxInputVitalSigns}>
                    {moment(dataExam2).locale('pt-br').format('DD/MM/YYYY')}
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'center' }}>
                  <Typography
                    className={classes.titleScenarioCheckbox}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {t("title_dialog_history_vital_signs_three")}
                  </Typography>
                  <Box className={classes.boxInputVitalSigns}>
                    {moment(dataExam3).locale('pt-br').format('DD/MM/YYYY')}
                  </Box>
                </Grid>
              </Grid>
              {metrics.map((mat, index) => (
                <Grid
                  container
                  key={index}
                  spacing={2}
                  sx={{ mt: 3, textAlign: 'center' }}
                >
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    <Typography
                      className={classes.titleScenarioCheckbox}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {mat.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {mat.exam_1?.value != '' && (
                      <Box className={classes.boxInputVitalSigns}>
                        {mat.exam_1?.value} {mat.unit}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {mat.exam_2?.value != '' && (
                      <Box className={classes.boxInputVitalSigns}>
                        {mat.exam_2?.value} {mat.unit}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {mat.exam_3?.value != '' && (
                      <Box className={classes.boxInputVitalSigns}>
                        {mat.exam_3?.value} {mat.unit}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Box className={classes.topField} sx={{ mb: 4 }}>
              <Divider className={classes.divider} />
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography
                className={classes.titleScenarioCheckbox}
                sx={{ fontWeight: 'bold' }}
              >
                {t('input_label_physical_exam')}
              </Typography>
              <Box className={classes.topField}>{physical_exam}</Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
