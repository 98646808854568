import { Box, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckListService from "../../services/CheckListService";
import { useStyles } from "../styles/index.styles";
import DebriefingComment from "./debriefing-rating-items/DebriefingComment";
import DebriefingConsiderations from "./debriefing-rating-items/DebriefingConsiderations";
import ScenarioTitleView from "./ScenarioTitleView";
import { useModal } from "../../common/hooks/useModal";
import { useTranslation } from "react-i18next";
import DebriefingCommentMarkers from "./debriefing-rating-items/DebriefingCommentMarkers";
import { AudioRecording, AudioPlayerWithWaveform, RecorderAudio } from "../../common/components/player/audio";

interface EventSkillFeedbackFormProps {
  eventId?: any;
  stationId?: any;
  evaluatedId?: any;
  debriefingId?: any;
  station?: any;
  scenario?: any;
  feedbackEnabled?: {
    data?: any[];
  };
}

export default function EventSkillFeedbackForm({
  eventId,
  stationId,
  evaluatedId,
  debriefingId,
  station,
  scenario,
  feedbackEnabled,
}: EventSkillFeedbackFormProps) {
  if (!eventId || !stationId || !evaluatedId) {
    return <Box />;
  }

  const [skills, setskills] = React.useState<any[]>([]);
  const [skillItems, setskillItems] = React.useState<any[]>([]);
  const [loadingCheckList, setLoadingCheckList] = React.useState<boolean>(false);
  const styles = useStyles();
  const { t } = useTranslation();

  const { handleOpenModal } = useModal();
  

  const getChecklist = async () => {
    setskillItems([]);
    setskills([]);
    setLoadingCheckList(true);

    try {
      const response  = await CheckListService.get(station?.checklist_id)
      setskillItems(response.data.items);
      setskills(response.data.skills);
    } catch (error:any) {
      console.error("Error getChecklist: ", error);
    }finally {
      setLoadingCheckList(false)
    }
  };

  const onOpenModal = (itemId: number) => {
    handleOpenModal({
      displayName: "chooseBookmark",
      data: { debriefingId, itemId, getChecklist, station },
    });
  };

  useEffect(() => {
    if (station) {
      getChecklist();
    }
  }, [eventId, stationId, evaluatedId, debriefingId, station, scenario]);

  const LoadingFetch = () => (
    <Box sx={{position: 'fixed', left:0, top: 0, width: "100vw", height: "100vh", bgcolor: '#0000002f', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress />
    </Box>
  )

  return (
    <Box className={styles.eventBoxskill}>
      {loadingCheckList && <LoadingFetch />}
      {skills?.length > 0
        ? skills.map((skill, index) => (
            <Box key={index}>
              <Box className={styles.containerItem}>
                <ScenarioTitleView
                  index={"0" + (index + 1)}
                  title={skill.name}
                />
                <Divider className={styles.dividerCard}></Divider>

                {skillItems
                  .filter((e) => e.skill_id === skill.skill_id)
                  .map((item, index) => (
                    <Box key={index} className={styles.container}>
                      <Typography>{item.question}</Typography>
                      {/**
                       * Rating Items
                       */}
                      <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
                        <Button
                          onClick={() => onOpenModal(item.item_id)}
                          variant="outlined"
                        >
                          {t("btn_add_marker")} +
                        </Button>
                      </Box>
                      <Box>
                        <DebriefingCommentMarkers
                          itemId={item.item_id}
                          debriefingId={debriefingId}
                        />
                      </Box>
                      <DebriefingComment
                        debriefingId={debriefingId}
                        stationId={stationId}
                        skillId={skill.skill_id}
                        itemId={item.item_id}
                        onAudioStoredSuccessfully={getChecklist} 
                      />
                      <Divider sx={{mt: 10}}/>
                    </Box>
                  ))}
              </Box>

              <Box className={styles.containerConsiderations}>
                <Typography variant="h5">
                  {" "}
                  {t("title_page_form_considerations_and_improvements")}{" "}
                </Typography>
                <DebriefingConsiderations
                  debriefingId={debriefingId}
                  skillId={skill.skill_id}
                />
              </Box>
            </Box>
          ))
        : t("loading")}
    </Box>
  );
}
