import React, { useCallback, useEffect, useState } from "react";
import { AppContext } from "../../../contexts/AppContext";
import {
  DisplayNameModal,
  ModalBaseComponentProps,
  ModalProps,
} from "../../dtos/modalDtos";
import { HeaderEditingImageSnapshot } from "../../components/modal/EditingImageSnapshot/Header";
import { EditingImageSnapshot } from "../../components/modal/EditingImageSnapshot";
import { DEFAULT_VALUE_APPCONTEXT } from "../../../contexts/AppContext/defaultValue";
import { HeaderChooseBookmark } from "../../components/modal/ChooseBookmark/Header";
import { ChooseBookmark } from "../../components/modal/ChooseBookmark";
import { HeaderEventStationModal } from "../../components/modal/EventStationModal/Header";
import { EventStationModal } from "../../components/modal/EventStationModal";
import { RoomCameraModal } from "../../components/modal/RoomCameraModal";
import { HeaderRoomCameraModal } from "../../components/modal/RoomCameraModal/Header";
import { ThemeModal } from "../../components/modal/ThemeModal";
import { HeaderThemeModal } from "../../components/modal/ThemeModal/Header";
import { UploadConfirmation } from "../../components/modal/UploadConfirmation";
import { HeaderCompetenceModal } from "../../components/modal/CompetenceModal/Header";
import { CompetenceModal } from "../../components/modal/CompetenceModal";
import { HeaderCompetenceExtraModal } from "../../components/modal/CompetenceExtraModal/Header";
import { CompetenceExtraModal } from "../../components/modal/CompetenceExtraModal";
import { ThemeExtraModal } from "../../components/modal/ThemeExtraModal";
import { HeaderThemeExtraModal } from "../../components/modal/ThemeExtraModal/Header";
import { RoomCameraColorModal } from "../../components/modal/RoomCameraColorModal";
import { HeaderRoomCameraColorModal } from "../../components/modal/RoomCameraColorModal/Header";
import { AppraisersListModal } from "../../components/modal/AppraisersList";
import { HeaderAppraisersListModal } from "../../components/modal/AppraisersList/Header";
import { HeaderAppraisersModal } from "../../components/modal/AppraisersModal/Header";
import { AppraisersModal } from "../../components/modal/AppraisersModal";

interface UseModalProps {
  data?: any;
  displayName: DisplayNameModal;
}

export function useModal() {
  const {
    state: { modal },
    updateDataContext,
  } = React.useContext(AppContext);

  const [modalComponents, setmodalComponents] =
    useState<ModalBaseComponentProps>({});

  const resteModalValue = DEFAULT_VALUE_APPCONTEXT.modal;

  const toggleDisplayNameInDataModal = ({
    displayName,
    data,
  }: UseModalProps) => {
    const filterData: any = modal?.modalsAreOpen?.filter(
      (i) => i.displayName === displayName
    );
    const newData =
      filterData?.length > 0
        ? modal?.modalsAreOpen?.filter((i) => i.displayName !== displayName)
        : [...filterData, { displayName, data }];

    return newData;
  };

  const handleOpenModal = ({ displayName, data }: UseModalProps) => {
    if (displayName) {
      updateDataContext({
        modal: {
          ...modal,
          data,
          displayName,
          modalsAreOpen: toggleDisplayNameInDataModal({ displayName, data }),
        },
      });
    }
  };

  const handleChangedModal = ({ displayName, data }: UseModalProps) => {
    if (displayName) {
      updateDataContext({
        modal: {
          ...modal,
          data,
          displayName,
          modalsAreOpen: toggleDisplayNameInDataModal({ displayName, data }),
        },
      });
    }
  };

  const handleCloseModal = () => {
    if (!modal?.loadingBtnSave) {
      updateDataContext({
        modal: {
          ...modal,
          btnCloseModalClicked: true,
          loadingBtnClosed: true,
        },
      });
    }
  };

  const handleSaveModal = () => {
    updateDataContext({
      modal: {
        ...modal,
        btnSaveModalClicked: true,
        loadingBtnSave: true,
      },
    });
  };

  const handleStopSaveModal = () => {
    updateDataContext({
      modal: {
        ...modal,
        btnSaveModalClicked: true,
        loadingBtnSave: false,
      },
    });
  };

  const onResetModal = () => {
    onResetActionModal();
    updateDataContext({
      modal: DEFAULT_VALUE_APPCONTEXT.modal,
    });
  };

  const onResetActionModal = () => {
    updateDataContext({
      modal: {
        ...modal,
        btnSaveModalClicked: false,
        loadingBtnSave: false,
      },
    });
  };

  const onChangeTextBtnFooter = ({
    text,
    typeText,
  }: {
    text: string;
    typeText?: "save" | "back";
  }) => {
    updateDataContext({
      modal: {
        ...modal,
        txtBtnSave: typeText === "save" ? text : modal?.txtBtnSave,
        txtBtnBack: typeText === "back" ? text : modal?.txtBtnBack,
      },
    });
  };

  const onCloseModalReset = useCallback(() => {
    const filterData = modal?.modalsAreOpen?.filter(
      (item) => item.displayName !== modal?.displayName
    );

    updateDataContext({
      modal: {
        ...resteModalValue,
        displayName:
          filterData && filterData[filterData.length - 1]?.displayName,
        modalsAreOpen: filterData,
      },
    });
  }, [modal?.modalsAreOpen]);

  const handleComponentsModal = () => {
    switch (modal?.displayName) {
      case "editScreenshot":
        return setmodalComponents({
          HeaderModal: HeaderEditingImageSnapshot,
          BodyModal: EditingImageSnapshot,
        });
      case "chooseBookmark":
        return setmodalComponents({
          HeaderModal: HeaderChooseBookmark,
          BodyModal: ChooseBookmark,
        });
      case "EventStationModal":
        return setmodalComponents({
          HeaderModal: HeaderEventStationModal,
          BodyModal: EventStationModal,
        });

      case "RoomCameraModal":
        return setmodalComponents({
          HeaderModal: HeaderRoomCameraModal,
          BodyModal: RoomCameraModal,
        });

      case "RoomCameraColorModal":
        return setmodalComponents({
          HeaderModal: HeaderRoomCameraColorModal,
          BodyModal: RoomCameraColorModal,
        });

      case "ThemeModal":
        return setmodalComponents({
          HeaderModal: HeaderThemeModal,
          BodyModal: ThemeModal,
        });

      case "CompetenceModal":
        return setmodalComponents({
          HeaderModal: HeaderCompetenceModal,
          BodyModal: CompetenceModal,
        });

      case "CompetenceExtraModal":
        return setmodalComponents({
          HeaderModal: HeaderCompetenceExtraModal,
          BodyModal: CompetenceExtraModal,
        });

      case "uploadConfirmationModal":
        return setmodalComponents({
          BodyModal: UploadConfirmation,
        });

      case "ThemeExtraModal":
        return setmodalComponents({
          HeaderModal: HeaderThemeExtraModal,
          BodyModal: ThemeExtraModal,
        });

      case "AppraisersList":
        return setmodalComponents({
          HeaderModal: HeaderAppraisersListModal,
          BodyModal: AppraisersListModal,
          FooterModal: null,
        });

      case "AppraisersModal":
        return setmodalComponents({
          HeaderModal: HeaderAppraisersModal,
          BodyModal: AppraisersModal,
          FooterModal: null,
        });

      default:
        break;
    }
  };

  useEffect(() => {
    modal?.displayName && handleComponentsModal();
  }, [modal?.displayName]);

  useEffect(() => {
    if (modal?.displayName) {
      const visible = modal?.modalsAreOpen
        ? modal?.modalsAreOpen.filter(
            (item) => item.displayName === modal?.displayName
          ).length > 0
        : false;

      updateDataContext({ modal: { ...modal, visible } });
    }
  }, [modal?.displayName, modal?.modalsAreOpen]);

  useEffect(() => {
    if (!modal?.visible) {
      return onResetModal();
    }
  }, [modal?.visible]);

  return {
    dataModal: modal as ModalProps,
    modalComponents,
    handleOpenModal,
    handleCloseModal,
    handleSaveModal,
    handleChangedModal,
    onCloseModalReset,
    handleStopSaveModal,
    onChangeTextBtnFooter,
    onResetModal,
  };
}
