import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Link, Typography } from "@mui/material";

import { useStationFilesStyles } from "../../events/styles/stationfiles.styles";

import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
export default function ItemFile({ id, name, url }: any) {
  const styles = useStationFilesStyles();

  useEffect(() => {}, [id, name, url]);

  return (
    <Box className={styles.boxFile} sx={{ mr: 4 }}>
      <Link
        className={styles.fontItemFile}
        sx={{ textDecoration: "none" }}
        href={url}
        target="_blank"
      >
        {name.toString().substring(0, 20) + " ..."}
      </Link>
      <Typography className={styles.fontItemFile}>
        <AttachFileOutlinedIcon sx={{ fontSize: "1em", ml: 3 }} />
      </Typography>
    </Box>
  );
}
