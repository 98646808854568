import React, { useState } from "react";
import { Box } from "@mui/system";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import StationsService from "../../services/StationsService";
import EventsService from "../../services/EventsService";
import { useTranslation } from "react-i18next";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { useModal } from "../../common/hooks/useModal";
import TableView from "../../common/components/TableView";

export default function ListStations({ eventId }: any) {
  /**
   * Se não possui o ID do Evento não carrega o componente
   */
  if (!eventId) {
    return <Box />;
  }

  const navigate = useNavigate();
  const { t } = useTranslation();

  const tablesData = [
    {
      field: "name",
      headerName: t("list_event_station_namestation"),
    },
    {
      field: "room_name",
      headerName: t("list_event_station_nameroom"),
    },
    {
      field: "scenario_name",
      headerName: t("list_event_station_namescenario"),
    },
    {
      field: "checklist_name",
      headerName: t("list_event_station_namechecklist"),
    },
    {
      field: "appraiser_name",
      headerName: t("list_event_station_namesapprazers"),
    },
    {
      field: "action",
      headerName: " ",
    },
  ];

  const [stationRemove, setStationRemove] = useState("");

  const [open, setOpen] = React.useState(false);

  const { handleOpenModal, dataModal } = useModal();

  const handleToEdit = (id: number) => {
    //handleOpenModal({ displayName: 'EventStationModal', data: { eventId, idStation: id}})
    navigate(`/events/station/form/${eventId}/${id}`);
  };

  const handleClickOpen = (id: any) => {
    setStationRemove(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [rows, setRows] = React.useState([]);

  const getStationsByEvent = async (eventId) => {
    await EventsService.getStationsByEvent(eventId)
      .then((response: any) => {
        setRows(response.data);
      })
      .catch((e: Error) => {});
  };

  const removeStation = () => {
    StationsService.remove(stationRemove)
      .then((response: any) => {
        setStationRemove("");
        getStationsByEvent(eventId);
        setOpen(false);
      })
      .catch((e: Error) => {});
  };

  React.useEffect(() => {
    getStationsByEvent(eventId);
  }, [dataModal.loadingBtnClosed, dataModal.loadingBtnSave]);

  return (
    <Box sx={{ mt: 4 }}>
      {rows.length > 0 && (
        <TableView
          tableHead={tablesData}
          data={rows}
          onClickEdit={(id) => handleToEdit(id)}
          onClickRemove={(id) => handleClickOpen(id)}
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("title_dialog_delete_station")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("description_dialog_delete_station")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("btn_dialog_no")}</Button>
          <Button onClick={removeStation} autoFocus>
            {t("btn_dialog_yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
