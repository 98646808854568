import { AppContextStateProps, DefaultValueProps } from "./types";



export const DEFAULT_VALUE_APPCONTEXT : AppContextStateProps = {
  modal: {
    btnCloseModalClicked: null,
    btnSaveModalClicked: null,
    showBtnClose: true,
    showBtnSave: true,
    data: null,
    displayName: null,
    showFooter: true,
    showHeader: true,
    visible: false,
    loadingBtnClosed: false,
    loadingBtnSave: false,
    modalsAreOpen: [],
    txtBtnBack: 'Voltar',
    txtBtnSave: 'Salvar'
  },
  debriefing: {},
  menu: {
    visibleMenu: true,
    menuActive: '',
    submenuActive: false,
    openMenuActive: '',
    menuHover: '',
    menuHoverActive: '',
  }
}

