import { Box, BoxProps, Typography } from "@mui/material";
import React from "react";
import { AppContext } from "../../../../contexts/AppContext";
import { useStyles } from "./styles/index.styles";
import { MenuProps } from "../../../dtos/menuDtos";
import colors from "../../../styles/colors";
import { SubmenuProps } from ".";
import { SubmenuItem } from "./SubmenuItem";
import useLayoutStyles from "../../../hooks/useLayoutStyles";

type Props = BoxProps & {
  data: SubmenuProps[];
  title: string;
  children?: any;
  submenu?: any;
  propsSubmenu?: BoxProps;
};

export function Tooltip({
  data,
  title,
  children,
  submenu,
  propsSubmenu,
  ...rest
}: Props) {
  const {
    state: { menu },
  } = React.useContext(AppContext);

  const classes = useStyles(menu as MenuProps);

  const { positionSideBarClosedTooltip } = useLayoutStyles();

  return (
    <Box
      className={classes.containerTooltip}
      right={positionSideBarClosedTooltip}
      {...rest}
    >
      <Box>
        <Typography
          className={
            submenu ? classes.wrapperTooltip : classes.wrapperTooltipNoSub
          }
          sx={{ fontSize: 16 }}
        >
          {" "}
          {title}{" "}
        </Typography>
      </Box>

      {title !== "Home" && submenu && (
        <Box {...propsSubmenu} className={classes.containerTooltipSubmenus}>
          {" "}
          {submenu}{" "}
        </Box>
      )}
    </Box>
  );
}
