import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UsersService from '../../services/UsersService'
import RolesService from '../../services/RolesService'
import EntitiesService from '../../services/EntitiesService'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import IUsersData from '../../types/Users'
import IRolesData from '../../types/Users'
import IEntityData from '../../types/Entities'
import { useDefaultStyles } from '../../common/styles/default'
import Banner from '../../common/components/banner'
import banner from '../../assets/images/banners/event_form_banner.png'
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined'

import {
  student,
  admin,
  entity_support,
  teacher,
  external_measurer,
} from '../extra-fields'

export default function FormUser() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const styles = useDefaultStyles()

  const [roles, setRoles] = useState<IRolesData[]>([])
  const [entities, setEntities] = useState<IEntityData[]>([])
  const [password, setPassword] = useState('12345678')
  const [profile, setProfile] = useState('')
  const [entity, setEntity] = useState('')
  const [name, setName] = useState('')
  const [cpf, setCpf] = useState('')
  const [rg, setRg] = useState('')
  const [email, setEmail] = useState('')
  const [ra, setRa] = useState('')
  const [fields, setFields] = useState<any[]>([])

  const handleProfileChange = (event: SelectChangeEvent) => {
    setProfile(event.target.value)
    if (event.target.value == '5') {
      setFields(student)
    }
  }

  const handleEntityChange = (event: SelectChangeEvent) => {
    setEntity(event.target.value)
  }

  const arrangeExtraFields = (data: any) => {
    let arrExtra: Array<any> = []
    fields.map((f) => {
      arrExtra.push({
        label: f.name,
        value: data.get(f.name),
      })
    })

    return arrExtra
  }

  const { id } = useParams()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const arrExtra: Array<any> = arrangeExtraFields(data)

    if (name == '') {
      toast(t('toast_msg_formuser_name'), {
        type: 'error',
      })
      return
    }

    if (email == '') {
      toast(t('toast_msg_formuser_email'), {
        type: 'error',
      })
      return
    }

    if (entity == '') {
      toast(t('toast_msg_formuser_entity'), {
        type: 'error',
      })
      return
    }

    if (profile == '') {
      toast(t('toast_msg_formuser_profile'), {
        type: 'error',
      })
      return
    }

    const dataUsers = {
      name: name,
      cpf: cpf,
      rg: rg,
      email: email,
      ra: ra,
      role: profile,
      entity_id: entity,
      extra_fields: JSON.stringify(arrExtra),
      password: password,
      password_confirmation: password,
    }

    if (!id) {
      UsersService.store(dataUsers)
        .then((response:any) => {
          if(response.data?.Error) {
            return toast(response?.data?.Msg, {
              type: 'error',
            })
          }
          toast(response?.data?.Msg, {
            type: 'success',
          })
          // navigate('/users/list')
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: 'error',
          })
        })
    } else {
      UsersService.update(id, dataUsers)
        .then((response: any) => {
          if(response.data?.Error) {
            return toast(response?.data?.Msg, {
              type: 'error',
            })
          }
          toast(response?.data?.Msg, {
            type: 'success',
          })
          // navigate('/users/list')
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: 'error',
          })
        })
    }
  }

  const loadUser = async (id: any) => {
    await UsersService.get(id)
      .then((response: any) => {
        if (response) {
          setName(response.data.name)
          setEmail(response.data.email)
          setCpf(response.data.cpf)
          setRa(response.data.ra)
          setRg(response.data.rg)
          setProfile(response.data.roles[0].id)
          setEntity(response.data.entity_id)
        }
      })
      .catch((e: Error) => {})
  }

  const loadRoles = async () => {
    await RolesService.getAll()
      .then((response: any) => {
        if (response) {
          setRoles(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  const loadEntities = async () => {
    await EntitiesService.getAll()
      .then((response: any) => {
        if (response) {
          setEntities(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  useEffect(() => {
    loadEntities()
    loadRoles()
    if (id) {
      loadUser(id)
    }
  }, [])

  return (
    <Box>
      <Banner
          type="small"
          image={banner}
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <PersonAddOutlinedIcon sx={{ width: '3em', height: '3em' }} />
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                {!id
                  ? t('title_page_form_register_user')
                  : t('title_page_form_register_user_edit')}
              </Typography>
            </Box>
          }
        />
      <Box className={styles.container}>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Box sx={{ mb: 2, width: '50%' }}>
            <TextField
              required
              fullWidth
              id="name"
              name="name"
              label={t('input_label_name')}
              variant="outlined"
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value)
              }}
            />
          </Box>

          <Box sx={{ mb: 2, width: '50%' }}>
            <TextField
              required
              fullWidth
              id="email"
              name="email"
              label={t('input_label_email')}
              variant="outlined"
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value)
              }}
            />
          </Box>

          <Box sx={{ mb: 2, width: '50%' }}>
            <FormControl fullWidth>
              <InputLabel id="entities-label">
                {t('input_label_entity')}
              </InputLabel>
              <Select
                labelId="entities-label"
                id="entities"
                label={t('input_label_entity')}
                value={entity}
                onChange={handleEntityChange}
              >
                {entities.map((row) => (
                  <MenuItem value={row.id} key={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mb: 2, width: '50%' }}>
            <FormControl fullWidth>
              <InputLabel id="roles-label">{t('input_label_profile')}</InputLabel>
              <Select
                labelId="roles-label"
                label={t('input_label_profile')}
                value={profile}
                onChange={handleProfileChange}
              >
                {roles.map((row) => (
                  <MenuItem value={row.id} key={row.id}>
                  {t(row.name)}
                  </MenuItem>
                ))}
                  
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mb: 2, width: '50%' }}>
            <TextField
              fullWidth
              id="cpf"
              name="cpf"
              label={t('input_label_document1')}
              variant="outlined"
              value={cpf}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCpf(event.target.value)
              }}
            />
          </Box>

          <Box sx={{ mb: 2, width: '50%' }}>
            <TextField
              fullWidth
              id="rg"
              name="rg"
              label={t('input_label_document2')}
              variant="outlined"
              value={rg}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRg(event.target.value)
              }}
            />
          </Box>

          <Box sx={{ mb: 2, width: '50%' }}>
            <TextField
              fullWidth
              id="ra"
              name="ra"
              label={t('input_label_registration')}
              variant="outlined"
              value={ra}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRa(event.target.value)
              }}
            />
          </Box>

          {profile == '5' &&
            fields.map((field, index) => (
              <Box sx={{ mb: 2, width: '50%' }} key={index}>
                <TextField
                  fullWidth
                  id="outlined-read-only-input"
                  label={field.label}
                  type={field.type}
                  name={field.name}
                  defaultValue={field.value}
                />
              </Box>
            ))}

          <Box sx={{ mb: 2, width: '50%' }}>
            <TextField
              fullWidth
              id="outlined-read-only-input"
              label={t("title_label_password")}
              type="password"
              defaultValue={password}
              value={password}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Button type="submit" variant="contained" size="large" sx={{ ml: 2 }}>
            {' '}
            {t('btn_save')}
          </Button>

          <Button
            variant="contained"
            size="large"
            color="secondary"
            sx={{ ml: 2 }}
            onClick={() => {
              navigate('/users/list/')
            }}
          >
            {' '}
            {t('btn_return')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
