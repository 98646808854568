import api from '../config/api'
import IAttachmentData from '../types/Attachment'

const upload = (data: IAttachmentData) => {
  let formData = new FormData()

  formData.append('feature', data.feature) //scenarios
  formData.append('object_id', data.object_id) //scenario_id
  formData.append('field_name', data.field_name) //file_necessary_items
  formData.append('file', data.file)
  formData.append('file_type', data.file_type) //document
  formData.append('multiple', data.multiple) //false

  return api.post('attachments', formData)
}

const get = (feature, object_id, field_name) => {
  return api.get(`/attachments/${feature}/${object_id}/${field_name}`)
}

const remove = (id: any) => {
  return api.delete(`/attachments/${id}`)
}

const AttachmentService = {
  upload,
  get,
  remove,
}

export default AttachmentService
