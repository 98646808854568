import React, { useEffect } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import { useStyles } from "./styles/index.styles";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../hooks/useModal";
import FormStations, {
  IFormStations,
} from "../../../../events/components/FormStations";
import AvatarHiplade from "../../avatarhiplade";
import { Stack } from "@mui/material";
import colors from "../../../styles/colors";

export function AppraisersListModal() {
  const classes = useStyles();

  const { t } = useTranslation();

  const { dataModal, onCloseModalReset, handleChangedModal } = useModal();

  useEffect(() => {
    if (dataModal.loadingBtnClosed) {
      onCloseModalReset();
    }
  }, [dataModal.btnCloseModalClicked]);

  return (
    <Box className={classes.containerBody}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          color: colors.purpleAvatarName,
        }}
      >
        <AvatarHiplade
          nameUser={"Lucas Teacher UIUX 2"}
          id={2}
          size="small"
          only
        />
        <span>{t("span_avatar_hiplade_user")}</span>
      </Stack>
    </Box>
  );
}
