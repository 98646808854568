import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useStyles } from "./styles/index.styles";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../hooks/useModal";

import ExtraTheme from "../../../../themes/components/ExtraTheme";

export function ThemeExtraModal() {
  const classes = useStyles();

  const { t } = useTranslation();

  const { dataModal, onCloseModalReset, handleChangedModal } = useModal();

  useEffect(() => {
    if (dataModal.loadingBtnClosed) {
      onCloseModalReset();
    }
  }, [dataModal.btnCloseModalClicked]);

  return (
    <Box className={classes.containerBody}>
      <ExtraTheme />
    </Box>
  );
}
