import { Box, BoxProps, Icon, Typography } from "@mui/material";
import React from "react";
import colors from "../../../styles/colors";
import { useStyles } from "./styles/index.styles";
import { AppContext } from "../../../../contexts/AppContext";
import { MenuProps } from "../../../dtos/menuDtos";
import { SubmenuItem } from "./SubmenuItem";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useMenu } from "../../../hooks/useMenu";
import { Tooltip } from "./Tooltip";

export type SubmenuProps = {
  title: string;
  link: string;
};

interface ItemMenuProps extends BoxProps {
  title: string;
  onClickArrowMenu: () => void;
  isOpenMenu: boolean;
  isHomeMenu: boolean;
  submenus: SubmenuProps[];
  iconName: string;
  profileId: any;
  idMenu: any;
}

export default function ItemMenu({
  iconName,
  title,
  isOpenMenu,
  submenus,
  isHomeMenu,
  profileId,
  idMenu,
  onClickArrowMenu,
  onClick,
  ...rest
}: ItemMenuProps) {
  const {
    state: { menu },
    updateDataContext,
  } = React.useContext(AppContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const classes = useStyles(menu as MenuProps);

  const { t } = useTranslation();

  const { handelNavigateSubmenu } = useMenu();

  const handleBgColor = () => {
    if (
      isHomeMenu &&
      (menu?.menuActive === title || menu?.openMenuActive === title)
    ) {
      return colors.purpleDarkMedium;
    }

    return "transparent";
  };

  const handleMouseLeaveSubmenu = () => {
    updateDataContext({
      menu: { ...menu, menuHoverActive: "", menuHover: "" } as MenuProps,
    });
  };

  const filterSubmenus = () => {
    const allowedSubmenus = {
      2: ["Perfis", "Guia rápido"],
      3: ["Perfis", "Guia rápido", "Entidade", "Usuários"],
      5: [
        "Planejar",
        "Entidade",
        "Cadastro de Turmas",
        "Perfis",
        "Guia rápido",
        "Cadastro de Salas",
        "Usuários",
      ],
      6: ["Perfis", "Guia rápido", "Entidade", "Usuários"],
    };

    return submenus.filter((submenu) => {
      const allowedTitles = allowedSubmenus[profileId];
      if (!allowedTitles) return true; // Se o profileId não estiver no objeto, mostra todos os submenus
      return !allowedTitles.includes(t(submenu.title));
    });
  };

  const handleSubmenuSelected = (pathSubmenu: string): boolean => {
    const handleSplitSlice = (link: string) => {
      const splitPathSubmenu = link.split("/").slice(1);
      return splitPathSubmenu[splitPathSubmenu[0] == "events" ? 1 : 0];
    };

    const linkSubmenu = handleSplitSlice(pathSubmenu);
    const linkPathname = handleSplitSlice(pathname);

    return pathSubmenu.split("/").slice(1)[1] === "schedule"
      ? pathSubmenu === pathname
      : linkSubmenu === linkPathname;
  };

  return (
    <Box
      sx={{
        backgroundColor:
          localStorage.getItem("menu") == idMenu
            ? colors.purpleDarkMedium
            : "transparent",
      }}
      className={classes.container}
    >
      <Box
        className={classes.content}
        sx={{
          borderBottom:
            menu?.visibleMenu && isHomeMenu && isOpenMenu
              ? `1px solid ${colors.grayTitlePoints}`
              : "",
          backgroundColor: handleBgColor(),
        }}
        onMouseEnter={() =>
          !menu?.visibleMenu &&
          updateDataContext({
            menu: { ...menu, menuHover: title } as MenuProps,
          })
        }
        onMouseLeave={() =>
          !menu?.visibleMenu &&
          menu?.menuHover !== title &&
          updateDataContext({ menu: { ...menu, menuHover: "" } as MenuProps })
        }
        {...rest}
      >
        {iconName && (
          <Icon className={classes.Icon} sx={{ fontSize: 28 }}>
            {" "}
            {iconName}{" "}
          </Icon>
        )}

        {menu?.visibleMenu && (
          <>
            <Box className={classes.wrapper}>
              <Typography
                onClick={onClick}
                sx={{ ":hover": { opacity: 0.7 } }}
                className={classes.titleMenu}
              >
                {" "}
                {title}{" "}
              </Typography>
              {!isHomeMenu && submenus && (
                <Icon
                  onClick={onClickArrowMenu}
                  className={classes.iconArrow}
                  sx={{
                    color: isOpenMenu
                      ? colors.grayLight
                      : colors.grayTitlePoints,
                    ":hover": { opacity: 0.6 },
                  }}
                >
                  {isOpenMenu ? "keyboard_arrow_down" : "keyboard_arrow_left"}
                </Icon>
              )}
            </Box>
          </>
        )}
        {!menu?.visibleMenu && menu?.menuHover === title && (
          <Tooltip
            title={title}
            data={submenus}
            submenu={
              submenus &&
              filterSubmenus().map((submenu) => (
                <SubmenuItem
                  key={submenu.link}
                  titleSubmenu={t(submenu.title)}
                  submenuSelected={submenu.link === pathname}
                  onClick={() => handelNavigateSubmenu(submenu.link, title)}
                />
              ))
            }
            propsSubmenu={{
              onMouseLeave: handleMouseLeaveSubmenu,
            }}
          />
        )}
      </Box>
      {menu?.visibleMenu && isOpenMenu && (
        <Box className={classes.containerSubmenu}>
          {submenus &&
            filterSubmenus().map((submenu) => (
              <SubmenuItem
                key={submenu.link}
                titleSubmenu={t(submenu.title)}
                submenuSelected={handleSubmenuSelected(submenu.link)}
                onClick={() => handelNavigateSubmenu(submenu.link, title)}
              />
            ))}
        </Box>
      )}
    </Box>
  );
}
