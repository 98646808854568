import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../common/styles/colors'
import fonts from '../../common/styles/fonts'

export const useScenarioStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerPage: {
      overflowX: 'hidden',
    },
    container: {
      marginLeft: '100px',
      position: 'relative',
      marginTop: 50,
      width: '92%',
    },
    titleNumber: {
      color: colors.purpleMediumSub,
      fontSize: '3em !important',
      marginRight: '10px! important',
    },
    title: {
      color: colors.blueDarkest,
      fontSize: '2.625em !important',
      marginLeft: '10px! important',
    },
    titleScenario: {
      color: colors.blueDarkest,
      fontSize: '2.625em !important',
      marginLeft: '10px! important',
      textTransform: 'uppercase',
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      cursor: 'pointer',
    },
    description: {
      color: colors.blackMedium,
    },
    divider: {
      backgroundColor: colors.lilacMediumLight,
      width: '100% !important',
    },
    backgroundItem: {
      backgroundColor: colors.lilacMedium,
    },
    boxCard: {
      border: '1px solid ' + colors.lilacMediumLight,
      padding: 20,
      marginTop: 20,
      minHeight: '200px',
    },
    containerScenario: {
      marginLeft: '100px !important',
      position: 'relative',
      marginTop: '50px !important',
    },
    boxScenarioRegister: {
      backgroundColor: colors.lilacMedium,
      marginTop: '50px',
      width: '100%',
      minHeight: '650px',
    },
    boxItemRegister: {
      color: colors.blackMedium,
      border: '1px solid ' + colors.lilacMediumLight,
      padding: '10px',
      marginTop: '20px !important',
    },
  }),
)
