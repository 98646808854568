import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { ClassNames } from '@emotion/react'
import { useStyles } from './styles/index.styles'
import { useModal } from '../../../hooks/useModal'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'


export const HeaderEventStationModal = () => {

  const classes = useStyles()
  const { dataModal } = useModal()

  const { t } = useTranslation()
  
  return (
    <Box
      className={classes.containerHeader}
    >
      <Typography className={classes.headerTitle}>
        {dataModal.data
          ? t('title_page_form_register_station_edit')  
          : t('title_page_form_register_station')
        }
      </Typography>
    </Box>
  )
}
