import api from "../config/api";

const store = (data) => {
  return api.post("debriefing/skillconsiderations", data);
};

const getConsideration = (debriefingId, skillId) => {
  return api.get(
    `debriefing/${debriefingId}/skill/${skillId}/skillconsiderations`
  );
};

const update = (id, data) => {
  return api.put("debriefing/consideration/" + id, data);
};

const DebriefingConsiderationsService = {
  store,
  update,
  getConsideration,
};

export default DebriefingConsiderationsService;
