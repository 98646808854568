import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import UsersService from '../../services/UsersService'
import StationsService from '../../services/StationsService'
import ScenarioService from '../../services/ScenarioService'
import EventScenarioView from '../../events/components/EventScenarioView'

export default function Feedback() {
  const { eventId, stationId, evaluatedId, debriefingId } = useParams()
  const [student, setStudent] = useState({} as any)
  const [station, setStation] = useState({} as any)
  const [scenario, setScenario] = useState('')
  const navigate = useNavigate()

  const loadStudent = async (evaluatedId: any) => {
    await UsersService.get(evaluatedId)
      .then((response: any) => {
        if (response) {
          setStudent(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  const loadStation = async (stationId: any) => {
    await StationsService.get(stationId)
      .then((response: any) => {
        if (response) {
          setStation(response.data)
          loadScenario(response.data.scenario_id)
        }
      })
      .catch((e: Error) => {})
  }

  const loadScenario = async (scenarioId: any) => {
    await ScenarioService.getScenarioById(scenarioId)
      .then((response: any) => {
        if (response) {
          setScenario(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  const backEvent = () => {
    navigate('/myevents/view/' + eventId)
  }

  useEffect(() => {
    loadStudent(evaluatedId)
    loadStation(stationId)
  }, [])

  return (
    <EventScenarioView 
      student={student}
      eventId={eventId}
      stationId={stationId}
      evaluatedId={evaluatedId}
      station={station}
      scenario={scenario}
      debriefingId={debriefingId}
      bannerOnBack={backEvent}
    />
  )
}
