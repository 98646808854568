import React, { useEffect } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import LayersClearOutlinedIcon from "@mui/icons-material/LayersClearOutlined";
import { useStyles } from "./styles/index.styles";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../hooks/useModal";

import FormCameraColor from "../../../../rooms/components/FormCameraColor";

export function RoomCameraColorModal() {
  const classes = useStyles();

  const { t } = useTranslation();

  const { dataModal, onCloseModalReset, handleChangedModal } = useModal();

  useEffect(() => {
    if (dataModal.loadingBtnClosed) {
      onCloseModalReset();
    }
  }, [dataModal.btnCloseModalClicked]);

  return (
    <Box className={classes.containerBody}>
      <FormCameraColor />
    </Box>
  );
}
