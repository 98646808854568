import * as React from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import { useHomeStyles } from "../styles/home.styles";
import CardQuickGuide from "./CardQuickGuide";
import Link from "@mui/material/Link";

import CardHomeTeacherLink from "../components/CardHomeTeacherLink";
import CardTeacherLink from "../components/CardTeacherLink";

import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import colors from "../../common/styles/colors";
import { Grid } from "@mui/material";
import DoctorWoman from "../../assets/images/img_card_teacher_02.png";
import QuickGuide from "../../assets/images/img_card_guia.png";
import AttachmentService from "../../services/AttachmentService";

export default function HomeAreaCoordinator() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const classes = useHomeStyles();

  const localPermissions = secureLocalStorage
    .getItem("permissions")
    ?.toString();
  const permissions = localPermissions?.split(",");

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const [attachment, setAttachment] = useState([] as any);
  const [url, setUrl] = useState("");

  const feature = "quickguide";
  const idGuide = 2; //id do perfil coordenador, porque o manual é o mesmo p/ adm
  const name = "manual_hiplade_coordinator"; // nome de campo do perfil coodenador, porque o manual é o mesmo p/ adm

  const getFile = async () => {
    await AttachmentService.get(feature, idGuide, name)
      .then((response) => {
        setAttachment(response.data);
        if (response.data.length > 0) {
          setUrl(response.data[0].url);
        }
      })
      .catch((e: any) => {});
  };

  useEffect(() => {
    if (idGuide) {
      getFile();
    }
  }, []);
  return (
    <Box className={classes.Box}>
      <Box className={classes.boxHomeCreateItens}>
        <Grid className={classes.cardImage}>
          <Link href={url} target="_blank">
            <CardQuickGuide
              title={t("btn_quick_guide")}
              subtitle={t("subtitle_quick_guide")}
              icon={
                <FindInPageOutlinedIcon sx={{ color: "#fff", fontSize: 40 }} />
              }
              image={QuickGuide}
            />
          </Link>
        </Grid>
        <Grid className={classes.cardImage}>
          <CardHomeTeacherLink
            title={t("btn_prepare_events")}
            subtitle={t("subtitle_prepare_events")}
            path="/events/area"
            icon={
              <AnalyticsOutlinedIcon
                sx={{ color: colors.purpleIcon, fontSize: 40 }}
              />
            }
            image={DoctorWoman}
          />
        </Grid>
      </Box>

      <Box className={classes.boxHomeCreateItens}>
        <Grid className={classes.cardText}>
          <CardTeacherLink
            title={t("btn_create_new_events")}
            subtitle={t("subtitle_create_new_events")}
            path="/events/form"
            icon={
              <AddBoxOutlinedIcon
                sx={{ color: colors.purpleIcon, fontSize: 40 }}
              />
            }
          />
        </Grid>
        <Grid className={classes.cardText}>
          <CardTeacherLink
            title={t("btn_ongoing_events")}
            subtitle={t("subtitle_ongoing_events")}
            path="/events/schedule/2"
            icon={
              <VideocamOutlinedIcon
                sx={{ color: colors.purpleIcon, fontSize: 40 }}
              />
            }
          />
        </Grid>
        <Grid className={classes.cardTextSchedule}>
          {permissions?.includes("read_event") && (
            <CardTeacherLink
              title={t("btn_student_schedule")}
              subtitle={t("subtitle_student_schedule")}
              path="/events/schedule"
              icon={
                <TodayOutlinedIcon
                  sx={{ color: colors.purpleIcon, fontSize: 40 }}
                />
              }
              date="10/10/2023"
              status_id="1"
              index="1"
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
}
