import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import AvatarHiplade from "../../../common/components/avatarhiplade";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useDefaultStyles } from "../../../common/styles/default";
import { Grid } from "@mui/material";
import StudentsGroupService from "../../../services/StudentsGroupService";
import TitleForm from "../../../common/components/titleform";
import EventStudentList from "./EventStudentList";

export default function EventStationList({ event }: any) {
  const navigate = useNavigate();

  const styles = useDefaultStyles();

  const { t } = useTranslation();

  useEffect(() => {}, [event]);

  return (
    <Box>
      <TitleForm title={t("title_list_event_dataai")} />
      <Typography sx={{ mb: 5 }}>{t("subtitle_list_event_dataai")}</Typography>
      {event?.stations?.map((station, index) => (
        <EventStudentList key={index} station={station} eventId={event.id} />
      ))}
    </Box>
  );
}
