import React from 'react'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import IconCardAdd from './IconCardAdd'
import { Typography } from '@mui/material'

export default function CardAddItem({ title, path }: any) {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const classes = useStyles()

  const handleNavigate = (path: any) => {
    navigate(path)
  }

  return (
    <Box className={classes.cardItemAdd} onClick={() => handleNavigate(path)}>
      <Box className={classes.boxIconAdd}>
        <IconCardAdd />
      </Box>
      <Box className={classes.boxTextCardAdd}>
        <Typography
          className={classes.textCardItemAdd}
          sx={{ fontWeight: 700 }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  )
}
