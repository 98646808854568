import { createTheme } from '@mui/material/styles'
import colors from '../styles/colors'
import fonts from '../styles/fonts'

const theme = createTheme({
  palette: {
    primary: {
      main: colors.purplePrimary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.white,
      contrastText: colors.purpleSecondary,
    },
  },
  typography: {
    fontFamily: `"${fonts.barlow}", "${fonts.inter}"`,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: colors.purpleMedium,
            fontFamily: fonts.interComponent,
          },
          '& label.Mui-focused': {
            color: colors.purpleMedium,
            fontFamily: fonts.interComponent,
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#3E68A8',
            fontFamily: fonts.interComponent,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: colors.purpleMedium,
              fontFamily: fonts.interComponent,
            },
            '&:hover fieldset': {
              borderColor: colors.purpleMedium,
              fontFamily: fonts.interComponent,
              borderWidth: '0.15rem',
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.purpleMedium,
              fontFamily: fonts.interComponent,
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontSize: '1rem',
          fontFamily: fonts.interComponent,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          fontFamily: fonts.interComponent,
          '&:hover': {
            backgroundColor: colors.white,
            fontFamily: fonts.interComponent,
            '@media (hover: none)': {
              backgroundColor: colors.white,
              fontFamily: fonts.interComponent,
            },
          },
          '&.Mui-focused': {
            backgroundColor: colors.white,
            fontFamily: fonts.interComponent,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: fonts.interComponent,
          fontWeight: 500,
        },
      },
    },
  },
})

export default theme
