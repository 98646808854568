import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CheckListService from "../../services/CheckListService";

import TitleForm from "../../common/components/titleform";
import CardSkill from "./CardSkill";
import { useStyles } from "../styles";
import { Grid } from "@material-ui/core";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";

export default function FormGeneralSkills({ idChecklist, idTheme }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const classes = useStyles();

  const [arrSkills, setArrSkills] = useState([] as any);

  const addSkill = useCallback(() => {
    let arrskill = arrSkills;
    const data = {
      skill_id: "",
      name: "",
      points: 100,
      passing_score: "",
      itens: [],
    };
    arrskill.push(data);
    setArrSkills(arrskill);

    setArrSkills((arr: any) => {
      return arr.map((item: any, index: any) => {
        return item;
      });
    });
  }, [arrSkills]);

  const removeSkillReg = useCallback(
    (index: any) => {
      let arrskill = arrSkills;
      arrskill.splice(index, 1);
      setArrSkills(arrskill);

      setArrSkills((arr: any) => {
        return arr.map((item: any, index: any) => {
          return item;
        });
      });
    },
    [arrSkills]
  );

  const loadSkillsChecklist = async (id: any, hasInsert = 0) => {
    await CheckListService.getSkills(id)
      .then((response: any) => {
        if (response) {
          let arrC = response.data;

          let arr = arrSkills;
          let arrEmpty: any = [];

          if (arr.length > 0) {
            for (let j = 0; j < arr.length; j++) {
              if (arr[j].skill_id == "") {
                arrEmpty.push(arr[j]);
              }
            }
          }

          if (
            arrEmpty != "" &&
            arrEmpty != undefined &&
            arrEmpty.length > 1 &&
            hasInsert == 1
          ) {
            for (let i = 0; i < arrEmpty.length - 1; i++) {
              arrC.push(arrEmpty[i]);
            }
          }

          setArrSkills(arrC);
          setArrSkills((arr: any) => {
            return arr.map((item: any, index: any) => {
              return item;
            });
          });
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (idChecklist) {
      loadSkillsChecklist(idChecklist);
    }
  }, [idChecklist, idTheme]);

  return (
    <Box>
      <Box className={classes.subContainerSkillItem}>
        <TitleForm title={t("instructions_checklist_title")} />
        <Box>
          <Typography
            sx={{
              color: "colors.blueDarkest",
              fontSize: "1.800em !important",
              fontWeight: "bold",
            }}
          >
            {/*{t("instructions_checklist_subtitle")}*/}
          </Typography>
          <Typography>
            {t("instructions_checklist")}
            <strong>{t("instructions_checklist_bold")}</strong>.
          </Typography>{" "}
          <br />
          <Typography>
            {t("instructions_checklist_first_paragraph")}
            <strong>{t("instructions_checklist_paragraph_bold")}</strong>
            {t("instructions_checklist_second_paragraph")}
          </Typography>
        </Box>
        <Box
          sx={{
            borderRadius: "4px",
            backgroundColor: "#B5D537",
            boxShadow: "0px 4px 15px 0px rgba(136, 165, 21, 0.36)",
            padding: "30px",
            mb: 8,
            mt: 3,
          }}
        >
          <Typography
            sx={{
              color: "#310AA2",
              fontSize: "18px !important",
              fontWeight: "bold",
            }}
          >
            {t("instructions_checklist_points")}
          </Typography>
          <Typography>
            {t("instructions_checklist_points_paragraph")}
            {/*<strong>{t("instructions_checklist_points_bold")}</strong>.*/}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#E3DCFA !important",
          padding: "30px",
          justifyContent: "center",
          mb: 2,
          mt: 14,
          paddingLeft: "150px",
          paddingRight: "150px",
          width: "100%", // Define a largura como 100% da largura da tela
        }}
      >
        <TitleForm title={t("title_skills_checklist")} />
      </Box>

      {arrSkills.map((skill, index) => (
        <CardSkill
          index={index}
          itemSkill={skill}
          key={index}
          idChecklist={idChecklist}
          loadSkillsChecklist={loadSkillsChecklist}
          removeSkillReg={removeSkillReg}
          arrSkills={arrSkills}
          idTheme={idTheme}
        />
      ))}

      <Box
        sx={{ textAlign: "right", mt: 10 }}
        className={classes.subContainerSkillItem}
      >
        <Button
          variant="contained"
          sx={{ mb: 5 }}
          onClick={addSkill}
          size="small"
        >
          {t("button_add_skill")}
        </Button>
      </Box>
    </Box>
  );
}
