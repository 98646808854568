import React, { useCallback, useRef, useEffect } from "react";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import EventsService from "../../services/EventsService";
import { useStyles } from "../../events/styles/index.styles";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { AvatarGroup, Button, Divider, Grid } from "@mui/material";
import IStationData from "../../types/Station";
import GetObjectAttribute from "../../common/components/services/GetObjectAttribute";
import StationStudentsList from "./StationStudentsList";
import "moment/locale/pt-br";
import AvatarHiplade from "../../common/components/avatarhiplade";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import Switch from "@mui/material/Switch";
import colors from "../../common/styles/colors";
import { useObserver } from "../hooks/useObserver";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../common/hooks/useModal";

export default function EventStationsView({ event, status }: any) {
  /**
   * Se não possui o ID do Evento não carrega o componente
   */
  if (!event) {
    return <Box />;
  }

  const styles = useStyles();
  const { t } = useTranslation();
  const [stationList, setStationList] = React.useState<IStationData[]>([]);

  const navigate = useNavigate();

  const {
    getObserverStatusByStation,
    startObserver,
    stopObserver,
    observerStartState,
    observerStopState,
    observerStatusState,
  } = useObserver();

  const { handleOpenModal, dataModal, onResetModal } = useModal();

  const [checked, setChecked] = React.useState<any>("false");
  const [countToCallObserver, setCountToCallObserver] =
    React.useState<number>(0);

  function stringToBoolean(value: string) {
    if (typeof value === "string") {
      const lowerCaseValue = value?.toLowerCase();
      return lowerCaseValue === "true"
        ? true
        : lowerCaseValue === "false"
        ? false
        : Boolean(value);
    }
    return value;
  }

  const getStationsByEvent = async (eventId) => {
    await EventsService.getStationsByEvent(eventId)
      .then((response: any) => {
        let stationsByEvent = response.data;
        setStationList(stationsByEvent);
      })
      .catch((e: Error) => {});
  };

  const openMeeting = (idStation: any) => {
    navigate("/events/meeting/" + event?.id + "/" + idStation);
  };

  const handleObserver = useCallback(async () => {
    getObserverStatusByStation(event.stations[0].id);
  }, [observerStatusState?.success]);

  const handleChangeChecked = useCallback(
    async (eventTarget: React.ChangeEvent<HTMLInputElement>) => {
      setCountToCallObserver(0);
      const targetChecked = eventTarget.target.checked;
      setChecked(targetChecked);

      if (!targetChecked) {
        await stopObserver({
          station_id: event.stations[0].id,
        });
        handleObserver();
      } else {
        await startObserver({
          event_id: event.id,
          station_id: event.stations[0].id,
        });
        handleObserver();
      }
    },
    [observerStatusState.data?.active, checked]
  );

  useEffect(() => {
    getStationsByEvent(event.id);
    handleObserver();
    setCountToCallObserver(1);
  }, []);

  useEffect(() => {
    if (countToCallObserver === 1 && !observerStatusState.loading) {
      const state = observerStatusState.data?.active;
      setChecked(state);
    }
  }, [
    countToCallObserver,
    observerStatusState.success,
    observerStatusState.data?.active,
    checked,
    observerStatusState.loading,
  ]);

  return (
    <Box className={styles.container}>
      <Typography
        variant="h5"
        component="div"
        className={styles.titleCard}
        sx={{ fontWeight: 700 }}
      >
        {t("title_page_form_station_management")}
      </Typography>
      <Divider className={styles.dividerStation} />

      {status !== 'concluded' && <Stack
        className={styles.eventBoxListMonitoringRoomWithoutBG}
        sx={{ mt: 4 }}
        direction="row"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Box>
            <SwitchAccountIcon sx={{ color: colors.purple }} fontSize="large" />
          </Box>
        </Grid>
        <Stack direction="row">
          <Typography variant="body1" className={styles.titleStudent}>
            <strong>{t("title_strong_event_stations_view")}</strong>
            {t("description_dialog_event_stations_view")} 
          </Typography>
        </Stack>
        <Stack direction="row" alignItems={"center"}>
          <Typography fontWeight={600}>
            {observerStartState.loading
              ? "carregando o status..."
              : observerStatusState.data?.status}
          </Typography>
          <Switch
            checked={stringToBoolean(checked)}
            onChange={handleChangeChecked}
            inputProps={{ "aria-label": "controlled" }}
            color="success"
          />
        </Stack>
      </Stack>}

      {stationList.map((station, index) => (
        <Box key={station.id} sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <Typography
                className={styles.eventStationTitle}
                display="inline-block"
                sx={{ fontWeight: "bold" }}
              >
                {station.name + " / "}
              </Typography>

              <Typography
                className={styles.eventStationSubTitle}
                display="inline-block"
              >
                <GetObjectAttribute
                  foreignKey={station.scenario_id}
                  apiGet="scenarios"
                  attributeName="name"
                />
              </Typography>

              <Typography
                className={styles.eventStationSubTitle}
                display="inline-block"
              >
                / {station?.room_name}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mb: 5 }}>
            <Divider />
          </Box>

          <Grid item xs={2} mb={5} className={styles.boxTitleStation} sx={{justifyContent: 'flex-start'}}>
            <Stack sx={{flexDirection: 'row', cursor: 'pointer'}} onClick={() => handleOpenModal({displayName: 'AppraisersList'})}> 
              <Stack sx={{flexDirection: 'row'}}>
                <AvatarHiplade
                  nameUser={station.appraiser_name}
                  id={station.appraiser_id}
                  size="small"
                  only
                />
                <Box sx={{position: 'relative'}}>
                  <Box sx={{position: 'absolute', left: '-30px'}}>
                    <AvatarHiplade
                      nameUser={station.appraiser_name}
                      id={station.appraiser_id}
                      size="small"
                      only
                    />
                  </Box>
                </Box>
              </Stack>
              <Stack sx={{flexDirection: 'row', mt: 1, ml: 3, gap:1}}>
                <Box
                  className={styles.appraiserName}
                >
                  {station.appraiser_name},
                </Box>
                <Box
                  className={styles.appraiserName}
                >
                  {station.appraiser_name}
                </Box>
                ...
                <b className={styles.appraiserName}>e outros</b>
              </Stack>
            </Stack>    
          </Grid>

          {status !== 'concluded' && <Box>
            <Grid
              className={styles.eventBoxListMeeting}
              sx={{ mt: 4 }}
              container
            >
              <Grid xs={1}>
                <ContactPhoneOutlinedIcon
                  sx={{ color: colors.purple }}
                  fontSize="large"
                />
              </Grid>
              <Grid xs={8}>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "left" }}
                  className={styles.titleMetting}
                >
                  <strong>{t("title_strong_event_stations_view_meeting")}</strong>
                  {t("description_dialog_event_stations_view_meeting")}{" "}
                  <strong>{t("second_title_strong_event_stations_view_meeting")}</strong>
                  {t("second_description_dialog_event_stations_view_meeting")}
                </Typography>
              </Grid>
              <Grid alignItems={"center"} xs={3} sx={{ textAlign: "right" }}>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ ml: 2 }}
                  className={styles.buttonMeeting}
                  onClick={() => openMeeting(station?.id)}
                >
                  {t("btn_enter")}
                </Button>
              </Grid>
            </Grid>
          </Box>}

          <StationStudentsList
            studentsgroupId={event.studentsgroup_id}
            eventId={event.id}
            stationId={station.id}
            status={status}
            station={station}
          />
        </Box>
      ))}
    </Box>
  );
}
