import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles/index.styles'
import { Divider, Grid, Link, TextField, Typography } from '@mui/material'
import { useScenarioStyles } from '../styles/scenarios.styles'
import AttachmentService from '../../services/AttachmentService'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'
import ItemFileExam from './ItemFileExam'

export default function ItemInstructionFileMulti({
  name,
  title,
  idScenario,
  feature,
}: any) {
  const { t } = useTranslation()
  const classes = useStyles()
  const styleScenario = useScenarioStyles()

  const [attachment, setAttachment] = useState([] as any)

  const uploadFile = async (e) => {
    const data = {
      feature: feature,
      object_id: idScenario,
      field_name: name,
      file: e.target.files[0],
      file_type: 'document',
      multiple: 'true',
    }

    await AttachmentService.upload(data)
      .then((response) => {
        getFile()
        toast(response.data.Msg, {
          type: 'success',
        })
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: 'error',
        })
      })
  }

  const getFile = async () => {
    await AttachmentService.get(feature, idScenario, name)
      .then((response) => {
        setAttachment(response.data)
      })
      .catch((e: any) => {})
  }

  const removeFile = async (id: any) => {
    await AttachmentService.remove(id)
      .then((response) => {
        getFile()
        toast(response.data.Msg, {
          type: 'success',
        })
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: 'error',
        })
      })
  }

  useEffect(() => {
    if (idScenario) {
      getFile()
    }
  }, [name, title, idScenario, feature])

  return (
    <Box className={styleScenario.boxExam}>
      <Box>
        <Typography
          className={styleScenario.titleExam}
          sx={{ fontWeight: 'bold' }}
        >
          {title}
        </Typography>
      </Box>
      <Divider className={styleScenario.divider} />
      <Grid container spacing={2} className={styleScenario.gridFiles}>
        <Grid item xs={10}>
          <Box className={styleScenario.boxListFilesExams}>
            {attachment.length > 0 &&
              attachment.map((row, index) => (
                <ItemFileExam
                  key={index}
                  name={row?.original_name}
                  removeFile={removeFile}
                  id={row?.id}
                  url={row?.url}
                />
              ))}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <input
              id={name}
              name={name}
              style={{ display: 'none' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files != null) {
                  uploadFile(event)
                }
              }}
              type="file"
              accept="application/pdf"
            />
            <label htmlFor={name}>
              <Typography
                sx={{ fontWeight: 'bold' }}
                className={styleScenario.buttonUploadExam}
              >
                {t("title_add_exam")}
                <AddIcon sx={{ fontSize: '18px', ml: 1, fontWeight: 'bold' }} />
              </Typography>
            </label>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
