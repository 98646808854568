import api from "../config/api";
import IThemeData from "../types/Themes";

const store = (data: any) => {
  return api.post<IThemeData>("/themes", data);
};

const getAll = (page = 1, paginate = 0) => {
  return api.get<IThemeData>("/themes?page=" + page + "&paginate=" + paginate);
};

const get = (id: number) => {
  return api.get<IThemeData>("/themes/" + id);
};

const update = (id: any, data: any) => {
  return api.put<IThemeData>("/themes/" + id, data);
};

const remove = (id: any) => {
  return api.delete("/themes/" + id);
};

const relationships = (id: number) => {
  return api.get(`/themes/${id}/relationships`);
};

const ThemesService = {
  store,
  getAll,
  get,
  update,
  remove,
  relationships,
};

export default ThemesService;
