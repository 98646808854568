import React, { useEffect, useState, useRef } from 'react'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMonitoringRoom } from '../../styles/monitoring-room.styles'
import ItemVideoLive from './ItemVideoLive'
import EventsService from '../../../services/EventsService'

export default function MonitoringRoom({ eventId }: any) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [list, setList] = useState([] as any)

  const loadLiveMonitoring = async (id: any) => {
    await EventsService.getLiveMonitoringByEvent(id)
      .then((response: any) => {
        if (response) {
          setList(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  const classes = useMonitoringRoom()

  useEffect(() => {
    if (eventId) {
      loadLiveMonitoring(eventId)
    }
  }, [eventId])

  return (
    <Box className={classes.boxEventLinks}>
      {list.map((row, index) => (
        <ItemVideoLive
          key={index}
          title={row?.station_name}
          subtitle={row?.scenario_name}
          evaluated_name={row?.evaluated_name}
          path={`/events/live/${eventId}/${row?.station_id}/${row?.evaluated_id}/${row?.debriefing_id}`}
          video={row?.streaming_url}
        />
      ))}
    </Box>
  )
}
