import api from "../config/api";

const store = (data: any) => {
  return api.post("/streamings", data);
};

const stop = (streamingId: any) => {
  return api.post(`/streamings/${streamingId}/stop`);
};

const meeting = (stationId: any) => {
  return api.post(`createmeeting/station/${stationId}`);
};

const StreamingService = {
  store,
  stop,
  meeting,
};

export default StreamingService;
