import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useScenarioStyles } from "../styles/scenarios.styles";
import ScenarioTitleView from "./ScenarioTitleView";
import { Grid } from "@mui/material";
import { envir } from "../../scenarios/const-values";

export default function ScenarioRegisterView({
  complexity,
  sex,
  time_duration,
  age,
  environment,
}: any) {
  const { t } = useTranslation();
  const scenarioStyle = useScenarioStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Box
          className={scenarioStyle.containerScenario}
          sx={{ marginBottom: 10 }}
        >
          <ScenarioTitleView index="02" title={t("title_dialog_scenario_registration")} />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box className={scenarioStyle.boxItemRegister}>
                <strong>{t("strong_dialog_complexity")}</strong>{" "}
                {complexity == "B"
                  ? t("title_dialog_low_complexity")
                  : complexity == "M"
                  ? t("title_dialog_medium_complexity")
                  : t("title_dialog_high_complexity")}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={scenarioStyle.boxItemRegister}>
                <strong>{t("strong_dialog_type_sex")}</strong>{" "}
                {sex == "O" ? t("title_dialog_others") : sex == "F" ? t("title_dialog_female") : t("title_dialog_male")}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box className={scenarioStyle.boxItemRegister}>
                <strong>{t("strong_dialog_duration_time")}</strong> {time_duration}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={scenarioStyle.boxItemRegister}>
                <strong>{t("strong_dialog_simulated_packge_age")}</strong> {age}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box className={scenarioStyle.boxItemRegister}>
                <strong>{t("strong_dialog_environment")}</strong> {envir[environment - 1]?.label}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
