import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import TableHead from '@mui/material/TableHead'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import moment from 'moment'
import 'moment/locale/pt-br'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import secureLocalStorage from 'react-secure-storage'

import IEventDataList from '../../types/Event'
import EventsService from '../../services/EventsService'

import Banner from '../../common/components/banner'
import event_banner from '../../assets/images/banners/banner_create_event.png'

import { useDefaultStyles } from '../../common/styles/default'

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

export default function List() {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const styles = useDefaultStyles()

  const handleAdd = () => {
    navigate('/events/form')
  }

  const paginate = 1
  const firstpage = 1

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [event, setEvent] = useState<IEventDataList>()

  const [eventRemove, setEventRemove] = useState('')

  const [open, setOpen] = React.useState(false)

  const localPermissions = secureLocalStorage.getItem('permissions')?.toString()
  const permissions = localPermissions?.split(',')

  const handleClickOpen = (id: any) => {
    setEventRemove(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
    const npage = newPage + 1

    setPage(newPage)
    loadEvent(npage, paginate)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {}

  const loadEvent = async (page: number, paginate: number) => {
    await EventsService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setEvent(response.data)
        }
      })
      .catch((e: Error) => {})
  }

  const removeEvent = () => {
    EventsService.remove(eventRemove)
      .then((response: any) => {
        setEventRemove('')
        loadEvent(firstpage, paginate)
        setOpen(false)
      })
      .catch((e: Error) => {})
  }

  const editEvent = (id: number) => {
    navigate('/events/form/' + id)
  }

  useEffect(() => {
    loadEvent(firstpage, paginate)
  }, [])

  return (
    <Box>
      <Banner
        type="medium"
        image={event_banner}
        title={t('banner_title_event')}
        subtitle={t('banner_subtitle_event_list')}
      />
      <Box className={styles.containerForm}>
        {permissions?.includes('create_checklist') && (
          <Box sx={{ mb: 2, width: '10%' }}>
            <Button variant="contained" onClick={handleAdd}>
              {t('btn_add')}
            </Button>
          </Box>
        )}
        <TableContainer component={Paper} sx={{ mt: 5 }}>
          <Table sx={{ minWidth: 600 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', fontSize: 20 }}>
                  {t('subtitle_table_event_name')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: 20 }}>
                  {t('subtitle_table_event_date')}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: 20 }}>
                  {t('table_label_button')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {event?.data?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(row.date).locale('pt-br').format('DD/MM/YYYY')}
                  </TableCell>

                  <TableCell>
                    {permissions?.includes('update_checklist') && (
                      <EditIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => editEvent(row.id)}
                      />
                    )}
                    {permissions?.includes('delete_event') && (
                      <DeleteIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleClickOpen(row.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {event && event?.total > rowsPerPage && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={7}
                    count={event?.total}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={t('table_label_number_per_page')}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('title_dialog_delete_event')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('description_dialog_delete_event')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('btn_dialog_no')}</Button>
          <Button onClick={removeEvent} autoFocus>
            {t('btn_dialog_yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
