import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Grid, Typography } from "@mui/material";
import GraphStudentSkillGrade from "./GraphStudentSkillGrade";
import GraphStudentGrade from "./GraphStudentGrade";
import GraphStudentStationGrade from "./GraphStudentStationGrade";
import AvatarHiplade from "../../common/components/avatarhiplade";
import { useStyles } from "../styles/index.styles";
import UsersService from "../../services/UsersService";
import { t } from "i18next";

export default function StudentGraph({ event, student_id, closeGraph, showIconClose=true }: any) {
  const styles = useStyles();
  const [name, setName] = useState("");

  const loadUser = async (id: any) => {
    await UsersService.get(id)
      .then((response: any) => {
        if (response) {
          setName(response.data.name);
        }
      })
      .catch((e: Error) => {});
  };
  useEffect(() => {
    if (student_id) {
      loadUser(student_id);
    }
  }, [event, student_id]);

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={11}>
          <Grid container spacing={0}>
            <Grid item sm={1}>
              <AvatarHiplade nameUser={name} size="small" id={student_id} />
            </Grid>
            <Grid item sm={11}>
              <Typography variant="h5">
                <b>{t("lbl_graph_student")}:</b> {name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!showIconClose || (
          <Grid item xs={1} className={styles.closeGraph}>
            <CloseOutlinedIcon sx={{ fontSize: "2.2em" }} onClick={closeGraph} />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <GraphStudentGrade event={event} student_id={student_id} />
        <GraphStudentStationGrade event={event} student_id={student_id} />
      </Grid>
      <GraphStudentSkillGrade event={event} student_id={student_id} />
    </Box>
  );
}
