import React, { useState, useEffect } from 'react'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import { useStyles } from './styles/index.styles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined'
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import AvatarHiplade from '../avatarhiplade'

import Breadcrumbs from '../breadcrumbs'
import { AppContext } from '../../../contexts/AppContext'
import useLayoutStyles from '../../hooks/useLayoutStyles'
import { useTheme } from '@mui/material/styles' // Importação necessária para acessar os breakpoints
import Menu from '../menu'
//import logo from './path/to/logo_horizontal.png'; // Importe o logo
import logo_horizontal from "../../../assets/images/logo_horizontal.png"


export default function Header({ showMenu }: any) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state: { menu } } = React.useContext(AppContext);
  const nameUser = secureLocalStorage.getItem('name')
  const auth_user_id = secureLocalStorage.getItem('id')
  const avatar_url = secureLocalStorage.getItem('avatar_url')
  const [openMenuMobile, setOpenMenuMobile] = useState(false)
  


  const navigate = useNavigate()

  const { mlLayout } = useLayoutStyles()
  const theme = useTheme() // Usando o hook useTheme para acessar os breakpoints

  return (
    <Box className={classes.containerHeader}>
      <CssBaseline />
      <Box
        className={classes.boxContainer}
        ml={mlLayout+"px"}
        // sx={{
        //   marginLeft: `${menu?.visibleMenu ? '270px' : '130px'}`,
        // }}
        sx={{
              [theme.breakpoints.between(0, 600)]: {  
                marginLeft: 2,
              },
              [theme.breakpoints.between(600, 900)]: {  
                marginLeft: 4,
              },
              [theme.breakpoints.between(900, 1200)]: {  
                marginLeft: 5,
              }
            }}
      >
        <Box className={classes.boxMenuOutlinedIcon}>
          {openMenuMobile ? (
            <CloseOutlinedIcon
              sx={{ mr: 4, fontSize: 36, cursor: 'pointer', display: { xs: 'block', lg: 'none' } }}
              className={classes.iconArrowColor}
              onClick={() => setOpenMenuMobile(false)}
            />
          ) : (
            <MenuOutlinedIcon
              sx={{ mr: 4, fontSize: 36, cursor: 'pointer', display: { xs: 'block', lg: 'none' } }}
              className={classes.iconArrowColor}
              onClick={() => setOpenMenuMobile(true)}
            />
            
          )}
            <SearchOutlinedIcon
              sx={{ mr: 4, fontSize: 32, cursor: 'pointer', display: { xs: 'block', lg: 'none' } }}
              className={classes.iconArrowColor}
              //onClick={() => setOpenMenuMobile(true)}
            />
            <NotificationsOutlinedIcon
              sx={{ mr: 4, fontSize: 32, cursor: 'pointer', display: { xs: 'block', lg: 'none' } }}
              className={classes.iconArrowColor}
              //onClick={() => setOpenMenuMobile(true)}
            />
        </Box>
        <Box className={classes.boxBreadCrumb} sx={{ 
          [theme.breakpoints.down('lg')]: {
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '90%',
            },
            [theme.breakpoints.up('lg')]: {
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '94%',
            }
          }}>
          <ArrowBackIosOutlinedIcon
            sx={{ mr: 4, fontSize: 24, cursor: 'pointer', display: { xs: 'none', lg: 'block' } }} // Ocultar entre xs e lg
            className={classes.iconArrowColor}
            onClick={() => navigate(-1)}
          />
          <ArrowForwardIosOutlinedIcon
            sx={{ mr: 4, fontSize: 24, cursor: 'pointer', display: { xs: 'none', lg: 'block' } }} // Ocultar entre xs e lg
            className={classes.iconArrowColor}
            onClick={() => navigate(1)}
          />
          <Breadcrumbs navigate={navigate} />
        </Box>

        <Box className={classes.boxAvatar} sx={{ display: { xs: 'none', lg: 'flex' } }}>
          {/* <Box className={classes.boxSearch}>
            <SearchOutlinedIcon sx={{ mr: 2, fontSize: 40 }} />
            <TextField
              margin="normal"
              name="search"
              label={t('input_search')}
              type="text"
              size="small"
              className={classes.textSearch}
            />
          </Box> */}
          {/* <HelpCenterOutlinedIcon sx={{ mr: 2, fontSize: 40 }} />
          <NotificationsOutlinedIcon sx={{ mr: 2, fontSize: 40 }} /> */}
          <Link href="/users/profile" sx={{ textDecoration: 'none' }}>
            <AvatarHiplade nameUser={nameUser} size='small' id={auth_user_id} source={avatar_url} />
          </Link>
        </Box>
      </Box>
      <Box
        component="img"
        src={logo_horizontal}
        alt="Logo"
        sx={{
          display: { xs: 'block', lg: 'none' }, // Mostrar logo entre xs e lg
          width: '100px', // Ajuste o tamanho conforme necessário
          margin: 'auto', // Centraliza horizontalmente
          mr: 2,
        }}
      />
      {openMenuMobile && (
        <Box sx={{
          position: 'absolute',
          width: '100vw',
          height: '95vh',
          zIndex: 10,
          top: "100%",
          //backgroundColor: "green"
        }}>
          <Menu showMenu={true} />
        </Box>
      )}
    </Box>
  )
}
