const inter = 'Inter'
const barlow = 'Barlow'
const barlowComponent = 'Barlow !important'
const interComponent = 'Inter !important'

const fonts = {
  inter,
  barlow,
  interComponent,
  barlowComponent,
}

export default fonts
