import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/system";
import { useDebriefingVideoLiveStyles } from "../../styles/debriefing-video-live";
import { Button, Typography } from "@mui/material";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import DebriefingService from "../../../services/DebriefingService";
import StreamingService from "../../../services/StreamingService";
import { useTranslation } from "react-i18next";

export default function DebriefingVideoMeetingLive({
  debriefingId,
  stationId,
}: any) {
  const stylesDebriefing = useDebriefingVideoLiveStyles();
  const { t } = useTranslation();
  const [video, setVideo] = useState("");
  const [meeting, setMeeting] = useState("");

  const loadStreaming = async (debriefingId: any) => {
    await DebriefingService.getDebriefingLiveUrl(debriefingId)
      .then((response: any) => {
        if (response) {
          setVideo(response.data.recording_url);
        }
      })
      .catch((e: Error) => {});
  };

  const loadMeeting = async (stationId: any) => {
    await StreamingService.meeting(stationId)
      .then((response: any) => {
        if (response.data.meeting_url) {
          console.log(response);
          setMeeting(response.data.meeting_url);

          if (
            response.data.streamings_urls &&
            response.data.streamings_urls.length > 0
          ) {
            setVideo(response.data.streamings_urls[0]);
          }
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    if (debriefingId) {
      loadStreaming(debriefingId);
    }

    if (stationId) {
      loadMeeting(stationId);
    }
  }, [debriefingId, stationId]);

  return (
    <Box>
      <Box className={stylesDebriefing.playerMeeting}>
        <Box className={stylesDebriefing.videoBar}>
          <Box className={stylesDebriefing.boxTitleVideo}>
            <VideocamOutlinedIcon
              sx={{ fontSize: "40px" }}
              className={stylesDebriefing.cameraIconVideo}
            />{" "}
            <Typography
              sx={{ fontSize: "bold", ml: 2 }}
              className={stylesDebriefing.titleCameraVideo}
            >
              <Button variant="contained" size="medium" color="error">
                {t("bt_live")}
              </Button>
            </Typography>
          </Box>
        </Box>
        <Box className={!video ? stylesDebriefing.backgroundVideo : ""}>
          <iframe
            src={video}
            style={{
              position: "relative",
              width: "100%",
              maxWidth: "100%",
              aspectRatio: "1280/720",
              border: "none",
              marginTop: "12px",
            }}
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Box>
        <Box className={stylesDebriefing.boxVideoFooter}></Box>
      </Box>
      <Box sx={{ mt: 10, mb: 5 }}>
        {meeting && (
          <iframe
            src={meeting}
            style={{
              position: "relative",
              width: "100%",
              maxWidth: "100%",
              aspectRatio: "1280/720",
              border: "none",
              marginTop: "12px",
            }}
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        )}
      </Box>
    </Box>
  );
}
