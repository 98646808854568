import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";
import {
  IMarkerProps,
  IMarker,
  IUpdaeDrawByMarker,
  ILinkMarkers,
} from "../../../types/Markers";
import MarkersService from "../../../services/MarkersService";
import { toast } from "react-toastify";
import { useModal } from "../../../common/hooks/useModal";
import { useTranslation } from "react-i18next";

type IStoreMarker = {
  loading: boolean;
  success: boolean;
  error: boolean;
  data: IMarker | boolean;
};

type IMarkerState = {
  data: IMarker[];
  loading: boolean;
  success: boolean;
  error: boolean;
};

const initialStateStoreMarker: IStoreMarker = {
  error: false,
  loading: false,
  success: false,
  data: false,
};

const initialStateMarker: IMarkerState = {
  error: false,
  loading: false,
  success: false,
  data: [],
};

export const useMarkers = () => {
  const { debriefingId } = useParams();

  const { dataModal } = useModal();

  const { t } = useTranslation();

  const [storeMarkerData, setStoreMarkerData] = useState<IStoreMarker>(
    initialStateStoreMarker
  );
  const [deleteMarkerState, setDeleteMarkerState] = useState<IStoreMarker>(
    initialStateStoreMarker
  );
  const [markerState, setMarkerState] =
    useState<IMarkerState>(initialStateMarker);
  const [drawByMarkerState, setDrawByMarkerState] =
    useState<IMarkerState>(initialStateMarker);
  const [updateDrawByMarkerState, setUpdateDrawByMarkerState] =
    useState<IMarkerState>(initialStateMarker);
  const [markerData, setMarkerData] = useState<IMarker[] | boolean>(false);
  const [drawMarkerData, setDrawMarkerData] = useState<IMarker>({});
  const [linkMarkersState, setLinkMarkersState] =
    useState<IMarkerState>(initialStateMarker);

  const [markers, setMarkers] = useState([] as any);

  const toastError = (msg?: any) => {
    toast(msg ?? t("toast_dialog_error"), {
      type: "error",
    });
  };

  const setMarkerByDebriefing = async (data: IMarkerProps) => {
    if (debriefingId) {
      setStoreMarkerData({ ...initialStateStoreMarker, loading: true });
      try {
        const response = await MarkersService.setMarkerByDebriefing({
          ...data,
          debriefing_id: parseInt(debriefingId),
        });

        setStoreMarkerData({
          ...initialStateStoreMarker,
          success: true,
          data: response?.data?.marker,
        });

        toast(response?.data?.Msg, {
          type: "success",
        });
        getMarkerByDebriefing(debriefingId, data.video_id);
      } catch (error) {
        console.log("Error: ", error);
        setStoreMarkerData({
          ...initialStateStoreMarker,
          error: true,
          data: storeMarkerData.data,
        });
        toastError();
      }
    }
  };

  const getMarkerByDebriefing = async (
    idDebriefing?: string,
    idVideo?: string
  ) => {
    const id = debriefingId ?? idDebriefing;
    if (id) {
      setMarkerState({ ...markerState, loading: true });
      try {
        const response = await MarkersService.getMarkerByDebriefing(
          id,
          idVideo
        );

        setMarkerData(response?.data);
        setMarkerState({ ...markerState, success: true });
      } catch (error) {
        console.log("Error: ", error);
        setMarkerState({ ...markerState, error: true });
        // toastError()
      } finally {
        setMarkerState({ ...markerState, loading: false });
      }
    }
  };

  const getMarkerByDebriefingItem = async (
    idDebriefing?: string,
    itemId?: string
  ) => {
    const id = debriefingId ?? idDebriefing;
    if (id && itemId) {
      setMarkerState({ ...markerState, loading: true });
      try {
        const response = await MarkersService.getMarkerByDebriefingItem(
          id,
          itemId
        );

        setMarkerData(response?.data);
        setMarkerState({ ...markerState, success: true });
      } catch (error) {
        console.log("Error: ", error);
        setMarkerState({ ...markerState, error: true });
        // toastError()
      } finally {
        setMarkerState({ ...markerState, loading: false });
      }
    }
  };

  const getDrawByMarker = async (markerId: number) => {
    if (markerId) {
      setDrawByMarkerState({ ...initialStateMarker, loading: true });
      try {
        const response = await MarkersService.getDrawByMarker(markerId);

        setDrawMarkerData(response?.data);
        setDrawByMarkerState({
          ...initialStateMarker,
          success: true,
          data: response?.data,
        });
      } catch (error) {
        console.log("Error: ", error);
        setDrawByMarkerState({
          ...drawByMarkerState,
          error: true,
          success: false,
        });
        // toastError()
      }
    }
  };

  const updateDrawByMarker = async (
    markerId: number,
    data: IUpdaeDrawByMarker
  ) => {
    if (markerId) {
      setUpdateDrawByMarkerState({ ...initialStateMarker, loading: true });
      try {
        const response = await MarkersService.updateDrawByMarker(
          markerId,
          data
        );

        setUpdateDrawByMarkerState({ ...initialStateMarker, success: true });

        toast(response.data.Msg, {
          type: "success",
        });
      } catch (error) {
        console.log("Error: ", error);
        setUpdateDrawByMarkerState({ ...initialStateMarker, error: true });
        // toastError()
      }
    }
  };

  const setLinkmarkers = async (data: ILinkMarkers) => {
    setLinkMarkersState({ ...initialStateMarker, loading: true });
    try {
      const response = await MarkersService.linkmarkers(data);

      setLinkMarkersState({
        ...initialStateMarker,
        success: true,
        data: response?.data,
      });

      toast(response?.data?.Msg, {
        type: "success",
      });
    } catch (error) {
      console.log("Error: ", error);
      setLinkMarkersState({
        ...initialStateMarker,
        error: true,
        data: linkMarkersState.data,
      });
      toastError();
    }
  };

  const deleteMarkerByDebriefing = async (markerId: number) => {
    try {
      setDeleteMarkerState({ ...initialStateStoreMarker, loading: true });

      await MarkersService.deleteMarkerByDebriefing(markerId);

      setDeleteMarkerState({ ...initialStateStoreMarker, success: true });
      toast(t("marker_exclude"), {
        type: "success",
      });
    } catch (error) {
      console.log("Error: ", error);
      setDeleteMarkerState({ ...initialStateStoreMarker, error: true });
      // toastError()
    }
  };

  const handleFormatTime = (time: string | number | undefined) => {
    return typeof time === "number"
      ? moment.utc(time * 1000).format("HH:mm:ss")
      : time;
  };

  const getDebriefingCommentMarkers = async (
    debriefingId: any,
    itemId: any
  ) => {
    setMarkers([]);
    const response = await MarkersService.getMarkerByItem(debriefingId, itemId);
    setMarkers(response?.data);
  };

  useEffect(() => {
    //getMarkerByDebriefing();
  }, [dataModal.visible]);

  return {
    storeMarkerData,
    markerData,
    markerState,
    markers,
    deleteMarkerState,
    updateDrawByMarkerState,
    drawByMarkerState,
    drawMarkerData,
    linkMarkersState,
    setMarkerByDebriefing,
    getMarkerByDebriefing,
    handleFormatTime,
    deleteMarkerByDebriefing,
    getDrawByMarker,
    updateDrawByMarker,
    setLinkmarkers,
    getMarkerByDebriefingItem,
    getDebriefingCommentMarkers,
  };
};
